import React from 'react';

import { UUID } from 'crypto';

import Button from 'components/interface/button';
import TemplateSelector from 'features/templates/components/templateSelector';

import { type Template } from 'features/templates/types/templateTypes';

import './styles.css';

type TemplateSelectStepProps = {
  chatbotId: UUID;
  integrationId: string;
  canNext: boolean;
  onNext: () => void;
  onTemplateChange: (templates: Template[]) => void;
};

const TemplateSelectStep: React.FC<TemplateSelectStepProps> = ({
  chatbotId,
  integrationId,
  canNext = false,
  onNext = () => {},
  onTemplateChange = (templates: Template[]) => {},
}) => {
  return (
    <>
      <div className={'template-select-step-scroll'} style={{ width: 480 }}>
        <TemplateSelector
          chatbotId={chatbotId}
          integrationId={Number(integrationId)}
          selectables={1}
          enableLoading={true}
          onSelectedTemplatesChange={(st) => onTemplateChange(st)}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button label={'Siguiente'} isDisabled={!canNext} onClick={onNext} />
      </div>
    </>
  );
};

export default TemplateSelectStep;
