import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Text from 'components/interface/text';
import Input from 'components/interface/input';
import Button from 'components/interface/button';

import { useUser } from 'features/user/hooks/useUser';

type Inputs = {
  email: string;
  password: string;
  vPassword: string;
};

const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const { createAccount } = useUser();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await createAccount(
      data.email,
      data.password,
      data.vPassword,
    );

    if (response?.errors) {
      if (response.errors?.email) {
        setError('email', {
          type: 'manual',
          message: response.errors?.email,
        });
      }
      if (response.errors?.password) {
        setError('password', {
          type: 'manual',
          message: response.errors?.password,
        });
      }
      if (response.errors?.vPassword) {
        setError('vPassword', {
          type: 'manual',
          message: response.errors?.vPassword,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
      }
    } else {
      toast.success(
        '¡Cuenta creada exitosamente! Revisa tu correo electrónico',
      );
      reset();
      navigate('/auth/login', { replace: true });
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant={'label'}>Continúa con tu correo electrónico</Text>
      <Input
        type={'email'}
        placeholder={'Email'}
        error={errors?.email?.message}
        {...register('email')}
      />
      <Input
        type={'password'}
        placeholder={'Password'}
        error={errors?.password?.message}
        {...register('password')}
      />
      <Input
        type={'password'}
        placeholder={'Confirm password'}
        error={errors?.vPassword?.message}
        {...register('vPassword')}
      />
      <Button type={'submit'} label={'Crear cuenta'} isLoading={isLoading} />
    </form>
  );
};

export default SignUpForm;
