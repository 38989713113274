import React, {
  FC,
  createContext,
  useReducer,
  useMemo,
  useEffect,
} from 'react';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import inboxReducer from './inboxReducer';

import {
  type Inbox,
  type InboxFilters,
  type InboxStatus,
  type InboxPreferences,
} from '../types/inboxTypes';

export type InboxState = {
  inbox: Inbox;
  inboxFilters: InboxFilters;
  inboxStatus: InboxStatus;
  inboxPreferences: InboxPreferences;
  fetchingThreads: boolean;
  saveInbox: (inbox: Inbox) => void;
  saveInboxFilters: (filters: InboxFilters) => void;
  saveInboxStatus: (status: InboxStatus) => void;
  saveInboxPreferences: (preferences: InboxPreferences) => void;
  saveFetchingThreads: (fetching: boolean) => void;
};

type InboxProviderProps = {
  children: React.ReactNode;
};

export const InboxContext = createContext<InboxState>({
  inbox: {} as Inbox,
  inboxFilters: {} as InboxFilters,
  inboxStatus: {} as InboxStatus,
  inboxPreferences: {} as InboxPreferences,
  fetchingThreads: false,
  saveInbox: () => {},
  saveInboxFilters: () => {},
  saveInboxStatus: () => {},
  saveInboxPreferences: () => {},
  saveFetchingThreads: () => {},
});

const InboxProvider: FC<InboxProviderProps> = ({ children }) => {
  const { chatbotsData } = useChatbot();

  const initialState = {
    inbox: {
      threads: {},
      activeThread: null,
      page: 0,
      hasMore: true,
    },
    inboxFilters: {
      selectedChatbots: [],
      selectedTags: [],
      selectedChannels: ['whatsapp', 'phonecall', 'api'],
      orderBy: 'desc',
      showUnreplied: false,
      showUnreadOnly: false,
      showAssignedOnly: true,
      search: '',
    },
    inboxStatus: {
      webSocketConnected: false,
      webSocketConnecting: true,
    },
    inboxPreferences: {
      detailsExpanded: true,
    },
    fetchingThreads: false,
    saveInbox: (inbox: Inbox) => {
      return null;
    },
    saveInboxFilters: (filters: InboxFilters) => {
      return null;
    },
    saveInboxStatus: (status: InboxStatus) => {
      return null;
    },
    saveInboxPreferences: (preferences: InboxPreferences) => {
      return null;
    },
    saveFetchingThreads: (fetching: boolean) => {
      return null;
    },
  };

  const [state, dispatch] = useReducer(inboxReducer, initialState);

  const saveInbox = (inbox: Inbox) => {
    dispatch({ type: 'SAVE_INBOX', payload: inbox });
  };

  const saveInboxFilters = (filters: InboxFilters) => {
    dispatch({ type: 'SAVE_INBOX_FILTERS', payload: filters });
  };

  const saveInboxStatus = (status: InboxStatus) => {
    dispatch({ type: 'SAVE_INBOX_STATUS', payload: status });
  };

  const saveInboxPreferences = (preferences: InboxPreferences) => {
    dispatch({ type: 'SAVE_INBOX_PREFERENCES', payload: preferences });
  };

  const saveFetchingThreads = (fetching: boolean) => {
    dispatch({ type: 'SAVE_FETCHING_THREADS', payload: fetching });
  };

  useEffect(() => {
    if (!chatbotsData) return;

    if (state.inboxFilters?.selectedChatbots.length === 0) {
      // TODO: Add localStorage check for filter preferences
      const inboxFiltersAux = { ...state.inboxFilters };
      inboxFiltersAux.selectedChatbots = Object.keys(chatbotsData);
      saveInboxFilters(inboxFiltersAux);
    }
  }, [chatbotsData]);

  const contextValue = useMemo(() => {
    return {
      inbox: state.inbox,
      inboxFilters: state.inboxFilters,
      inboxStatus: state.inboxStatus,
      inboxPreferences: state.inboxPreferences,
      fetchingThreads: state.fetchingThreads,
      saveInbox,
      saveInboxFilters,
      saveInboxStatus,
      saveInboxPreferences,
      saveFetchingThreads,
    };
  }, [
    state.inbox,
    state.inboxFilters,
    state.inboxStatus,
    state.inboxPreferences,
    state.fetchingThreads,
  ]);

  return (
    <InboxContext.Provider value={contextValue}>
      {children}
    </InboxContext.Provider>
  );
};

export default InboxProvider;
