import React, { FC, createContext, useReducer, useMemo } from 'react';

import userReducer from './userReducer';

export type UserData = {
  id: string;
  email: string;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  firstName: string;
  lastName: string;
  profilePicture: string;
  organization: string;
};

export type UserDisplayData = {
  id: string;
  email: string;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  displayName: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
  role: string;
};

export type UserPermissions = {
  [key: string]: { [key: string]: boolean | { [key: string]: boolean } };
};

export type UserNotificationPreferences = {
  [key: string]: boolean;
};

export type UserState = {
  userData: UserData;
  userDisplayData: UserDisplayData;
  userPermissions: UserPermissions;
  userNotificationPreferences: UserNotificationPreferences;
  saveUserData: (userData: UserData) => void;
  saveUserDisplayData: (userDisplayData: UserDisplayData) => void;
  saveUserPermissions: (userPermissions: UserPermissions) => void;
  saveUserNotificationPreferences: (
    userNotificationPreferences: UserNotificationPreferences,
  ) => void;
};

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserContext = createContext<UserState>({
  userData: {
    id: '',
    email: '',
    phoneNumber: '',
    phoneNumberVerified: false,
    firstName: '',
    lastName: '',
    profilePicture: '',
    organization: '',
  },
  userDisplayData: {
    id: '',
    email: '',
    phoneNumber: '',
    phoneNumberVerified: false,
    displayName: '',
    firstName: '',
    lastName: '',
    profilePicture: '',
    role: '',
  },
  userPermissions: {},
  userNotificationPreferences: {},
  saveUserData: () => {},
  saveUserDisplayData: () => {},
  saveUserPermissions: () => {},
  saveUserNotificationPreferences: () => {},
});

const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const initialState: UserState = {
    userData: {
      id: '',
      email: '',
      phoneNumber: '',
      phoneNumberVerified: false,
      firstName: '',
      lastName: '',
      profilePicture: '',
      organization: '',
    },
    userDisplayData: {
      id: '',
      email: '',
      phoneNumber: '',
      phoneNumberVerified: false,
      displayName: '',
      firstName: '',
      lastName: '',
      profilePicture: '',
      role: '',
    },
    userPermissions: {},
    userNotificationPreferences: {},
    saveUserData: (userData: UserData) => {
      return null;
    },
    saveUserDisplayData: (userDisplayData: UserDisplayData) => {
      return null;
    },
    saveUserPermissions: (userPermissions: UserPermissions) => {
      return null;
    },
    saveUserNotificationPreferences: (
      userNotificationPreferences: UserNotificationPreferences,
    ) => {
      return null;
    },
  };

  const [state, dispatch] = useReducer(userReducer, initialState);

  const saveUserData = (userData: UserData) => {
    dispatch({
      type: 'SAVE_USER_DATA',
      payload: userData,
    });
  };

  const saveUserDisplayData = (userDisplayData: UserDisplayData) => {
    dispatch({
      type: 'SAVE_USER_DISPLAY_DATA',
      payload: userDisplayData,
    });
  };

  const saveUserPermissions = (userPermissions: UserPermissions) => {
    dispatch({
      type: 'SAVE_USER_PERMISSIONS',
      payload: userPermissions,
    });
  };

  const saveUserNotificationPreferences = (
    userNotificationPreferences: UserNotificationPreferences,
  ) => {
    dispatch({
      type: 'SAVE_USER_NOTIFICATION_PREFERENCES',
      payload: userNotificationPreferences,
    });
  };

  const contextValue = useMemo(() => {
    return {
      userData: state.userData,
      userDisplayData: state.userDisplayData,
      userPermissions: state.userPermissions,
      userNotificationPreferences: state.userNotificationPreferences,
      saveUserData,
      saveUserDisplayData,
      saveUserPermissions,
      saveUserNotificationPreferences,
    };
  }, [
    state.userData,
    state.userDisplayData,
    state.userPermissions,
    state.userNotificationPreferences,
  ]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
