import React, { useState, useEffect } from 'react';

import { Table } from 'ka-table';
import { DataType, EditingMode, SortingMode } from 'ka-table/enums';

import SettingsFields from 'components/interface/settingsFields';
import RoleCreateButton from 'features/dashboard/features/organization/components/roleCreateButton';
import RoleCreatorTag from 'features/dashboard/features/organization/components/roleCreatorTag';
import RoleActions from 'features/dashboard/features/organization/components/roleActions';
import RolePermissionsGraph from 'features/dashboard/features/organization/components/rolePermissionsGraph';
import RoleDetailsView from './RoleDetailsView';
import EmptyState from 'components/interface/emptyState';

import { useUser } from 'features/user/hooks/useUser';
import { useOrganization } from 'features/organization/hooks/useOrganization';

import 'ka-table/style.css';

const RolesOrganizationView: React.FC = () => {
  const { userHasPermission } = useUser();
  const { organizationData } = useOrganization();
  const [columns, setColumns] = useState<any[]>([]);
  const [openRole, setOpenRole] = useState<string | null>(null);

  useEffect(() => {
    const columns: any[] = [
      {
        key: 'name',
        title: `Rol`,
        dataType: DataType.String,
      },
      {
        key: 'isSystem',
        title: `Tipo`,
        dataType: DataType.Boolean,
      },
      {
        key: 'permissions',
        title: `Permisos`,
        dataType: DataType.Object,
      },
    ];
    if (userHasPermission('organization.update')) {
      columns.push({
        key: 'actions',
        title: '',
        width: '72',
        dataType: DataType.String,
      });
    }
    setColumns(columns);
  }, []);

  useEffect(() => {
    if (openRole && !organizationData.members?.[openRole]) {
      setOpenRole(null);
    }
  }, [organizationData]);

  const RoleTable = () => {
    const organizationRoles: { [key: string]: Object }[] = [];
    Object.keys(organizationData.roles).forEach((key: string) => {
      organizationRoles.push({
        name: organizationData.roles[key].name,
        isSystem: organizationData.roles[key].isSystem,
        permissions: organizationData.roles[key].permissions,
        actions: key,
      });
    });

    return (
      <>
        <Table
          columns={columns}
          data={organizationRoles}
          rowKeyField={'actions'}
          editingMode={EditingMode.None}
          sortingMode={SortingMode.Single}
          childComponents={{
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case 'isSystem':
                    return <RoleCreatorTag isSystem={props.value} />;
                  case 'permissions':
                    return <RolePermissionsGraph permissions={props.value} />;
                  case 'actions':
                    return (
                      <RoleActions id={props.value} callback={setOpenRole} />
                    );
                  default:
                    return props.value;
                }
              },
            },
          }}
        />
        {organizationRoles.length === 0 && (
          <EmptyState text={'No hay roles creados'} iconName={'user'} />
        )}
      </>
    );
  };

  const fields = {
    roles: {
      label: 'Roles y permisos',
      description:
        'Asigna roles predeterminados o personalizados a tus colaboradores para definir sus permisos dentro de la aplicación.',
      options: [<RoleCreateButton />],
      component: <RoleTable />,
    },
  };

  return (
    <>
      {openRole && organizationData.roles[openRole] && (
        <RoleDetailsView
          role={organizationData.roles[openRole]}
          goBack={() => setOpenRole(null)}
        />
      )}
      {!openRole && <SettingsFields fields={fields} />}
    </>
  );
};

export default RolesOrganizationView;
