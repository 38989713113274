import React from 'react';

import TabMenu from 'components/interface/tabMenu';
import PageTitle from 'features/dashboard/components/pageTitle';

import { IconName } from 'components/interface/icon';

import AccountView from '../features/settings/view/AccountView';
import NotificationsView from '../features/settings/view/NotificationsView';
import SecurityView from '../features/settings/view/SecurityView';

const SettingsView: React.FC = () => {
  const tabs = {
    general: {
      label: 'Cuenta',
      iconName: 'user' as IconName,
      component: <AccountView />,
    },
    notifications: {
      label: 'Notificaciones',
      iconName: 'bell' as IconName,
      component: <NotificationsView />,
    },
    security: {
      label: 'Seguridad',
      iconName: 'shield' as IconName,
      component: <SecurityView />,
    },
  };

  return (
    <div className={'dashboard-page'}>
      <PageTitle title={'Configuración'} iconName={'gear'} />
      <TabMenu tabs={tabs} />
    </div>
  );
};

export default SettingsView;
