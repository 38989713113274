import React, { useState, useEffect } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Modal from 'components/interface/modal';
import Button from 'components/interface/button';

import PageTitle from 'features/dashboard/components/pageTitle';
import ChatbotCard from '../features/chatbots/components/chatbotCard';
import CreateChatbotForm from '../features/chatbots/forms/CreateForm';
import EmptyState from 'components/interface/emptyState';

import { useUser } from 'features/user/hooks/useUser';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';

const ChatbotsView: React.FC = () => {
  const { userHasPermission } = useUser();
  const { chatbotsData } = useChatbot();
  const [chatbots, setChatbots] = useState<ChatbotData[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (chatbotsData) {
      setChatbots(Object.values(chatbotsData));
    }
  }, [chatbotsData]);

  return (
    <div className={'dashboard-page'}>
      <PageTitle
        title={'Asistentes'}
        iconName={'robot'}
        button={
          <>
            {userHasPermission('bots.all.create') && (
              <div style={{ marginTop: -16 }}>
                <Button
                  variant={'primary'}
                  label={'Nuevo asistente'}
                  icon={<Icon name={'plus'} size={16} color={'var(--white)'} />}
                  onClick={() => setIsModalOpen(true)}
                />
              </div>
            )}
          </>
        }
      />
      <div className={'chatbot-card-container'}>
        {chatbots.map((chatbot) => (
          <ChatbotCard key={chatbot.id} chatbot={chatbot} />
        ))}
      </div>
      {chatbots.length === 0 && (
        <div style={{ marginTop: 124 }}>
          <EmptyState iconName={'robot'} text={'No hay asistentes creados'} />
        </div>
      )}
      {userHasPermission('bots.all.create') && (
        <Modal
          isOpen={isModalOpen}
          title={'Nuevo Asistente'}
          onClose={() => setIsModalOpen(false)}
        >
          <Text variant={'b2'}>
            Crea un nuevo asistente virtual para tu empresa, por medio de este
            podrás atender a tus clientes de una manera más rápida y eficaz.
          </Text>
          <CreateChatbotForm submitCallback={() => setIsModalOpen(false)} />
        </Modal>
      )}
    </div>
  );
};

export default ChatbotsView;
