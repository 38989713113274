import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Icon from 'components/interface/icon';
import Input from 'components/interface/input';
import Button from 'components/interface/button';
import Select from 'components/interface/select';
import TextArea from 'components/interface/textArea';
import FileInput from 'components/interface/fileInput';
import SettingsFields from 'components/interface/settingsFields';
import TemplateSelector from '../../../../templates/components/templateSelector';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';
import { useCampaign } from 'features/campaigns/hooks/useCampaign';

import { getTimezones } from 'utils/dates';

import { type SettingFields } from 'components/interface/settingsFields';

type FormInputs = {
  name: string;
  description: string;
  tz: string;
  retries: number;
  template: string;
  templateAlt: string;
  templateImage: File;
  firstMessage: string;
  messageLimit: number;
};

type EditCampaignFormProps = {
  campaignId: string;
};

const EditCampaignForm: React.FC<EditCampaignFormProps> = ({ campaignId }) => {
  const navigate = useNavigate();

  const { chatbotsData } = useChatbot();
  const { campaignsData, updateCampaign } = useCampaign();

  const [campaignData] = useState(campaignsData[campaignId]);
  const [integrationDisplay, setIntegrationDisplay] = useState<string>('');
  const [integrationType, setIntegrationType] = useState<string>('');
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([
    campaignsData[campaignId].template,
    campaignsData[campaignId].templateAlt,
  ]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const timezonesArray = getTimezones();
  const timezonesOptions = timezonesArray.map((tz) => ({
    label: tz,
    value: tz,
  }));

  useEffect(() => {
    if (campaignData) {
      if (campaignData.integrationType === 'bots | Integration Whatsapp') {
        setIntegrationType('whatsapp');
      }
      if (campaignData.integrationType === 'bots | Integration Phone Call') {
        setIntegrationType('phonecall');
      }
    }

    if (campaignData?.integrationType === 'bots | Integration Whatsapp') {
      setIntegrationDisplay(
        `WhatsApp | ${
          chatbotsData[campaignData?.botId].whatsappIntegrations[
            campaignData?.integrationId
          ].whatsappNumberId
        }`,
      );
    }
    if (campaignData?.integrationType === 'bots | Integration Phone Call') {
      setIntegrationDisplay(
        `Llamadas | ${
          chatbotsData[campaignData?.botId].phoneCallIntegrations[
            campaignData?.integrationId
          ].phoneNumber
        }`,
      );
    }
  }, [campaignData]);

  const handleEditTemplates = () => {
    window.open(
      'https://business.facebook.com/wa/manage/message-templates/',
      '_blank',
    );
  };

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async (data, event: any) => {
    if (isLoading) return;
    setIsLoading(true);

    // Get form data
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('tz', data.tz);
    formData.append('retries', String(data.retries));
    formData.append('template', selectedTemplates[0]);
    formData.append(
      'templateAlt',
      selectedTemplates.length > 1
        ? selectedTemplates[1]
        : selectedTemplates[0],
    );
    if (event.target.templateImage.files[0]) {
      formData.append('templateImage', event.target.templateImage.files[0]);
    }
    formData.append('firstMessage', data.firstMessage);
    formData.append('messageLimit', String(data.messageLimit));

    // Send request to API
    const response = await updateCampaign(campaignData.id, formData);

    // Manage response errors or success
    if (response?.errors) {
      if (response.errors?.name) {
        setError('name', {
          type: 'manual',
          message: response.errors.name,
        });
      }
      if (response.errors?.description) {
        setError('description', {
          type: 'manual',
          message: response.errors.description,
        });
      }
      if (response.errors?.tz) {
        setError('tz', {
          type: 'manual',
          message: response.errors.tz,
        });
      }
      if (response.errors?.retries) {
        setError('retries', {
          type: 'manual',
          message: response.errors.retries,
        });
      }
      if (response.errors?.templateImage) {
        setError('templateImage', {
          type: 'manual',
          message: response.errors.templateImage,
        });
      }
      if (response.errors?.firstMessage) {
        setError('firstMessage', {
          type: 'manual',
          message: response.errors.firstMessage,
        });
      }
      if (response.errors?.messageLimit) {
        setError('messageLimit', {
          type: 'manual',
          message: response.errors.messageLimit,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar campaña');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Campaña actualizada exitosamente');
      } else {
        toast.error('Error al actualizar campaña');
        reset();
      }
    }
    setIsLoading(false);
  };

  const fields: SettingFields = {
    name: {
      label: 'Nombre',
      description: 'Asigna un nombre corto y descriptivo a la campaña.',
      component: (
        <Input
          placeholder={'Nombre de la campaña'}
          defaultValue={campaignData?.name}
          error={errors.name?.message}
          {...register('name', {
            required: 'This field may not be blank.',
          })}
        />
      ),
    },
    description: {
      label: 'Descripción',
      description:
        'Describe el objetivo de la campaña o cualquier otra información relevante.',
      component: (
        <TextArea
          placeholder={'Descripción de la campaña'}
          defaultValue={campaignData?.description}
          error={errors.description?.message}
          {...register('description', {
            required: 'This field may not be blank.',
          })}
        />
      ),
    },
    botId: {
      label: 'Asistente',
      description:
        'Selecciona el asistente que se utilizará para la campaña. Este asistente debe de tener el contexto e instrucciones necesarias para llevar a cabo las interacciones de la campaña.',
      component: (
        <Input
          placeholder={'Asistente'}
          defaultValue={chatbotsData[campaignData?.botId]?.nickname}
          isDisabled={true}
        />
      ),
    },
    integrationId: {
      label: 'Integración',
      description:
        'Selecciona la integración de la cual se enviarán y recibirán los mensajes para la campaña. Asegúrate de que la integración seleccionada no cuente con restricciones por parte de Meta.',
      component: (
        <Input
          placeholder={'Integración'}
          defaultValue={integrationDisplay}
          isDisabled={true}
        />
      ),
    },
    tz: {
      label: 'Zona horaria',
      description:
        'Selecciona la zona horaria de la campaña, por defecto será la de Ciudad de México, si tus clientes se encuentran en otra zona, asegúrate de modificar el parámetro.',
      component: (
        <Select
          options={timezonesOptions}
          selectedOption={campaignData?.tz}
          error={errors.tz?.message}
          {...register('tz', {
            required: 'This field may not be blank.',
          })}
        />
      ),
    },
    retries: {
      label: 'Reintentos',
      description:
        'Define el número de veces que se intentará enviar un mensaje en caso de que no haya sido posible enviarlo o no haya sido recibido por el cliente.',
      component: (
        <Input
          placeholder={'Numero de reintentos'}
          type={'number'}
          defaultValue={campaignData?.retries}
          minValueValidation={0}
          maxValueValidation={5}
          error={errors.retries?.message}
          {...register('retries', {
            required: 'This field may not be blank.',
          })}
        />
      ),
    },
    templates: {
      label: 'Plantilla(s)',
      description:
        'Selecciona la plantilla que se utilizará para la campaña. Las plantillas contienen el contenido de los mensajes que se enviarán a los clientes. Es posible elegir dos para tener una alternativa.',
      component: (
        <>
          <TemplateSelector
            chatbotId={campaignData?.botId}
            integrationId={Number(campaignData?.integrationId)}
            selectables={2}
            defaultSelectedTemplates={[
              campaignsData[campaignId].template,
              campaignsData[campaignId].templateAlt,
            ]}
            onSelectedTemplatesChange={(st) =>
              setSelectedTemplates(st.map((t) => t.name))
            }
            enableLoading={true}
          />
        </>
      ),
      options: [
        <Button
          label={'Administrar plantillas'}
          variant={'secondary'}
          icon={<Icon name={'cards'} />}
          onClick={handleEditTemplates}
        />,
      ],
      hide: integrationType !== 'whatsapp',
    },
    templateImage: {
      label: 'Imagen de la plantilla',
      description:
        'En caso de que la plantilla seleccionada contenga una imagen, puedes subirla aquí. Esto es un requisito para poder enviar mensajes con imágenes.',
      component: (
        <FileInput
          allowedExtensions={['.png', '.jpg', '.jpeg', '.gif']}
          error={errors.templateImage?.message}
          {...register('templateImage')}
        />
      ),
      hide: integrationType !== 'whatsapp',
    },
    firstMessage: {
      label: 'Mensaje inicial',
      description:
        'Ingresa una frase o mensaje inicial que dirá el asistente al iniciar la conversación con el cliente. Incluye una variable para el nombre del cliente si es necesario.',
      component: (
        <TextArea
          placeholder={'¡Hola {{1}}! Tengo una promoción para ti.'}
          defaultValue={campaignData?.firstMessage}
          error={errors.firstMessage?.message}
          {...register('firstMessage')}
        />
      ),
      hide: integrationType !== 'phonecall',
    },
    messageLimit: {
      label: 'Límite de mensajes',
      description:
        'Ingresa aquí el límite de mensajes diarios con los que cuenta la integración seleccionada o el número de mensajes que se enviarán a diario.',
      component: (
        <Input
          placeholder={'Numero de mensajes'}
          type={'number'}
          defaultValue={campaignData?.messageLimit}
          minValueValidation={100}
          maxValueValidation={10000}
          error={errors.messageLimit?.message}
          {...register('messageLimit')}
        />
      ),
      hide: integrationType !== 'whatsapp',
    },
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: 24 }}
      encType={'multipart/form-data'}
    >
      <SettingsFields fields={fields} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
        <Button
          label={'Cancelar'}
          variant={'secondary'}
          onClick={() =>
            navigate(`/dashboard/campaigns/${campaignId}`, { replace: true })
          }
        />
        <Button
          type={'submit'}
          label={'Guardar'}
          variant={'primary'}
          isDisabled={selectedTemplates.length < 1}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default EditCampaignForm;
