import React from 'react';

import Hr from 'components/interface/hr';
import Text from 'components/interface/text';

import './styles.css';

type CardProps = {
  title?: string;
  subtitle?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  onClick?: () => void;
};

const Card: React.FC<CardProps> = ({
  title,
  subtitle,
  style,
  children,
  onClick,
}) => {
  return (
    <div
      className={`card ${onClick ? 'card-clickable' : ''}`}
      style={style}
      onClick={onClick ? onClick : () => {}}
    >
      {title ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text variant={'h8'}>{title}</Text>
            {subtitle ? (
              <Text variant={'h8'} weight={400} color={'var(--gray)'}>
                {subtitle}
              </Text>
            ) : null}
          </div>
          <Hr marginTop={6} marginBottom={6} />
        </>
      ) : null}
      {children}
    </div>
  );
};

export default Card;
