export const templateStatusTranslations = {
  APPROVED: 'Aprobada',
  IN_APPEAL: 'En apelación',
  PENDING: 'Pendiente',
  REJECTED: 'Rechazada',
  PENDING_DELETION: 'Pendiente de eliminación',
  DELETED: 'Eliminada',
  DISABLED: 'Desactivada',
  PAUSED: 'Pausada',
  LIMIT_EXCEEDED: 'Límite excedido',
};
