import React from 'react';
import { PulseLoader } from 'react-spinners';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import { type IconName } from 'components/interface/icon';

import './styles.css';

type EmptyStateProps = {
  text: string;
  iconName: IconName;
  isLoading?: boolean;
};

/**
 * EmptyState
 * @description Description of EmptyState
 * @param {string} text - Description of text
 * @param {IconName} iconName - Description of iconName
 * @param {boolean} isLoading - Description of isLoading
 * @returns {TSX.Element} EmptyState component
 */

const EmptyState: React.FC<EmptyStateProps> = ({
  text,
  iconName,
  isLoading = false,
}) => {
  return (
    <div className={'empty-state-container'}>
      {isLoading ? (
        <div className={'empty-state-loader'}>
          <PulseLoader color={'var(--gray-2)'} size={8} />
        </div>
      ) : (
        <Icon name={iconName} size={18} color={'var(--gray-2)'} />
      )}
      <Text variant={'b3'} color={'var(--gray-2)'}>
        {isLoading ? 'Cargando...' : text}
      </Text>
    </div>
  );
};

export default EmptyState;
