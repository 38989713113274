import React from 'react';

import Button from 'components/interface/button';
import TemplatePreview from 'features/templates/components/templatePreview';

import {
  type Template,
  type TemplateVariable,
} from 'features/templates/types/templateTypes';

import './styles.css';

type TemplateSendStepProps = {
  template: Template;
  headerVariables: TemplateVariable[];
  bodyVariables: TemplateVariable[];
  isSending: boolean;
  onBack: () => void;
  onSend: () => void;
};

const TemplateSendStep: React.FC<TemplateSendStepProps> = ({
  template,
  headerVariables,
  bodyVariables,
  isSending = false,
  onBack = () => {},
  onSend = () => {},
}) => {
  return (
    <>
      <div className={'template-variables-step-container'}>
        <TemplatePreview
          templateData={template}
          headerVariables={headerVariables}
          bodyVariables={bodyVariables}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button label={'Atrás'} variant={'secondary'} onClick={onBack} />
        <Button label={'Enviar'} isLoading={isSending} onClick={onSend} />
      </div>
    </>
  );
};

export default TemplateSendStep;
