import React from 'react';

import TabMenu from 'components/interface/tabMenu';
import PageTitle from 'features/dashboard/components/pageTitle';

import { IconName } from 'components/interface/icon';

import { useOrganization } from 'features/organization/hooks/useOrganization';

import MembersView from 'features/dashboard/features/organization/views/MembersView';
import InvitesView from 'features/dashboard/features/organization/views/InvitesView';
import RolesView from 'features/dashboard/features/organization/views/RolesView';
import SettingsView from 'features/dashboard/features/organization/views/SettingsView';

const Organization: React.FC = () => {
  const { organizationData } = useOrganization();

  const tabs = {
    members: {
      label: 'Miembros',
      iconName: 'users' as IconName,
      component: <MembersView />,
    },
    invites: {
      label: 'Invitaciones',
      iconName: 'envelopeSimple' as IconName,
      component: <InvitesView />,
    },
    roles: {
      label: 'Roles',
      iconName: 'userGear' as IconName,
      component: <RolesView />,
    },
    settings: {
      label: 'Ajustes',
      iconName: 'gear' as IconName,
      component: <SettingsView />,
    },
  };

  return (
    <div className={'dashboard-page'}>
      <PageTitle title={organizationData.name} iconName={'buildings'} />
      <TabMenu tabs={tabs} />
    </div>
  );
};

export default Organization;
