import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Hr from 'components/interface/hr';
import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Input from 'components/interface/input';
import Button from 'components/interface/button';
import SettingsFields from 'components/interface/settingsFields';

import { useUser } from 'features/user/hooks/useUser';
import { useOrganization } from 'features/organization/hooks/useOrganization';

import 'ka-table/style.css';

type FormInputs = {
  name: string;
};

const SettingsView: React.FC = () => {
  const { userHasPermission } = useUser();
  const { organizationData, updateOrganizationData } = useOrganization();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>();

  const isAccessible = userHasPermission('organization.update');

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await updateOrganizationData(data.name);

    if (response?.errors) {
      if (response.errors?.name) {
        setError('name', {
          type: 'manual',
          message: response.errors?.name,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar los parametros');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Datos actualizados');
      } else {
        toast.error('Error al actualizar los datos');
        reset();
      }
    }
    setIsLoading(false);
  };

  const fields = {
    id: {
      label: 'ID de la organización',
      description:
        'El ID de la organización es el que se utilizará para identificarla en procesos internos y la API.',
      component: (
        <Input
          placeholder={'ID de la organización'}
          defaultValue={organizationData.id}
          isDisabled={true}
        />
      ),
    },
    name: {
      label: 'Nombre de la organización',
      description:
        'El nombre de la organización es el que se mostrará a los usuarios que se unan a tu organización.',
      component: (
        <Input
          placeholder={'Nombre de la organización'}
          defaultValue={organizationData.name}
          isDisabled={!isAccessible}
          error={errors?.name?.message}
          {...register('name')}
        />
      ),
    },
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SettingsFields fields={fields} />
      {isAccessible && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
          <Button
            label={'Cancelar'}
            variant={'secondary'}
            onClick={() => reset()}
          />
          <Button
            type={'submit'}
            label={'Guardar'}
            variant={'primary'}
            isLoading={isLoading}
          />
        </div>
      )}
    </form>
  );
};

export default SettingsView;
