import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { UUID } from 'crypto';

import Modal from 'components/interface/modal';
import Select from 'components/interface/select';
import Button from 'components/interface/button';

import { useEndUser } from 'features/endUsers/hooks/useEndUser';

type AddContactToListModalProps = {
  endUserId: UUID;
  isOpen: boolean;
  onClose: () => void;
};

/**
 * AddContactToListModal
 * @description Modal to add a contact to a list
 * @param {UUID} endUserId - End user ID
 * @param {boolean} isOpen - Is the modal open
 * @param {function} onClose - Function to close the modal
 * @returns {TSX.Element} AddContactToListModal component
 */
const AddContactToListModal: React.FC<AddContactToListModalProps> = ({
  endUserId,
  isOpen,
  onClose,
}) => {
  const { endUserLists, addEndUserToList } = useEndUser();

  const [listOptions, setListOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [selectedList, setSelectedList] = useState<UUID | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const listOptionsAux: Array<{ value: string; label: string }> = [];

    Object.values(endUserLists).forEach((list) => {
      if (!list.endUsers.includes(endUserId)) {
        listOptionsAux.push({
          value: list.id,
          label: list.name,
        });
      }
    });

    setListOptions(listOptionsAux);
  }, [endUserLists, endUserId]);

  const handleAddEndUserToList = async () => {
    setIsLoading(true);
    if (selectedList) {
      const response = await addEndUserToList(selectedList, endUserId);
      if (response?.data?.success) {
        toast.success('Contacto añadido a la lista');
        handleClose();
      } else {
        toast.error('Error al añadir el contacto a la lista');
      }
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    setSelectedList(null);
    onClose();
  };

  return (
    <Modal title={'Añadir a lista'} isOpen={isOpen} onClose={handleClose}>
      <Select
        placeholder={'-- Selecciona una lista --'}
        options={listOptions as any}
        onChange={(value) =>
          setSelectedList((value.target.value as UUID) || null)
        }
      />
      <Button
        label={'Añadir'}
        isDisabled={!selectedList}
        isLoading={isLoading}
        onClick={handleAddEndUserToList}
      />
    </Modal>
  );
};

export default AddContactToListModal;
