import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Input from 'components/interface/input';
import Button from 'components/interface/button';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';

type Inputs = {
  nickname: string;
  name: string;
};

type CreateFormProps = {
  submitCallback: () => void;
};

const CreateForm: React.FC<CreateFormProps> = ({ submitCallback }) => {
  const { createChatbot } = useChatbot();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await createChatbot(data.nickname, data.name);

    if (response?.errors) {
      if (response.errors?.nickname) {
        setError('nickname', {
          type: 'manual',
          message: response.errors?.nickname,
        });
      }
      if (response.errors?.name) {
        setError('name', {
          type: 'manual',
          message: response.errors?.name,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al crear nuevo asistente');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Asistente creado con éxito');
        reset();
        submitCallback();
      } else {
        toast.error('Error al crear nuevo asistente');
        reset();
      }
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={'input-row'}>
        <Input
          placeholder={'Apodo (identificador interno)'}
          style={{ marginRight: 10 }}
          error={errors?.nickname?.message}
          {...register('nickname')}
        />
      </div>
      <div className={'input-row'}>
        <div>
          <Input
            placeholder={'Nombre (visible para el usuario)'}
            style={{ marginRight: 10 }}
            error={errors?.name?.message}
            {...register('name')}
          />
        </div>
        <Button
          type={'submit'}
          variant={'primary'}
          label={'Crear'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default CreateForm;
