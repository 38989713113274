import React, { useState, useEffect, useRef } from 'react';

import Text from 'components/interface/text';

import { formatDateVariable } from 'utils/dates';

import './styles.css';

/**
 * InboxConversationDate
 * @description Conversation date separator component
 * @param {string} date - date string
 * @returns {TSX.Element} InboxConversationDate component
 */

type InboxConversationDateProps = {
  date: string;
  scrollRef: React.RefObject<HTMLDivElement>;
};

const InboxConversationDate: React.FC<InboxConversationDateProps> = ({
  date,
  scrollRef,
}) => {
  const [isPinned, setIsPinned] = useState(false);
  const stickyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollRef.current) return;

    const handleScroll = () => {
      if (stickyRef.current) {
        const stickyTop = stickyRef.current.getBoundingClientRect().top;
        setIsPinned(stickyTop <= 80);
      }
    };

    scrollRef.current.addEventListener('scroll', handleScroll);
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollRef]);

  return (
    <div
      ref={stickyRef}
      className={`inbox-conversation-date ${
        isPinned ? 'inbox-conversation-date-pinned' : ''
      }`}
    >
      <span>
        <Text variant={'b3'} color={'var(--gray)'}>
          {formatDateVariable(date)}
        </Text>
      </span>
      <div className={'inbox-conversation-date-line'} />
    </div>
  );
};

export default InboxConversationDate;
