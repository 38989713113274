import React from 'react';

import { UUID } from 'crypto';

import SidebarExpandable from '../sidebarExpandable';
import SidebarSelectable from '../sidebarSelectable';

import { useInbox } from 'features/inbox/hooks/useInbox';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

/**
 * InboxSidebarChatbots
 * @description Sidebar component to toggle chatbots
 * @returns {TSX.Element} InboxSidebarChatbots component
 */

const InboxSidebarChatbots: React.FC = () => {
  const { inboxFilters, filterToggleChatbot } = useInbox();
  const { chatbotsData } = useChatbot();

  const handleChatbotClick = (chatbotId: UUID) => {
    filterToggleChatbot(chatbotId);
  };

  return (
    <SidebarExpandable
      title={'Asistentes'}
      elements={Object.values(chatbotsData).map((chatbot) => (
        <SidebarSelectable
          key={chatbot.id}
          title={chatbot.nickname}
          iconName={'robot'}
          isActive={inboxFilters.selectedChatbots.includes(chatbot.id)}
          onClick={() => handleChatbotClick(chatbot.id)}
        />
      ))}
    />
  );
};

export default InboxSidebarChatbots;
