import React, { useState } from 'react';
import { toast } from 'react-toastify';

import OTPInput from 'react-otp-input';

import Text from 'components/interface/text';
import Modal from 'components/interface/modal';
import Button from 'components/interface/button';

import { useUser } from 'features/user/hooks/useUser';

type VerifyPhoneNumberModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const VerifyPhoneNumberModal: React.FC<VerifyPhoneNumberModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { verifyPhoneNumberCode } = useUser();

  const [code, setCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (event: any) => {
    event?.preventDefault();

    if (isLoading) return;
    setIsLoading(true);

    const response = await verifyPhoneNumberCode(code);

    if (response) {
      toast.success('Número de teléfono verificado correctamente');
      setIsOpen(false);
    } else {
      toast.error('Código incorrecto o expirado, por favor intenta de nuevo');
    }

    setCode('');
    setIsLoading(false);
  };

  return (
    <Modal
      iconName={'deviceMobile'}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <form
        onSubmit={(e) => onSubmit(e)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text variant={'h6'}>Verífica tu número de teléfono</Text>
        <div style={{ height: 16 }}></div>
        <Text variant={'b2'} color={'var(--gray)'} align={'center'}>
          Para proteger tu cuenta y asegurar el correcto funcionamiento de la
          plataforma ingresa el código que te enviamos a tu WhatsApp.
        </Text>
        <OTPInput
          value={code}
          onChange={setCode}
          numInputs={4}
          renderInput={(props) => <input {...props} />}
          containerStyle={'otp-container'}
          inputStyle={'otp-input'}
          inputType={'number'}
          shouldAutoFocus={true}
        />
        <div style={{ marginTop: 8, display: 'flex', gap: 8 }}>
          <Button
            type={'submit'}
            label={'Verificar código'}
            onClick={() => {}}
            isLoading={isLoading}
          />
        </div>
      </form>
    </Modal>
  );
};

export default VerifyPhoneNumberModal;
