import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Text from 'components/interface/text';
import Button from 'components/interface/button';
import Toggle from 'components/interface/toggle';
import SettingsFields from 'components/interface/settingsFields';

import { useUser } from 'features/user/hooks/useUser';

type Inputs = {
  inbox: boolean;
  bots: boolean;
  campaigns: boolean;
  templates: boolean;
  billing: boolean;
  organizations: boolean;
  emailEnabled: boolean;
  whatsappEnabled: boolean;
};

const NotificationsForm: React.FC = () => {
  const {
    userDisplayData,
    userNotificationPreferences,
    updateUserNotificationPreferences,
  } = useUser();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await updateUserNotificationPreferences(data);

    if (response?.errors) {
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar los parametros');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Información actualizada');
      } else {
        toast.error('Error al actualizar los datos');
        reset();
      }
    }
    setIsLoading(false);
  };

  const fields = {
    outlets: {
      label: '¿Cómo quieres recibir tus notificaciones?',
      description:
        'Indica por donde quieres recibir notificaciones sobre actualizaciónes en tu inbox, campañas, asistentes, plantillas, organización y más. ',
      component: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: 8 }}>
            <Toggle
              defaultChecked={userNotificationPreferences.emailEnabled}
              {...register('emailEnabled')}
            />
            <div>
              <Text variant={'h8'}>Correo electrónico</Text>
              <div style={{ height: 4 }} />
              <Text variant={'b3'} color={'var(--gray)'}>
                Recibe notificaciones por tu correo electrónico configurado.
              </Text>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: 8 }}>
            <Toggle
              defaultChecked={userNotificationPreferences.whatsappEnabled}
              isDisabled={userDisplayData.phoneNumberVerified ? false : true}
              {...register('whatsappEnabled')}
            />
            <div>
              <Text variant={'h8'}>WhatsApp</Text>
              <div style={{ height: 4 }} />
              <Text variant={'b3'} color={'var(--gray)'}>
                Recibe notificaciones por WhatsApp a tu número configurado,
                asegurate de haber agregado y verificado tu número.
              </Text>
            </div>
          </div>
        </div>
      ),
    },
    inputs: {
      label: 'Recibir notificaciones de...',
      description:
        'Selecciona las opciones de las cuales deseas recibir notificaciones.',
      component: (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 24,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: 8 }}>
            <Toggle
              defaultChecked={userNotificationPreferences.inbox}
              {...register('inbox')}
            />
            <div>
              <Text variant={'h8'}>Inbox</Text>
              <div style={{ height: 4 }} />
              <Text variant={'b3'} color={'var(--gray)'}>
                Recibe notificaciones sobre actualizaciones en tu inbox.
              </Text>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: 8 }}>
            <Toggle
              defaultChecked={userNotificationPreferences.bots}
              {...register('bots')}
            />
            <div>
              <Text variant={'h8'}>Asistentes</Text>
              <div style={{ height: 4 }} />
              <Text variant={'b3'} color={'var(--gray)'}>
                Recibe notificaciones sobre actualizaciones en tus asistentes.
              </Text>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: 8 }}>
            <Toggle
              defaultChecked={userNotificationPreferences.campaigns}
              {...register('campaigns')}
            />
            <div>
              <Text variant={'h8'}>Campañas</Text>
              <div style={{ height: 4 }} />
              <Text variant={'b3'} color={'var(--gray)'}>
                Recibe notificaciones sobre actualizaciones en tus campañas.
              </Text>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: 8 }}>
            <Toggle
              defaultChecked={userNotificationPreferences.templates}
              {...register('templates')}
            />
            <div>
              <Text variant={'h8'}>Plantillas</Text>
              <div style={{ height: 4 }} />
              <Text variant={'b3'} color={'var(--gray)'}>
                Recibe notificaciones sobre actualizaciones en tus plantillas.
              </Text>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: 8 }}>
            <Toggle
              defaultChecked={userNotificationPreferences.billing}
              {...register('billing')}
            />
            <div>
              <Text variant={'h8'}>Facturación</Text>
              <div style={{ height: 4 }} />
              <Text variant={'b3'} color={'var(--gray)'}>
                Recibe notificaciones sobre actualizaciones en tu facturación.
              </Text>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: 8 }}>
            <Toggle
              defaultChecked={userNotificationPreferences.organizations}
              {...register('organizations')}
            />
            <div>
              <Text variant={'h8'}>Organización</Text>
              <div style={{ height: 4 }} />
              <Text variant={'b3'} color={'var(--gray)'}>
                Recibe notificaciones sobre actualizaciones en tu organización.
              </Text>
            </div>
          </div>
        </div>
      ),
    },
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SettingsFields fields={fields} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
          <Button
            label={'Cancelar'}
            variant={'secondary'}
            onClick={() => reset()}
          />
          <Button
            type={'submit'}
            label={'Guardar'}
            variant={'primary'}
            isLoading={isLoading}
          />
        </div>
      </form>
    </>
  );
};

export default NotificationsForm;
