import { type OrganizationState } from './OrganizationProvider';

export type OrganizationTypes =
  | 'SAVE_HAS_ORGANIZATION'
  | 'SAVE_ORGANIZATION_DATA';

type OrganizationAction = {
  type: OrganizationTypes;
  payload?: any;
};

const organizationReducer = (
  state: OrganizationState,
  action: OrganizationAction,
) => {
  const { type, payload } = action;

  switch (type) {
    case 'SAVE_HAS_ORGANIZATION':
      return {
        ...state,
        hasOrganization: payload,
      };
    case 'SAVE_ORGANIZATION_DATA':
      return {
        ...state,
        organizationData: payload,
      };
    default:
      return state;
  }
};

export default organizationReducer;
