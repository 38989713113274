import React from 'react';
import { useParams } from 'react-router-dom';

import DetailView from 'features/dashboard/components/detailView';

import NewCampaignForm from '../features/campaigns/forms/NewCampaignForm';
import EditCampaignForm from '../features/campaigns/forms/EditCampaignForm';

import { useUser } from 'features/user/hooks/useUser';

const CampaignFormView: React.FC = () => {
  const { userHasPermission } = useUser();
  const { id } = useParams();

  if (!userHasPermission('campaigns.create')) {
    return null;
  }

  return (
    <div className={'dashboard-page'}>
      <DetailView title={id ? 'Editar Campaña' : 'Nueva Campaña'}>
        {id ? <EditCampaignForm campaignId={id} /> : <NewCampaignForm />}
      </DetailView>
    </div>
  );
};

export default CampaignFormView;
