import React from 'react';

import Text from 'components/interface/text';

import './styles.css';

type CampaignContactStatusProps = {
  status: string;
};

/**
 * CampaignContactStatus
 * @description Component that displays the status of a contact in a campaign
 * @param {string} status - Status of the contact
 * @returns {TSX.Element} CampaignContactStatus component
 */

const CampaignContactStatus: React.FC<CampaignContactStatusProps> = ({
  status,
}) => {
  return (
    <div className="campaign-contact-status">
      <div
        style={{
          backgroundColor: `var(--${status})`,
          borderColor: `var(--${status}-pale)`,
        }}
      />
      <Text variant={'b3'} color={'var(--gray)'} numberOfLines={1}>
        {status}
      </Text>
    </div>
  );
};

export default CampaignContactStatus;
