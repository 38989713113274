import { type EndUsersState } from './EndUserProvider';

export type EndUsersTypes =
  | 'SAVE_ENDUSERS_DATA'
  | 'SAVE_ENDUSER_COUNT'
  | 'SAVE_ENDUSER_LISTS';

type EndUserAction = {
  type: EndUsersTypes;
  payload?: any;
};

const endUserReducer = (state: EndUsersState, action: EndUserAction) => {
  const { type, payload } = action;

  switch (type) {
    case 'SAVE_ENDUSERS_DATA':
      return {
        ...state,
        endUsersData: payload,
      };
    case 'SAVE_ENDUSER_COUNT':
      return {
        ...state,
        endUserCount: payload,
      };
    case 'SAVE_ENDUSER_LISTS':
      return {
        ...state,
        endUserLists: payload,
      };
    default:
      return state;
  }
};

export default endUserReducer;
