import { type UserState } from './UserProvider';

export type UserTypes =
  | 'SAVE_USER_DATA'
  | 'SAVE_USER_DISPLAY_DATA'
  | 'SAVE_USER_PERMISSIONS'
  | 'SAVE_USER_NOTIFICATION_PREFERENCES';

type UserAction = {
  type: UserTypes;
  payload?: any;
};

const userReducer = (state: UserState, action: UserAction) => {
  const { type, payload } = action;

  switch (type) {
    case 'SAVE_USER_DATA':
      return {
        ...state,
        userData: payload,
      };
    case 'SAVE_USER_DISPLAY_DATA':
      return {
        ...state,
        userDisplayData: payload,
      };
    case 'SAVE_USER_PERMISSIONS':
      return {
        ...state,
        userPermissions: payload,
      };
    case 'SAVE_USER_NOTIFICATION_PREFERENCES':
      return {
        ...state,
        userNotificationPreferences: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
