import React from 'react';

import Text from 'components/interface/text';

import { useOrganization } from 'features/organization/hooks/useOrganization';

import './styles.css';

type RoleCreatorTagProps = {
  isSystem: boolean;
};

const RoleCreatorTag: React.FC<RoleCreatorTagProps> = ({ isSystem }) => {
  const { organizationData } = useOrganization();

  return (
    <div
      className={`role-creator-tag ${isSystem ? 'rct-system' : 'rct-custom'}`}
    >
      <Text
        variant={'b2'}
        size={12}
        color={isSystem ? 'var(--primary)' : 'var(--gray)'}
      >
        {isSystem ? 'Predeterminado' : 'Personalizado'}
      </Text>
    </div>
  );
};

export default RoleCreatorTag;
