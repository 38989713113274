import api from 'api';

/**
 * fetchOrganizationData
 * @description Function that gets organization data
 * @returns {Object} { status, data: {organizationData} } | { status, ...errors }
 */

export const fetchOrganizationData = async () => {
  try {
    const response = await api.get(
      process.env.REACT_APP_WEST_BASE_URL + '/organization/',
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putOrganizationData
 * @description Function that updates organization data
 * @param name
 * @returns {Object} { status } | { status, ...errors }
 */

export const putOrganizationData = async (name: any) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/organization/',
      { name },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postOrganizationInvite
 * @description Function that sends organization invite
 * @param email
 * @returns {Object} { status } | { status, ...errors }
 */

export const postOrganizationInvite = async (email: any) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/organization/invite/',
      { email },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * deleteOrganizationInvite
 * @description Function that revokes organization invite
 * @param code
 * @returns {Object} { status } | { status, ...errors }
 */

export const deleteOrganizationInvite = async (code: any) => {
  try {
    const response = await api.delete(
      `${process.env.REACT_APP_WEST_BASE_URL}/organization/invite/?code=${code}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * fetchOrganizationInviteDetails
 * @description Function that gets organization invite details
 * @param code
 * @returns {Object} { status, data: {organizationInvite} } | { status, ...errors }
 */

export const fetchOrganizationInviteDetails = async (code: any) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/organization/invite/?code=${code}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postJoinOrganization
 * @description Function that joins organization
 * @param code
 * @returns {Object} { status } | { status, ...errors }
 */

export const postJoinOrganization = async (code: any) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/organization/join/',
      { code },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putOrganizationMember
 * @description Function that updates organization member
 * @param organizationMember
 * @returns {Object} { status } | { status, ...errors }
 */

export const putOrganizationMember = async (organizationMember: any) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/organization/member/',
      { ...organizationMember },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * deleteOrganizationMember
 * @description Function that deletes organization member
 * @param organizationMember
 * @returns {Object} { status } | { status, ...errors }
 */

export const deleteOrganizationMember = async (memberId: any) => {
  try {
    const response = await api.delete(
      `${process.env.REACT_APP_WEST_BASE_URL}/organization/member/?id=${memberId}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postOrganizationRole
 * @description Function that creates organization role
 * @param name
 * @param description
 * @returns {Object} { status } | { status, ...errors }
 */

export const postOrganizationRole = async (name: any) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/organization/role/',
      { name },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putOrganizationRole
 * @description Function that updates organization role
 * @param organizationRole
 * @returns {Object} { status } | { status, ...errors }
 */

export const putOrganizationRole = async (organizationRole: any) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/organization/role/',
      { ...organizationRole },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * fetchBillingCycleDays
 * @description Function that gets billing cycle days
 * @param organizationId
 * @param billingCycleId
 * @returns {Object} { status, data: {billingCycleDays} } | { status, ...errors }
 */

export const fetchBillingCycleDays = async (
  organizationId: any,
  billingCycleId: any,
) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/organization/billing/cycle/?organizationId=${organizationId}&billingCycleId=${billingCycleId}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};
