import React from 'react';

import Text from 'components/interface/text';

import './styles.css';

/**
 * SidebarStatus
 * @description Inbox sidebar status display component
 * @param {boolean} webSocketConnected - Description of webSocketConnected
 * @param {boolean} webSocketConnecting - Description of webSocketConnecting
 * @returns {TSX.Element} SidebarStatus component
 */

type SidebarStatusProps = {
  webSocketConnected: boolean;
  webSocketConnecting: boolean;
};

const SidebarStatus: React.FC<SidebarStatusProps> = ({
  webSocketConnected,
  webSocketConnecting,
}) => {
  return (
    <div
      className={`inbox-sidebar-header-status ${
        webSocketConnecting
          ? 'inbox-sidebar-header-status-connecting'
          : webSocketConnected
          ? 'inbox-sidebar-header-status-connected'
          : 'inbox-sidebar-header-status-disconnected'
      }`}
    >
      <div />
      <Text variant={'b2'} color={'var(--gray)'}>
        {webSocketConnecting
          ? 'Conectando...'
          : webSocketConnected
          ? 'Conectado'
          : 'Desconectado'}
      </Text>
    </div>
  );
};

export default SidebarStatus;
