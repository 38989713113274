import React, { useState, useEffect } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import {
  type Template,
  type TemplateComponent,
} from 'features/templates/types/templateTypes';

import { templateStatusTranslations } from 'features/templates/utils/translations';

import './styles.css';

type TemplateCardProps = {
  templateData: Template;
  isSelected?: boolean;
  onClick: () => void;
};

const TemplateCard: React.FC<TemplateCardProps> = ({
  templateData,
  isSelected = false,
  onClick,
}) => {
  const [headerComponent, setHeaderComponent] =
    useState<TemplateComponent | null>(null);
  const [bodyComponent, setBodyComponent] = useState<TemplateComponent | null>(
    null,
  );

  useEffect(() => {
    const templateComponents = templateData?.components;

    setHeaderComponent(
      templateComponents?.find((component) => component.type === 'HEADER') ??
        null,
    );
    setBodyComponent(
      templateComponents?.find((component) => component.type === 'BODY') ??
        null,
    );
  }, [templateData]);

  const handleOnClick = () => {
    if (templateData?.status === 'APPROVED') onClick();
  };

  return headerComponent?.format !== 'VIDEO' &&
    headerComponent?.format !== 'LOCATION' ? (
    <div
      className={`template-card ${isSelected ? 'template-card__selected' : ''}`}
      style={{
        cursor: `${
          templateData?.status === 'APPROVED' ? 'pointer' : 'not-allowed'
        }`,
      }}
      onClick={handleOnClick}
    >
      <div className={'template-card__status'}>
        {templateData?.status === 'APPROVED' ? (
          <>
            <div
              className={'template-card__status_icon'}
              style={{ backgroundColor: 'var(--success-pale)' }}
            >
              <Icon size={14} name={'checkCircle'} color={'var(--success)'} />
            </div>
            <Text size={12} variant={'s2'}>
              {templateStatusTranslations[templateData?.status]}
            </Text>
          </>
        ) : templateData?.status === 'PENDING' ? (
          <>
            <div
              className={'template-card__status_icon'}
              style={{ backgroundColor: 'var(--warning-pale)' }}
            >
              <Icon size={14} name={'clock'} color={'var(--warning)'} />
            </div>
            <Text size={12} variant={'s2'}>
              {templateStatusTranslations[templateData?.status]}
            </Text>
          </>
        ) : (
          <>
            <div
              className={'template-card__status_icon'}
              style={{ backgroundColor: 'var(--error-pale)' }}
            >
              <Icon size={14} name={'xCircle'} color={'var(--error)'} />
            </div>
            <Text size={12} variant={'s2'}>
              {templateStatusTranslations[templateData?.status]}
            </Text>
          </>
        )}
      </div>
      {headerComponent && (
        <div className={'template-card__header'}>
          {headerComponent.format === 'IMAGE' && (
            <>
              <Icon name={'image'} />
              <Text variant={'h8'}>Imagen</Text>
            </>
          )}
          {headerComponent.format === 'DOCUMENT' && (
            <>
              <Icon name={'file'} />
              <Text variant={'h8'}>Documento</Text>
            </>
          )}
          {headerComponent.format === 'TEXT' && (
            <Text variant={'h8'}>{headerComponent.text}</Text>
          )}
        </div>
      )}
      {bodyComponent && (
        <div className={'template-card__body'}>
          <Text variant={'b3'} numberOfLines={3}>
            {bodyComponent.text}
          </Text>
        </div>
      )}
      <div className={'template-card__footer'}>
        <Text variant={'s2'}>{templateData.name}</Text>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default TemplateCard;
