import React from 'react';

import Icon from 'components/interface/icon';

import { type IconName } from 'components/interface/icon';

import './styles.css';

export type ListTagColor =
  | 'red'
  | 'blue'
  | 'green'
  | 'orange'
  | 'pink'
  | 'purple'
  | 'yellow';

type ListTagProps = {
  text: string;
  color?: ListTagColor;
  iconName?: IconName;
  altIconName?: IconName;
  isClickable?: boolean;
  showBorder?: boolean;
  onClick?: () => void;
};

/**
 * ListTag
 * @description Component that displays a list name as a tag with color
 * @param {string} text Text of the tag
 * @param {string} color Color of the tag
 * @param {IconName} iconName Icon name of the tag
 * @param {IconName} altIconName Icon name of the tag when is clicked
 * @param {boolean} isClickable Is the tag clickable
 * @param {boolean} showBorder Show border of the tag
 * @param {function} onClick Function to execute when the tag is clicked
 * @returns {TSX.Element} Tag component
 */

const ListTag: React.FC<ListTagProps> = ({
  text,
  color = 'blue',
  iconName = 'userList',
  altIconName = 'close',
  isClickable = false,
  showBorder = false,
  onClick = () => {},
}) => {
  return (
    <span
      className={`list-tag ${color} ${showBorder ? 'list-tag-border' : ''}`}
      style={
        isClickable
          ? { borderColor: `var(--tag-${color})`, cursor: 'pointer' }
          : { borderColor: `var(--tag-${color})` }
      }
      onClick={onClick}
    >
      <div className={isClickable ? 'list-tag-close' : 'list-tag-hide'}>
        <Icon name={altIconName} color={`var(--tag-${color})`} size={16} />
      </div>
      <div className={isClickable ? 'list-tag-icon' : ''}>
        <Icon name={iconName} color={`var(--tag-${color})`} size={16} />
      </div>
      {text}
    </span>
  );
};

export default ListTag;
