import React from 'react';

import IconButton from 'components/interface/iconButton';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';

type CampaignCardOptionsProps = {
  campaignId: string;
};

const CampaignCardOptions: React.FC<CampaignCardOptionsProps> = ({
  campaignId,
}) => {
  const { campaignsData, updateCampaignArchive } = useCampaign();

  const handleArchive = (event: any) => {
    event?.stopPropagation();
    updateCampaignArchive(campaignId, !campaignsData[campaignId]?.archived);
  };

  return (
    <div style={{ position: 'absolute', right: 8, top: 24 }}>
      <IconButton
        iconName={
          campaignsData[campaignId]?.archived ? 'archive' : 'archiveTray'
        }
        hint={campaignsData[campaignId]?.archived ? 'Desarchivar' : 'Archivar'}
        onClick={handleArchive}
      />
    </div>
  );
};

export default CampaignCardOptions;
