import React from 'react';

import Text from 'components/interface/text';

import './styles.css';

/**
 * SendTemplate
 * @description Component that displays the send template section for inbox
 * @param {Function} onClick - function to execute when the button is clicked
 * @returns {TSX.Element} SendTemplate component
 */

type SendTemplateProps = {
  onClick: () => void;
};

const SendTemplate: React.FC<SendTemplateProps> = ({ onClick }) => {
  return (
    <div className={'inbox-send-template'} onClick={onClick}>
      <Text variant={'b3'} color={'var(--gray)'} align={'center'}>
        La ventana de 24h para responder a la conversación ha terminado. Puedes
        enviar un mensaje de plantilla para continuar la conversación.
      </Text>
    </div>
  );
};

export default SendTemplate;
