import React, { useState, useEffect } from 'react';

import Text from 'components/interface/text';
import Image from 'components/interface/image';
import Modal from 'components/interface/modal';

import { useInbox } from 'features/inbox/hooks/useInbox';

import { type Message } from 'features/inbox/types/inboxTypes';

import './styles.css';

/**
 * MessageContentImage
 * @description Component to display an image in a message's content
 * @param {Message} message - message object
 * @returns {TSX.Element} MessageContentImage component
 */

type MessageContentImageProps = {
  message: Message;
};

const MessageContentImage: React.FC<MessageContentImageProps> = ({
  message,
}) => {
  const { inbox, getMessageMediaBinary } = useInbox();

  const [mediaBinary, setMediaBinary] = useState<string>('');
  const [mediaType, setMediaType] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const getMediaData = async () => {
      if (!inbox.activeThread) return;
      const mediaData = await getMessageMediaBinary(
        inbox.activeThread.chatbotId,
        inbox.activeThread.integrationId,
        message.media,
      );
      setMediaBinary(mediaData?.binary);
      setMediaType(mediaData?.mimeType);
    };

    getMediaData();
  }, [message]);

  return (
    <>
      <div className={'inbox-message-image-content'}>
        <div
          className={'inbox-message-image'}
          onClick={() => setIsModalOpen(true)}
        >
          <Image
            src={`data:${mediaType};base64,${mediaBinary}`}
            skeletonWidth={320}
            skeletonHeight={240}
          />
        </div>
        {message.content.length > 0 && (
          <Text variant={'b2'}>{message.content}</Text>
        )}
      </div>
      <Modal
        title={'Imagen'}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Image src={`data:${mediaType};base64,${mediaBinary}`} />
      </Modal>
    </>
  );
};

export default MessageContentImage;
