import React from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';

import { type CallType } from 'features/calls/types';

import { callTypeTranslation } from 'features/calls/translations';

import { CallTypeEnum } from 'features/calls/enums';

import './styles.css';

type CampaignCallTypeProps = {
  type: CallType;
};

/**
 * CampaignCallType
 * @description Component that displays the type of a call
 * @param {string} type - Type of the call
 * @returns {TSX.Element} CampaignCallType component
 */

const CampaignCallType: React.FC<CampaignCallTypeProps> = ({ type }) => {
  return (
    <div className="campaign-call-type">
      <Icon
        name={
          type === CallTypeEnum.OUTBOUND ? 'phoneOutgoing' : 'phoneIncoming'
        }
        color={'var(--gray)'}
      />
      <Text variant={'b3'} color={'var(--gray)'} numberOfLines={1}>
        {callTypeTranslation[type]}
      </Text>
    </div>
  );
};

export default CampaignCallType;
