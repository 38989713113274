import React, { useState, useEffect } from 'react';
import { DataType, Table, useTable } from 'ka-table';
import { SortingMode } from 'ka-table/enums';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import SearchInput from 'components/interface/searchInput';
import CampaignCallType from '../campaignCallType';
import CampaignCallStatus from '../campaignCallStatus';
import CampaignCallAnsweredBy from '../campaignCallAnsweredBy';
import CampaignCallsDownload from '../campaignCallsDownload';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';

import {
  type CampaignData,
  type CampaignCall,
} from 'features/campaigns/providers/CampaignProvider';

import { formatDateTimeWithSeconds, formatDuration } from 'utils/dates';
import { formatNumber } from 'utils/numbers';

import { ActionType } from 'ka-table/enums';
import './styles.css';

type CampaignCallsProps = {
  campaignId: string;
  canUpdate: boolean;
};

/**
 * CampaignCalls
 * @description Component that displays the calls of a campaign in a table
 * @param {string} campaignId - Campaign id
 * @param {boolean} canUpdate - If the user can update the campaign
 * @returns {TSX.Element} CampaignCalls component
 */

const CampaignCalls: React.FC<CampaignCallsProps> = ({
  campaignId,
  canUpdate,
}) => {
  const { campaignsData, getCampaignCalls, getCampaignStats } = useCampaign();

  const campaignData: CampaignData = campaignsData[campaignId];

  const [campaignCallsArray, setCampaignCallsArray] = useState<CampaignCall[]>(
    [],
  );
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(35);
  const [pageCount, setPageCount] = useState(0);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [tableKey, setTableKey] = useState(Date.now());

  const table = useTable({
    onDispatch: async (action) => {
      if (action.type === ActionType.UpdatePageIndex) {
        setPageIndex(action.pageIndex);
      }
      if (action.type === ActionType.UpdateSortDirection) {
        setSortBy(action.columnKey);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      }
    },
  });

  useEffect(() => {
    const campaignCallsAux: CampaignCall[] = [];

    campaignData.campaignCalls.forEach((call) => {
      campaignCallsAux.push(call);
    });

    setCampaignCallsArray(campaignCallsAux);
    setTableKey(Date.now());
  }, [campaignData.campaignCalls]);

  useEffect(() => {
    const handleGetCalls = async () => {
      if (isLoading) return;
      setIsLoading(true);

      await getCampaignCalls(
        campaignId,
        pageIndex,
        pageSize,
        sortBy,
        sortOrder,
        searchTerm,
      );

      setIsLoading(false);
    };

    handleGetCalls();
  }, [pageIndex, pageSize, sortBy, sortOrder, searchTerm]);

  useEffect(() => {
    setPageCount(Math.ceil(campaignData.campaignCallsCount / pageSize));
  }, [campaignData.campaignCallsCount, pageSize]);

  return (
    <div>
      <div className={'campaign-calls-header'}>
        <div className={'campaign-calls-header-left'}>
          <div className={'campaign-calls-header-left-icon'}>
            <Icon name={'phone'} size={18} color={'var(--primary)'} />
          </div>
          <Text variant={'h7'}>
            {formatNumber(campaignData.campaignCallsCount)} Llamadas
          </Text>
        </div>
        <div className={'campaign-calls-header-right'}>
          <SearchInput onChange={(e: any) => setSearchTerm(e.target.value)} />
          <CampaignCallsDownload campaignId={campaignId} />
        </div>
      </div>
      <div className={'campaign-calls-table'}>
        <Table
          key={tableKey}
          table={table}
          rowKeyField={'id'}
          columns={[
            {
              key: 'id',
              dataType: DataType.String,
              title: 'ID',
              style: { width: 200 },
            },
            {
              key: 'numberTo',
              dataType: DataType.String,
              title: 'Número',
              style: { width: 120 },
            },
            {
              key: 'type',
              dataType: DataType.String,
              title: 'Tipo',
              style: { width: 120 },
            },
            {
              key: 'status',
              dataType: DataType.String,
              title: 'Estado',
              style: { width: 120 },
            },
            {
              key: 'answeredBy',
              dataType: DataType.String,
              title: 'Respondido por',
              style: { width: 160 },
            },
            {
              key: 'createdAt',
              dataType: DataType.String,
              title: 'Hora',
              style: { width: 160 },
            },
            {
              key: 'duration',
              dataType: DataType.Number,
              title: 'Duración',
              style: { width: 80, textAlign: 'center' },
            },
            {
              key: 'billedDuration',
              dataType: DataType.Number,
              title: 'Consumido',
              style: { width: 80, textAlign: 'center' },
            },
          ]}
          data={campaignCallsArray}
          loading={{
            enabled: isLoading,
          }}
          paging={{
            enabled: true,
            pageIndex: pageIndex,
            pageSize: pageSize,
            pagesCount: pageCount,
          }}
          childComponents={{
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case 'id':
                    return (
                      <Text variant={'b3'} numberOfLines={1}>
                        {props.value}
                      </Text>
                    );
                  case 'numberTo':
                    return (
                      <Text variant={'b3'} color={'var(--gray)'}>
                        {props.value}
                      </Text>
                    );
                  case 'type':
                    return <CampaignCallType type={props.value} />;
                  case 'status':
                    return <CampaignCallStatus status={props.value} />;
                  case 'answeredBy':
                    return <CampaignCallAnsweredBy answeredBy={props.value} />;
                  case 'createdAt':
                    return (
                      <Text variant={'b3'} color={'var(--gray)'}>
                        {formatDateTimeWithSeconds(props.value)}
                      </Text>
                    );
                  case 'duration':
                  case 'billedDuration':
                    return (
                      <Text variant={'b3'} style={{ textAlign: 'center' }}>
                        {formatDuration(props.value)}
                      </Text>
                    );
                }
              },
            },
          }}
          sortingMode={SortingMode.Single}
        />
      </div>
    </div>
  );
};

export default CampaignCalls;
