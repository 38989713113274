import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from 'components/interface/button';
import Select from 'components/interface/select';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';
import { useEndUser } from 'features/endUsers/hooks/useEndUser';

type FormInputs = {
  endUserListId: string;
};

type ImportCampaignContactsFromListFormProps = {
  campaignId: string;
  onSubmitCallback: () => void;
};

const ImportCampaignContactsFromListForm: React.FC<
  ImportCampaignContactsFromListFormProps
> = ({ campaignId, onSubmitCallback }) => {
  const { addCampaignContactsFromList } = useCampaign();
  const { endUserLists } = useEndUser();

  const [listOptions, setListOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const listOptionsAux: Array<{ label: string; value: string }> = [];

    Object.values(endUserLists).forEach((list) => {
      listOptionsAux.push({
        label: `${list.name} (${list.endUsers.length})`,
        value: list.id,
      });
    });

    setListOptions(listOptionsAux);
  }, [endUserLists]);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async (data, event: any) => {
    if (isLoading) return;
    setIsLoading(true);

    const endUserListId = data.endUserListId;

    // Send request to API conditional to create or update
    const response = await addCampaignContactsFromList(
      campaignId,
      endUserListId,
    );

    // Manage response errors or success
    if (response?.errors) {
      if (response.errors?.endUserListId) {
        setError('endUserListId', {
          type: 'manual',
          message: response.errors.endUserListId,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error importar contactos');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Contactos agregados correctamente');
      } else {
        toast.error('Error importar contactos');
        reset();
      }
      onSubmitCallback();
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} encType={'multipart/form-data'}>
      <Select
        placeholder={'-- Selecciona una lista --'}
        options={listOptions}
        error={errors.endUserListId?.message}
        {...register('endUserListId')}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 16,
          gap: 8,
        }}
      >
        <Button
          label={'Cancelar'}
          variant={'secondary'}
          onClick={() => {
            onSubmitCallback();
            reset();
          }}
        />
        <Button
          type={'submit'}
          label={'Guardar'}
          variant={'primary'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default ImportCampaignContactsFromListForm;
