import React, { useState, useEffect } from 'react';

import { UUID } from 'crypto';

import TagInput from 'components/interface/tagInput';

import { useInbox } from 'features/inbox/hooks/useInbox';
import { useEndUser } from 'features/endUsers/hooks/useEndUser';

import { type EndUser } from 'features/endUsers/providers/EndUserProvider';
import { type TagInputTag } from 'components/interface/tagInput';

import './styles.css';

/**
 * InboxDetailsLists
 * @description Container for the endUser's lists
 * @returns {TSX.Element} InboxDetailsLists component
 */

const InboxDetailsLists: React.FC = () => {
  const { inbox } = useInbox();
  const {
    endUsersData,
    endUserLists,
    addEndUserToList,
    removeEndUserFromList,
  } = useEndUser();

  const [endUserData, setEndUserData] = useState<EndUser>(
    endUsersData[inbox.activeThread?.endUser?.id as UUID],
  );
  const [availableLists, setAvailableLists] = useState<TagInputTag[]>([]);
  const [selectedLists, setSelectedLists] = useState<TagInputTag[]>([]);

  useEffect(() => {
    if (inbox.activeThread) {
      setEndUserData(endUsersData[inbox.activeThread?.endUser?.id as UUID]);
    }
  }, [inbox.activeThread?.endUser?.id, endUsersData]);

  useEffect(() => {
    if (inbox.activeThread) {
      const availableListsAux: TagInputTag[] = [];
      const selectedListsAux: TagInputTag[] = [];

      Object.values(endUserLists).forEach((list) => {
        availableListsAux.push({
          id: list.id,
          name: list.name,
          color: list.color,
          onClick: () => {
            if (inbox.activeThread)
              removeEndUserFromList(list.id, inbox.activeThread?.endUser?.id);
          },
        });
        if (endUserData?.inLists && endUserData?.inLists.includes(list.id)) {
          selectedListsAux.push({
            id: list.id,
            name: list.name,
            color: list.color,
            onClick: () => {
              if (inbox.activeThread)
                removeEndUserFromList(list.id, inbox.activeThread?.endUser?.id);
            },
          });
        }
      });

      setAvailableLists(availableListsAux);
      setSelectedLists(selectedListsAux);
    }
  }, [endUserData?.inLists, endUserLists]);

  if (!inbox.activeThread) return null;

  return (
    <div className={'inbox-lists'}>
      <TagInput
        inputPlaceholder={'Añadir a lista...'}
        iconName={'userList'}
        availableTags={availableLists}
        selectedTags={selectedLists}
        onTagAdd={(tag) => {
          if (inbox.activeThread)
            addEndUserToList(tag.id, inbox.activeThread?.endUser?.id);
        }}
      />
    </div>
  );
};

export default InboxDetailsLists;
