import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Text from 'components/interface/text';
import Modal from 'components/interface/modal';
import Input from 'components/interface/input';
import Button from 'components/interface/button';
import Toggle from 'components/interface/toggle';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';

import {
  type CampaignScheduling,
  type CampaignSchedulingDays,
} from 'features/campaigns/providers/CampaignProvider';

import {
  daysOfTheWeekToNumber,
  daysOfTheWeekToSpanish,
  daysOfTheWeekToSpanishLong,
} from 'utils/dates';

import './styles.css';

const schedulingPlaceholder = [
  {
    display: 'Lun',
    code: 'mon',
  },
  {
    display: 'Mar',
    code: 'tue',
  },
  {
    display: 'Mié',
    code: 'wed',
  },
  {
    display: 'Jue',
    code: 'thu',
  },
  {
    display: 'Vie',
    code: 'fri',
  },
  {
    display: 'Sáb',
    code: 'sat',
  },
  {
    display: 'Dom',
    code: 'sun',
  },
];

type CampaignConfigSchedulingProps = {
  campaignId: string;
  canUpdate?: boolean;
};

/**
 * CampaignConfigScheduling
 * @description Campaign scheduling configuration component
 * @param {string} campaignId - Campaign ID
 * @param {boolean} canUpdate - If user can update campaign
 * @returns {TSX.Element} CampaignConfigScheduling component
 */

const CampaignConfigScheduling: React.FC<CampaignConfigSchedulingProps> = ({
  campaignId,
  canUpdate = false,
}) => {
  const { campaignsData, updateCampaignScheduling } = useCampaign();
  const [campaignScheduling, setCampaignScheduling] = useState<any[]>(
    schedulingPlaceholder,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<any>({});
  const [selectedDayStartTime, setSelectedDayStartTime] = useState<string>('');
  const [selectedDayStopTime, setSelectedDayStopTime] = useState<string>('');
  const [selectedDayActive, setSelectedDayActive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const campaignAux = Object.values(campaignsData).find(
      (c) => c.id === campaignId,
    );
    if (campaignAux) {
      const campaignSchedulingAux: any = [];
      Object.keys(campaignAux?.scheduling).forEach((key) => {
        campaignSchedulingAux.push({
          code: key,
          display: daysOfTheWeekToSpanish[key as CampaignSchedulingDays],
          value: campaignAux?.scheduling[key as CampaignSchedulingDays],
        });
      });
      campaignSchedulingAux.sort((a: any, b: any) => {
        return (
          daysOfTheWeekToNumber[a.code as CampaignSchedulingDays] -
          daysOfTheWeekToNumber[b.code as CampaignSchedulingDays]
        );
      });
      setCampaignScheduling(campaignSchedulingAux);
    }
  }, [campaignsData, campaignId]);

  const handleOpenModal = (day: any) => {
    if (!canUpdate) return;
    setSelectedDay(day);
    setSelectedDayStartTime(day?.value?.startTime);
    setSelectedDayStopTime(day?.value?.stopTime);
    setSelectedDayActive(day?.value?.active);
    setIsModalOpen(true);
  };

  const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDayStartTime(e.target.value);
  };

  const handleStopTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDayStopTime(e.target.value);
  };

  const handleActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDayActive(e.target.checked);
  };

  const handleSave = async () => {
    if (!canUpdate) return;
    setIsLoading(true);
    const campaignSchedulingAux: CampaignScheduling =
      campaignsData[campaignId].scheduling;
    campaignSchedulingAux[
      selectedDay.code as CampaignSchedulingDays
    ].startTime = selectedDayStartTime;
    campaignSchedulingAux[selectedDay.code as CampaignSchedulingDays].stopTime =
      selectedDayStopTime;
    campaignSchedulingAux[selectedDay.code as CampaignSchedulingDays].active =
      selectedDayActive;

    const response = await updateCampaignScheduling(
      campaignId,
      campaignSchedulingAux,
    );

    if (response?.data?.success) {
      toast.success('Horario actualizado exitosamente');
    } else {
      toast.error('Error al actualizar el horario');
    }

    setIsLoading(false);
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={'campaign-scheduling-grid'}>
        {campaignScheduling.map((day, index) => {
          return (
            <div
              key={index}
              className={`campaign-scheduling-grid__day ${
                day?.value?.active ? 'campaign-scheduling-grid__day-active' : ''
              }`}
              onClick={() => handleOpenModal(day)}
            >
              <Text
                variant={'b3'}
                color={day?.value?.active ? 'var(--body)' : 'var(--gray)'}
              >
                {day.display}
              </Text>
            </div>
          );
        })}
      </div>
      {canUpdate && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={
            daysOfTheWeekToSpanishLong[
              selectedDay?.code as CampaignSchedulingDays
            ]
          }
        >
          <div className={'campaign-scheduling-modal-row'}>
            <Input
              placeholder={'00:00'}
              defaultValue={selectedDayStartTime}
              onChange={handleStartTimeChange}
              type={'time'}
            />
            <Input
              placeholder={'00:00'}
              defaultValue={selectedDayStopTime}
              onChange={handleStopTimeChange}
              type={'time'}
            />
            <Toggle
              defaultChecked={selectedDayActive}
              onChange={handleActiveChange}
            />
          </div>
          <Button
            label={'Guardar'}
            isLoading={isLoading}
            onClick={handleSave}
          />
        </Modal>
      )}
    </>
  );
};

export default CampaignConfigScheduling;
