import api from '../../../api';

/**
 * authenticateTokenPassword
 * @description Function that obtains JTW token with email and password
 * @param email
 * @param password
 * @returns {Object} { status, data: {accessToken, refreshToken} } | { status, ...errors }
 */

export const authenticateTokenPassword = async (
  email: string,
  password: string,
) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/token/',
      {
        email,
        password,
      },
      { headers: { 'No-Auth': true } },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * authenticateTokenGoogle
 * @description Function that obtains JTW tokens with Google OAuth
 * @param code
 * @returns {Object} { status, data: {accessToken, refreshToken} } | { status, ...errors }
 */

export const authenticateTokenGoogle = async (code: string) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/token/google/',
      {
        code,
      },
      { headers: { 'No-Auth': true } },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * verifyTwoFactorAuthCode
 * @description Function that verifies 2FA code
 * @param email
 * @param code
 * @returns {Object} { status, data: {accessToken, refreshToken} } | { status, ...errors }
 */

export const verifyTwoFactorAuthCode = async (email: string, code: string) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/token/2fa/',
      {
        email,
        code,
      },
      { headers: { 'No-Auth': true } },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * confirmAccountVerify
 * @description Function that confirms account link
 * @param uid
 * @param token
 * @returns {Object} { status, data: {confirmationStatus} } | { status, ...errors }
 */

export const confirmAccountVerify = async (uidb64: string, token: string) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/user/confirm/',
      {
        uidb64,
        token,
      },
      { headers: { 'No-Auth': true } },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * confirmAccountResend
 * @description Function that resends account confirmation email
 * @param uidb64
 * @param token
 * @returns {Object} { status, data: {resendStatus} } | { status, ...errors }
 */

export const confirmAccountResend = async (uidb64: string, token: string) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/user/confirm/resend/',
      {
        uidb64,
        token,
      },
      { headers: { 'No-Auth': true } },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * requestPasswordRecovery
 * @description Function that sends email to reset password
 * @param email
 * @returns {Object} { status, data: {requestStatus} } | { status, ...errors }
 */

export const requestPasswordRecovery = async (email: string) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/user/recovery/request/',
      {
        email,
      },
      { headers: { 'No-Auth': true } },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * requestPasswordReset
 * @description Function that resets password
 * @param uidb64
 * @param token
 * @param password
 * @param vPassword
 * @returns {Object} { status, data: {resetStatus} } | { status, ...errors }
 */

export const requestPasswordReset = async (
  uidb64: string,
  token: string,
  password: string,
  vPassword: string,
) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/user/recovery/',
      {
        uidb64,
        token,
        password,
        vPassword,
      },
      { headers: { 'No-Auth': true } },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};
