import React from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import Card from 'components/interface/card';

import { type IconName } from 'components/interface/icon';

type SelectableCardProps = {
  title: string;
  description?: string;
  iconName?: IconName;
  isSelected?: boolean;
};

/**
 * SelectableCard
 * @description Selectable card component
 * @param {string} title - Title of the card
 * @param {string} description - Description of the card
 * @param {string} iconName - Icon name of the card
 * @param {boolean} isSelected - Is card selected
 * @returns {TSX.Element} SelectableCard component
 */

const SelectableCard: React.FC<SelectableCardProps> = ({
  title,
  description,
  iconName,
  isSelected = false,
}) => {
  return (
    <Card
      style={
        isSelected
          ? {
              border: '1px solid var(--primary)',
              outline: '4px solid var(--primary-pale)',
            }
          : {}
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 16,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 'var(--border-input)',
            backgroundColor: isSelected ? 'var(--primary-pale)' : 'var(--pale)',
            height: 'fit-content',
            width: 'fit-content',
            padding: 4,
          }}
        >
          <Icon
            name={iconName}
            size={20}
            color={isSelected ? 'var(--primary)' : 'var(--gray-2)'}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Text variant={'h8'}>{title}</Text>
          {description && <Text variant={'b3'}>{description}</Text>}
        </div>
      </div>
    </Card>
  );
};

export default SelectableCard;
