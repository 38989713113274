import React from 'react';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Text from 'components/interface/text';
import Input from 'components/interface/input';
import Button from 'components/interface/button';

import { useAuth } from '../hooks/useAuth';

type Inputs = {
  email: string;
  password: string;
};

const LoginForm: React.FC = () => {
  const { authPassword } = useAuth();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await authPassword(data.email, data.password);

    if (response?.errors) {
      if (response.errors?.email) {
        setError('email', {
          type: 'manual',
          message: response.errors?.email,
        });
      }
      if (response.errors?.password) {
        setError('password', {
          type: 'manual',
          message: response.errors?.password,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar los parametros');
      }
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant={'label'}>Continúa con tu correo electrónico</Text>
      <Input
        type={'email'}
        placeholder={'Email'}
        error={errors?.email?.message}
        {...register('email')}
      />
      <Input
        type={'password'}
        placeholder={'Password'}
        error={errors?.password?.message}
        {...register('password')}
      />
      <Text variant={'s1'} align={'right'}>
        <Link to={'/auth/recovery'}>¿Olvidaste tu contraseña?</Link>
      </Text>
      <Button type={'submit'} label={'Iniciar sesión'} isLoading={isLoading} />
    </form>
  );
};

export default LoginForm;
