import React, { useState, useEffect } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Image from 'components/interface/image';
import Button from 'components/interface/button';

import { type ChatbotWhatsappIntegration } from 'features/chatbots/providers/ChatbotProvider';
import { type ChatbotPhoneCallIntegration } from 'features/chatbots/providers/ChatbotProvider';

import { formatPhoneNumber } from 'utils/strings';

import whatsappLogo from 'assets/images/integrations/whatsapp.png';

import './styles.css';

type Integrations = 'whatsapp' | 'phonecall';

type IntegrationCardProps = {
  type: Integrations;
  integration?: ChatbotWhatsappIntegration | ChatbotPhoneCallIntegration | null;
  isAddCard?: boolean;
  isEditable?: boolean;
  onClick?: () => void;
};

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  type,
  integration,
  isAddCard = false,
  isEditable = false,
  onClick = () => {},
}) => {
  const [displayId, setDisplayId] = useState<string>('');

  useEffect(() => {
    if (!integration) return;

    if (type === 'whatsapp' && 'whatsappNumberId' in integration) {
      setDisplayId(integration?.whatsappNumberId);
    } else if (type === 'phonecall' && 'phoneNumber' in integration) {
      setDisplayId(integration?.phoneNumber);
    }
  }, [integration, type]);

  return (
    <div className={'integration-card'}>
      <div className={'integration-card__header'}>
        {type === 'whatsapp' ? (
          <>
            <Image
              src={whatsappLogo}
              width={40}
              height={40}
              blackAndWhite={isAddCard}
            />
            <div className={'integration-card__header__text'}>
              <Text variant={'h8'}>WhatsApp API</Text>
              <div className={'integration-card__id'}>
                <Text
                  variant={'b3'}
                  size={11}
                  color={'var(--gray)'}
                  numberOfLines={1}
                >
                  {!isAddCard ? displayId : 'Sin integración'}
                </Text>
              </div>
            </div>
          </>
        ) : (
          type === 'phonecall' && (
            <>
              <div
                className={`integration-card_header_icon_container ${
                  isAddCard ? 'grayscale' : ''
                }`}
              >
                <Icon name={'phone'} size={32} color={'var(--white)'} />
              </div>
              <div className={'integration-card__header__text'}>
                <Text variant={'h8'}>Llamadas</Text>
                <div className={'integration-card__id'}>
                  <Text
                    variant={'b3'}
                    size={11}
                    color={'var(--gray)'}
                    numberOfLines={1}
                  >
                    {!isAddCard
                      ? formatPhoneNumber(displayId)
                      : 'Sin integración'}
                  </Text>
                </div>
              </div>
            </>
          )
        )}
      </div>
      <div className={'integration-card__body'}>
        <Button
          variant={'secondary'}
          label={isAddCard ? 'Configurar' : 'Editar'}
          icon={
            <Icon
              name={'plugs'}
              size={16}
              color={!isEditable ? 'var(--gray-light)' : 'var(--body)'}
            />
          }
          isDisabled={!isEditable}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default IntegrationCard;
