import React, { useEffect } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import { type ChatbotKnowledgeSource } from 'features/chatbots/providers/ChatbotProvider';

import { formatDate } from 'utils/dates';

import './styles.css';

type KnowledgeSourceCardProps = {
  knowledgeSource?: ChatbotKnowledgeSource;
  isAddCard?: boolean;
  onClick?: () => void;
};

const KnowledgeSourceCard: React.FC<KnowledgeSourceCardProps> = ({
  knowledgeSource,
  isAddCard = false,
  onClick = () => {},
}) => {
  useEffect(() => {}, [knowledgeSource]);

  return (
    <button className={'knowledge-source-card'} onClick={onClick}>
      {isAddCard ? (
        <div className={'knowledge-source-card__add'}>
          <Icon name={'plus'} size={20} color={'var(--border-hover)'} />
          <div style={{ height: 8 }}></div>
          <Text variant={'b3'} color={'var(--border-hover)'} align={'center'}>
            Agregar nueva fuente
          </Text>
        </div>
      ) : (
        <div className={'knowledge-source-card__body'}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <Icon name={'bookBookmark'} size={16} />
            <Text variant={'h8'} numberOfLines={1}>
              {knowledgeSource?.name ?? ''}
            </Text>
          </div>
          <Text variant={'b3'} numberOfLines={3}>
            Fuente:{' '}
            {knowledgeSource?.type === 'text' ? 'Texto' : 'Documento PDF'}
          </Text>
          <Text
            variant={'b3'}
            color={'var(--gray)'}
            numberOfLines={3}
            style={{ marginTop: 'auto' }}
          >
            Última actualización:{' '}
            {formatDate(knowledgeSource?.lastUpdated ?? '')}
          </Text>
        </div>
      )}
    </button>
  );
};

export default KnowledgeSourceCard;
