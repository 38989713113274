import React from 'react';

import Hr from 'components/interface/hr';
import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import IconButton from 'components/interface/iconButton';
import Notification from 'features/dashboard/components/notification';

import { useDashboard } from 'features/dashboard/hooks/useDashboard';
import { useNotification } from 'features/notifications/hooks/useNotification';

import './styles.css';

const NotificationSidebar = () => {
  const {
    isSidebarOpen,
    forceSidebarClosed,
    isNotificationsOpen,
    toggleNotifications,
  } = useDashboard();
  const { notifications } = useNotification();

  const handleForegroundClick = () => {
    if (isNotificationsOpen) toggleNotifications();
  };

  const handleNotificationSidebarClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div
      className={'notification-sidebar-background'}
      style={{
        display: isNotificationsOpen ? 'block' : 'none',
        left: isSidebarOpen && !forceSidebarClosed ? 226 : 60,
      }}
      onClick={handleForegroundClick}
    >
      <div
        className={'notification-sidebar'}
        style={{
          left: isSidebarOpen && !forceSidebarClosed ? 226 : 60,
        }}
        onClick={handleNotificationSidebarClick}
      >
        <div className={'notification-sidebar-header'}>
          <Text variant={'h6'}>Notificaciones</Text>
          <IconButton iconName={'close'} onClick={toggleNotifications} />
        </div>
        <Hr marginTop={8} marginBottom={8} />
        <div className={'notification-sidebar-content'}>
          {notifications.map((notification, index) => (
            <Notification key={index} notification={notification} />
          ))}
          {notifications.length === 0 && (
            <div className={'notification-sidebar-content-empty'}>
              <Icon name={'bellZ'} size={24} color={'var(--gray-2)'} />
              <Text variant={'b3'} color={'var(--gray-2)'}>
                No tienes notificaciones
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationSidebar;
