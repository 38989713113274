import React from 'react';

import Text from 'components/interface/text';
import Card from 'components/interface/card';

import {
  type BillingPlan,
  type BillingCycle,
} from 'features/organization/providers/OrganizationProvider';

import { formatNumber } from 'utils/numbers';

import './styles.css';

type PlanCardProps = {
  billing: BillingPlan;
  cycle: BillingCycle;
};

/**
 * PlanCard
 * @description Description of PlanCard
 * @param {BillingPlan} billing - Billing information
 * @param {BillingCycle} cycle - Billing cycle
 * @returns {TSX.Element} PlanCard component
 */

const PlanCard: React.FC<PlanCardProps> = ({ billing, cycle }) => {
  return (
    <div className={'plan-card'}>
      <Card title={'Plan activo'}>
        <div className={'plan-card-items'}>
          <div className={'plan-card-item'}>
            <Text variant={'b3'} color={'var(--gray)'}>
              Minutos de Llamada
            </Text>
            <div className={'plan-card-item__content'}>
              <Text variant={'h6'} size={16} weight={400}>
                {formatNumber(billing.outboundCallMinutesLimit)} Minutos
              </Text>
            </div>
          </div>
          <div className={'plan-card-item'}>
            <Text variant={'b3'} color={'var(--gray)'}>
              Mensajes WhatsApp Outbound
            </Text>
            <div className={'plan-card-item__content'}>
              <Text variant={'h6'} size={16} weight={400}>
                {formatNumber(billing.outboundMessageLimit)} Mensajes
              </Text>
            </div>
          </div>
          <div className={'plan-card-item'}>
            <Text variant={'b3'} color={'var(--gray)'}>
              Mensajes WhatsApp Generados IA
            </Text>
            <div className={'plan-card-item__content'}>
              <Text variant={'h6'} size={16} weight={400}>
                {formatNumber(billing.generatedMessageLimit)} Mensajes
              </Text>
            </div>
          </div>
          <div className={'plan-card-item'}>
            <Text variant={'b3'} color={'var(--gray)'}>
              Concurrencia de Llamadas
            </Text>
            <div className={'plan-card-item__content'}>
              <Text variant={'h6'} size={16} weight={400}>
                {formatNumber(billing.concurrencyLimit)} Llamadas Simultaneas
              </Text>
            </div>
          </div>
          <div className={'plan-card-item'}>
            <Text variant={'b3'} color={'var(--gray)'}>
              Asientos
            </Text>
            <div className={'plan-card-item__content'}>
              <Text variant={'h6'} size={16} weight={400}>
                {formatNumber(billing.seatLimit)} Usuarios
              </Text>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PlanCard;
