import api from 'api';

/**
 * fetchNotifications
 * @description Fetches unread notifications from the API
 * @returns {Object} The fetch promise response
 */

export const fetchNotifications = async () => {
  try {
    const response = await api.get(
      process.env.REACT_APP_WEST_BASE_URL + '/notifications/',
    );
    return { status: 200, data: response.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      data: error?.response?.data,
    };
  }
};

/**
 * postReadNotification
 * @description Marks a notification as read
 * @param {number} id The ID of the notification to mark as read
 * @returns {Object} Success or error response
 */

export const postReadNotification = async (id: number) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/notifications/',
      { id },
    );
    return { status: 200, data: response.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      data: error?.response?.data,
    };
  }
};
