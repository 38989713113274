import React, { useState } from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import GeneralCard from '../features/overview/components/generalCard';
import UsageCard from 'features/dashboard/features/overview/components/usageCard';
import CampaignSummaryCard from '../features/overview/components/campaignSummaryCard';
import CampaignsActiveCard from '../features/overview/components/campaignsActiveCard';
import OrganizationMembersCard from '../features/overview/components/organizationMembersCard';

import { useUser } from 'features/user/hooks/useUser';

import { formatDateLongWithWeekday } from 'utils/dates';

const DashboardView = () => {
  const { userHasPermission } = useUser();

  const [todayDate] = useState(new Date());
  const [showUsage] = useState<boolean>(userHasPermission('billing.read'));

  return (
    <div className={'dashboard-page'}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 6,
          marginBottom: 24,
        }}
      >
        <Icon name={'calendarBlank'} size={16} color={'var(--gray)'} />
        <Text variant={'h8'} weight={400} color={'var(--gray)'}>
          {formatDateLongWithWeekday(String(todayDate))}
        </Text>
      </div>
      <div
        style={{
          display: 'flex',
          gap: 16,
          marginBottom: 16,
        }}
      >
        <GeneralCard />
        <OrganizationMembersCard />
      </div>
      <CampaignSummaryCard />
      <div
        style={{
          display: 'flex',
          gap: 16,
          marginTop: 16,
          marginBottom: 16,
        }}
      >
        <CampaignsActiveCard />
        {showUsage && <UsageCard />}
      </div>
    </div>
  );
};

export default DashboardView;
