import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Hr from 'components/interface/hr';
import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Button from 'components/interface/button';
import Select from 'components/interface/select';
import SettingsFields from 'components/interface/settingsFields';

import { useUser } from 'features/user/hooks/useUser';
import { useOrganization } from 'features/organization/hooks/useOrganization';

import { OrganizationMember } from 'features/organization/providers/OrganizationProvider';

import 'ka-table/style.css';

type FormInputs = {
  role: string;
};

type MemberDetailsViewProps = {
  member: OrganizationMember;
  goBack: () => void;
};

const MemberDetailsView: React.FC<MemberDetailsViewProps> = ({
  member,
  goBack,
}) => {
  const { userDisplayData, userHasPermission } = useUser();
  const {
    organizationData,
    updateOrganizationMember,
    removeOrganizationMember,
  } = useOrganization();
  const [roleOptions, setRoleOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>();

  const isAccessible =
    organizationData.roles?.[member?.role]?.name !== 'Owner' &&
    member?.email !== userDisplayData?.email &&
    userHasPermission('organization.update');

  useEffect(() => {
    if (!isAccessible) goBack();
    const options: { value: string; label: string }[] = [];
    Object.keys(organizationData.roles).forEach((key: string) => {
      if (
        !(
          organizationData.roles[key].name === 'Owner' &&
          organizationData.roles[key].isSystem === true
        )
      ) {
        options.push({ value: key, label: organizationData.roles[key].name });
      }
    });
    setRoleOptions(options);
  }, [member]);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await updateOrganizationMember(member.id, data.role);

    if (response?.errors) {
      if (response.errors?.role) {
        setError('role', {
          type: 'manual',
          message: response.errors?.role,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar los parametros');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Datos actualizados');
      } else {
        toast.error('Error al actualizar los datos');
        reset();
      }
    }
    setIsLoading(false);
  };

  const handleRemoveMember = async () => {
    const response = await removeOrganizationMember(member.id);
    if (response?.errors) {
      if (typeof response.errors === 'string') {
        toast.error('Error al eliminar el miembro');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Miembro eliminado con éxito');
      } else {
        toast.error('Error al eliminar el miembro');
      }
    }
  };

  const fields = {
    role: {
      label: 'Asignar nuevo rol',
      description:
        'Selecciona el rol que deseas asignar a este miembro. Para más detalles, visita la pestaña "Roles".',
      component: (
        <Select
          options={roleOptions}
          placeholder={'Selecciona un rol'}
          selectedOption={member.role}
          error={errors?.role?.message}
          {...register('role')}
        />
      ),
    },
    kick: {
      label: 'Eliminar miembro',
      description:
        'Elimina a este miembro de tu organización. Este usuario perdera acceso a la aplicación.',
      component: (
        <Button
          variant={'secondary'}
          label={'Eliminar miembro'}
          icon={<Icon name={'userMinus'} />}
          requiresConfirmation={true}
          confirmationTitle={'Eliminar miembro'}
          confirmationText={`¿Estás seguro que deseas eliminar a ${member.displayName} de tu organización?`}
          onClick={handleRemoveMember}
        />
      ),
    },
  };

  return (
    <>
      {isAccessible && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginTop: -16 }}>
            <Button
              variant={'tertiary'}
              label={'Lista de miembros'}
              icon={<Icon name={'arrowLeft'} />}
              onClick={goBack}
            />
          </div>
          <div style={{ height: 16 }}></div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Text variant={'h6'}>{member.displayName}</Text>
            <div className={'member-cell-info-role-large'}>
              <Text variant={'b2'} color={'var(--gray)'} size={12}>
                {organizationData.roles[member.role].name}
              </Text>
            </div>
          </div>
          <Text variant={'b1'} color={'var(--gray)'}>
            {member.email}
          </Text>
          <Hr />
          <SettingsFields fields={fields} />
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
            <Button
              label={'Cancelar'}
              variant={'secondary'}
              onClick={() => reset()}
            />
            <Button
              type={'submit'}
              label={'Guardar'}
              variant={'primary'}
              isLoading={isLoading}
            />
          </div>
        </form>
      )}
    </>
  );
};

export default MemberDetailsView;
