import React, { useState, useEffect } from 'react';

import { Pie } from '@nivo/pie';

import Text from 'components/interface/text';
import Card from 'components/interface/card';

import { useDashboard } from 'features/dashboard/hooks/useDashboard';
import { useOrganization } from 'features/organization/hooks/useOrganization';

import './styles.css';

/**
 * CampaignSummaryCard component
 * @description This component is used to display current period's campaign data information in the overview page
 * @returns {React.FunctionComponent}
 */

const CampaignSummaryCard = () => {
  const { organizationData } = useOrganization();
  const { getCurrentCycleCampaignsData } = useDashboard();

  const [totalCampaigns, setTotalCampaigns] = useState<number>(0);
  const [totalMessagesSent, setTotalMessagesSent] = useState<number>(0);
  const [totalMessagesDelivered, setTotalMessagesDelivered] =
    useState<number>(0);
  const [totalMessagesAccepted, setTotalMessagesAccepted] = useState<number>(0);
  const [totalTotalMessages, setTotalTotalMessages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getCycleCampaignStats = async () => {
      if (!organizationData?.id) return;

      const data = await getCurrentCycleCampaignsData(organizationData?.id);

      setTotalCampaigns(data?.totalCampaigns ?? 0);
      setTotalMessagesSent(data?.totalMessagesSent ?? 0);
      setTotalMessagesDelivered(data?.totalMessagesDelivered ?? 0);
      setTotalMessagesAccepted(data?.totalMessagesAccepted ?? 0);
      setTotalTotalMessages(
        (data?.totalMessagesSent ?? 0) +
          (data?.totalMessagesDelivered ?? 0) +
          (data?.totalMessagesAccepted ?? 0),
      );

      setIsLoading(false);
    };

    getCycleCampaignStats();
  }, []);

  return (
    <Card title={'Resumen de las campañas del periodo actual'}>
      <div className={'overview-campaign-summary'}>
        <div className={'overview-campaign-summary-chart'}>
          <Pie
            animate={false}
            data={[
              {
                id: 'totalDelivered',
                label: 'Entregados',
                value: totalMessagesDelivered,
              },
              {
                id: 'totalAccepted',
                label: 'Aceptados',
                value: totalMessagesAccepted,
              },
              {
                id: 'totalSent',
                label: 'Enviados',
                value: totalMessagesSent !== undefined ? totalMessagesSent : 1,
              },
            ]}
            height={64}
            width={64}
            innerRadius={0.6}
            colors={['var(--primary)', 'var(--success)', 'var(--gray-light)']}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            isInteractive={false}
          />
          <div>
            {isLoading ? (
              <div
                className={'skeleton-loader'}
                style={{
                  height: 30,
                  width: 40,
                  borderRadius: 'var(--border-input)',
                }}
              />
            ) : (
              <Text variant={'h5'}>{totalCampaigns}</Text>
            )}
            <Text variant={'b3'}>Campañas</Text>
          </div>
        </div>
        <div className={'overview-campaign-summary-stats'}>
          <div className={'overview-campaign-summary-stat'}>
            {isLoading ? (
              <div
                className={'skeleton-loader'}
                style={{
                  height: 20,
                  width: 40,
                  borderRadius: 'var(--border-input)',
                }}
              />
            ) : (
              <Text variant={'h7'} formatter={'percentage'}>
                {isLoading ? 0 : 100}
              </Text>
            )}
            <div>
              <span
                style={{
                  backgroundColor: 'var(--queue)',
                  borderColor: 'var(--queue-pale)',
                }}
              />
              <Text variant={'b3'}>Enviados</Text>
            </div>
          </div>
          <div className={'overview-campaign-summary-stat'}>
            {isLoading ? (
              <div
                className={'skeleton-loader'}
                style={{
                  height: 20,
                  width: 40,
                  borderRadius: 'var(--border-input)',
                }}
              />
            ) : (
              <Text variant={'h7'} formatter={'percentage'}>
                {((totalMessagesAccepted + totalMessagesDelivered) /
                  totalTotalMessages) *
                  100}
              </Text>
            )}
            <div>
              <span
                style={{
                  backgroundColor: 'var(--primary)',
                  borderColor: 'var(--primary-pale)',
                }}
              />
              <Text variant={'b3'}>Entregados</Text>
            </div>
          </div>
          <div className={'overview-campaign-summary-stat'}>
            {isLoading ? (
              <div
                className={'skeleton-loader'}
                style={{
                  height: 20,
                  width: 40,
                  borderRadius: 'var(--border-input)',
                }}
              />
            ) : (
              <Text variant={'h7'} formatter={'percentage'}>
                {(totalMessagesAccepted / totalTotalMessages) * 100}
              </Text>
            )}
            <div>
              <span
                style={{
                  backgroundColor: 'var(--success)',
                  borderColor: 'var(--success-pale)',
                }}
              />
              <Text variant={'b3'}>Aceptados</Text>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CampaignSummaryCard;
