import React, { useState, useEffect } from 'react';
import { DataType, Table } from 'ka-table';
import { SortingMode } from 'ka-table/enums';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import Button from 'components/interface/button';
import Modal from 'components/interface/modal';
import IconButton from 'components/interface/iconButton';
import ListTag from 'components/interface/listTag';
import EmptyState from 'components/interface/emptyState';

import ContactListForm from 'features/dashboard/features/contacts/forms/ContactListForm';

import { useEndUser } from 'features/endUsers/hooks/useEndUser';

import { type EndUserList } from 'features/endUsers/providers/EndUserProvider';

import './styles.css';

type ContactListsProps = {
  canUpdate: boolean;
};

/**
 * ContactLists
 * @description Component that displays the contact lists of the organization in a table
 * @param {boolean} canUpdate - If the user can update contact lists
 * @returns {TSX.Element} Contacts component
 */

const ContactLists: React.FC<ContactListsProps> = ({ canUpdate = false }) => {
  const { endUserLists } = useEndUser();

  const [endUserListsArray, setEndUserListsArray] = useState(
    Object.values(endUserLists),
  );
  const [openEndUserList, setOpenEndUserList] = useState<EndUserList | null>(
    null,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const endUserListsArrayAux = Object.values(endUserLists);

    endUserListsArrayAux.forEach((endUserList) => {
      endUserList.endUserCount = endUserList.endUsers.length;
    });

    setEndUserListsArray(endUserListsArrayAux);
  }, [endUserLists]);

  useEffect(() => {
    if (!isModalOpen) setOpenEndUserList(null);
  }, [isModalOpen]);

  const handleOpenModal = (endUserList?: EndUserList) => {
    setOpenEndUserList(endUserList ?? null);
    setIsModalOpen(true);
  };

  return (
    <div>
      <div className={'contact-lists-header'}>
        <div className={'contact-lists-header-left'}>
          {/* Noting to show here */}
        </div>
        <div className={'contact-lists-header-right'}>
          {canUpdate && (
            // TODO: Update lists button (Permissions)
            <></>
          )}
          <Button
            label={'Nueva lista'}
            variant={'secondary'}
            icon={<Icon name={'plus'} />}
            onClick={() => handleOpenModal()}
          />
        </div>
      </div>
      <div className={'contact-lists-table'}>
        <Table
          rowKeyField={'id'}
          columns={[
            {
              key: 'id',
              dataType: DataType.String,
              title: 'Lista',
            },
            {
              key: 'description',
              dataType: DataType.String,
              title: 'Descripción',
            },
            {
              key: 'endUserCount',
              dataType: DataType.Number,
              title: 'Contactos',
            },
            {
              key: 'actions',
              title: '',
              width: 72,
              style: { textAlign: 'right' },
            },
          ]}
          data={endUserListsArray}
          childComponents={{
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case 'id':
                    return (
                      <ListTag
                        text={endUserLists?.[props.rowKeyValue]?.name}
                        color={endUserLists?.[props.rowKeyValue]?.color as any}
                        iconName={'userList'}
                      />
                    );
                  case 'description':
                    return (
                      <Text
                        variant={'b3'}
                        color={'var(--gray)'}
                        numberOfLines={1}
                      >
                        {props.value}
                      </Text>
                    );
                  case 'endUserCount':
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Icon name={'users'} size={16} />
                        <Text variant={'b2'} numberOfLines={1}>
                          {props.value}
                        </Text>
                      </div>
                    );
                  case 'actions':
                    return (
                      <div className={'contact-lists-actions'}>
                        <IconButton
                          iconName={'gear'}
                          onClick={() =>
                            handleOpenModal(endUserLists?.[props.rowKeyValue])
                          }
                        />
                      </div>
                    );
                }
              },
            },
          }}
          sortingMode={SortingMode.Single}
        />
      </div>
      {endUserListsArray.length === 0 && (
        <div style={{ marginBottom: 80 }}>
          <EmptyState text={'No hay listas'} iconName={'userList'} />
        </div>
      )}
      {canUpdate ? (
        <Modal
          isOpen={isModalOpen}
          title={
            openEndUserList
              ? 'Editar lista de contactos'
              : 'Nueva lista de contactos'
          }
          onClose={() => setIsModalOpen(false)}
        >
          <ContactListForm
            endUserList={openEndUserList}
            onSubmitCallback={() => setIsModalOpen(false)}
          />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ContactLists;
