import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import { useInbox } from 'features/inbox/hooks/useInbox';

import { type Message } from 'features/inbox/types/inboxTypes';

import './styles.css';

/**
 * MessageContentAudio
 * @description Component to display and play an audio in a message's content
 * @param {Message} message - message object
 * @returns {TSX.Element} MessageContentAudio component
 */

type MessageContentAudioProps = {
  message: Message;
};

const MessageContentAudio: React.FC<MessageContentAudioProps> = ({
  message,
}) => {
  const { inbox, getMessageMediaBinary } = useInbox();
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    if (!audio) return;

    const handleEnded = () => setIsPlaying(false);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('ended', handleEnded);
    };
  }, [audio]);

  const toggleAudio = async () => {
    if (!inbox.activeThread || isLoading) return;

    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
      return;
    }

    setIsLoading(true);

    try {
      const mediaData = await getMessageMediaBinary(
        inbox.activeThread.chatbotId,
        inbox.activeThread.integrationId,
        message.media,
      );

      if (mediaData?.binary.length === 0)
        throw new Error('No se pudo obtener el audio');

      const url = `data:${mediaData?.mimeType};base64,${mediaData?.binary}`;
      const audioAux = new Audio(url);
      setAudio(audioAux);
      audioAux.play();
      setIsPlaying(true);
    } catch (error) {
      toast.error('Error al reproducir el audio');
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className={'inbox-message-audio'}>
        <div style={{ cursor: 'pointer' }} onClick={toggleAudio}>
          <Icon
            name={isPlaying ? 'pause' : 'play'}
            style={'fill'}
            size={20}
            color={'var(--gray)'}
          />
        </div>
        <div className={'inbox-message-audio-content'}>
          <div
            style={{ display: 'flex', alignItems: 'center', marginLeft: -4 }}
          >
            <Icon
              name={'microphone'}
              style={'fill'}
              size={12}
              color={'var(--gray)'}
            />
            <Text variant={'b3'} color={'var(--gray)'}>
              Transcripción
            </Text>
          </div>
          <Text variant={'b2'}>{message.content}</Text>
        </div>
      </div>
    </>
  );
};

export default MessageContentAudio;
