import React, { FC, createContext, useReducer, useMemo } from 'react';

import organizationReducer from './organizationReducer';

export type OrganizationMember = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  profilePicture: string;
  role: string;
  isOnline: boolean;
  lastOnline: string;
};

export type OrganizationRole = {
  id: string;
  name: string;
  description: string;
  permissions: Object;
  isSystem: boolean;
};

export type OrganizationInvite = {
  code: string;
  email: string;
  sender: string;
  organization: string;
  sentAt: string;
  lastRetry: string;
  revoked: boolean;
};

export type OrganizationInviteDetails = {
  code: string;
  email: string;
  organizationName: string;
};

export type BillingCycleDay = {
  date: string;
  generatedMessageCount: number;
  outboundMessageCount: number;
  outboundCallCount: number;
  outboundCallConnectedCount: number;
  outboundCallMinutes: number;
};

export type BillingCycle = {
  id: number;
  startCycleDate: string;
  nextCycleDate: string;
  generatedMessageCount: number;
  outboundMessageCount: number;
  outboundCallCount: number;
  outboundCallConnectedCount: number;
  outboundCallMinutes: number;
  days: { [key: string]: BillingCycleDay };
};

export type BillingPlan = {
  outboundCallMinutesLimit: number;
  generatedMessageLimit: number;
  outboundMessageLimit: number;
  concurrencyLimit: number;
  seatLimit: number;
  billingCycles: { [key: string]: BillingCycle };
};

export type OrganizationData = {
  id: string;
  name: string;
  timezone: string;
  members: { [key: string]: OrganizationMember };
  roles: { [key: string]: OrganizationRole };
  invites: { [key: string]: OrganizationInvite };
  billing: BillingPlan;
};

export type OrganizationState = {
  hasOrganization: boolean;
  organizationData: OrganizationData;
  saveHasOrganization: (hasOrganization: boolean) => void;
  saveOrganizationData: (organizationData: OrganizationData) => void;
};

type OrganizationProviderProps = {
  children: React.ReactNode;
};

export const OrganizationContext = createContext<OrganizationState>({
  hasOrganization: false,
  organizationData: {
    id: '',
    name: '',
    timezone: '',
    members: {},
    roles: {},
    invites: {},
    billing: {
      outboundCallMinutesLimit: 0,
      generatedMessageLimit: 0,
      outboundMessageLimit: 0,
      concurrencyLimit: 0,
      seatLimit: 0,
      billingCycles: {},
    },
  },
  saveHasOrganization: () => {},
  saveOrganizationData: () => {},
});

const OrganizationProvider: FC<OrganizationProviderProps> = ({ children }) => {
  const initialState = {
    hasOrganization: false,
    organizationData: {
      id: '',
      name: '',
      timezone: '',
    },
    saveHasOrganization: (hasOrganization: boolean) => {
      return null;
    },
    saveOrganizationData: (organizationData: OrganizationData) => {
      return null;
    },
  };

  const [state, dispatch] = useReducer(organizationReducer, initialState);

  const saveHasOrganization = (hasOrganization: boolean) => {
    dispatch({ type: 'SAVE_HAS_ORGANIZATION', payload: hasOrganization });
  };

  const saveOrganizationData = (organizationData: OrganizationData) => {
    dispatch({ type: 'SAVE_ORGANIZATION_DATA', payload: organizationData });
  };

  const contextValue = useMemo(() => {
    return {
      hasOrganization: state.hasOrganization,
      organizationData: state.organizationData,
      saveHasOrganization,
      saveOrganizationData,
    };
  }, [state.hasOrganization, state.organizationData]);

  return (
    <OrganizationContext.Provider value={contextValue}>
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationProvider;
