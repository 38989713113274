import React from 'react';

import { UUID } from 'crypto';

import { EndUserContext } from '../providers/EndUserProvider';

import {
  fetchEndUsersData,
  fetchEndUserLists,
  postCreateEndUserList,
  putUpdateEndUserList,
  deleteEndUserList,
  postAddEndUserToList,
  postRemoveEndUserFromList,
} from '../functions/endUserFunctions';

import { type EndUser, EndUserList } from '../providers/EndUserProvider';

/**
 * @hook useEndUser
 * @description This hook is used to access the endUsers context
 * @returns {Object} The endUsers context
 */
export const useEndUser = () => {
  const {
    endUsersData,
    endUserCount,
    endUserLists,
    saveEndUsersData,
    saveEndUserCount,
    saveEndUserLists,
  } = React.useContext(EndUserContext);

  /**
   * @function getEndUsersData
   * @description Fetches organization's contacts from the API and saves it
   * @param {number} pageIndex Page number
   * @param {number} pageSize Page size
   * @param {string} sortBy Sort column name
   * @param {string} sortOrder Sort order by 'asc' or 'desc'
   * @param {string} searchTerm Search term
   * @returns {Object} { status, data: {endUsersData} } | { status, ...errors }
   */
  const getEndUsersData = async (
    pageIndex: number,
    pageSize: number,
    sortBy: string,
    sortOrder: 'asc' | 'desc',
    searchTerm?: string,
  ): Promise<any> => {
    const response = await fetchEndUsersData(
      pageIndex,
      pageSize,
      sortBy,
      sortOrder,
      searchTerm,
    );
    if (response.status === 200) {
      const endUserCount = response.data?.count;
      const endUsersDataAux = response.data?.endUsers;
      const endUsersDict: { [key: UUID]: EndUser } = {};

      endUsersDataAux.forEach((endUser: EndUser) => {
        const inLists = Object.keys(endUserLists).filter((listId) =>
          endUserLists[listId].endUsers.includes(endUser.id),
        );

        endUsersDict[endUser.id] = {
          id: endUser.id,
          displayName: endUser.displayName,
          info: endUser.info,
          lastSeen: endUser.lastSeen,
          inLists: inLists,
          whatsapp: endUser.whatsapp,
          phoneCall: endUser.phoneCall,
          api: endUser.api,
        };
      });

      saveEndUsersData(endUsersDict);
      saveEndUserCount(endUserCount);
    }

    return response;
  };

  /**
   * @function getEndUserLists
   * @description Fetches organization's contacts lists from the API and saves it
   * @returns {Object} { status, data: {endUsersLists} } | { status, ...errors }
   */
  const getEndUserLists = async (): Promise<any> => {
    const response = await fetchEndUserLists();
    if (response.status === 200) {
      const endUserListsAux = response.data;
      const endUserListsDict: { [key: string]: EndUserList } = {};

      endUserListsAux.forEach((endUserList: EndUserList) => {
        endUserListsDict[endUserList.id] = {
          id: endUserList.id,
          name: endUserList.name,
          description: endUserList.description,
          color: endUserList.color,
          endUsers: endUserList.endUsers,
        };
      });

      saveEndUserLists(endUserListsDict);
    }

    return response;
  };

  /**
   * @function createEndUserList
   * @description Creates an endUser list
   * @param {string} name EndUser list name
   * @param {string} description EndUser list description
   * @param {string} color EndUser list color
   * @returns {Object} { status, success: boolean } | { status, ...errors }
   */
  const createEndUserList = async (
    name: string,
    description: string,
    color: string,
  ): Promise<any> => {
    const response = await postCreateEndUserList(name, description, color);
    if (response.status === 200) {
      const endUserListsAux = { ...endUserLists };
      const endUserList = response.data;

      endUserListsAux[endUserList.id] = {
        id: endUserList.id,
        name: endUserList.name,
        description: endUserList.description,
        color: endUserList.color,
        endUsers: [],
      };

      saveEndUserLists(endUserListsAux);
    }

    return response;
  };

  /**
   * @function updateEndUserList
   * @description Updates an endUser list
   * @param {string} id EndUser list ID
   * @param {string} name EndUser list name
   * @param {string} description EndUser list description
   * @param {string} color EndUser list color
   * @returns {Object} { status, success: boolean } | { status, ...errors }
   */
  const updateEndUserList = async (
    id: string,
    name: string,
    description: string,
    color: string,
  ): Promise<any> => {
    const response = await putUpdateEndUserList(id, name, description, color);
    if (response.status === 200) {
      const endUserListsAux = { ...endUserLists };
      const endUserList = response.data;

      endUserListsAux[endUserList.id] = {
        id: endUserList.id,
        name: endUserList.name,
        description: endUserList.description,
        color: endUserList.color,
        endUsers: endUserListsAux[endUserList.id].endUsers,
      };

      saveEndUserLists(endUserListsAux);
    }

    return response;
  };

  /**
   * @function eliminateEndUserList
   * @description Deletes an endUser list
   * @param {string} id EndUser list ID
   * @returns {Object} { status, success: boolean } | { status, ...errors }
   */
  const eliminateEndUserList = async (id: string): Promise<any> => {
    const response = await deleteEndUserList(id);
    if (response.status === 200) {
      const endUsersDataAux = { ...endUsersData };
      Object.keys(endUsersDataAux).forEach((endUserId: any) => {
        const endUserAux = { ...endUsersDataAux[endUserId] };
        const endUserInListsAux = endUserAux.inLists;

        if (endUserInListsAux) {
          const endUserInListsFiltered = endUserInListsAux.filter(
            (listId) => listId !== id,
          );

          endUserAux.inLists = endUserInListsFiltered;
          endUsersDataAux[endUserId] = endUserAux;
        }
      });
      saveEndUsersData(endUsersDataAux);

      const endUserListsAux = { ...endUserLists };
      delete endUserListsAux[id];
      saveEndUserLists(endUserListsAux);
    }

    return response;
  };

  /**
   * @function addEndUserToList
   * @description Adds an endUser to a list
   * @param {string} endUserListId EndUser list ID
   * @param {string} endUserId EndUser ID
   * @returns {Object} { status, success: boolean } | { status, ...errors }
   */
  const addEndUserToList = async (
    endUserListId: string,
    endUserId: UUID,
  ): Promise<any> => {
    const response = await postAddEndUserToList(endUserListId, endUserId);
    if (response.status === 200) {
      const endUserListsAux = { ...endUserLists };
      endUserListsAux[endUserListId].endUsers.push(endUserId);
      saveEndUserLists(endUserListsAux);

      const endUsersDataAux = { ...endUsersData };
      const endUserAux = { ...endUsersData[endUserId] };
      const endUserInListsAux = endUserAux.inLists;

      if (endUserInListsAux) {
        endUserInListsAux.push(endUserListId);
      } else {
        endUserAux.inLists = [endUserListId];
      }

      endUsersDataAux[endUserId] = endUserAux;
      saveEndUsersData(endUsersDataAux);
    }

    return response;
  };

  /**
   * @function removeEndUserFromList
   * @description Removes an endUser from a list
   * @param {string} endUserListId EndUser list ID
   * @param {string} endUserId EndUser ID
   * @returns {Object} { status, success: boolean } | { status, ...errors }
   */
  const removeEndUserFromList = async (
    endUserListId: string,
    endUserId: UUID,
  ): Promise<any> => {
    const response = await postRemoveEndUserFromList(endUserListId, endUserId);
    if (response.status === 200) {
      const endUserListsAux = { ...endUserLists };
      const endUserListAux = { ...endUserListsAux[endUserListId] };
      const endUserListEndUsersAux = endUserListAux.endUsers.filter(
        (id) => id !== endUserId,
      );

      endUserListAux.endUsers = endUserListEndUsersAux;
      endUserListsAux[endUserListId] = endUserListAux;
      saveEndUserLists(endUserListsAux);

      const endUsersDataAux = { ...endUsersData };
      const endUserAux = { ...endUsersData[endUserId] };
      const endUserInListsAux = endUserAux.inLists;

      if (endUserInListsAux) {
        const endUserInListsFiltered = endUserInListsAux.filter(
          (id) => id !== endUserListId,
        );

        endUserAux.inLists = endUserInListsFiltered;
        endUsersDataAux[endUserId] = endUserAux;
        saveEndUsersData(endUsersDataAux);
      }
    }

    return response;
  };

  return {
    endUsersData,
    endUserCount,
    endUserLists,
    getEndUsersData,
    getEndUserLists,
    createEndUserList,
    updateEndUserList,
    eliminateEndUserList,
    addEndUserToList,
    removeEndUserFromList,
  };
};
