import React, { useState } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import { useInbox } from 'features/inbox/hooks/useInbox';

import { type Message } from 'features/inbox/types/inboxTypes';

import './styles.css';

/**
 * MessageContentDocument
 * @description Component to display an document in a message's content
 * @param {Message} message - message object
 * @returns {TSX.Element} MessageContentDocument component
 */

type MessageContentDocumentProps = {
  message: Message;
};

const MessageContentDocument: React.FC<MessageContentDocumentProps> = ({
  message,
}) => {
  const { inbox, getMessageMediaBinary } = useInbox();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const downloadDocument = async () => {
    if (!inbox.activeThread || isLoading) return;
    setIsLoading(true);

    const mediaData = await getMessageMediaBinary(
      inbox.activeThread.chatbotId,
      inbox.activeThread.integrationId,
      message.media,
    );

    const url = `data:${mediaData?.mimeType};base64,${mediaData?.binary}`;
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', message.content);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);

    setIsLoading(false);
  };

  return (
    <>
      <div
        className={'inbox-message-document'}
        onClick={() => downloadDocument()}
      >
        <Icon name={'file'} size={18} color={'var(--gray)'} />
        <Text variant={'b3'} color={'var(--gray)'} numberOfLines={2}>
          {message.content}
        </Text>
      </div>
    </>
  );
};

export default MessageContentDocument;
