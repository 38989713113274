import React, { useState } from 'react';

import Text from 'components/interface/text';

import { useInbox } from 'features/inbox/hooks/useInbox';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type Message } from 'features/inbox/types/inboxTypes';
import { type TagColor } from 'components/interface/tag';

import { formatTime } from 'utils/dates';

import './styles.css';

/**
 * MessageContentSystemTag
 * @description Component to display system tag message
 * @param {UUID} chatbotId - chatbot id
 * @param {Message} message - message object
 * @returns {TSX.Element} MessageContentSystemTag component
 */

type MessageContentSystemTagProps = {
  message: Message;
};

const MessageContentSystemTag: React.FC<MessageContentSystemTagProps> = ({
  message,
}) => {
  const { inbox } = useInbox();
  const { chatbotsData } = useChatbot();

  const [tag] = useState(
    inbox.activeThread
      ? chatbotsData[inbox.activeThread?.chatbotId].tags[message.media]
      : null,
  );

  if (!inbox.activeThread) return null;

  return (
    <>
      <div className={'inbox-message-system-tag'}>
        <Text variant={'b3'} size={10} color={'var(--gray-2)'}>
          Etiqueta
        </Text>
        <Text variant={'b3'} size={10} color={tag?.color as TagColor}>
          {tag?.name ?? ''}
        </Text>
        <Text variant={'b3'} size={10} color={'var(--gray-2)'}>
          asignada @ {formatTime(message.sentAt)}
        </Text>
      </div>
    </>
  );
};

export default MessageContentSystemTag;
