import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Icon from 'components/interface/icon';
import Button from 'components/interface/button';
import DetailView from 'features/dashboard/components/detailView';
import TabMenu from 'components/interface/tabMenu';
import CampaignFunnelGraph from 'features/dashboard/features/campaigns/components/campaignFunnelGraph';
import CampaignStatsCards from '../features/campaigns/components/campaignStatsCards';
import CampaignConfigCard from '../features/campaigns/components/campaignConfigCard';
import CampaignContacts from '../features/campaigns/components/campaignContacts';
import CampaignCalls from '../features/campaigns/components/campaignCalls';

import { useUser } from 'features/user/hooks/useUser';
import { useCampaign } from 'features/campaigns/hooks/useCampaign';

import {
  type CampaignData,
  type CampaignStats,
} from 'features/campaigns/providers/CampaignProvider';

import { type IconName } from 'components/interface/icon';
import { type TabMenuTabs } from 'components/interface/tabMenu';

import { CAMPAIGN_INTEGRATION_TYPE_PHONECALL } from 'features/campaigns/providers/CampaignProvider';

const CampaignView: React.FC = () => {
  const navigate = useNavigate();
  const { userHasPermission } = useUser();
  const { id } = useParams<{ id?: string }>();
  const { campaignsData, updateCampaignArchive } = useCampaign();

  if (id === undefined) {
    navigate('/dashboard/campaigns');
    return null;
  }

  if (!userHasPermission('campaigns.read')) {
    navigate('/dashboard');
    return null;
  }

  const canUpdate = userHasPermission('campaigns.update');

  const campaignData: CampaignData = campaignsData[id];

  const tabs: TabMenuTabs = {
    contacts: {
      label: 'Contactos',
      iconName: 'users' as IconName,
      component: (
        <CampaignContacts campaignId={campaignData?.id} canUpdate={canUpdate} />
      ),
    },
  };

  if (campaignData?.integrationType === CAMPAIGN_INTEGRATION_TYPE_PHONECALL) {
    tabs.calls = {
      label: 'Llamadas',
      iconName: 'phone' as IconName,
      component: (
        <CampaignCalls campaignId={campaignData?.id} canUpdate={canUpdate} />
      ),
    };
  }

  return (
    <div className={'dashboard-page'}>
      <DetailView
        title={campaignData?.name}
        buttons={
          canUpdate
            ? [
                <div style={{ marginTop: -16 }}>
                  <Button
                    variant={'secondary'}
                    label={campaignData?.archived ? 'Desarchivar' : 'Archivar'}
                    icon={
                      <Icon
                        name={
                          campaignData?.archived ? 'archive' : 'archiveTray'
                        }
                      />
                    }
                    onClick={() =>
                      updateCampaignArchive(id, !campaignData?.archived)
                    }
                  />
                </div>,
                <div style={{ marginTop: -16 }}>
                  <Button
                    label={'Editar'}
                    icon={<Icon name={'gear'} color={'var(--white)'} />}
                    onClick={() => navigate(`/dashboard/campaigns/${id}/edit`)}
                  />
                </div>,
              ]
            : []
        }
      >
        <CampaignFunnelGraph campaignId={campaignData?.id} />
        <CampaignStatsCards
          campaignIntegrationType={campaignData?.integrationType}
          campaignStats={campaignData?.campaignStats as CampaignStats}
        />
        <CampaignConfigCard
          campaignId={campaignData?.id}
          canUpdate={canUpdate}
        />
        <TabMenu tabs={tabs} />
      </DetailView>
    </div>
  );
};

export default CampaignView;
