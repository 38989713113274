import React from 'react';

import Image from 'components/interface/image';

import { useUser } from 'features/user/hooks/useUser';

import { type OrganizationMember } from 'features/organization/providers/OrganizationProvider';

import './styles.css';

type UserAvatarProps = {
  organizationMember: OrganizationMember;
  size?: number;
};

/**
 * UserAvatar
 * @description Renders the user's avatar with active status
 * @param {OrganizationMember} organizationMember - Member of the organization
 * @param {number} size - Size of the avatar
 * @returns {TSX.Element} UserAvatar component
 */

const UserAvatar: React.FC<UserAvatarProps> = ({
  organizationMember,
  size = 32,
}) => {
  const { userDisplayData } = useUser();

  return (
    <div
      className={`organization-member-avatar ${
        organizationMember.isOnline ||
        userDisplayData.id === organizationMember.id
          ? 'oma-online'
          : 'oma-offline'
      }`}
      style={{ width: size, height: size }}
    >
      <Image
        src={organizationMember.profilePicture}
        width={size}
        height={size}
        skeletonWidth={size}
        skeletonHeight={size}
      />
      <div />
    </div>
  );
};

export default UserAvatar;
