import React from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import { type EndUser } from 'features/endUsers/providers/EndUserProvider';

import { getEndUserInitials } from 'features/inbox/utils/strings';

import './styles.css';

type EndUserPictureProps = {
  endUser: EndUser;
  size?: number;
};

/**
 * EndUserPicture
 * @description Component to display the end user picture
 * @param {EndUser} endUser - end user object
 * @param {number} size - size of the picture
 * @returns {TSX.Element} EndUsePicture component
 */

const EndUserPicture: React.FC<EndUserPictureProps> = ({
  endUser,
  size = 36,
}) => {
  return (
    <div
      className={'inbox-thread-picture'}
      style={{ height: size, width: size }}
    >
      {!endUser.displayName ? (
        <Icon
          name={'user'}
          color={'var(--primary)'}
          size={size > 36 ? 20 : 16}
          style={'bold'}
        />
      ) : (
        <Text variant={size > 32 ? 'h7' : 'h8'} color={'var(--primary)'}>
          {getEndUserInitials(endUser.displayName)}
        </Text>
      )}
    </div>
  );
};

export default EndUserPicture;
