import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import AuthRouter from './AuthRouter';
import DashboardRouter from './DashboardRouter';
import InviteRouter from './InviteRouter';

const AppRouter = () => {
  const location = useLocation();

  const hasInviteCode = location.search.includes('code');

  return (
    <Routes>
      <Route path={'*'} element={<Navigate to={'/auth/*'} replace />} />
      <Route path={'/auth/*'} element={<AuthRouter />} />
      <Route path={'/dashboard/*'} element={<DashboardRouter />} />
      {hasInviteCode && <Route path={'/join'} element={<InviteRouter />} />}
    </Routes>
  );
};

export default AppRouter;
