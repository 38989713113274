import React from 'react';

import Text from 'components/interface/text';
import Loader from 'components/interface/loader';

type LoadingProps = {
  message?: string;
};

const Loading: React.FC<LoadingProps> = ({ message }) => {
  return (
    <div className={'loading-screen'}>
      <div className={'loading-screen-container'}>
        <Loader size={40} />
      </div>
      {message && (
        <Text variant={'h8'} color={'var(--gray)'}>
          {message}
        </Text>
      )}
    </div>
  );
};

export default Loading;
