import React, { useState, useEffect } from 'react';

import Text from 'components/interface/text';
import Modal from 'components/interface/modal';
import SendableMediaCard from 'features/dashboard/features/chatbots/components/sendableMediaCard';
import SendableMediaForm from 'features/dashboard/features/chatbots/forms/SendableMediaForm';

import { useUser } from 'features/user/hooks/useUser';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';
import { type ChatbotSendableMedia } from 'features/chatbots/providers/ChatbotProvider';

type SendableMediaViewProps = {
  chatbotData: ChatbotData;
};

const SendableMediaView: React.FC<SendableMediaViewProps> = ({
  chatbotData,
}) => {
  const { userHasPermission } = useUser();
  const { getChatbotSendableMedia } = useChatbot();
  const [isLoading, setIsLoading] = useState<boolean>(
    Object.keys(chatbotData.sendableMedia).length === 0,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [openSendableMedia, setOpenSendableMedia] =
    useState<ChatbotSendableMedia | null>(null);

  useEffect(() => {
    const getSendableMedia = async () => {
      await new Promise((resolve) => setTimeout(resolve, 600));
      await getChatbotSendableMedia(chatbotData.id);
      setIsLoading(false);
    };

    getSendableMedia();
  }, []);

  useEffect(() => {
    if (!isModalOpen) setOpenSendableMedia(null);
  }, [isModalOpen]);

  const handleOpenModal = (sendableMedia?: ChatbotSendableMedia) => {
    setOpenSendableMedia(sendableMedia ?? null);
    setIsModalOpen(true);
  };

  const isAccessible =
    userHasPermission('bots.all.update') ||
    userHasPermission(`bots.${chatbotData.id}.update`);

  return (
    <>
      <Text variant={'h8'} weight={500}>
        Archivos multimedia enviables
      </Text>
      <div style={{ height: 4 }}></div>
      <Text variant={'b3'} color={'gray'}>
        Los archivos que se agreguen a este apartado estarán disponibles para
        que el asistente los pueda enviar inteligentemente. Para esto es
        necesario una descripción clara de lo que contiene el archivo y en qué
        casos el asistente los debe de enviar. Está funcionalidad está
        disponible solo para WhatsApp.
      </Text>
      <div style={{ height: 16 }}></div>
      <div className={'sendable-media-card-container'}>
        {isLoading ? (
          <>
            <SendableMediaCard />
            <SendableMediaCard />
            <SendableMediaCard />
          </>
        ) : (
          <>
            {isAccessible ? (
              <SendableMediaCard isAddCard onClick={() => handleOpenModal()} />
            ) : null}
            {Object.keys(chatbotData.sendableMedia).map((key) => {
              const sendableMedia = chatbotData.sendableMedia[key];
              return (
                <SendableMediaCard
                  key={key}
                  sendableMedia={sendableMedia}
                  onClick={() => {
                    if (isAccessible) handleOpenModal(sendableMedia);
                  }}
                />
              );
            })}
          </>
        )}
      </div>
      {isAccessible ? (
        <Modal
          isOpen={isModalOpen}
          title={openSendableMedia ? 'Actualizar archivo' : 'Agregar archivo'}
          onClose={() => setIsModalOpen(false)}
        >
          <SendableMediaForm
            chatbotId={chatbotData.id}
            sendableMedia={openSendableMedia}
            onSubmitCallback={() => setIsModalOpen(false)}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default SendableMediaView;
