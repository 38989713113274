import React from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Card from 'components/interface/card';

import { type CampaignStats } from 'features/campaigns/providers/CampaignProvider';
import { type IconName } from 'components/interface/icon';

import { CAMPAIGN_INTEGRATION_TYPE_PHONECALL } from 'features/campaigns/providers/CampaignProvider';

import './styles.css';

type StatCardProps = {
  icon: IconName;
  label: string;
  percentage: number;
  number: string;
};

const StatCard: React.FC<StatCardProps> = ({
  icon,
  label,
  percentage,
  number,
}) => {
  return (
    <Card>
      <div className={'campaign-stat-card__header'}>
        <Icon name={icon} size={14} color={'var(--gray)'} />
        <Text variant={'b3'} color={'var(--gray)'}>
          {label}
        </Text>
      </div>
      <div className={'campaign-stat-card__body'}>
        <Text variant={'h6'} formatter={'percentage'}>
          {percentage}
        </Text>
        <Text variant={'b3'} color={'var(--gray)'}>
          {number}
        </Text>
      </div>
    </Card>
  );
};

type CampaignStatsCardsProps = {
  campaignIntegrationType: string;
  campaignStats: CampaignStats;
};

/**
 * CampaignStatsCards
 * @description Component that renders cards to display campaign stats
 * @param {string} campaignIntegrationType - Campaign integration type
 * @param {CampaignStats} campaignStats - CampaignStats object
 * @returns {TSX.Element} CampaignStatsCards component
 */

const CampaignStatsCards: React.FC<CampaignStatsCardsProps> = ({
  campaignIntegrationType,
  campaignStats,
}) => {
  if (campaignIntegrationType === CAMPAIGN_INTEGRATION_TYPE_PHONECALL) {
    return (
      <div className={'campaign-stats-cards-grid'}>
        <StatCard
          icon={'phoneOutgoing'}
          label={'Progreso de la campaña'}
          percentage={campaignStats?.contactsSentRate ?? 0}
          number={`(${campaignStats?.contactsSent ?? 0}/${
            campaignStats?.contacts ?? 0
          })`}
        />
        <StatCard
          icon={'phone'}
          label={'Contactos conectados'}
          percentage={campaignStats?.contactsReachedRate ?? 0}
          number={`(${campaignStats?.contactsReached ?? 0})`}
        />
        <StatCard
          icon={'phoneX'}
          label={'Contactos en buzón'}
          percentage={campaignStats?.contactsVoicemailRate ?? 0}
          number={`(${campaignStats?.contactsVoicemail ?? 0})`}
        />
        <StatCard
          icon={'phoneCall'}
          label={'Contactos alcanzados'}
          percentage={campaignStats?.contactsRepliedRate ?? 0}
          number={`(${campaignStats?.contactsReplied ?? 0})`}
        />
        {campaignStats?.contactsConverted > 0 && (
          <StatCard
            icon={'dollar'}
            label={'Contactos convertidos'}
            percentage={campaignStats?.contactsConvertedRate ?? 0}
            number={`(${campaignStats?.contactsConverted ?? 0})`}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className={'campaign-stats-cards-grid'}>
        <StatCard
          icon={'send'}
          label={'Enviados'}
          percentage={campaignStats?.completionRate ?? 0}
          number={`(${campaignStats?.completed ?? 0}/${
            campaignStats?.contacts ?? 0
          })`}
        />
        <StatCard
          icon={'warningCircle'}
          label={'Fallidos'}
          percentage={campaignStats?.failedRate ?? 0}
          number={`(${campaignStats?.failed ?? 0})`}
        />
        <StatCard
          icon={'envelopeSimple'}
          label={'Entregados'}
          percentage={campaignStats?.deliveryRate ?? 0}
          number={`(${campaignStats?.delivered ?? 0})`}
        />
        <StatCard
          icon={'envelopeOpen'}
          label={'Leídos'}
          percentage={campaignStats?.readRate ?? 0}
          number={`(${campaignStats?.read ?? 0})`}
        />
        <StatCard
          icon={'prohibitInset'}
          label={'Rechazados'}
          percentage={campaignStats?.rejectionRate ?? 0}
          number={`(${campaignStats?.rejected ?? 0})`}
        />
        <StatCard
          icon={'checkCircle'}
          label={'Respondidos'}
          percentage={campaignStats?.repliedRate ?? 0}
          number={`(${campaignStats?.replied ?? 0})`}
        />
        {campaignStats?.converted > 0 && (
          <StatCard
            icon={'dollar'}
            label={'Convertidos'}
            percentage={campaignStats?.convertedRate ?? 0}
            number={`(${campaignStats?.converted ?? 0})`}
          />
        )}
      </div>
    );
  }
};

export default CampaignStatsCards;
