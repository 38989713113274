import React from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import './styles.css';

const TemplateCard: React.FC = () => {
  return (
    <div className={`template-card-skeleton`}>
      <div className={'template-card-skeleton__status'}>
        <>
          <div
            className={'template-card-skeleton__status_icon skeleton-loader'}
          />
          <div
            className={'template-card-skeleton__status_text skeleton-loader'}
          />
        </>
      </div>
      <div className={'template-card-skeleton__header skeleton-loader'} />
      <div className={'template-card-skeleton__body'}>
        <div className={'skeleton-loader'} />
        <div className={'skeleton-loader'} />
        <div className={'skeleton-loader'} />
      </div>
      <div className={'template-card-skeleton__footer skeleton-loader'} />
    </div>
  );
};

export default TemplateCard;
