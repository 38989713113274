import React from 'react';

import Rive from '@rive-app/react-canvas';

import loadingAnimation from 'assets/rive/loading.riv';

import './styles.css';

type LoaderProps = {
  size?: number;
};

/**
 * Loader
 * @description Loader component
 * @param {Number} size - Size of the loader
 * @returns {TSX.Element} Loader component
 */

const Loader: React.FC<LoaderProps> = ({ size }) => {
  return (
    <div className="loader">
      <Rive src={loadingAnimation} style={{ width: size, height: size }} />
    </div>
  );
};

export default Loader;
