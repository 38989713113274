import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Text from 'components/interface/text';

import CampaignContactStatus from 'features/dashboard/features/campaigns/components/campaignContactStatus';

import { useInbox } from 'features/inbox/hooks/useInbox';
import { useCampaign } from 'features/campaigns/hooks/useCampaign';

import { formatDate } from 'utils/dates';

import './styles.css';

type CampaignUserDisplay = {
  campaignId: number;
  campaignName: string;
  campaignDate: string;
  fId: string;
  name: string;
  status: string;
};

/**
 * InboxDetailsCampaigns
 * @description Container for the thread's enduser's campaign user data
 * @returns {TSX.Element} InboxDetailsCampaigns component
 */

const InboxDetailsCampaigns: React.FC = () => {
  const navigate = useNavigate();

  const { inbox } = useInbox();
  const { campaignsData } = useCampaign();

  const [campaignUsers, setCampaignUsers] = useState<CampaignUserDisplay[]>([]);

  useEffect(() => {
    if (inbox.activeThread) {
      const campaignUsersAux: CampaignUserDisplay[] = [];

      inbox.activeThread?.endUser?.campaignUsers?.forEach((campaignUser) => {
        campaignUsersAux.push({
          campaignId: campaignUser.campaignId,
          campaignName: campaignsData[campaignUser.campaignId].name,
          campaignDate: campaignsData[campaignUser.campaignId].createdAt,
          fId: campaignUser.fId,
          name: campaignUser.name,
          status: campaignUser.status,
        });
      });

      campaignUsersAux.sort((a, b) =>
        b.campaignDate.localeCompare(a.campaignDate),
      );

      setCampaignUsers(campaignUsersAux);
    }
  }, [inbox.activeThread?.endUser?.id]);

  const handleCampaignClick = (campaignId: number) => {
    navigate(`/dashboard/campaigns/${campaignId}`);
  };

  return (
    <div className={'inbox-details-campaigns'}>
      {campaignUsers.map((campaignUser) => (
        <div
          key={campaignUser.campaignId}
          className={'inbox-details-campaigns-item'}
          onClick={() => handleCampaignClick(campaignUser.campaignId)}
        >
          <div className={'inbox-details-campaigns-item-header'}>
            <Text variant={'b3'}>{campaignUser.campaignName}</Text>
            {campaignUser.fId && (
              <Text variant={'b3'} color={'var(--gray-2)'}>
                ID: {campaignUser.fId}
              </Text>
            )}
          </div>
          <div className={'inbox-details-campaigns-item-body'}>
            <Text variant={'b3'} color={'var(--gray-2)'}>
              {formatDate(campaignUser.campaignDate)}
            </Text>
            <CampaignContactStatus status={campaignUser.status} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default InboxDetailsCampaigns;
