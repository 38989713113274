import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import LoadingView from 'views/LoadingView';

import { useAuth } from 'features/auth/hooks/useAuth';

const InviteRouter = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const [searchParams] = useSearchParams();
  localStorage.setItem('inviteCode', searchParams.get('code') ?? '');

  useEffect(() => {
    const redirectToRegister = async () => {
      await new Promise((resolve) => setTimeout(resolve, 840));
      navigate('/auth/signup', { replace: true });
    };

    redirectToRegister();
  }, [isAuthenticated]);

  return <LoadingView />;
};

export default InviteRouter;
