import React, { useEffect } from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import Card from 'components/interface/card';

import { useDashboard } from 'features/dashboard/hooks/useDashboard';
import { useOrganization } from 'features/organization/hooks/useOrganization';

import './styles.css';

/**
 * GeneralCard component
 * @description This component is used to display the general information in the overview page
 * @returns {React.FunctionComponent}
 */

const GeneralCard = () => {
  const { overviewData, getOverviewData } = useDashboard();
  const { organizationData } = useOrganization();

  useEffect(() => {
    getOverviewData();
  }, []);

  return (
    <Card
      style={{
        position: 'relative',
        flex: 3,
        alignSelf: 'stretch',
        background: 'linear-gradient(180deg, #dfedff 0%, transparent 80%)',
        overflow: 'hidden',
      }}
    >
      <div className={'overview-radial'} />
      <div className={'overview-general-card'}>
        <div className={'overview-general-icon'}>
          <Icon name={'squaresFour'} size={28} color={'var(--primary)'} />
        </div>
        <div>
          <Text variant={'h7'}>
            {organizationData?.name ?? 'Sin organización'}
          </Text>
          <div
            style={{
              display: 'flex',
              gap: 16,
              marginTop: 16,
            }}
          >
            <div className={'overview-general-card-item'}>
              <Text variant={'b3'}>Asistentes activos</Text>
              <div className={'overview-general-card-item-bottom'}>
                <Icon name={'robot'} size={12} color={'var(--primary)'} />
                <Text variant={'h7'}>
                  {overviewData?.activeChatbots ?? '-'}
                </Text>
              </div>
            </div>
            <div className={'overview-general-card-item'}>
              <Text variant={'b3'}>Campañas activas</Text>
              <div className={'overview-general-card-item-bottom'}>
                <Icon
                  name={'megaphoneSimple'}
                  size={12}
                  color={'var(--primary)'}
                />
                <Text variant={'h7'}>
                  {overviewData?.activeCampaigns ?? '-'}
                </Text>
              </div>
            </div>
            <div className={'overview-general-card-item'}>
              <Text variant={'b3'}>Usuarios</Text>
              <div className={'overview-general-card-item-bottom'}>
                <Icon name={'users'} size={12} color={'var(--primary)'} />
                <Text variant={'h7'}>
                  {overviewData?.usersInOrganization ?? '-'}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default GeneralCard;
