import React, { useState, useEffect, useRef } from 'react';
import { PulseLoader } from 'react-spinners';

import InboxMessage from 'features/inbox/components/message';
import InboxConversationDate from 'features/inbox/components/conversationDate';

import { useInbox } from 'features/inbox/hooks/useInbox';

import { type Message } from 'features/inbox/types/inboxTypes';

import { isSameDay } from 'utils/dates';

import './styles.css';

/**
 * ConversationMessages
 * @description Container for the inbox conversation
 * @returns {TSX.Element} ConversationMessages component
 */

const ConversationMessages: React.FC = () => {
  const { inbox, getThreadMessages } = useInbox();
  const scrollRef = useRef<HTMLDivElement>(null);

  const [chatDays, setChatDays] = useState<
    {
      date: string;
      messages: {
        message: Message;
        isLast: Boolean;
      }[];
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchMessages = async () => {
      if (!inbox.activeThread || isLoading) return;
      setIsLoading(true);
      await getThreadMessages().then(() => {
        // Timeouts to allow for messages to load and render
        setTimeout(() => scrollToBottom(), 120);
        setTimeout(() => setIsLoading(false), 180);
      });
    };

    fetchMessages();
  }, [inbox.activeThread?.id]);

  useEffect(() => {
    setTimeout(() => scrollToBottom(true), 120);
  }, [chatDays]);

  useEffect(() => {
    const chatDaysAux: {
      date: string;
      messages: {
        message: Message;
        isLast: Boolean;
      }[];
    }[] = [];

    if (inbox.activeThread?.messages) {
      const callMessages: Message[] = [];

      inbox.activeThread.calls.forEach((call) => {
        if (call.startedAt) {
          callMessages.push({
            id: 0,
            type: 'system_call_started',
            content: 'Llamada iniciada',
            media: call.recordingUrl ? call.id : '',
            sentBy: 'SYS',
            sentAt: call.startedAt,
            fStatus: 'delivered',
            read: true,
          });
        }
        if (call.endedAt) {
          callMessages.push({
            id: 0,
            type: 'system_call_ended',
            content: 'Llamada finalizada',
            media: call.recordingUrl ? call.id : '',
            sentBy: 'SYS',
            sentAt: call.endedAt,
            fStatus: 'delivered',
            read: true,
          });
        }
      });

      const threadMessages = [
        ...inbox.activeThread.messages,
        ...callMessages,
      ].sort(
        (a, b) => new Date(a.sentAt).getTime() - new Date(b.sentAt).getTime(),
      );

      let currentMessages: {
        message: Message;
        isLast: Boolean;
      }[] = [];

      threadMessages.forEach((message, index) => {
        let isLast = true;
        let displayDate = true;

        if (inbox.activeThread && index < threadMessages.length - 1) {
          const nextMessage = threadMessages[index + 1];
          if (
            nextMessage.sentBy === message.sentBy &&
            nextMessage.sentByUser === message.sentByUser &&
            isSameDay(nextMessage.sentAt, message.sentAt)
          ) {
            isLast = false;
          }
        }

        if (inbox.activeThread && index > 0) {
          const previousMessage = threadMessages[index - 1];
          if (isSameDay(previousMessage.sentAt, message.sentAt)) {
            displayDate = false;
          }
        }

        if (displayDate) {
          if (currentMessages.length > 0) {
            chatDaysAux.push({
              date: currentMessages[0].message.sentAt,
              messages: currentMessages,
            });
          }
          currentMessages = [];
        }

        currentMessages.push({ message, isLast });

        if (inbox.activeThread && index === threadMessages.length - 1) {
          chatDaysAux.push({
            date: message.sentAt,
            messages: currentMessages,
          });
        }
      });
    }

    setChatDays(chatDaysAux);
    scrollToBottom(true);
  }, [inbox.activeThread?.messages, inbox.activeThread?.calls]);

  const scrollToBottom = (smooth: boolean = false) => {
    if (!scrollRef.current) return;

    if (smooth) {
      scrollRef.current?.scrollTo({
        top: scrollRef.current?.scrollHeight,
        behavior: 'smooth',
      });
    } else {
      scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
    }
  };

  return (
    <div className={'inbox-conversation-messages'}>
      {isLoading && (
        <div className={'inbox-conversation-messages-loading'}>
          <PulseLoader size={10} color={'var(--gray-2)'} />
        </div>
      )}
      <div ref={scrollRef} className={'inbox-conversation-messages-scroll'}>
        {chatDays.map((chatDay, index) => (
          <div key={index}>
            <InboxConversationDate date={chatDay.date} scrollRef={scrollRef} />
            {chatDay.messages.map((chatMessage, index) => (
              <InboxMessage
                key={index}
                message={chatMessage.message}
                isLast={chatMessage.isLast}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConversationMessages;
