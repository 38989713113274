import React from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import Modal from 'components/interface/modal';
import Button from 'components/interface/button';

import { useAuth } from 'features/auth/hooks/useAuth';

const OrganizationBlockModal = () => {
  const { authLogout } = useAuth();

  const handleClickDemo = () => {
    const link = 'https://cal.pv.dev/luis-olave-altur/demo';
    window.open(link, '_blank');
  };

  const handleClickWhatsapp = () => {
    const link = 'https://wa.me/5218711528576';
    window.open(link, '_blank');
  };

  const handleClickEmail = () => {
    const to = 'luis@altur.io';
    const subject = 'Demo Altur';
    const body = 'Hola Luis, me gustaría agendar una demo para conocer Altur.';

    const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Modal isOpen={true} canClose={false} onClose={() => {}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text variant={'h6'}>¡Bienvenido a Altur!</Text>
        <div style={{ height: 16 }}></div>
        <Text variant={'b2'} color={'var(--gray)'} align={'center'}>
          Para comenzar a usar la plataforma pide a tu administrador que envíe
          una invitación a tu correo electrónico de trabajo. Si tu organización
          todavía no utiliza Altur, por favor agenda un demo o contactanos por
          cualquier medio.
        </Text>
        <div style={{ marginTop: 8, display: 'flex', gap: 8 }}>
          <Button label={'Agenda un demo'} onClick={handleClickDemo} />
          <Button
            label={'Envíanos un WhatsApp'}
            variant={'secondary'}
            icon={<Icon name={'whatsapp'} size={20} />}
            onClick={handleClickWhatsapp}
          />
          <Button
            variant={'secondary'}
            icon={
              <div style={{ marginLeft: -4, marginRight: -4 }}>
                <Icon name={'signOut'} size={20} />
              </div>
            }
            onClick={authLogout}
          />
        </div>
      </div>
    </Modal>
  );
};

export default OrganizationBlockModal;
