import React from 'react';

import Text from 'components/interface/text';

const NotFoundView: React.FC = () => {
  return (
    <div className="not-found-screen">
      <Text variant="h1" color="var(--gray)">
        404
      </Text>
      <Text variant="b1" color="var(--gray)">
        Página no existe
      </Text>
    </div>
  );
};

export default NotFoundView;
