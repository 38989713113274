import React from 'react';

import ThreadSkeleton from '../threadSkeleton';

/**
 * InboxThreadsLoading
 * @description Component to display inbox threads loading
 * @returns {TSX.Element} InboxThreadsLoading component
 */

const InboxThreadsLoading: React.FC = () => {
  return (
    <>
      {[...Array(6)].map((_, index) => (
        <ThreadSkeleton key={index} />
      ))}
    </>
  );
};

export default InboxThreadsLoading;
