import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DashboardContext } from '../providers/DashboardProvider';

import {
  fetchOverviewData,
  fetchCycleCampaignsData,
} from '../functions/dashboardFunctions';

/**
 * @hook useDashboard
 * @description Custom hook to use DashboardContext
 * @returns {Object} DashboardContext
 */
export const useDashboard = () => {
  const navigate = useNavigate();
  const {
    activeTab,
    isSidebarOpen,
    isNotificationsOpen,
    forceSidebarClosed,
    overviewData,
    clientSocketStatus,
    saveActiveTab,
    saveIsSidebarOpen,
    saveIsNotificationsOpen,
    saveIsShootingConfetti,
    saveForceSidebarClosed,
    saveOverviewData,
    saveClientSocketStatus,
  } = React.useContext(DashboardContext);

  /**
   * setActiveTab
   * Function that sets active tab
   */
  const setActiveTab = (tab: string) => {
    const path = window.location.pathname.split('/');
    const tabPath = path[2];
    if (tabPath !== tab) {
      navigate(`${tab}`);
    }
    setForceSidebarClosed(tab === 'inbox');
    saveActiveTab(tab);
  };

  /**
   * toggleSidebar
   * Function that toggles sidebar
   */
  const toggleSidebar = () => {
    saveIsSidebarOpen(!isSidebarOpen);
  };

  /**
   * toggleNotifications
   * Function that toggles notifications sidebar
   */
  const toggleNotifications = () => {
    saveIsNotificationsOpen(!isNotificationsOpen);
  };

  /**
   * shootConfetti
   * Function that shoots confetti
   */
  const shootConfetti = () => {
    saveIsShootingConfetti(true);
  };

  /**
   * forceSidebarClosed
   * Function that forces sidebar closed
   */
  const setForceSidebarClosed = (force: boolean) => {
    saveForceSidebarClosed(force);
  };

  /**
   * getOverviewData
   * Function that gets overview data
   */
  const getOverviewData = async () => {
    const response = await fetchOverviewData();

    if (response.status === 200) {
      saveOverviewData(response?.data);
    }
  };

  /**
   * getCurrentCycleCampaignsData
   * Function that gets current cycle campaigns data
   */
  const getCurrentCycleCampaignsData = async (organizationId: string) => {
    const response = await fetchCycleCampaignsData(organizationId);

    if (response.status === 200) {
      return response.data;
    }
    return null;
  };

  /**
   * updateClientSocketStatus
   * Function that updates client socket status
   * webSocketConnected - The web socket connection status
   * webSocketConnecting - The web socket connection status
   */
  const updateClientSocketStatus = (
    webSocketConnected: boolean,
    webSocketConnecting: boolean,
  ) => {
    saveClientSocketStatus({ webSocketConnected, webSocketConnecting });
  };

  return {
    activeTab,
    isSidebarOpen,
    forceSidebarClosed,
    isNotificationsOpen,
    overviewData,
    clientSocketStatus,
    setActiveTab,
    toggleSidebar,
    toggleNotifications,
    shootConfetti,
    setForceSidebarClosed,
    getOverviewData,
    getCurrentCycleCampaignsData,
    updateClientSocketStatus,
  };
};
