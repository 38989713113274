import React from 'react';

import Hr from 'components/interface/hr';
import Text from 'components/interface/text';
import Icon, { IconName } from 'components/interface/icon';
import IconButton from 'components/interface/iconButton';

import './styles.css';

type ModalProps = {
  isOpen?: boolean;
  title?: string;
  isLarge?: boolean;
  iconName?: IconName;
  canClose?: boolean;
  children?: React.ReactNode;
  onClose: () => void;
};

/**
 * Modal
 * @description Custom modal component
 * @param {Boolean} isOpen - If the modal is open
 * @param {String} title - The title of the modal
 * @param {Boolean} isLarge - If the modal is large
 * @param {String} iconName - The name of the icon to display
 * @param {Boolean} canClose - If the modal is closable (will block the user from closing if false)
 * @param {Function} onClose - Function to run when the modal is closed
 * @param {React.Component} children - The children of the modal
 * @returns {TSX.Element} BaseComponent component
 */

const Modal: React.FC<ModalProps> = ({
  isOpen = false,
  title,
  isLarge = false,
  iconName,
  canClose = true,
  onClose = () => {},
  children,
}) => {
  if (!isOpen) return null;

  const handleForegroundClick = () => {
    if (canClose) {
      onClose();
    }
  };

  const handleModalClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div className={'modal-foreground'} onClick={handleForegroundClick}>
      <div
        className={`modal ${isLarge ? 'modal-large' : ''}`}
        onClick={handleModalClick}
      >
        {title && (
          <>
            <div className={'modal-title'}>
              {iconName && (
                <div className={'modal-title-icon'}>
                  <Icon name={iconName} color={'var(--primary)'} size={18} />
                </div>
              )}
              <Text variant={'h7'} weight={500}>
                {title}
              </Text>
            </div>
            <div style={{ marginLeft: -16, marginRight: -16 }}>
              <Hr marginBottom={8} marginTop={12} />
            </div>
          </>
        )}
        {!title && iconName && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <div className={'modal-icon'}>
              <Icon name={iconName} color={'var(--primary)'} size={32} />
            </div>
          </div>
        )}
        {canClose && (
          <div className={'modal-close'}>
            <IconButton
              iconName={'close'}
              iconColor={'var(--gray)'}
              iconStyle={'bold'}
              onClick={onClose}
            />
          </div>
        )}
        <div className={'modal-content'}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
