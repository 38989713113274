import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, set } from 'react-hook-form';
import { toast } from 'react-toastify';

import { UUID } from 'crypto';

import Icon from 'components/interface/icon';
import Input from 'components/interface/input';
import Button from 'components/interface/button';
import Select from 'components/interface/select';
import TextArea from 'components/interface/textArea';
import FileInput from 'components/interface/fileInput';
import SettingsFields from 'components/interface/settingsFields';
import TemplateSelector from '../../../../templates/components/templateSelector';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';
import { useCampaign } from 'features/campaigns/hooks/useCampaign';

import { getTimezones } from 'utils/dates';

import { type SettingFields } from 'components/interface/settingsFields';
import { type Template } from 'features/templates/types/templateTypes';

const getSelectedIntegrationModelName = (integrationTypeAux: string) => {
  if (integrationTypeAux === 'whatsapp') return 'bots | Integration Whatsapp';
  if (integrationTypeAux === 'phonecall')
    return 'bots | Integration Phone Call';
  return '';
};

type FormInputs = {
  name: string;
  description: string;
  botId: string;
  integrationId: string;
  tz: string;
  retries: number;
  template: string;
  templateAlt: string;
  templateImage: File;
  firstMessage: string;
  messageLimit: number;
};

const NewCampaignForm: React.FC = () => {
  const navigate = useNavigate();

  const { chatbotsData } = useChatbot();
  const { createCampaign } = useCampaign();

  const [selectedChatbot, setSelectedChatbot] = useState<string>('');
  const [chatbotIntegrations, setChatbotIntegrations] = useState<
    Array<{ value: string; type: string; label: string }>
  >([]);
  const [selectedIntegrationId, setSelectedIntegrationId] =
    useState<string>('');
  const [selectedIntegrationType, setSelectedIntegrationType] =
    useState<string>('');
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [selectedTemplateIncludesImage, setSelectedTemplateIncludesImage] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const timezonesArray = getTimezones();
  const timezonesOptions = timezonesArray.map((tz) => ({
    label: tz,
    value: tz,
  }));

  useEffect(() => {
    setSelectedIntegrationId('');
    if (selectedChatbot) {
      const integrationOptionsWhatsapp = Object.values(
        chatbotsData[selectedChatbot as UUID].whatsappIntegrations,
      ).map((integration) => ({
        label: `WhatsApp | ${integration.whatsappNumberId}`,
        type: 'whatsapp',
        value: String(integration.id),
      }));

      const integrationOptionsPhoneCall = Object.values(
        chatbotsData[selectedChatbot as UUID].phoneCallIntegrations,
      ).map((integration) => ({
        label: `Llamadas | ${integration.phoneNumber}`,
        type: 'phonecall',
        value: String(integration.id),
      }));

      const integrationOptions = [
        ...integrationOptionsWhatsapp,
        ...integrationOptionsPhoneCall,
      ];

      setChatbotIntegrations(
        integrationOptions.length ? integrationOptions : [],
      );
    } else {
      setChatbotIntegrations([]);
    }
  }, [selectedChatbot]);

  useEffect(() => {
    if (selectedIntegrationId) {
      const integrationType = chatbotIntegrations.find(
        (integration) => integration.value === selectedIntegrationId,
      )?.type;
      setSelectedIntegrationType(integrationType || '');
      setSelectedTemplates([]);
      setSelectedTemplateIncludesImage(false);
    } else {
      setSelectedIntegrationType('');
    }
  }, [selectedIntegrationId]);

  const handleEditTemplates = () => {
    window.open(
      'https://business.facebook.com/wa/manage/message-templates/',
      '_blank',
    );
  };

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async (data, event: any) => {
    if (isLoading) return;
    setIsLoading(true);

    // Get form data
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('botId', data.botId);
    formData.append('integrationId', data.integrationId);
    formData.append(
      'integrationType',
      getSelectedIntegrationModelName(selectedIntegrationType),
    );
    formData.append('tz', data.tz);
    formData.append('retries', String(data.retries));
    formData.append('template', selectedTemplates[0]);
    formData.append(
      'templateAlt',
      selectedTemplates.length > 1
        ? selectedTemplates[1]
        : selectedTemplates[0],
    );
    if (event.target.templateImage.files[0]) {
      formData.append('templateImage', event.target.templateImage.files[0]);
    }
    formData.append('firstMessage', data.firstMessage);
    formData.append('messageLimit', String(data.messageLimit));

    // Send request to API
    const response = await createCampaign(formData);

    // Manage response errors or success
    if (response?.errors) {
      if (response.errors?.name) {
        setError('name', {
          type: 'manual',
          message: response.errors.name,
        });
      }
      if (response.errors?.description) {
        setError('description', {
          type: 'manual',
          message: response.errors.description,
        });
      }
      if (response.errors?.botId) {
        setError('botId', {
          type: 'manual',
          message: response.errors.botId,
        });
      }
      if (response.errors?.integrationId) {
        setError('integrationId', {
          type: 'manual',
          message: response.errors.integrationId,
        });
      }
      if (response.errors?.tz) {
        setError('tz', {
          type: 'manual',
          message: response.errors.tz,
        });
      }
      if (response.errors?.retries) {
        setError('retries', {
          type: 'manual',
          message: response.errors.retries,
        });
      }
      if (response.errors?.templateImage) {
        setError('templateImage', {
          type: 'manual',
          message: response.errors.templateImage,
        });
      }
      if (response.errors?.firstMessage) {
        setError('firstMessage', {
          type: 'manual',
          message: response.errors.firstMessage,
        });
      }
      if (response.errors?.messageLimit) {
        setError('messageLimit', {
          type: 'manual',
          message: response.errors.messageLimit,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al crear la campaña');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Campaña creada correctamente');
        navigate('/dashboard/campaigns');
      } else {
        toast.error('Error al crear la campaña');
        reset();
      }
    }
    setIsLoading(false);
  };

  const fields: SettingFields = {
    name: {
      label: 'Nombre',
      description: 'Asigna un nombre corto y descriptivo a la campaña.',
      component: (
        <Input
          placeholder={'Nombre de la campaña'}
          error={errors.name?.message}
          {...register('name', {
            required: 'This field may not be blank.',
          })}
        />
      ),
    },
    description: {
      label: 'Descripción',
      description:
        'Describe el objetivo de la campaña o cualquier otra información relevante.',
      component: (
        <TextArea
          placeholder={'Descripción de la campaña'}
          error={errors.description?.message}
          {...register('description', {
            required: 'This field may not be blank.',
          })}
        />
      ),
    },
    botId: {
      label: 'Asistente',
      description:
        'Selecciona el asistente que se utilizará para la campaña. Este asistente debe de tener el contexto e instrucciones necesarias para llevar a cabo las interacciones de la campaña.',
      component: (
        <Select
          placeholder={'Selecciona un asistente'}
          options={Object.values(chatbotsData).map((chatbot) => ({
            label: chatbot.nickname,
            value: chatbot.id,
          }))}
          error={errors.botId?.message}
          {...register('botId', {
            required: 'This field may not be blank.',
          })}
          onChange={(e) => setSelectedChatbot(e.target.value)}
        />
      ),
    },
    integrationId: {
      label: 'Integración',
      description:
        'Selecciona la integración de la cual se enviarán y recibirán los mensajes para la campaña. Asegúrate de que la integración seleccionada no cuente con restricciones por parte de Meta.',
      component: (
        <Select
          placeholder={'Selecciona una integración'}
          options={chatbotIntegrations}
          isDisabled={!chatbotIntegrations.length}
          error={errors.integrationId?.message}
          {...register('integrationId', {
            required: 'This field may not be blank.',
          })}
          onChange={(e) => setSelectedIntegrationId(e.target.value)}
        />
      ),
    },
    tz: {
      label: 'Zona horaria',
      description:
        'Selecciona la zona horaria de la campaña, por defecto será la de Ciudad de México, si tus clientes se encuentran en otra zona, asegúrate de modificar el parámetro.',
      component: (
        <Select
          options={timezonesOptions}
          selectedOption={'America/Mexico_City'}
          error={errors.tz?.message}
          {...register('tz', {
            required: 'This field may not be blank.',
          })}
        />
      ),
    },
    retries: {
      label: 'Reintentos',
      description:
        'Define el número de veces que se intentará enviar un mensaje en caso de que no haya sido posible enviarlo o no haya sido recibido por el cliente.',
      component: (
        <Input
          placeholder={'Numero de reintentos'}
          type={'number'}
          defaultValue={1}
          minValueValidation={0}
          maxValueValidation={5}
          error={errors.retries?.message}
          {...register('retries', {
            required: 'This field may not be blank.',
          })}
        />
      ),
    },
    templates: {
      label: 'Plantilla(s)',
      description:
        'Selecciona la plantilla que se utilizará para la campaña. Las plantillas contienen el contenido de los mensajes que se enviarán a los clientes. Es posible elegir dos para tener una alternativa.',
      component: (
        <>
          {selectedIntegrationType === 'whatsapp' && (
            <TemplateSelector
              chatbotId={selectedChatbot as UUID}
              integrationId={Number(selectedIntegrationId)}
              selectables={2}
              onSelectedTemplatesChange={(st: Template[]) => {
                const includesImageHeader = st.some(
                  (t) =>
                    t.components?.some(
                      (component) =>
                        component.type === 'HEADER' &&
                        component.format === 'IMAGE',
                    ),
                );

                setSelectedTemplateIncludesImage(includesImageHeader);
                setSelectedTemplates(st.map((t) => t.name));
              }}
              enableLoading={true}
            />
          )}
        </>
      ),
      options: [
        <Button
          label={'Administrar plantillas'}
          variant={'secondary'}
          icon={<Icon name={'cards'} />}
          onClick={handleEditTemplates}
        />,
      ],
      hide: selectedIntegrationType !== 'whatsapp',
    },
    templateImage: {
      label: 'Imagen de la plantilla',
      description:
        'En caso de que la plantilla seleccionada contenga una imagen, puedes subirla aquí. Esto es un requisito para poder enviar mensajes con imágenes.',
      component: (
        <FileInput
          allowedExtensions={['.png', '.jpg', '.jpeg', '.gif']}
          error={errors.templateImage?.message}
          {...register('templateImage')}
        />
      ),
      hide: !selectedTemplateIncludesImage,
    },
    firstMessage: {
      label: 'Mensaje inicial',
      description:
        'Ingresa una frase o mensaje inicial que dirá el asistente al iniciar la conversación con el cliente. Incluye una variable para el nombre del cliente si es necesario.',
      component: (
        <TextArea
          placeholder={'¡Hola {{1}}! Tengo una promoción para ti.'}
          error={errors.firstMessage?.message}
          {...register('firstMessage')}
        />
      ),
      hide: selectedIntegrationType !== 'phonecall',
    },
    messageLimit: {
      label: 'Límite de mensajes',
      description:
        'Ingresa aquí el límite de mensajes diarios con los que cuenta la integración seleccionada o el número de mensajes que se enviarán a diario.',
      component: (
        <Input
          placeholder={'Numero de mensajes'}
          type={'number'}
          defaultValue={250}
          minValueValidation={100}
          maxValueValidation={10000}
          error={errors.messageLimit?.message}
          {...register('messageLimit')}
        />
      ),
      hide: selectedIntegrationType !== 'whatsapp',
    },
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: 24 }}
      encType={'multipart/form-data'}
    >
      <SettingsFields fields={fields} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
        <Button
          label={'Cancelar'}
          variant={'secondary'}
          onClick={() => reset()}
        />
        <Button
          type={'submit'}
          label={'Guardar'}
          variant={'primary'}
          isDisabled={
            selectedIntegrationType.length < 1 ||
            (selectedIntegrationType === 'whatsapp' &&
              selectedTemplates.length < 1)
          }
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default NewCampaignForm;
