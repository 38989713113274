import React from 'react';

import Hr from 'components/interface/hr';
import Text from 'components/interface/text';
import IntegrationCard from 'features/dashboard/features/integrations/components/integrationCard';

import { useUser } from 'features/user/hooks/useUser';

import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';
import { type ChatbotWhatsappIntegration } from 'features/chatbots/providers/ChatbotProvider';

import './styles.css';

type ChatbotIntegrationsProps = {
  chatbotData: ChatbotData;
  callback?: (
    integration: ChatbotWhatsappIntegration | null,
    chatbot: ChatbotData,
  ) => void;
};

const ChatbotIntegrations: React.FC<ChatbotIntegrationsProps> = ({
  chatbotData,
  callback = () => {},
}) => {
  const { userHasPermission } = useUser();

  const isAccessible =
    userHasPermission('bots.all.update') ||
    userHasPermission(`bots.${chatbotData.id}.update`);

  const hasWhatsappIntegration =
    Object.keys(chatbotData.whatsappIntegrations).length > 0;

  const hasPhoneCallIntegration =
    Object.keys(chatbotData.phoneCallIntegrations).length > 0;

  return (
    <>
      <div className={'chatbot-integrations'}>
        <div className={'chatbot-integrations__header'}>
          <Text variant={'h7'}>{chatbotData.nickname}</Text>
          <div className={'chatbot-integrations__header__id'}>
            <Text
              variant={'b3'}
              size={11}
              color={'var(--gray)'}
              numberOfLines={1}
            >
              {chatbotData.id}
            </Text>
          </div>
        </div>
        <Hr marginTop={16} marginBottom={16} />
        <div className={'chatbot-integrations__body'}>
          {hasWhatsappIntegration ? (
            Object.values(chatbotData.whatsappIntegrations).map(
              (integration) => (
                <IntegrationCard
                  key={integration.id}
                  type={'whatsapp'}
                  isEditable={isAccessible}
                  integration={integration}
                  onClick={() => callback(integration, chatbotData)}
                />
              ),
            )
          ) : (
            <IntegrationCard
              type={'whatsapp'}
              isEditable={isAccessible}
              onClick={() => callback(null, chatbotData)}
              isAddCard={true}
            />
          )}
          {hasPhoneCallIntegration ? (
            Object.values(chatbotData.phoneCallIntegrations).map(
              (integration) => (
                <IntegrationCard
                  key={integration.id}
                  type={'phonecall'}
                  isEditable={false}
                  integration={integration}
                  onClick={() => {}}
                />
              ),
            )
          ) : (
            <IntegrationCard
              type={'phonecall'}
              onClick={() => {}}
              isAddCard={true}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ChatbotIntegrations;
