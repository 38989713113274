import React from 'react';
import { Link } from 'react-router-dom';

import OTPInput from 'react-otp-input';

import Text from 'components/interface/text';
import Button from 'components/interface/button';

import { useAuth } from '../hooks/useAuth';

import '../components/tfaInput/styles.css';

const TFAForm: React.FC = () => {
  const { verifyTwoFactorAuth } = useAuth();

  const [code, setCode] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onSubmit = async (event: any) => {
    event?.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    await verifyTwoFactorAuth(code);
    setIsLoading(false);
  };

  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <Text variant={'s1'}>
        Enviamos un código de 6 dígitos a tu correo para verificar tu identidad.
      </Text>
      <OTPInput
        value={code}
        onChange={setCode}
        numInputs={6}
        renderInput={(props) => <input {...props} />}
        containerStyle={'otp-container'}
        inputStyle={'otp-input'}
        inputType={'number'}
        shouldAutoFocus={true}
      />
      <Button
        type={'submit'}
        label={'Verificar código'}
        isLoading={isLoading}
      />
      <Text variant={'s1'} align={'left'}>
        <Link to={'/auth/login'}>Volver a inicio de sesión</Link>
      </Text>
    </form>
  );
};

export default TFAForm;
