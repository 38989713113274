import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthContext } from '../providers/AuthProvider';

import {
  authenticateTokenPassword,
  authenticateTokenGoogle,
  verifyTwoFactorAuthCode,
  confirmAccountVerify,
  confirmAccountResend,
  requestPasswordRecovery,
  requestPasswordReset,
} from '../functions/authFunctions';

import { generateStateSessionToken } from 'features/auth/utils/tokens';

/**
 * @hook useAuth
 * @description Custom hook to use AuthContext
 * @returns {Object} AuthContext
 */

export const useAuth = () => {
  const {
    accessToken,
    refreshToken,
    isAuthenticated,
    saveAccessToken,
    saveRefreshToken,
    saveIsAuthenticated,
  } = useContext(AuthContext);

  const navigate = useNavigate();

  /**
   * authPassword
   * Function that authenticates user with email and password
   */
  const authPassword = async (email: string, password: string) => {
    const response = await authenticateTokenPassword(email, password);
    if (response.status === 200) {
      if (response.data.requires2fa) {
        localStorage.setItem('tfa_email', email);
        navigate('/auth/2fa');
      } else {
        saveAccessToken(response.data.access);
        saveRefreshToken(response.data.refresh);
        saveIsAuthenticated(true);
      }
    }
    return response;
  };

  /**
   * authGoogleRedirect
   * Function that redirects user to Google OAuth
   */
  const authGoogleRedirect = () => {
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = 'auth/google';

    const scope = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
    ].join(' ');

    const state = generateStateSessionToken();
    localStorage.setItem('google_oauth2_state', state);

    const params = {
      response_type: 'code',
      client_id: `${process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID}`,
      redirect_uri: `${process.env.REACT_APP_EAST_BASE_URL}/${redirectUri}`,
      prompt: 'select_account',
      access_type: 'offline',
      state,
      scope,
    };

    const urlParams = new URLSearchParams(params).toString();

    window.location.assign(`${googleAuthUrl}?${urlParams}`);
  };

  /**
   * authGoogle
   * Function that authenticates user with Google OAuth
   */
  const authGoogle = async (code: string) => {
    const response = await authenticateTokenGoogle(code);
    if (response.status === 200) {
      saveAccessToken(response.data.access);
      saveRefreshToken(response.data.refresh);
      saveIsAuthenticated(true);
    }
    return response;
  };

  /**
   * verifyTwoFactorAuth
   * Function that verifies 2FA code
   */
  const verifyTwoFactorAuth = async (code: string) => {
    const email = localStorage.getItem('tfa_email') || '';
    const response = await verifyTwoFactorAuthCode(email, code);
    if (response.status === 200) {
      if (response.data.access && response.data.refresh) {
        saveAccessToken(response.data.access);
        saveRefreshToken(response.data.refresh);
        saveIsAuthenticated(true);
      } else {
        toast.error('El código es incorrecto o ha expirado');
      }
    }
    return response;
  };

  /**
   * verificationTry
   * Function that confirms account
   */
  const verificationTry = async (uidb64: string, token: string) => {
    const response = await confirmAccountVerify(uidb64, token);
    if (response.status === 200) {
      return response.data?.success ?? false;
    }
    return false;
  };

  /**
   * verificationResend
   * Function that resends account confirmation email
   */
  const verificationResend = async (uidb64: string, token: string) => {
    const response = await confirmAccountResend(uidb64, token);
    if (response.status === 200) {
      return response.data?.success ?? false;
    }
    return false;
  };

  /**
   * recoveryRequest
   * Function that requests password recovery email
   */
  const recoveryRequest = async (email: string) => {
    const response = await requestPasswordRecovery(email);
    return response;
  };

  /**
   * recoveryReset
   * Function that requests password reset
   */
  const recoveryReset = async (
    uidb64: string,
    token: string,
    password: string,
    vPassword: string,
  ) => {
    const response = await requestPasswordReset(
      uidb64,
      token,
      password,
      vPassword,
    );
    return response;
  };

  /**
   * authLogout
   * Function that logs out user
   */
  const authLogout = () => {
    saveAccessToken(null);
    saveRefreshToken(null);
    saveIsAuthenticated(false);
  };

  return {
    accessToken,
    refreshToken,
    isAuthenticated,
    authPassword,
    authGoogleRedirect,
    authGoogle,
    verifyTwoFactorAuth,
    verificationTry,
    verificationResend,
    recoveryRequest,
    recoveryReset,
    authLogout,
  };
};
