import React, { useState, useEffect } from 'react';

import Modal from 'components/interface/modal';
import PageTitle from 'features/dashboard/components/pageTitle';
import ChatbotIntegrations from '../features/integrations/components/chatbotIntegrations';
import ChatbotForm from 'features/dashboard/features/integrations/forms/IntegrationForm';
import EmptyState from 'components/interface/emptyState';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';
import { type ChatbotWhatsappIntegration } from 'features/chatbots/providers/ChatbotProvider';

const IntegrationsView: React.FC = () => {
  const { chatbotsData } = useChatbot();
  const [chatbots, setChatbots] = useState<ChatbotData[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openIntegration, setOpenIntegration] =
    useState<ChatbotWhatsappIntegration | null>(null);
  const [openChatbot, setOpenChatbot] = useState<ChatbotData | null>(null);

  useEffect(() => {
    if (chatbotsData) {
      setChatbots(Object.values(chatbotsData));
    }
  }, [chatbotsData]);

  useEffect(() => {
    if (!isModalOpen) {
      setOpenChatbot(null);
      setOpenIntegration(null);
    }
  }, [isModalOpen]);

  const handleOpenModal = (
    integration: ChatbotWhatsappIntegration | null,
    chatbot: ChatbotData,
  ) => {
    setOpenIntegration(integration ?? null);
    setOpenChatbot(chatbot ?? null);
    setIsModalOpen(true);
  };

  return (
    <div className={'dashboard-page'}>
      <PageTitle title={'Integraciones'} iconName={'plugs'} />
      {chatbots.map((chatbotData) => (
        <ChatbotIntegrations
          key={chatbotData.id}
          chatbotData={chatbotData}
          callback={(
            integration: ChatbotWhatsappIntegration | null,
            chatbot: ChatbotData,
          ) => handleOpenModal(integration, chatbot)}
        />
      ))}
      {chatbots.length === 0 && (
        <div style={{ marginTop: 124 }}>
          <EmptyState
            iconName={'plugs'}
            text={'Crea un asistente para ver sus integraciones'}
          />
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        title={openIntegration ? 'Editar integración' : 'Nueva integración'}
        onClose={() => setIsModalOpen(false)}
      >
        <ChatbotForm
          chatbotId={openChatbot?.id ?? null}
          integration={openIntegration}
          onSubmitCallback={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default IntegrationsView;
