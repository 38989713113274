import React, { useState, useEffect } from 'react';
import { DataType, Table, useTable } from 'ka-table';
import { SortingMode } from 'ka-table/enums';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import Tag from 'components/interface/tag';
import SearchInput from 'components/interface/searchInput';
import EndUserPicture from 'features/inbox/components/endUserPicture';
import EmptyState from 'components/interface/emptyState';

import AddContactToListModal from '../addContactToListModal';

import { useEndUser } from 'features/endUsers/hooks/useEndUser';

import { formatDateTime } from 'utils/dates';
import { capitalize } from 'utils/strings';
import {
  formatSocialId,
  displayIntegrationName,
  displayIntegrationIcon,
} from 'utils/integrations';

import { ActionType } from 'ka-table/enums';

import { UUID } from 'crypto';

import './styles.css';

type ContactsProps = {
  canUpdate: boolean;
};

const Contacts: React.FC<ContactsProps> = ({ canUpdate = false }) => {
  const {
    endUsersData,
    endUserCount,
    endUserLists,
    getEndUsersData,
    removeEndUserFromList,
  } = useEndUser();

  const [selectedEndUser, setSelectedEndUser] = useState<UUID | null>(null);

  const [endUsersArray, setEndUsersArray] = useState(
    Object.values(endUsersData),
  );
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(16);
  const [pageCount, setPageCount] = useState(0);
  const [sortBy, setSortBy] = useState('lastSeen');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [tableKey, setTableKey] = useState(Date.now());

  const table = useTable({
    onDispatch: async (action) => {
      if (action.type === ActionType.UpdatePageIndex) {
        setPageIndex(action.pageIndex);
      }
      if (action.type === ActionType.UpdateSortDirection) {
        setSortBy(action.columnKey);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      }
    },
  });

  useEffect(() => {
    setEndUsersArray([...Object.values(endUsersData)]);
    setTableKey(Date.now());
  }, [endUsersData, endUserLists]);

  useEffect(() => {
    const handleGetContacts = async () => {
      if (isLoading) return;
      setIsLoading(true);

      await getEndUsersData(pageIndex, pageSize, sortBy, sortOrder, searchTerm);

      setIsLoading(false);
    };

    handleGetContacts();
  }, [pageIndex, pageSize, sortBy, sortOrder, searchTerm]);

  useEffect(() => {
    setPageCount(Math.ceil(endUserCount / pageSize));
  }, [endUserCount, pageSize]);

  return (
    <>
      <div>
        <div className={'contacts-header'}>
          <div className={'contacts-header-left'}>
            {/* Noting to show here */}
          </div>
          <div className={'contacts-header-right'}>
            {canUpdate && (
              // TODO: Update contacts button
              <></>
            )}
            {/* TODO: Download contacts button */}
            <SearchInput onChange={(e: any) => setSearchTerm(e.target.value)} />
          </div>
        </div>
        <div className={'contacts-table'}>
          <Table
            key={tableKey}
            table={table}
            rowKeyField={'id'}
            columns={[
              {
                key: 'avatar',
                dataType: DataType.Object,
                style: { width: 48 },
              },
              {
                key: 'displayName',
                dataType: DataType.String,
                title: 'Nombre',
              },
              {
                key: 'whatsapp',
                dataType: DataType.Object,
                title: 'WhatsApp',
              },
              {
                key: 'phoneCall',
                dataType: DataType.Object,
                title: 'Llamadas',
              },
              {
                key: 'inLists',
                dataType: DataType.Object,
                title: 'Listas',
                isSortable: false,
              },
              {
                key: 'lastSeen',
                dataType: DataType.Date,
                title: 'Última vez visto',
                style: { width: 200, textAlign: 'right' },
              },
            ]}
            data={endUsersArray}
            loading={{
              enabled: isLoading,
            }}
            paging={{
              enabled: true,
              pageIndex: pageIndex,
              pageSize: pageSize,
              pagesCount: pageCount,
            }}
            childComponents={{
              cellText: {
                content: (props) => {
                  switch (props.column.key) {
                    case 'avatar':
                      return (
                        <EndUserPicture endUser={props.rowData} size={32} />
                      );
                    case 'displayName':
                      return (
                        <Text variant={'b2'} numberOfLines={1}>
                          {props.value || '-'}
                        </Text>
                      );
                    case 'whatsapp':
                    case 'phoneCall':
                      const integrationType =
                        props.column.key.toLocaleLowerCase();
                      if (props.value == undefined)
                        return (
                          <Text
                            variant={'b3'}
                            color={'var(--body)'}
                            numberOfLines={1}
                          >
                            -
                          </Text>
                        );
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Icon
                            name={
                              displayIntegrationIcon[integrationType]?.name ||
                              'code'
                            }
                            color={
                              displayIntegrationIcon[integrationType]?.color ||
                              'var(--body)'
                            }
                            style={'duotone'}
                            size={16}
                          />
                          <Text
                            variant={'b3'}
                            color={'var(--body)'}
                            numberOfLines={1}
                          >
                            {formatSocialId(
                              integrationType,
                              props.value?.phoneNumber || '',
                            )}
                          </Text>
                        </div>
                      );
                    case 'inLists':
                      return (
                        <div className={'contact-tags'}>
                          <Tag
                            color={'white'}
                            iconName={'plus'}
                            altIconName={'plus'}
                            isClickable={true}
                            onClick={() => setSelectedEndUser(props.rowData.id)}
                          />
                          {props.value.map((listId: string) => (
                            <Tag
                              key={listId}
                              text={endUserLists[listId].name}
                              color={endUserLists[listId].color as any}
                              iconName={'userList'}
                              isClickable={true}
                              onClick={() =>
                                removeEndUserFromList(listId, props.rowData.id)
                              }
                            />
                          ))}
                        </div>
                      );
                    case 'lastSeen':
                      return (
                        <Text
                          variant={'b3'}
                          color={'var(--body)'}
                          align={'right'}
                        >
                          {formatDateTime(props.value)}
                        </Text>
                      );
                  }
                },
              },
            }}
            sortingMode={SortingMode.Single}
          />
        </div>
        {Object.keys(endUsersData).length === 0 && (
          <div style={{ marginBottom: 80 }}>
            <EmptyState
              text={'No hay contactos'}
              iconName={'users'}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
      <AddContactToListModal
        endUserId={selectedEndUser as UUID}
        isOpen={selectedEndUser !== null}
        onClose={() => setSelectedEndUser(null)}
      />
    </>
  );
};

export default Contacts;
