import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Button from 'components/interface/button';
import SettingsFields from 'components/interface/settingsFields';
import SelectableCard from 'components/interface/selectableCard';
import AssigneeCards from '../components/assingeeCards';

import { useUser } from 'features/user/hooks/useUser';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';

type AssigneesViewProps = {
  chatbotData: ChatbotData;
};

const AssigneesView: React.FC<AssigneesViewProps> = ({ chatbotData }) => {
  const { userHasPermission } = useUser();
  const { updateChatbotEligibleAssignees } = useChatbot();

  const [eligibleAssigneesIds, setEligibleAssigneesIds] = useState<string[]>(
    Object.values(chatbotData.assignees)
      .filter((assignee) => assignee.isEligible)
      .map((assignee) => assignee.userId),
  );

  const handleReset = () => {
    setEligibleAssigneesIds(
      Object.values(chatbotData.assignees)
        .filter((assignee) => assignee.isEligible)
        .map((assignee) => assignee.userId),
    );
  };

  const handleSave = async () => {
    const response = await updateChatbotEligibleAssignees(
      chatbotData.id,
      eligibleAssigneesIds,
    );
    if (response?.data?.success) {
      toast.success('Cambios guardados correctamente');
    } else {
      toast.error('Error al guardar cambios');
    }
  };

  const isAccessible =
    userHasPermission('bots.all.update') ||
    userHasPermission(`bots.${chatbotData.id}.update`);

  const fields = {
    strategy: {
      label: 'Estrategia de asignación',
      description:
        'La estrategia de asignación de conversaciones dicta como serán asignadas las conversaciones entrantes a los agentes seleccionados.',
      component: (
        <SelectableCard
          title={'Round Robin'}
          description={
            'Esta estrategia distribuye conversaciónes entrantes equitativamente entre los agentes disponibles en forma de ciclo.'
          }
          iconName={'repeat'}
          isSelected={true}
        />
      ),
    },
    assignees: {
      label: 'Agentes asignados',
      description:
        'Solo los agentes con acceso a este asistente podrán ser asignados a las conversaciones entrantes. Por defecto, todos los agentes con acceso al asistente están asignados.',
      component: (
        <AssigneeCards
          assignees={Object.values(chatbotData.assignees)}
          eligableAssigneesIds={eligibleAssigneesIds}
          setEligibleAssigneesIds={setEligibleAssigneesIds}
          canEdit={isAccessible}
        />
      ),
    },
  };

  return (
    <div>
      <SettingsFields fields={fields} />
      {isAccessible && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: 8,
          }}
        >
          <Button
            label={'Cancelar'}
            variant={'secondary'}
            onClick={handleReset}
          />
          <Button label={'Guardar'} onClick={handleSave} />
        </div>
      )}
    </div>
  );
};

export default AssigneesView;
