import React, { useState } from 'react';

import { toast } from 'react-toastify';

import IconButton from 'components/interface/iconButton';

import { useOrganization } from 'features/organization/hooks/useOrganization';

import { IconName } from 'components/interface/icon';

type OrganizationActionsProps = {
  code: string;
};

const OrganizationActions: React.FC<OrganizationActionsProps> = ({ code }) => {
  const { organizationData, sendOrganizationInvite, revokeOrganizationInvite } =
    useOrganization();
  const [isRetryLoading, setIsRetryLoading] = useState<boolean>(false);
  const [isRevokeLoading, setIsRevokeLoading] = useState<boolean>(false);

  const handleInviteRetry = async () => {
    if (isRetryLoading) return;
    setIsRetryLoading(true);
    const response = await sendOrganizationInvite(
      organizationData.invites[code].email,
    );
    if (response?.data?.success) {
      toast.success('Invitación reenviada');
    } else {
      toast.error('Error al reenviar la invitación');
    }
    setIsRetryLoading(false);
  };

  const handleInviteRevoke = async () => {
    if (isRevokeLoading) return;
    setIsRevokeLoading(true);
    const response = await revokeOrganizationInvite(code);
    if (response?.data?.success) {
      toast.success('Invitación revocada');
    } else {
      toast.error('Error al revocar la invitación');
    }
    setIsRevokeLoading(false);
  };

  return (
    <div style={{ display: 'flex', gap: 8 }}>
      <IconButton
        iconName={'arrowClockwise' as IconName}
        hint={'Reintentar'}
        isLoading={isRetryLoading}
        onClick={handleInviteRetry}
      />
      <IconButton
        iconName={'prohibitInset' as IconName}
        hint={'Revocar'}
        isLoading={isRevokeLoading}
        requiresConfirmation={true}
        confirmationTitle={'Revocar invitación'}
        confirmationText={
          '¿Estás seguro de que quieres revocar esta invitación? Los correos enviados con esta invitación serán invalidados.'
        }
        onClick={handleInviteRevoke}
      />
    </div>
  );
};

export default OrganizationActions;
