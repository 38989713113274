import React from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import { type IconName, type IconStyle } from 'components/interface/icon';

import './styles.css';

/**
 * SidebarSelectable
 * @description Component for selecting a filter option
 * @param {string} title - Title of the filter option
 * @param {IconName} iconName - Icon of the filter option
 * @param {string} color - Color of the filter option
 * @param {boolean} isActive - Whether the filter option is active
 * @param {function} onClick - Function to run when the filter option is clicked
 * @returns {TSX.Element} SidebarSelectable component
 */

type SidebarSelectableProps = {
  title: string;
  iconName?: IconName;
  iconStyle?: IconStyle;
  color?: string;
  isActive: boolean;
  onClick: () => void;
};

const SidebarSelectable: React.FC<SidebarSelectableProps> = ({
  title,
  iconName = 'check',
  iconStyle = 'regular',
  color = 'primary',
  isActive,
  onClick,
}) => {
  return (
    <div
      className={`inbox-sidebar-selectable ${
        isActive ? 'inbox-sidebar-selectable-active' : ''
      }`}
      onClick={onClick}
    >
      <div
        className={'inbox-sidebar-selectable-box'}
        style={isActive ? { backgroundColor: `var(--${color}-pale)` } : {}}
      >
        <Icon
          name={iconName}
          size={12}
          style={iconStyle}
          color={isActive ? `var(--${color})` : 'var(--gray-2)'}
        />
      </div>
      <Text
        variant={'b2'}
        color={isActive ? 'var(--body)' : 'var(--gray-2)'}
        numberOfLines={1}
      >
        {title}
      </Text>
    </div>
  );
};

export default SidebarSelectable;
