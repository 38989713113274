import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import { UUID } from 'crypto';

import Text from 'components/interface/text';
import Input from 'components/interface/input';
import Button from 'components/interface/button';
import TextArea from 'components/interface/textArea';
import FileInput from 'components/interface/fileInput';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type ChatbotSendableMedia } from 'features/chatbots/providers/ChatbotProvider';

type FormInputs = {
  name: string;
  description: string;
  file: File;
};

type SendableMediaFormProps = {
  chatbotId: UUID;
  sendableMedia?: ChatbotSendableMedia | null;
  onSubmitCallback: () => void;
};

const SendableMediaForm: React.FC<SendableMediaFormProps> = ({
  chatbotId,
  sendableMedia,
  onSubmitCallback,
}) => {
  const {
    createChatbotSendableMedia,
    updateChatbotSendableMedia,
    eliminateChatbotSendableMedia,
  } = useChatbot();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async (data, event: any) => {
    if (isLoading) return;
    setIsLoading(true);

    // Get form data
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('description', data.description);
    if (event.target.file.files[0]) {
      formData.append('file', event.target.file.files[0]);
    }

    // Send request to API conditional to create or update
    const response = sendableMedia
      ? await updateChatbotSendableMedia(chatbotId, sendableMedia.id, formData)
      : await createChatbotSendableMedia(chatbotId, formData);

    // Manage response errors or success
    if (response?.errors) {
      if (response.errors?.name) {
        setError('name', {
          type: 'manual',
          message: response.errors.name,
        });
      }
      if (response.errors?.description) {
        setError('description', {
          type: 'manual',
          message: response.errors.description,
        });
      }
      if (response.errors?.file) {
        setError('file', {
          type: 'manual',
          message: response.errors.file,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar el archivo');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Archivo subido correctamente');
      } else {
        toast.error('Error al subir el archivo');
        reset();
      }
      onSubmitCallback();
    }
    setIsLoading(false);
  };

  const onDelete = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const response = await eliminateChatbotSendableMedia(
      chatbotId,
      sendableMedia?.id ?? '',
    );
    if (response?.errors) {
      response.errors?.non_field_errors.map((error: string) => {
        toast.error(error);
      });
    } else {
      if (response?.data?.success) {
        toast.success('Archivo eliminado correctamente');
      } else {
        toast.error('Error al eliminar el archivo');
      }
      onSubmitCallback();
    }
    setIsLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: -16 }}
      encType={'multipart/form-data'}
    >
      <Text variant={'s1'}>Nombre</Text>
      <Input
        placeholder={'Nombre del archivo'}
        defaultValue={sendableMedia?.name ?? ''}
        error={errors.name?.message}
        {...register('name')}
      />
      <Text variant={'s1'}>Descripción e instucciones</Text>
      <TextArea
        placeholder={'Descripción del archivo'}
        defaultValue={sendableMedia?.description ?? ''}
        error={errors.description?.message}
        {...register('description')}
      />
      <Text variant={'s1'}>Archivo</Text>
      <FileInput
        allowedExtensions={[
          '.png',
          '.jpg',
          '.jpeg',
          '.doc',
          '.docx',
          '.csv',
          '.pdf',
          '.gif',
        ]}
        error={errors.file?.message}
        {...register('file')}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
        {sendableMedia ? (
          <Button
            label={'Eliminar'}
            variant={'tertiary'}
            requiresConfirmation={true}
            confirmationTitle={'Eliminar archivo'}
            confirmationText={'¿Estás seguro que deseas eliminar este archivo?'}
            onClick={onDelete}
          />
        ) : null}
        <Button
          label={'Cancelar'}
          variant={'secondary'}
          onClick={() => {
            onSubmitCallback();
            reset();
          }}
        />
        <Button
          type={'submit'}
          label={'Guardar'}
          variant={'primary'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default SendableMediaForm;
