import React, { useState, useEffect } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import { type IconName } from 'components/interface/icon';

import './styles.css';

/**
 * InboxInputMessageTimer
 * @description Component to display time left to send a message
 * @param {number} timeLeft - time left to send a message in seconds
 * @returns {TSX.Element} InboxInputMessageTimer component
 */

type InboxInputMessageTimerProps = {
  timeLeft: number;
};

const InboxInputMessageTimer: React.FC<InboxInputMessageTimerProps> = ({
  timeLeft,
}) => {
  const [hourglassIcon, setHourglassIcon] =
    useState<IconName>('hourglassMedium');

  useEffect(() => {
    if (timeLeft < 7200) {
      // Two hours
      setHourglassIcon('hourglassLow');
    } else if (timeLeft < 43200) {
      // Twelve hours
      setHourglassIcon('hourglassMedium');
    } else {
      // More than twelve hours
      setHourglassIcon('hourglassHigh');
    }
  }, [timeLeft]);

  if (timeLeft <= 0) return null;

  return (
    <div className={'inbox-conversation-window-message'}>
      <Icon
        name={hourglassIcon}
        size={14}
        style={'fill'}
        color={'var(--gray)'}
      />
      <Text variant={'b3'} color={'gray'}>
        WhatsApp cerrará la conversación en {Math.floor(timeLeft / 3600)}h{' '}
        {Math.floor((timeLeft % 3600) / 60)}m
      </Text>
    </div>
  );
};

export default InboxInputMessageTimer;
