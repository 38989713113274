import React from 'react';

import Text from 'components/interface/text';

import './styles.css';

type HrProps = {
  label?: string;
  marginTop?: number;
  marginBottom?: number;
};

/**
 * Hr
 * @description Custom hr component
 * @param {String} label - Hr label
 * @param {Number} marginTop - Hr margin top
 * @param {Number} marginBottom - Hr margin bottom
 * @returns {TSX.Element} Hr component
 */

const Hr: React.FC<HrProps> = ({ label, marginTop, marginBottom }) => {
  return (
    <span
      className={'hr'}
      style={
        label
          ? {}
          : { marginTop: marginTop ?? 16, marginBottom: marginBottom ?? 24 }
      }
    >
      <span />
      {label ? (
        <>
          <Text variant={'b2'}>{label}</Text>
          <span />
        </>
      ) : (
        <></>
      )}
    </span>
  );
};

export default Hr;
