import React, { useState, useEffect } from 'react';

import Modal from 'components/interface/modal';
import Button from 'components/interface/button';
import FileInput from 'components/interface/fileInput';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

/**
 * SendImageModal
 * @description Modal component for sending images
 * @param {boolean} isOpen - Whether the modal is open
 * @param {function} onClose - Function to close the modal
 * @returns {TSX.Element} SendImageModal component
 */

type SendImageModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SendImageModal: React.FC<SendImageModalProps> = ({
  isOpen,
  onClose,
}: SendImageModalProps) => {
  const { inbox, uploadMessageMedia, sendMessage } = useInbox();

  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFile(null);
  }, [isOpen]);

  const handleSend = async () => {
    if (!inbox.activeThread || !file) return;
    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', file);

    const media = await uploadMessageMedia(
      inbox.activeThread?.chatbotId,
      inbox.activeThread?.integrationId,
      formData,
    );

    sendMessage('image', '_', media?.mediaId);

    setIsLoading(false);
    onClose();
  };

  return (
    <Modal title={'Enviar Imagen'} isOpen={isOpen} onClose={onClose}>
      <FileInput
        allowedExtensions={['.png', '.jpg', '.jpeg', '.gif']}
        onChange={(event) => setFile(event.target.files?.[0] || null)}
      />
      <Button
        label={'Enviar'}
        isDisabled={!file}
        isLoading={isLoading}
        onClick={handleSend}
      />
    </Modal>
  );
};

export default SendImageModal;
