import React, { useState, useEffect } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Image from 'components/interface/image';

import { type ChatbotSendableMedia } from 'features/chatbots/providers/ChatbotProvider';

import './styles.css';

type SendableMediaProps = {
  sendableMedia?: ChatbotSendableMedia;
  isAddCard?: boolean;
  onClick?: () => void;
};

const SendableMedia: React.FC<SendableMediaProps> = ({
  sendableMedia,
  isAddCard = false,
  onClick = () => {},
}) => {
  const [isFileImage, setIsFileImage] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  const [fileName, setFileName] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (sendableMedia?.file) {
      setFileName((sendableMedia?.file).split('/').pop()?.split('?')[0]);

      if (sendableMedia?.file?.match(/\.(jpeg|jpg|gif|png)(\?.*)?/) != null) {
        setIsFileImage(true);
        setFileUrl(sendableMedia?.file?.split('?')[0]);
      }
    }
  }, [sendableMedia]);

  return (
    <button className={'sendable-media-card'} onClick={onClick}>
      {isAddCard ? (
        <div className={'sendable-media-card__add'}>
          <Icon name={'cloudArrowUp'} size={20} color={'var(--border-hover)'} />
          <div style={{ height: 8 }}></div>
          <Text variant={'b3'} color={'var(--border-hover)'} align={'center'}>
            Agregar nuevo archivo
          </Text>
        </div>
      ) : (
        <>
          <div
            className={`sendable-media-card__image__container ${
              !sendableMedia ? 'skeleton-loader' : ''
            }`}
          >
            {sendableMedia &&
              (isFileImage ? (
                <Image src={fileUrl ?? ''} />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    padding: '0 16px',
                    gap: 2,
                  }}
                >
                  <Icon name={'file'} size={16} color={'var(--gray-2)'} />
                  <Text
                    variant={'b3'}
                    color={'var(--gray-2)'}
                    numberOfLines={1}
                  >
                    {fileName}
                  </Text>
                </div>
              ))}
          </div>
          <div className={'sendable-media-card__body'}>
            <Text variant={'h8'} numberOfLines={1} isLoading={!sendableMedia}>
              {sendableMedia?.name ?? ''}
            </Text>
            <Text variant={'b3'} numberOfLines={3} isLoading={!sendableMedia}>
              {sendableMedia?.description ?? ''}
            </Text>
          </div>
        </>
      )}
    </button>
  );
};

export default SendableMedia;
