import { type CampaignsState } from './CampaignProvider';

export type CampaignsTypes = 'SAVE_CAMPAIGNS_DATA';

type CampaignAction = {
  type: CampaignsTypes;
  payload?: any;
};

const campaignReducer = (state: CampaignsState, action: CampaignAction) => {
  const { type, payload } = action;

  switch (type) {
    case 'SAVE_CAMPAIGNS_DATA':
      return {
        ...state,
        campaignsData: payload,
      };
    default:
      return state;
  }
};

export default campaignReducer;
