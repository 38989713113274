import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import Text from 'components/interface/text';
import Loader from 'components/interface/loader';
import Icon from 'components/interface/icon';

import { useAuth } from '../hooks/useAuth';

import { colors } from 'constants/colors';

const GoogleLogInView = () => {
  const [params] = useSearchParams({
    code: '',
    state: '',
    error: '',
  });
  const [viewState, setViewState] = useState('LOADING');
  const { authGoogle } = useAuth();

  const STATES = {
    LOADING: {
      icon: <Loader size={40} />,
      title: 'Iniciando sesión, espera un momento',
      subtitle: 'En un momento serás redirigido...',
    },
    ERROR: {
      icon: <Icon size={40} color={colors.error} name={'warningCircle'} />,
      title: 'whoops! Algo salió mal',
      subtitle: 'Hubo un error al iniciar sesión, intenta de nuevo.',
    },
  };

  useEffect(() => {
    const tryLogin = async () => {
      try {
        const code = params.get('code');
        const state = params.get('state');
        const error = params.get('error');
        if (error) {
          setViewState('ERROR');
          return;
        }
        const storedState = localStorage.getItem('google_oauth2_state');
        if (code && state === storedState) {
          await authGoogle(code);
          localStorage.removeItem('google_oauth2_state');
        } else {
          setViewState('ERROR');
        }
      } catch (error) {
        setViewState('ERROR');
      }
    };
    setTimeout(() => {
      tryLogin();
    }, 800);
  }, []);

  return (
    <div className={'auth-confirm'}>
      <div className={'auth-confirm-container'}>
        <div className={'auth-confirm-loader'}>
          {STATES[viewState as keyof typeof STATES].icon}
        </div>
        <Text variant={'h7'}>
          {STATES[viewState as keyof typeof STATES].title}
        </Text>
        <Text variant={'s1'}>
          {STATES[viewState as keyof typeof STATES].subtitle}
        </Text>
      </div>
      <div className={'auth-confirm-disclaimer'}>
        <Text variant={'s2'}>© 2024 Altur, S.A.P.I. de C.V.</Text>
      </div>
    </div>
  );
};

export default GoogleLogInView;
