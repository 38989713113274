import React, { useState } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Modal from 'components/interface/modal';
import Button from 'components/interface/button';

import InviteForm from 'features/dashboard/features/organization/forms/inviteForm';

import { useUser } from 'features/user/hooks/useUser';

const InviteButton: React.FC = () => {
  const { userHasPermission } = useUser();
  const [isOpen, setIsOpen] = useState(false);

  if (!userHasPermission('organization.update')) return null;

  return (
    <>
      <Button
        variant={'secondary'}
        label={'Nueva invitación'}
        icon={<Icon name={'userPlus'} size={18} />}
        onClick={() => setIsOpen(true)}
      />
      <Modal
        title={'Nueva invitación'}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Text variant={'b2'}>
          Invita a tus colaboradores a Altur, ingresa su correo electrónico y
          recibirán una invitación automáticamente.
        </Text>
        <InviteForm submitCallback={() => setIsOpen(false)} />
      </Modal>
    </>
  );
};

export default InviteButton;
