import React from 'react';

import SettingsFields from 'components/interface/settingsFields';

import PasswordChangeForm from '../forms/passwordChangeForm';

const SecuritySettingsView: React.FC = () => {
  const fields = {
    passwordChange: {
      label: 'Cambiar contraseña',
      description:
        'Recomendamos incluir mayúsculas, números y caracteres especiales.',
      component: <PasswordChangeForm />,
    },
  };

  return <SettingsFields fields={fields} />;
};

export default SecuritySettingsView;
