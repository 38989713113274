import api from 'api';

/**
 * fetchOverviewData
 * @description fetches the dashboard data
 * @returns {Promise}
 */

export const fetchOverviewData = async () => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/dashboard/overview/`,
    );
    return { status: 200, data: response.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * fetchCycleCampaignsData
 * @description fetches the cycle's campaigns data
 * @param {organizationId} organizationId Organization ID
 * @returns {Promise}
 */

export const fetchCycleCampaignsData = async (organizationId: string) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/dashboard/campaigns/?organizationId=${organizationId}`,
    );
    return { status: 200, data: response.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};
