import React from 'react';

import { ApiContext } from '../providers/ApiProvider';

import {
  fetchApiKeys,
  postApiKey,
  deleteApiKey,
} from '../functions/apiFunctions';

import { type ApiKey } from '../providers/ApiProvider';

/**
 * @hook useApi
 * @description This hook is used to access the api context
 * @returns {Object} The api context
 */
export const useApi = () => {
  const { apiKeys, saveApiKeys } = React.useContext(ApiContext);

  /**
   * getApiKeys
   * Function to get the api keys data
   */
  const getApiKeys = async (): Promise<any> => {
    const response = await fetchApiKeys();
    if (response.status === 200) {
      const apiKeys = response.data;
      const apiKeysDict: { [key: number]: ApiKey } = {};
      apiKeys.forEach((element: ApiKey) => {
        apiKeysDict[element.id] = {
          id: element.id,
          name: element.name,
          displayableKey: element.displayableKey,
          createdAt: element.createdAt,
          createdBy: element.createdBy,
          lastUsedAt: element.lastUsedAt,
          revoked: element.revoked,
        };
      });
      saveApiKeys(apiKeys);
    }
    return response;
  };

  /**
   * createApiKey
   * Function to create a new api key
   * @param {string} name - The name of the api key
   */
  const createApiKey = async (name: string): Promise<any> => {
    const response = await postApiKey(name);
    if (response.status === 200) {
      getApiKeys();
    }
    return response;
  };

  /**
   * revokeApiKey
   * Function to revoke an api key
   * @param {number} id - The id of the api key
   * @returns {Object} { status } | { status, ...errors }
   */
  const revokeApiKey = async (id: number): Promise<any> => {
    const response = await deleteApiKey(id);
    if (response.status === 200) {
      getApiKeys();
    }
    return response;
  };

  return {
    apiKeys,
    getApiKeys,
    createApiKey,
    revokeApiKey,
  };
};

export default useApi;
