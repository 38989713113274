import React, { useState, useEffect } from 'react';

import { UUID } from 'crypto';

import Text from 'components/interface/text';
import Input from 'components/interface/input';
import Modal from 'components/interface/modal';
import Select from 'components/interface/select';
import Button from 'components/interface/button';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import './styles.css';

/**
 * NewMessageModal
 * @description New message modal component
 * @param {boolean} isOpen - modal open state
 * @param {UUID} chatbotId - chatbot id
 * @param {string} integrationId - integration id
 * @param {string} recipient - recipient contact (phone number)
 * @param {function} setChatbotId - function to set chatbot id
 * @param {function} setIntegrationId - function to set integration id
 * @param {function} setRecipient - function to set recipient
 * @param {function} onNext - function to go to next step
 * @param {function} onClose - function to close modal
 * @returns {TSX.Element} NewMessageModal component
 */

type NewMessageModalProps = {
  isOpen: boolean;
  chatbotId?: UUID;
  integrationId?: string;
  recipient?: string;
  setChatbotId: (chatbotId: UUID) => void;
  setIntegrationId: (integrationId: string) => void;
  setRecipient: (recipient: string) => void;
  onNext: () => void;
  onClose: () => void;
};

const NewMessageModal: React.FC<NewMessageModalProps> = ({
  isOpen,
  chatbotId,
  integrationId,
  recipient,
  setChatbotId,
  setIntegrationId,
  setRecipient,
  onNext,
  onClose,
}) => {
  const { chatbotsData } = useChatbot();

  const [chatbotOptions, setChatbotOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [integrationOptions, setIntegrationOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);

  useEffect(() => {
    if (chatbotsData) {
      const options = Object.values(chatbotsData).map((chatbot) => ({
        value: chatbot.id,
        label: chatbot.nickname,
      }));

      setChatbotOptions(options);
    }
  }, [chatbotsData]);

  useEffect(() => {
    if (chatbotId) {
      const options = Object.values(
        chatbotsData[chatbotId]?.whatsappIntegrations || {},
      ).map((integration) => ({
        value: String(integration.id),
        label: 'WhatsApp | ' + integration.whatsappNumberId,
      }));

      setIntegrationOptions(options);
    } else {
      setIntegrationOptions([]);
    }
  }, [chatbotId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Nuevo mensaje'}>
      <Text variant={'b3'}>Asistente</Text>
      <Select
        placeholder={'Selecciona un asistente'}
        options={chatbotOptions}
        onChange={(e) => setChatbotId(e.target.value as UUID)}
      />
      <div style={{ height: 8 }} />
      <Text variant={'b3'}>Integración</Text>
      <Select
        placeholder={'Selecciona una integración'}
        options={integrationOptions}
        isDisabled={!chatbotId}
        onChange={(e) => setIntegrationId(e.target.value)}
      />
      <div style={{ height: 8 }} />
      <Text variant={'b3'}>Destinatario</Text>
      <Input
        placeholder={'Número de teléfono'}
        isDisabled={!chatbotId || !integrationId}
        onChange={(e) => setRecipient(e.target.value)}
      />
      <div style={{ height: 16 }} />
      <Button
        label={'Siguiente'}
        isDisabled={
          !chatbotId || !integrationId || (recipient?.length ?? 0) !== 10
        }
        onClick={onNext}
      />
    </Modal>
  );
};

export default NewMessageModal;
