import React from 'react';

import { UserContext } from '../providers/UserProvider';

import {
  postCreateAccount,
  fetchUserData,
  putUserData,
  putUserNotificationPreferences,
  putUserPassword,
  postVerifyPhoneNumber,
  getResendPhoneNumberVerificationCode,
} from '../functions/userFunctions';

import { capitalize } from 'utils/strings';
import { getDisplayName } from '../utils/userUtils';
import { hasPermission } from 'features/permissions/utils/permissionUtils';

/**
 * @hook useUser
 * @description Custom hook to use UserContext
 * @returns {Object} UserContext
 */
export const useUser = () => {
  const {
    userDisplayData,
    userPermissions,
    userNotificationPreferences,
    saveUserData,
    saveUserDisplayData,
    saveUserPermissions,
    saveUserNotificationPreferences,
  } = React.useContext(UserContext);

  /**
   * createAccount
   * Function that creates user account
   */
  const createAccount = async (
    email: string,
    password: string,
    vPassword: string,
  ): Promise<any> => {
    const response = await postCreateAccount(email, password, vPassword);
    return response;
  };

  /**
   * getUserData
   * Function that gets user data
   */
  const getUserData = async (): Promise<any> => {
    const response = await fetchUserData();
    if (response.status === 200) {
      const userData = response.data;
      const userDisplayData = {
        id: userData.id,
        email: response.data.email,
        phoneNumber: userData.phoneNumber,
        phoneNumberVerified: userData.phoneNumberVerified,
        displayName: getDisplayName(userData),
        firstName: capitalize(userData.firstName),
        lastName: capitalize(userData.lastName),
        profilePicture: userData.profilePicture,
        role: userData.organizationRole,
      };
      const userPermissions = userData.permissions;
      const userNotificationPreferences = userData.notificationPreferences;
      saveUserData(userData);
      saveUserDisplayData(userDisplayData);
      saveUserPermissions(userPermissions);
      saveUserNotificationPreferences(userNotificationPreferences);
    }
    return response;
  };

  /**
   * updateUserData
   * Function that updates user data
   * @param firstName
   * @param lastName
   * @param phoneNumber
   */
  const updateUserData = async (
    firstName: string,
    lastName: string,
    phoneNumber: string,
  ): Promise<any> => {
    const response = await putUserData(firstName, lastName, phoneNumber);
    if (response.status === 200) {
      getUserData();
    }
    return response;
  };

  /**
   * updateUserNotificationPreferences
   * Function that updates user notification preferences
   * @param notificationPreferences
   */
  const updateUserNotificationPreferences = async (notificationPreferences: {
    [key: string]: boolean;
  }): Promise<any> => {
    const response = await putUserNotificationPreferences(
      notificationPreferences,
    );
    if (response.status === 200) {
      getUserData();
    }
    return response;
  };

  /**
   * updateUserPassword
   * Function that updates user password
   * @param currentPassword
   * @param newPassword
   * @param vNewPassword
   */
  const updateUserPassword = async (
    currentPassword: string,
    newPassword: string,
    vNewPassword: string,
  ): Promise<any> => {
    const response = await putUserPassword(
      currentPassword,
      newPassword,
      vNewPassword,
    );
    return response;
  };

  /**
   * verifyPhoneNumberCode
   * Function that verifies phone number code
   * @param code
   * @returns {boolean}
   */
  const verifyPhoneNumberCode = async (code: string): Promise<any> => {
    const response = await postVerifyPhoneNumber(code);
    if (response.status === 200) {
      getUserData();
      return response?.data?.success;
    }
    return false;
  };

  /**
   * resendPhoneNumberVerificationCode
   * Function that resends phone number verification code
   * @returns {Promise<any>}
   */
  const resendPhoneNumberVerificationCode = async (): Promise<any> => {
    const response = await getResendPhoneNumberVerificationCode();
    return response;
  };

  /**
   * userHasPermission
   * Function that checks if user has permission
   * @param requiredPermissions
   * @returns {boolean}
   */
  const userHasPermission = (requiredPermission: string): boolean => {
    return hasPermission(userPermissions, requiredPermission);
  };

  return {
    userDisplayData,
    userPermissions,
    userNotificationPreferences,
    createAccount,
    getUserData,
    updateUserData,
    updateUserNotificationPreferences,
    updateUserPassword,
    verifyPhoneNumberCode,
    resendPhoneNumberVerificationCode,
    userHasPermission,
  };
};
