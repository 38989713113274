import React, { useState, useEffect } from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';

import { IconName } from 'components/interface/icon';

import './styles.css';

export type TabMenuTabs = {
  [key: string]: {
    label: string;
    iconName?: IconName;
    component: React.ReactNode;
  };
};

type TabMenuProps = {
  tabs: TabMenuTabs;
};

/**
 * TabMenu
 * @description Custom tab menu component
 * @param {TabMenuTabs} tabs - Tab menu tabs
 * @returns {TSX.Element} TabMenu component
 */

const TabMenu: React.FC<TabMenuProps> = ({ tabs }) => {
  const [selectedTab, setSelectedTab] = useState(
    new URL(window.location.href).searchParams.get('tab') ??
      Object.keys(tabs)[0],
  );

  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('tab', selectedTab);
    window.history.replaceState(null, '', url.toString());
  }, [selectedTab]);

  return (
    <div className={'tab-menu'}>
      <div className={'tab-menu-header'}>
        {Object.keys(tabs).map((tabKey) => (
          <div
            key={tabKey}
            className={`tab-menu-header-title ${
              tabKey === selectedTab ? 'tab-menu-header-title-selected' : ''
            }`}
            onClick={() => setSelectedTab(tabKey)}
          >
            {tabs[tabKey].iconName && (
              <div className={'tab-menu-header-title-icon'}>
                <Icon
                  name={tabs[tabKey].iconName}
                  color={tabKey === selectedTab ? 'var(--body)' : 'var(--gray)'}
                  size={18}
                />
              </div>
            )}
            <Text
              variant={'b2'}
              color={tabKey === selectedTab ? 'var(--body)' : 'var(--gray)'}
            >
              {tabs[tabKey].label}
            </Text>
          </div>
        ))}
      </div>
      <div className={'tab-menu-content'}>
        <div className={'tab-content tab-content-selected'}>
          {tabs[selectedTab].component}
        </div>
      </div>
    </div>
  );
};

export default TabMenu;
