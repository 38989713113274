import React from 'react';

import Text from 'components/interface/text';
import MessageContentImage from '../messageContentImage';
import MessageContentDocument from '../messageContentDocument';
import MessageContentAudio from '../messageContentAudio';
import MessageContentTemplate from '../messageContentTemplate';
import MessageContentSystemTag from '../messageContentSystemTag';
import MessageContentSystemCall from '../messageContentSystemCall';
import MessageStatusIcon from '../messageStatusIcon';

import { useInbox } from 'features/inbox/hooks/useInbox';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';
import { useOrganization } from 'features/organization/hooks/useOrganization';

import { type Message } from 'features/inbox/types/inboxTypes';

import { formatTime } from 'utils/dates';

import './styles.css';

/**
 * InboxMessage
 * @description Component to display message
 * @param {Message} message - message object
 * @param {boolean} isLast - is last consecutive message of its type
 * @returns {TSX.Element} Message component
 */

type MessageProps = {
  message: Message;
  isLast: Boolean;
};

const InboxMessage: React.FC<MessageProps> = ({ message, isLast }) => {
  const { inbox } = useInbox();
  const { chatbotsData } = useChatbot();
  const { organizationData } = useOrganization();

  return (
    <div
      className={`inbox-message-container-${message.sentBy} ${
        isLast ? 'inbox-message-container-last' : ''
      }`}
    >
      <div
        className={`inbox-message inbox-message-${message.sentBy} ${
          isLast ? `inbox-message-last-${message.sentBy}` : ''
        }`}
      >
        {message.type === 'image' ? (
          <MessageContentImage message={message} />
        ) : message.type === 'document' ? (
          <MessageContentDocument message={message} />
        ) : message.type === 'audio' ? (
          <MessageContentAudio message={message} />
        ) : message.type === 'template' ? (
          <MessageContentTemplate message={message} />
        ) : message.type === 'system_tag' ? (
          <MessageContentSystemTag message={message} />
        ) : message.type === 'system_call_started' ? (
          <MessageContentSystemCall message={message} callMessage="started" />
        ) : message.type === 'system_call_ended' ? (
          <MessageContentSystemCall message={message} callMessage="ended" />
        ) : (
          <Text variant={'b2'}>{message.content}</Text>
        )}
      </div>
      {isLast && message.sentBy !== 'SYS' && (
        <div className={'inbox-message-details'}>
          {message.sentBy === 'AI' && (
            <>
              <Text variant={'b3'} size={10} color={'var(--gray-2)'}>
                {message.sentByUser
                  ? organizationData.members[message.sentByUser].displayName
                  : `🤖 ${
                      inbox.activeThread
                        ? chatbotsData[inbox.activeThread?.chatbotId].name
                        : 'Bot'
                    }`}
              </Text>
              <Text variant={'b3'} size={10} color={'var(--gray-2)'}>
                @
              </Text>
            </>
          )}
          <Text variant={'b3'} size={10} color={'var(--gray-2)'}>
            {formatTime(message.sentAt)}
          </Text>
          {message.sentBy === 'AI' && (
            <MessageStatusIcon status={message.fStatus} light />
          )}
        </div>
      )}
    </div>
  );
};

export default InboxMessage;
