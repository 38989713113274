import React, { InputHTMLAttributes } from 'react';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  defaultChecked?: boolean;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Checkbox
 * @description Description of Checkbox
 * @param {boolean} defaultChecked - Default value
 * @param {boolean} isDisabled - Is disabled
 * @param {function} onChange - On change function
 * @returns {TSX.Element} Checkbox component
 */

const Checkbox: React.FC<CheckboxProps> = ({
  defaultChecked = false,
  isDisabled = false,
  onChange = () => {},
}) => {
  return (
    <input
      type={'checkbox'}
      defaultChecked={defaultChecked}
      disabled={isDisabled}
      onChange={onChange}
    />
  );
};

export default Checkbox;
