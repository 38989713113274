import React, { useState, useEffect } from 'react';

import { UUID } from 'crypto';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';
import { useTemplate } from 'features/templates/hooks/useTemplate';

import { type CampaignData } from 'features/campaigns/providers/CampaignProvider';

import './styles.css';

type CampaignTemplateProps = {
  name: string;
  status: string;
};

const CampaignTemplate: React.FC<CampaignTemplateProps> = ({
  name,
  status,
}) => {
  return (
    <div className={'campaign-config-card-template'}>
      <div
        className={'campaign-config-card-template__icon'}
        style={{
          backgroundColor: `${
            status === 'APPROVED'
              ? 'var(--success-pale)'
              : status === 'PENDING'
              ? 'var(--pale)'
              : 'var(--error-pale)'
          }`,
        }}
      >
        {status === 'APPROVED' ? (
          <Icon size={14} name={'checkCircle'} color={'var(--success)'} />
        ) : status === 'PENDING' ? (
          <Icon size={14} name={'clock'} color={'var(--gray)'} />
        ) : (
          <Icon size={14} name={'xCircle'} color={'var(--error)'} />
        )}
      </div>
      <Text variant={'b2'} color={'var(--gray)'}>
        {name}
      </Text>
    </div>
  );
};

type CampaignConfigTemplatesProps = {
  campaignId: string;
};

/**
 * CampaignConfigTemplates
 * @description Template for campaign configuration
 * @param {string} campaignId - Campaign ID
 * @returns {TSX.Element} CampaignConfigTemplates component
 */

const CampaignConfigTemplates: React.FC<CampaignConfigTemplatesProps> = ({
  campaignId,
}) => {
  const { campaignsData } = useCampaign();
  const { getIntegrationTemplates } = useTemplate();
  const [templateStatus, setTemplateStatus] = useState<string>('PENDING');
  const [templateAltStatus, setTemplateAltStatus] = useState<string>('PENDING');

  const campaignData: CampaignData = campaignsData[campaignId];

  useEffect(() => {
    const fetchTemplates = async () => {
      if (!campaignData) return;

      const templates = await getIntegrationTemplates(
        campaignData?.botId,
        Number(campaignData?.integrationId),
      );

      if (!templates.data?.templates) return;

      const template = templates.data.templates.find(
        (t: any) => t.name === campaignData?.template,
      );
      const templateAlt = templates.data.templates.find(
        (t: any) => t.name === campaignData?.templateAlt,
      );

      setTemplateStatus(template?.status ?? 'REJECTED');
      setTemplateAltStatus(templateAlt?.status ?? 'REJECTED');
    };

    fetchTemplates();
  }, []);

  return (
    <div className={'campaign-config-card-templates'}>
      {campaignData?.template && (
        <CampaignTemplate
          name={campaignData?.template}
          status={templateStatus}
        />
      )}
      {campaignData?.templateAlt && (
        <CampaignTemplate
          name={campaignData?.templateAlt}
          status={templateAltStatus}
        />
      )}
      {!campaignData?.template && !campaignData?.templateAlt && (
        <CampaignTemplate name={'Sin plantillas'} status={templateAltStatus} />
      )}
    </div>
  );
};

export default CampaignConfigTemplates;
