import React, { useState, useEffect, KeyboardEvent } from 'react';

import Icon from 'components/interface/icon';
import Button from 'components/interface/button';
import TextArea from 'components/interface/textArea';
import IconButton from 'components/interface/iconButton';
import InputChatbotToggle from 'features/inbox/components/inputChatbotToggle';
import InputMessageTimer from 'features/inbox/components/inputMessageTimer';
import InputSendTemplate from 'features/inbox/components/inputSendTemplate';
import InputApi from 'features/inbox/components/inputApi';
import InputPhoneCall from 'features/inbox/components/inputPhoneCall';

import SendDocumentModal from '../sendDocumentModal';
import SendImageModal from 'features/inbox/components/sendImageModal';
import SendTemplateModal from 'features/inbox/components/sendTemplateModal';

import { useInbox } from 'features/inbox/hooks/useInbox';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import './styles.css';

const integrationConfig = {
  whatsapp: {
    allowImages: true,
    allowFiles: true,
    allowTemplates: true,
    allowSend: true,
    allowChatbotToggle: true,
    showConvWindow: true,
  },
  phonecall: {
    allowImages: false,
    allowFiles: false,
    allowTemplates: false,
    allowSend: false,
    allowChatbotToggle: false,
    showConvWindow: false,
  },
  api: {
    allowImages: false,
    allowFiles: false,
    allowTemplates: false,
    allowSend: false,
    allowChatbotToggle: true,
    showConvWindow: false,
  },
};

/**
 * ConversationFooter
 * @description Footer component for the inbox conversation
 * @returns {TSX.Element} ConversationFooter component
 */

const ConversationFooter: React.FC = () => {
  const { inbox, sendMessage } = useInbox();
  const { chatbotsData } = useChatbot();

  const [isSendDocumentModalOpen, setIsSendDocumentModalOpen] = useState(false);
  const [isSendImageModalOpen, setIsSendImageModalOpen] = useState(false);
  const [isSendTemplateModalOpen, setIsSendTemplateModalOpen] = useState(false);

  const [convWindowOpen, setConvWindowOpen] = useState(false);
  const [convWindowTimeLeft, setConvWindowTimeLeft] = useState(0);

  const [sendEnabled, setSendEnabled] = useState(false);
  const [message, setMessage] = useState('');

  const [config, setConfig] = useState(integrationConfig.whatsapp);

  useEffect(() => {
    setMessage('');
    setSendEnabled(false);
    setConfig(integrationConfig[inbox.activeThread?.integrationType ?? 'api']);
  }, [inbox.activeThread?.id]);

  useEffect(() => {
    const checkConvWindow = async () => {
      if (!inbox.activeThread) return;

      const lastMessage = inbox.activeThread.messages
        .slice()
        .reverse()
        .find((message) => message.sentBy === 'EU');

      const lastMessageDate = new Date(lastMessage?.sentAt ?? '1979-03-31');
      const now = new Date();

      const difference = now.getTime() - lastMessageDate.getTime();
      const differenceSeconds = difference / 1000;
      const timeLeft = 86400 - differenceSeconds;

      setConvWindowTimeLeft(timeLeft);
      setConvWindowOpen(timeLeft > 0);
    };

    checkConvWindow();
  }, [inbox.activeThread?.messages]);

  useEffect(() => {
    setSendEnabled(message.length > 0);
  }, [message]);

  const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (!inbox.activeThread) return;
    if (!sendEnabled) return;
    sendMessage('text', message);
    setMessage('');
  };

  return (
    <>
      <div className={'inbox-conversation-footer'}>
        <div className={'inbox-conversation-row'}>
          <InputChatbotToggle allowToggle={config.allowChatbotToggle} />
          {config.showConvWindow ? (
            <InputMessageTimer timeLeft={convWindowTimeLeft} />
          ) : (
            <></>
          )}
        </div>
        <div className={'inbox-conversation-input-container'}>
          {inbox.activeThread?.integrationType === 'whatsapp' ? (
            convWindowOpen ? (
              <TextArea
                placeholder={'Escribe tu mensaje...'}
                value={message}
                onKeyDown={handleKeyPress}
                onChange={(e) => setMessage(e.target.value)}
              />
            ) : (
              <InputSendTemplate
                onClick={() => setIsSendTemplateModalOpen(true)}
              />
            )
          ) : inbox.activeThread?.integrationType === 'phonecall' ? (
            <InputPhoneCall />
          ) : (
            <InputApi />
          )}
        </div>
        <div className={'inbox-conversation-row'}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              iconName={'paperclip'}
              iconColor={'var(--gray)'}
              isDisabled={!convWindowOpen || !config.allowFiles}
              onClick={() => setIsSendDocumentModalOpen(true)}
            />
            <IconButton
              iconName={'image'}
              iconColor={'var(--gray)'}
              isDisabled={!convWindowOpen || !config.allowImages}
              onClick={() => setIsSendImageModalOpen(true)}
            />
            <IconButton
              iconName={'cards'}
              iconColor={'var(--gray)'}
              isDisabled={!config.allowTemplates}
              onClick={() => setIsSendTemplateModalOpen(true)}
            />
          </div>
          <div style={{ marginTop: -16 }}>
            <Button
              label={'Enviar'}
              icon={
                <Icon name={'send'} style={'fill'} color={'var(--white)'} />
              }
              isDisabled={!sendEnabled || !config.allowSend}
              onClick={handleSendMessage}
            />
          </div>
        </div>
      </div>
      {config.allowFiles ? (
        <SendDocumentModal
          isOpen={isSendDocumentModalOpen}
          onClose={() => setIsSendDocumentModalOpen(false)}
        />
      ) : (
        <></>
      )}
      {config.allowImages ? (
        <SendImageModal
          isOpen={isSendImageModalOpen}
          onClose={() => setIsSendImageModalOpen(false)}
        />
      ) : (
        <></>
      )}
      {config.allowTemplates && inbox.activeThread ? (
        <SendTemplateModal
          isOpen={isSendTemplateModalOpen}
          onClose={() => setIsSendTemplateModalOpen(false)}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ConversationFooter;
