import React, {
  FC,
  createContext,
  useState,
  useEffect,
  useReducer,
  useMemo,
} from 'react';

import Confetti from 'react-confetti';

import OrganizationBlockModal from 'features/organization/components/organizationBlockModal';
import OrganizationJoinModal from 'features/organization/components/organizationJoinModal';
import LoadingView from 'views/LoadingView';

import ClientSocket from 'features/user/sockets/clientSocket';

import { useUser } from 'features/user/hooks/useUser';
import { useNotification } from 'features/notifications/hooks/useNotification';
import { useOrganization } from 'features/organization/hooks/useOrganization';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';
import { useCampaign } from 'features/campaigns/hooks/useCampaign';
import { useEndUser } from 'features/endUsers/hooks/useEndUser';

import dashboardReducer from './dashboardReducer';

import { type OrganizationInviteDetails } from 'features/organization/providers/OrganizationProvider';

export type OverviewData = {
  activeChatbots: number;
  activeCampaigns: number;
  usersInOrganization: number;
};

export type SocketStatus = {
  webSocketConnected: boolean;
  webSocketConnecting: boolean;
};

export type DashboardState = {
  activeTab: string;
  isSidebarOpen: boolean;
  isNotificationsOpen: boolean;
  isShootingConfetti: boolean;
  forceSidebarClosed: boolean;
  overviewData: OverviewData;
  clientSocketStatus: SocketStatus;
  saveActiveTab: (tab: string) => void;
  saveIsSidebarOpen: (isOpen: boolean) => void;
  saveIsNotificationsOpen: (isOpen: boolean) => void;
  saveIsShootingConfetti: (isShooting: boolean) => void;
  saveForceSidebarClosed: (forceSidebarClosed: boolean) => void;
  saveOverviewData: (overviewData: OverviewData) => void;
  saveClientSocketStatus: (clientSocketStatus: SocketStatus) => void;
};

type DashboardProviderProps = {
  children: React.ReactNode;
};

export const DashboardContext = createContext<DashboardState>({
  activeTab: 'dashboard',
  isSidebarOpen: true,
  isNotificationsOpen: false,
  isShootingConfetti: false,
  forceSidebarClosed: false,
  overviewData: {
    activeChatbots: 0,
    activeCampaigns: 0,
    usersInOrganization: 0,
  },
  clientSocketStatus: {
    webSocketConnected: false,
    webSocketConnecting: true,
  },
  saveActiveTab: () => {},
  saveIsSidebarOpen: () => {},
  saveIsNotificationsOpen: () => {},
  saveIsShootingConfetti: () => {},
  saveForceSidebarClosed: () => {},
  saveOverviewData: () => {},
  saveClientSocketStatus: () => {},
});

const DashboardProvider: FC<DashboardProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingMessage, setLoadingMessage] = useState<string>('Cargando...');
  const [inviteDetails, setInviteDetails] =
    useState<OrganizationInviteDetails | null>(null);

  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [windowHeight, setWindowHeight] = useState<number>(0);

  const { getUserData } = useUser();
  const { getNotifications } = useNotification();
  const { getOrganizationData, getOrganizationInviteDetails, hasOrganization } =
    useOrganization();
  const { getChatbotsData } = useChatbot();
  const { getCampaignsData } = useCampaign();
  const { getEndUserLists } = useEndUser();

  const initialState: DashboardState = {
    activeTab: 'dashboard',
    isSidebarOpen: true,
    isNotificationsOpen: false,
    isShootingConfetti: false,
    forceSidebarClosed: false,
    overviewData: {
      activeChatbots: 0,
      activeCampaigns: 0,
      usersInOrganization: 0,
    },
    clientSocketStatus: {
      webSocketConnected: false,
      webSocketConnecting: true,
    },
    saveActiveTab: (tab: string) => {
      return null;
    },
    saveIsSidebarOpen: (isOpen: boolean) => {
      return null;
    },
    saveIsNotificationsOpen: (isOpen: boolean) => {
      return null;
    },
    saveIsShootingConfetti: (isShooting: boolean) => {
      return null;
    },
    saveForceSidebarClosed: (forceSidebarClosed: boolean) => {
      return null;
    },
    saveOverviewData: (overviewData: OverviewData) => {
      return null;
    },
    saveClientSocketStatus: (clientSocketStatus: SocketStatus) => {
      return null;
    },
  };

  const [state, dispatch] = useReducer(dashboardReducer, initialState);

  const saveActiveTab = (tab: string) => {
    dispatch({ type: 'SAVE_ACTIVE_TAB', payload: tab });
  };

  const saveIsSidebarOpen = (isOpen: boolean) => {
    dispatch({ type: 'SAVE_SIDEBAR_OPEN', payload: isOpen });
  };

  const saveIsNotificationsOpen = (isOpen: boolean) => {
    dispatch({ type: 'SAVE_IS_NOTIFICATIONS_OPEN', payload: isOpen });
  };

  const saveIsShootingConfetti = (isShootingConfetti: boolean) => {
    dispatch({ type: 'SAVE_SHOOTING_CONFETTI', payload: isShootingConfetti });
  };

  const saveForceSidebarClosed = (forceSidebarClosed: boolean) => {
    dispatch({
      type: 'SAVE_FORCE_SIDEBAR_CLOSED',
      payload: forceSidebarClosed,
    });
  };

  const saveOverviewData = (overviewData: OverviewData) => {
    dispatch({ type: 'SAVE_OVERVIEW_DATA', payload: overviewData });
  };

  const saveClientSocketStatus = (clientSocketStatus: SocketStatus) => {
    dispatch({
      type: 'SAVE_CLIENT_SOCKET_STATUS',
      payload: clientSocketStatus,
    });
  };

  useEffect(() => {
    const inviteCode = localStorage.getItem('inviteCode');
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);

    const getDashboardData = async () => {
      setLoadingMessage('Cargando información...');
      await getUserData();
      await getNotifications();
      setLoadingMessage('Cargando organización...');
      await getOrganizationData();
      setLoadingMessage('Cargando asistentes...');
      await getChatbotsData();
      setLoadingMessage('Cargando contactos...');
      await getEndUserLists();
      setLoadingMessage('Cargando campañas...');
      await getCampaignsData();

      if (inviteCode) {
        const fetchedInviteDetails =
          await getOrganizationInviteDetails(inviteCode);
        setInviteDetails(fetchedInviteDetails?.data ?? null);
      }

      setIsLoading(false);
    };

    const getLocalStorageData = async () => {
      const isSidebarOpen = localStorage.getItem('isSidebarOpen');

      saveIsSidebarOpen(isSidebarOpen === 'true');
    };

    getDashboardData();
    getLocalStorageData();
  }, []);

  const contextValue = useMemo(() => {
    return {
      activeTab: state.activeTab,
      isSidebarOpen: state.isSidebarOpen,
      isNotificationsOpen: state.isNotificationsOpen,
      isShootingConfetti: state.isShootingConfetti,
      forceSidebarClosed: state.forceSidebarClosed,
      overviewData: state.overviewData,
      clientSocketStatus: state.clientSocketStatus,
      saveActiveTab,
      saveIsSidebarOpen,
      saveIsNotificationsOpen,
      saveIsShootingConfetti,
      saveForceSidebarClosed,
      saveOverviewData,
      saveClientSocketStatus,
    };
  }, [
    state.activeTab,
    state.isSidebarOpen,
    state.isNotificationsOpen,
    state.isShootingConfetti,
    state.forceSidebarClosed,
    state.overviewData,
    state.clientSocketStatus,
  ]);

  return (
    <DashboardContext.Provider value={contextValue}>
      {isLoading ? (
        <LoadingView message={loadingMessage} />
      ) : (
        <>
          {!hasOrganization &&
            (inviteDetails ? (
              <OrganizationJoinModal
                code={inviteDetails.code}
                organizationName={inviteDetails.organizationName}
              />
            ) : (
              <OrganizationBlockModal />
            ))}
          {contextValue.isShootingConfetti && (
            <Confetti
              run={contextValue.isShootingConfetti}
              width={windowWidth}
              height={windowHeight}
              numberOfPieces={200}
              onConfettiComplete={() =>
                contextValue.saveIsShootingConfetti(false)
              }
              recycle={false}
            />
          )}
          <ClientSocket />
          {children}
        </>
      )}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
