import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import { UUID } from 'crypto';

import Text from 'components/interface/text';
import Input from 'components/interface/input';
import Toggle from 'components/interface/toggle';
import Button from 'components/interface/button';
import CopyInput from 'components/interface/copyInput';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type ChatbotWhatsappIntegration } from 'features/chatbots/providers/ChatbotProvider';

type FormInputs = {
  whatsappAccountId: string;
  whatsappNumberId: string;
  whatsappSecretId: string;
  pageAccessToken: string;
  botActiveDefault: boolean;
};

type IntegrationFormProps = {
  chatbotId: UUID | null;
  integration?: ChatbotWhatsappIntegration | null;
  onSubmitCallback: () => void;
};

const IntegrationForm: React.FC<IntegrationFormProps> = ({
  chatbotId,
  integration,
  onSubmitCallback,
}) => {
  const { createChatbotWhatsappIntegration, updateChatbotWhatsappIntegration } =
    useChatbot();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async (data, event: any) => {
    if (isLoading) return;
    setIsLoading(true);

    if (!chatbotId) {
      toast.error('Error al actualizar la integración');
      setIsLoading(false);
      return;
    }

    // Send request to API conditional to create or update
    const response = integration
      ? await updateChatbotWhatsappIntegration(
          chatbotId,
          integration.id,
          data.whatsappAccountId,
          data.whatsappNumberId,
          data.whatsappSecretId,
          data.pageAccessToken,
          data.botActiveDefault,
        )
      : await createChatbotWhatsappIntegration(
          chatbotId,
          data.whatsappAccountId,
          data.whatsappNumberId,
          data.whatsappSecretId,
          data.pageAccessToken,
          data.botActiveDefault,
        );

    // Handle response errors or success
    if (response?.errors) {
      if (response.errors?.whatsappAccountId) {
        setError('whatsappAccountId', {
          type: 'manual',
          message: response.errors.whatsappAccountId,
        });
      }
      if (response.errors?.whatsappNumberId) {
        setError('whatsappNumberId', {
          type: 'manual',
          message: response.errors.whatsappNumberId,
        });
      }
      if (response.errors?.whatsappSecretId) {
        setError('whatsappSecretId', {
          type: 'manual',
          message: response.errors.whatsappSecretId,
        });
      }
      if (response.errors?.pageAccessToken) {
        setError('pageAccessToken', {
          type: 'manual',
          message: response.errors.pageAccessToken,
        });
      }
      if (response.errors?.botActiveDefault) {
        setError('botActiveDefault', {
          type: 'manual',
          message: response.errors.botActiveDefault,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar la integración');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Integración guardada correctamente');
      } else {
        toast.error('Error al guardar la integración');
        reset();
      }
      onSubmitCallback();
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: -16 }}>
      <Text variant={'s1'}>WhatsApp Account ID</Text>
      <Input
        placeholder={'ex. 102542439484312'}
        defaultValue={integration?.whatsappAccountId ?? ''}
        error={errors.whatsappAccountId?.message}
        {...register('whatsappAccountId')}
      />
      <Text variant={'s1'}>WhatsApp Number ID</Text>
      <Input
        placeholder={'ex. 107542429474362'}
        defaultValue={integration?.whatsappNumberId ?? ''}
        error={errors.whatsappNumberId?.message}
        {...register('whatsappNumberId')}
      />
      <Text variant={'s1'}>WhatsApp Secret ID</Text>
      <Input
        placeholder={'ex. 13f0a92b0c2c9cc2147c1bd38afe6bc5'}
        defaultValue={integration?.whatsappSecretId ?? ''}
        error={errors.whatsappSecretId?.message}
        {...register('whatsappSecretId')}
      />
      <Text variant={'s1'}>Page Access Token</Text>
      <Input
        placeholder={
          'ex. EAARBGT1FZpixrnZClQVQvKZiq5FBNmosZCymEYpYPmyTzi1H5opU2sifNZB4ucv5MMBLiwdaZBzKSLbDfeUq5JtUMP5W7lHBAFB5nUVSApb07myhCZAz8swKNae8560vP1zZCW7ysVN1oNtZA1IQ78an4I2e99nsIkZAWgcwpbZB4sA6xRVzerAMBXXomVAZDZD'
        }
        defaultValue={integration?.pageAccessToken ?? ''}
        error={errors.pageAccessToken?.message}
        {...register('pageAccessToken')}
      />
      <Text variant={'s1'}>Webhook URL</Text>
      <CopyInput text={`https://api.altur.io/bots/${chatbotId}/whatsapp/`} />
      <div style={{ height: 8 }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 8,
        }}
      >
        <Text variant={'s1'} style={{ margin: 0 }}>
          Respuestas automáticas
        </Text>
        <Toggle
          defaultChecked={integration?.botActiveDefault ?? true}
          error={errors.botActiveDefault?.message}
          {...register('botActiveDefault')}
        />
      </div>
      <div style={{ height: 16 }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
        <Button
          label={'Cancelar'}
          variant={'secondary'}
          onClick={() => {
            onSubmitCallback();
            reset();
          }}
        />
        <Button
          type={'submit'}
          label={'Guardar'}
          variant={'primary'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default IntegrationForm;
