import React from 'react';

import SidebarExpandable from '../sidebarExpandable';
import SidebarSelectable from '../sidebarSelectable';

import { useInbox } from 'features/inbox/hooks/useInbox';

import { type IntegrationType } from 'features/inbox/types/inboxTypes';

/**
 * InboxSidebarChannels
 * @description Sidebar component to toggle channels
 * @returns {TSX.Element} InboxSidebarChannels component
 */

const InboxSidebarChannels: React.FC = () => {
  const { inboxFilters, filterToggleChannel } = useInbox();

  const handleChannelClick = (channel: IntegrationType) => {
    filterToggleChannel(channel);
  };

  return (
    <SidebarExpandable
      title={'Canales'}
      elements={[
        <SidebarSelectable
          key={'whatsapp'}
          title={'WhatsApp'}
          iconName={'whatsapp'}
          color={'integration-whatsapp'}
          isActive={inboxFilters.selectedChannels.includes('whatsapp')}
          onClick={() => handleChannelClick('whatsapp')}
        />,
        <SidebarSelectable
          key={'phonecall'}
          title={'Llamadas'}
          iconName={'phone'}
          color={'integration-phone-call'}
          isActive={inboxFilters.selectedChannels.includes('phonecall')}
          onClick={() => handleChannelClick('phonecall')}
        />,
      ]}
    />
  );
};

export default InboxSidebarChannels;
