import React, { useState, useEffect } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import EndUserPicture from '../endUserPicture';

import { useInbox } from 'features/inbox/hooks/useInbox';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { formatSocialId, displayIntegrationIcon } from 'utils/integrations';
import { formatPhoneNumber } from 'utils/strings';

import './styles.css';

/**
 * InboxDetailsEndUser
 * @description Container for the end user's info
 * @returns {TSX.Element} InboxDetailsEndUser component
 */

const InboxDetailsEndUser: React.FC = () => {
  const { inbox } = useInbox();
  const { chatbotsData } = useChatbot();

  const [integrationDisplay, setIntegrationDisplay] = useState<string>('');
  const [endUserContactDisplay, setEndUserContactDisplay] =
    useState<string>('');

  useEffect(() => {
    if (inbox.activeThread?.integrationType) {
      if (inbox.activeThread.integrationType === 'whatsapp') {
        setIntegrationDisplay(
          chatbotsData[inbox.activeThread.chatbotId]?.whatsappIntegrations[
            inbox.activeThread.integrationId
          ]?.whatsappNumberId,
        );
        setEndUserContactDisplay(
          formatSocialId(
            inbox.activeThread.integrationType,
            inbox.activeThread.endUser?.whatsapp?.phoneNumber ?? '',
          ),
        );
      } else if (inbox.activeThread.integrationType === 'phonecall') {
        setIntegrationDisplay(
          formatPhoneNumber(
            chatbotsData[inbox.activeThread.chatbotId]?.phoneCallIntegrations[
              inbox.activeThread.integrationId
            ]?.phoneNumber,
          ),
        );
        setEndUserContactDisplay(
          formatPhoneNumber(
            inbox.activeThread.endUser?.phoneCall?.phoneNumber ?? '',
          ),
        );
      } else if (inbox.activeThread.integrationType === 'api') {
        setIntegrationDisplay('API');
        setEndUserContactDisplay(inbox.activeThread.endUser?.api?.id ?? '');
      }
    }
  }, [inbox.activeThread?.endUser]);

  if (!inbox.activeThread) return <></>;

  return (
    <div className={'inbox-details-enduser'}>
      <div className={'inbox-details-enduser-card'}>
        <EndUserPicture endUser={inbox.activeThread?.endUser} size={48} />
        <div style={{ height: 4 }} />
        <Text variant={'b2'} weight={500}>
          {inbox.activeThread?.endUser?.displayName || 'Anónimo'}
        </Text>
        <Text variant={'b3'} color={'var(--gray-2)'}>
          {endUserContactDisplay}
        </Text>
      </div>
      <div style={{ marginTop: -8, marginLeft: 4, marginRight: 4 }}>
        <Text variant={'s2'}>Asistente</Text>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: -4 }}>
          <Icon name={'robot'} size={14} />
          <Text variant={'b3'}>
            {chatbotsData[inbox.activeThread?.chatbotId]?.nickname}
          </Text>
        </div>
      </div>
      <div style={{ marginTop: -8, marginLeft: 4, marginRight: 4 }}>
        <Text variant={'s2'}>Integración</Text>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: -4 }}>
          <Icon
            name={
              displayIntegrationIcon[inbox.activeThread?.integrationType].name
            }
            size={14}
          />
          <Text variant={'b3'}>{integrationDisplay}</Text>
        </div>
      </div>
    </div>
  );
};

export default InboxDetailsEndUser;
