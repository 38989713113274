import React from 'react';

import Text from 'components/interface/text';
import Image from 'components/interface/image';

import TFAForm from 'features/auth/forms/tfaForm';

import logo from 'assets/svgs/brand/logo_primary.svg';
import graphic from 'assets/images/auth/auth_graphic.png';

import './styles.css';

const TFA = () => {
  return (
    <div className="auth-container">
      <div>
        <Image src={logo} width={96} className={'auth-logo'} />
        <div>
          <Text variant={'h4'}>Ingresa el código de autenticación</Text>
          <TFAForm />
        </div>
      </div>
      <div>
        <Image src={graphic} />
      </div>
    </div>
  );
};

export default TFA;
