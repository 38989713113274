import React from 'react';

import { OrganizationContext } from '../providers/OrganizationProvider';

import {
  fetchOrganizationData,
  putOrganizationData,
  postOrganizationInvite,
  deleteOrganizationInvite,
  fetchOrganizationInviteDetails,
  putOrganizationMember,
  deleteOrganizationMember,
  postJoinOrganization,
  postOrganizationRole,
  putOrganizationRole,
  fetchBillingCycleDays,
} from '../functions/organizationFunctions';

import { getDisplayName } from 'features/user/utils/userUtils';

import {
  type OrganizationMember,
  type OrganizationRole,
  type OrganizationInvite,
  type BillingCycle,
  type BillingCycleDay,
} from '../providers/OrganizationProvider';

/**
 * @hook useOrganization
 * @description This hook is used to access the organization context
 * @returns {Object} The organization context
 */
export const useOrganization = () => {
  const {
    hasOrganization,
    organizationData,
    saveOrganizationData,
    saveHasOrganization,
  } = React.useContext(OrganizationContext);

  /**
   * getOrganization
   * Function to get the organization data
   */
  const getOrganizationData = async (): Promise<any> => {
    const response = await fetchOrganizationData();
    if (response.status === 200) {
      const organizationData = response.data;
      const organizationMembersDict: { [key: string]: OrganizationMember } = {};
      organizationData.members.forEach((element: OrganizationMember) => {
        organizationMembersDict[element.id] = {
          id: element.id,
          email: element.email,
          firstName: element.firstName,
          lastName: element.lastName,
          displayName: getDisplayName(element),
          profilePicture: element.profilePicture,
          role: element.role,
          isOnline: element.isOnline,
          lastOnline: element.lastOnline,
        };
      });
      const organizationRolesDict: { [key: string]: OrganizationRole } = {};
      organizationData.roles.forEach((element: OrganizationRole) => {
        organizationRolesDict[element.id] = {
          id: element.id,
          name: element.name,
          description: element.description,
          permissions: element.permissions,
          isSystem: element.isSystem,
        };
      });
      const organizationInvitesDict: { [key: string]: OrganizationInvite } = {};
      organizationData.invites.forEach((element: OrganizationInvite) => {
        organizationInvitesDict[element.code] = {
          code: element.code,
          email: element.email,
          sender: element.sender,
          organization: element.organization,
          sentAt: element.sentAt,
          lastRetry: element.lastRetry,
          revoked: element.revoked,
        };
      });
      const organizationBillingCyclesDict: { [key: string]: any } = {};
      organizationData.billing.billingCycles.forEach(
        (element: BillingCycle) => {
          organizationBillingCyclesDict[element.id] = {
            id: element.id,
            startCycleDate: element.startCycleDate,
            nextCycleDate: element.nextCycleDate,
            generatedMessageCount: element.generatedMessageCount,
            outboundMessageCount: element.outboundMessageCount,
            outboundCallCount: element.outboundCallCount,
            outboundCallConnectedCount: element.outboundCallConnectedCount,
            outboundCallMinutes: element.outboundCallMinutes,
            days: {},
          };
        },
      );
      organizationData.members = organizationMembersDict;
      organizationData.roles = organizationRolesDict;
      organizationData.invites = organizationInvitesDict;
      organizationData.billing.billingCycles = organizationBillingCyclesDict;
      saveOrganizationData(organizationData);
      saveHasOrganization(true);
    } else {
      saveHasOrganization(false);
    }
    return response;
  };

  /**
   * updateOrganization
   * Function to update the organization data
   * @param {string} name - The name of the organization
   * @returns {Promise<any>} The response of the request
   */
  const updateOrganizationData = async (name: string): Promise<any> => {
    const response = await putOrganizationData(name);
    if (response.status === 200) {
      getOrganizationData();
    }
    return response;
  };

  /**
   * sendOrganizationInvite
   * @description Function that sends organization invite
   * @param {string} email - The email of the user to invite
   * @returns {Promise<any>} The response of the request
   */
  const sendOrganizationInvite = async (email: string): Promise<any> => {
    const response = await postOrganizationInvite(email);
    if (response.status === 200) {
      getOrganizationData();
    }
    return response;
  };

  /**
   * revokeOrganizationInvite
   * @description Function that revokes organization invite
   * @param {string} code - The code of the invite to revoke
   * @returns {Promise<any>} The response of the request
   */
  const revokeOrganizationInvite = async (code: string): Promise<any> => {
    const response = await deleteOrganizationInvite(code);
    if (response.status === 200) {
      getOrganizationData();
    }
    return response;
  };

  /**
   * getOrganizationInviteDetails
   * @description Function that gets organization invite details
   * @param {string} code - The code of the invite to get
   * @returns {Promise<any>} The response of the request
   */
  const getOrganizationInviteDetails = async (code: string): Promise<any> => {
    const response = await fetchOrganizationInviteDetails(code);
    return response;
  };

  /**
   * acceptOrganizationInvite
   * @description Function that accepts organization invite
   * @param {string} code - The code of the invite to accept
   * @returns {Promise<any>} The response of the request
   */
  const acceptOrganizationInvite = async (code: string): Promise<any> => {
    const response = await postJoinOrganization(code);
    if (response.status === 200) {
      getOrganizationData();
    }
    return response;
  };

  /**
   * Update organization member
   * @param {string} id - The id of the member to update
   * @param {string} role - The role to update
   * @returns {Promise<any>} The response of the request
   */
  const updateOrganizationMember = async (
    id: string,
    role: string,
  ): Promise<any> => {
    const member = organizationData.members[id];
    const memberCopy = { ...member };
    memberCopy.role = role;
    const response = await putOrganizationMember(memberCopy);
    if (response.status === 200) {
      getOrganizationData();
    }
    return response;
  };

  /**
   * Remove organization member
   * @param {string} id - The id of the member to remove
   * @returns {Promise<any>} The response of the request
   */
  const removeOrganizationMember = async (id: string): Promise<any> => {
    const response = await deleteOrganizationMember(id);
    if (response.status === 200) {
      getOrganizationData();
    }
    return response;
  };

  /**
   * Update organization member status
   * @param {string} id - The id of the member to update
   * @param {boolean} isOnline - The status of the member to update
   * @param {string} lastOnline - The last online of the member to update
   * @returns
   */
  const updateOrganizationMemberStatus = (
    id: string,
    isOnline: boolean,
    lastOnline: string,
  ) => {
    const member = organizationData.members[id];
    const memberCopy = { ...member };
    memberCopy.isOnline = isOnline;
    memberCopy.lastOnline = lastOnline;
    const organizationDataAux = { ...organizationData };
    organizationDataAux.members[id] = memberCopy;
    saveOrganizationData(organizationDataAux);
  };

  /**
   * Create organization role
   * @param {string} name - The name of the role to create
   * @param {string} description - The description of the role to create
   */
  const createOrganizationRole = async (name: string): Promise<any> => {
    const response = await postOrganizationRole(name);
    if (response.status === 200) {
      getOrganizationData();
    }
    return response;
  };

  /**
   * Update organization role
   * @param {string} id - The id of the role to update
   * @param {string} name - The name of the role to update
   * @param {string} description - The description of the role to update
   * @param {object} permissions - The permissions of the role to update
   * @returns {Promise<any>} The response of the request
   */
  const updateOrganizationRole = async (
    id: string,
    name: string,
    description: string,
    permissions: object,
  ): Promise<any> => {
    const role = organizationData.roles[id];
    const roleCopy = { ...role };
    roleCopy.name = name;
    roleCopy.description = description;
    roleCopy.permissions = permissions;
    const response = await putOrganizationRole(roleCopy);
    if (response.status === 200) {
      getOrganizationData();
    }
    return response;
  };

  /**
   * Get Billing Cycle days
   * @param {string} organizationId - The id of the organization
   * @param {number} billingCycleId - The billing cycle's id
   * @returns {Promise<any>} The response of the request
   */
  const getBillingCycleDays = async (
    organizationId: string,
    billingCycleId: number,
  ): Promise<any> => {
    const response = await fetchBillingCycleDays(
      organizationId,
      billingCycleId,
    );
    const organizationDataAux = { ...organizationData };
    const billingCycleDaysDict: { [key: string]: BillingCycleDay } = {};

    if (!response.data || response.data.length === 0) {
      organizationDataAux.billing.billingCycles[billingCycleId].days = {};
      saveOrganizationData(organizationDataAux);
      return;
    }

    response.data.forEach((element: BillingCycleDay) => {
      billingCycleDaysDict[element.date] = {
        date: element.date,
        generatedMessageCount: element.generatedMessageCount,
        outboundMessageCount: element.outboundMessageCount,
        outboundCallCount: element.outboundCallCount,
        outboundCallConnectedCount: element.outboundCallConnectedCount,
        outboundCallMinutes: element.outboundCallMinutes,
      };
    });
    organizationDataAux.billing.billingCycles[billingCycleId].days =
      billingCycleDaysDict;
    saveOrganizationData(organizationDataAux);
  };

  return {
    hasOrganization,
    organizationData,
    getOrganizationData,
    updateOrganizationData,
    sendOrganizationInvite,
    revokeOrganizationInvite,
    getOrganizationInviteDetails,
    acceptOrganizationInvite,
    updateOrganizationMember,
    removeOrganizationMember,
    updateOrganizationMemberStatus,
    createOrganizationRole,
    updateOrganizationRole,
    getBillingCycleDays,
  };
};
