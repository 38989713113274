import React from 'react';

import PhoneInputWithCountrySelect from 'react-phone-number-input/react-hook-form';

import 'react-phone-number-input/style.css';
import './styles.css';

type PhoneFormInputProps = {
  name: string;
  control: any;
  defaultValue?: string;
  placeholder: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  error?: string;
};

/**
 * PhoneFormInput
 * @description PhoneFormInput component wrapper
 * @param {String} name - PhoneFormInput name
 * @param {Any} control - PhoneFormInput control
 * @param {String} defaultValue - PhoneFormInput default value
 * @param {String} placeholder - PhoneFormInput placeholder
 * @param {Boolean} isDisabled - PhoneFormInput disabled
 * @param {Boolean} isRequired - PhoneFormInput required
 * @param {String} error - PhoneFormInput error message
 * @returns {TSX.Element} PhoneFormInput component
 */

const PhoneFormInput: React.FC<PhoneFormInputProps> = ({
  name,
  control,
  defaultValue,
  placeholder,
  isDisabled = false,
  isRequired = false,
  error,
  ...props
}) => {
  return (
    <>
      <PhoneInputWithCountrySelect
        className={`${error ? 'PhoneInput-error' : ''}`}
        name={name}
        control={control}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={isDisabled}
        rules={{ required: isRequired }}
        defaultCountry={'MX'}
        {...props}
      />
      {error && <span className="PhoneInput-error-message">{error}</span>}
    </>
  );
};

export default PhoneFormInput;
