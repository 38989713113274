import React from 'react';

import CampaignCard from 'features/dashboard/features/campaigns/components/campaignCard';
import EmptyState from 'components/interface/emptyState';

import { type CampaignData } from 'features/campaigns/providers/CampaignProvider';

import './styles.css';

type CampaignGridProps = {
  campaigns: CampaignData[];
};

const CampaignGrid: React.FC<CampaignGridProps> = ({ campaigns }) => {
  return (
    <>
      <div className="campaign-card-container">
        {campaigns.map((campaign) => {
          return <CampaignCard key={campaign.id} campaign={campaign} />;
        })}
      </div>
      {campaigns.length === 0 && (
        <div style={{ marginTop: 124 }}>
          <EmptyState text={'No hay campañas'} iconName={'megaphoneSimple'} />
        </div>
      )}
    </>
  );
};

export default CampaignGrid;
