import React, { useState } from 'react';

import { toast } from 'react-toastify';

import Text from 'components/interface/text';
import Modal from 'components/interface/modal';
import Button from 'components/interface/button';

import { useDashboard } from 'features/dashboard/hooks/useDashboard';
import { useOrganization } from 'features/organization/hooks/useOrganization';

type OrganizationJoinModalProps = {
  code: string;
  organizationName: string;
};

const OrganizationJoinModal: React.FC<OrganizationJoinModalProps> = ({
  code,
  organizationName,
}) => {
  const { shootConfetti } = useDashboard();
  const { acceptOrganizationInvite } = useOrganization();
  const [isJoining, setIsJoining] = useState<boolean>(false);

  const handleJoin = async () => {
    setIsJoining(true);
    const response = await acceptOrganizationInvite(code);
    if (response?.status === 200) {
      toast.success(`Te has unido a ${organizationName}`);
      shootConfetti();
    } else {
      toast.warning('La invitación ya no es válida o los correos no coinciden');
    }
    setIsJoining(false);
    localStorage.removeItem('inviteCode');
  };

  const handleReject = () => {
    localStorage.removeItem('inviteCode');
    window.location.href = '/';
  };

  return (
    <>
      <Modal
        iconName={'envelopeOpen'}
        isOpen={true}
        canClose={false}
        onClose={() => {}}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text variant={'h6'}>Has sido invitado a {organizationName}</Text>
          <div style={{ height: 16 }}></div>
          <Text variant={'b2'} color={'var(--gray)'} align={'center'}>
            Los administradores de {organizationName} te han invitado a unirte a
            su organización. Si no reconoces esta organización rechaza la
            invitación.
          </Text>
          <div style={{ marginTop: 8, display: 'flex', gap: 8 }}>
            <Button
              label={`Rechazar`}
              variant={'secondary'}
              onClick={handleReject}
            />
            <Button
              label={`Unirse a ${organizationName}`}
              onClick={handleJoin}
              isLoading={isJoining}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OrganizationJoinModal;
