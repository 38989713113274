import React, { FC, createContext, useReducer, useMemo } from 'react';

import { UUID } from 'crypto';

import endUserReducer from './endUserReducer';

import { type CampaignContact } from 'features/campaigns/providers/CampaignProvider';

export type EndUserWhatsapp = {
  fId: string;
  phoneNumber: string;
};

export type EndUserPhoneCall = {
  phoneNumber: string;
};

export type EndUserApi = {
  id: string;
};

export type EndUser = {
  id: UUID;
  displayName: string;
  info: string;
  lastSeen: string;
  inLists?: string[];
  campaignUsers?: CampaignContact[];
  whatsapp?: EndUserWhatsapp;
  phoneCall?: EndUserPhoneCall;
  api?: EndUserApi;
};

export type EndUsersData = {
  [key: UUID]: EndUser;
};

export type EndUserList = {
  id: string;
  name: string;
  description: string;
  color: string;
  endUsers: UUID[];
  endUserCount?: number;
};

export type EndUserLists = {
  [key: string]: EndUserList;
};

export type EndUsersState = {
  endUsersData: EndUsersData;
  endUserCount: number;
  endUserLists: EndUserLists;
  saveEndUsersData: (endUsers: EndUsersData) => void;
  saveEndUserCount: (endUserCount: number) => void;
  saveEndUserLists: (endUserLists: EndUserLists) => void;
};

type EndUserProviderProps = {
  children: React.ReactNode;
};

export const EndUserContext = createContext<EndUsersState>({
  endUsersData: {},
  endUserCount: 0,
  endUserLists: {},
  saveEndUsersData: () => {},
  saveEndUserCount: () => {},
  saveEndUserLists: () => {},
});

const EndUserProvider: FC<EndUserProviderProps> = ({ children }) => {
  const initialState: EndUsersState = {
    endUsersData: {},
    endUserCount: 0,
    endUserLists: {},
    saveEndUsersData: (endUsers: EndUsersData) => {
      return null;
    },
    saveEndUserCount: (endUserCount: number) => {
      return null;
    },
    saveEndUserLists: (endUserLists: EndUserLists) => {
      return null;
    },
  };

  const [state, dispatch] = useReducer(endUserReducer, initialState);

  const saveEndUsersData = (endUsersData: EndUsersData) => {
    dispatch({ type: 'SAVE_ENDUSERS_DATA', payload: endUsersData });
  };

  const saveEndUserCount = (endUserCount: number) => {
    dispatch({ type: 'SAVE_ENDUSER_COUNT', payload: endUserCount });
  };

  const saveEndUserLists = (endUserLists: EndUserLists) => {
    dispatch({ type: 'SAVE_ENDUSER_LISTS', payload: endUserLists });
  };

  const contextValue = useMemo(() => {
    return {
      endUsersData: state.endUsersData,
      endUserCount: state.endUserCount,
      endUserLists: state.endUserLists,
      saveEndUsersData,
      saveEndUserCount,
      saveEndUserLists,
    };
  }, [state.endUsersData, state.endUserCount, state.endUserLists]);

  return (
    <EndUserContext.Provider value={contextValue}>
      {children}
    </EndUserContext.Provider>
  );
};

export default EndUserProvider;
