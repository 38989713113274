import React from 'react';

import Icon from 'components/interface/icon';

import './styles.css';

type SearchInputProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * SearchInput
 * @description Input component used for search
 * @param {function} onChange - Function to handle input change
 * @returns {TSX.Element} SearchInput component
 */

const SearchInput: React.FC<SearchInputProps> = ({ onChange }) => {
  return (
    <div className={'input-search'}>
      <Icon name={'magnifyingGlass'} color={'var(--gray)'} />
      <input placeholder={'Buscar...'} onChange={onChange} />
    </div>
  );
};

export default SearchInput;
