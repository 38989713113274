import React, { useState } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Input from 'components/interface/input';

import './styles.css';

type CopyInputProps = {
  text: string;
};

/**
 * CopyInput
 * @description Input component that copies text to clipboard
 * @param {string} text - Text to copy
 * @returns {TSX.Element} CopyInput component
 */

const CopyInput: React.FC<CopyInputProps> = ({ text }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  return (
    <div className={'copy-input'}>
      <Input placeholder={'Copy this text'} defaultValue={text} isDisabled />
      <button type={'button'} onClick={handleCopy}>
        <div className={'copy-input__hint'}>
          <Text variant={'h8'} align={'center'} color={'var(--white)'}>
            {isCopied ? '¡Listo!' : 'Copiar'}
          </Text>
        </div>
        <Icon name={'copy'} />
      </button>
    </div>
  );
};

export default CopyInput;
