import { type DashboardState } from './DashboardProvider';

export type DashboardTypes =
  | 'SAVE_ACTIVE_TAB'
  | 'SAVE_SIDEBAR_OPEN'
  | 'SAVE_IS_NOTIFICATIONS_OPEN'
  | 'SAVE_SHOOTING_CONFETTI'
  | 'SAVE_FORCE_SIDEBAR_CLOSED'
  | 'SAVE_OVERVIEW_DATA'
  | 'SAVE_CLIENT_SOCKET_STATUS';

type DashboardAction = {
  type: DashboardTypes;
  payload?: any;
};

const dashboardReducer = (state: DashboardState, action: DashboardAction) => {
  const { type, payload } = action;

  switch (type) {
    case 'SAVE_ACTIVE_TAB':
      return {
        ...state,
        activeTab: payload,
      };
    case 'SAVE_SIDEBAR_OPEN':
      payload === null
        ? localStorage.removeItem('isSidebarOpen')
        : localStorage.setItem('isSidebarOpen', JSON.stringify(payload));
      return {
        ...state,
        isSidebarOpen: payload,
      };
    case 'SAVE_IS_NOTIFICATIONS_OPEN':
      return {
        ...state,
        isNotificationsOpen: payload,
      };
    case 'SAVE_SHOOTING_CONFETTI':
      return {
        ...state,
        isShootingConfetti: payload,
      };
    case 'SAVE_FORCE_SIDEBAR_CLOSED':
      return {
        ...state,
        forceSidebarClosed: payload,
      };
    case 'SAVE_OVERVIEW_DATA':
      return {
        ...state,
        overviewData: payload,
      };
    case 'SAVE_CLIENT_SOCKET_STATUS':
      return {
        ...state,
        clientSocketStatus: payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
