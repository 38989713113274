import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import Card from 'components/interface/card';
import EmptyState from 'components/interface/emptyState';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import {
  type CampaignData,
  type CampaignStats,
} from 'features/campaigns/providers/CampaignProvider';

import {
  CAMPAIGN_INTEGRATION_TYPE_WHATSAPP,
  CAMPAIGN_INTEGRATION_TYPE_PHONECALL,
} from 'features/campaigns/providers/CampaignProvider';

import './styles.css';

type CampaignCompletion = {
  contactsReachedPercentage: number;
  contactsReached: number;
  contacts: number;
};

/**
 * CampaignsActiveCard component
 * @description This component is used to display currently active campaigns in the overview page
 * @returns {React.FunctionComponent}
 */

const CampaignsActiveCard = () => {
  const navigate = useNavigate();
  const { campaignsData, getCampaignStats } = useCampaign();
  const { chatbotsData } = useChatbot();

  const [activeCampaigns, setActiveCampaigns] = useState<CampaignData[]>([]);

  useEffect(() => {
    if (!campaignsData) return;

    const activeCampaigns = Object.values(campaignsData).filter(
      (campaign) => campaign.status === 'active',
    );

    for (const campaign of activeCampaigns) {
      getStats(campaign.id);
    }

    setActiveCampaigns(activeCampaigns);
  }, []);

  const getStats = async (id: string) => {
    await getCampaignStats(id);
  };

  const getCampaignCompletion = (campaign: CampaignData) => {
    if (campaign.integrationType === CAMPAIGN_INTEGRATION_TYPE_WHATSAPP) {
      return {
        contactsReachedPercentage: campaign.campaignStats?.completionRate ?? 0,
        contactsReached: campaign.campaignStats?.completed ?? 0,
        contacts: campaign.campaignStats?.contacts ?? 0,
      };
    } else {
      return {
        contactsReachedPercentage:
          campaign.campaignStats?.contactsSentRate ?? 0,
        contactsReached: campaign.campaignStats?.contactsSent ?? 0,
        contacts: campaign.campaignStats?.contacts ?? 0,
      };
    }
  };

  return (
    <Card
      title={'Campañas activas'}
      style={{
        flex: 1,
        alignSelf: 'stretch',
      }}
    >
      <div className={'overview-active-campaigns'}>
        {activeCampaigns.map((campaign) => (
          <div
            key={campaign.id}
            className={'overview-active-campaign'}
            onClick={() => navigate(`/dashboard/campaigns/${campaign.id}`)}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 80,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                <Icon
                  name={'megaphoneSimple'}
                  size={16}
                  color={'var(--primary)'}
                />
                <div>
                  <Text variant={'b2'}>{campaign.name}</Text>
                  <div className={'overview-active-campaign-bot'}>
                    <Text variant={'b3'} size={11} color={'var(--gray)'}>
                      {chatbotsData[campaign.botId].name}
                    </Text>
                  </div>
                </div>
              </div>
              <Text variant={'b2'} formatter={'percentage'}>
                {getCampaignCompletion(campaign).contactsReachedPercentage}
              </Text>
            </div>
            <div className={'overview-active-campaign__completion'}>
              <div
                style={{
                  width: `${
                    getCampaignCompletion(campaign).contactsReachedPercentage ??
                    0
                  }%`,
                  backgroundColor: 'var(--primary)',
                }}
              />
            </div>
          </div>
        ))}
        {activeCampaigns.length === 0 && (
          <div style={{ margin: 24, minWidth: 320 }}>
            <EmptyState
              text={'No hay campañas activas'}
              iconName={'megaphoneSimple'}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default CampaignsActiveCard;
