import React from 'react';
import { Link } from 'react-router-dom';

import Text from 'components/interface/text';
import Image from 'components/interface/image';
import Hr from 'components/interface/hr';

import GoogleAuthButton from '../components/googleAuthButton';

import SignUpForm from 'features/auth/forms/signupForm';

import logo from 'assets/svgs/brand/logo_primary.svg';
import graphic from 'assets/images/auth/auth_graphic.png';

import './styles.css';

const SignUp = () => {
  return (
    <div className="auth-container">
      <div>
        <Image src={logo} width={96} className={'auth-logo'} />
        <div>
          <Text variant={'h4'}>Crea tu cuenta</Text>
          <GoogleAuthButton />
          <Hr label={'o'} />
          <SignUpForm />
          <Text variant={'s1'}>
            ¿Ya tienes una cuenta?{' '}
            <Link to={'/auth/login'}>Inicia sesión aquí.</Link>
          </Text>
        </div>
      </div>
      <div>
        <Image src={graphic} />
      </div>
    </div>
  );
};

export default SignUp;
