import api from 'api';

import { UUID } from 'crypto';

/**
 * fetchIntegrationTemplates
 * @description Fetches integration templates from Meta Cloud API through Altur West API
 * @returns {Promise} Promise object represents the integration templates
 */

export const fetchIntegrationTemplates = async (
  botId: UUID,
  integrationId: number,
) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/templates/?botId=${botId}&integrationId=${integrationId}`,
    );
    return { status: 200, data: response.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};
