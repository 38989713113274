import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import LogInView from 'features/auth/views/LogInView';
import SignUpView from 'features/auth/views/SignUpView';
import ConfirmView from 'features/auth/views/ConfirmView';
import PasswordRequestView from 'features/auth/views/PasswordRequestView';
import PasswordChangeView from 'features/auth/views/PasswordChangeView';
import GoogleLogInView from 'features/auth/views/GoogleLogInView';
import TFAView from 'features/auth/views/TFAView';

import { useAuth } from 'features/auth/hooks/useAuth';

const AuthRouter = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard', { replace: true });
    }
  }, [isAuthenticated]);

  return (
    <Routes>
      <Route path={'*'} element={<Navigate to={'login'} replace />} />
      <Route path={'login'} element={<LogInView />} />
      <Route path={'signup'} element={<SignUpView />} />
      <Route path={'google'} element={<GoogleLogInView />} />
      <Route path={'2fa'} element={<TFAView />} />
      <Route path={'confirm/:uidb64/:token'} element={<ConfirmView />} />
      <Route path={'recovery'} element={<PasswordRequestView />} />
      <Route
        path={'recovery/:uidb64/:token'}
        element={<PasswordChangeView />}
      />
    </Routes>
  );
};

export default AuthRouter;
