import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Button from 'components/interface/button';
import TextArea from 'components/interface/textArea';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

/**
 * InboxDetailsInfo
 * @description Container for the enduser's info
 * @returns {TSX.Element} InboxDetailsInfo component
 */

const InboxDetailsInfo: React.FC = () => {
  const { inbox, updateEndUserInfo } = useInbox();

  const [info, setInfo] = useState<string>(
    inbox.activeThread?.endUser?.info ?? '',
  );
  const [changesDetected, setChangesDetected] = useState<boolean>(false);

  useEffect(() => {
    setChangesDetected(info !== inbox.activeThread?.endUser?.info);
  }, [info]);

  useEffect(() => {
    setInfo(inbox.activeThread?.endUser?.info ?? '');
  }, [inbox?.activeThread?.endUser?.id]);

  const handleSave = async () => {
    if (inbox.activeThread) {
      const response = await updateEndUserInfo(inbox.activeThread?.id, info);
      if (response) {
        toast.success('Información actualizada');
      } else {
        toast.error('Error al actualizar la información');
      }
    }
  };

  return (
    <div className={'inbox-details-info'}>
      <TextArea
        placeholder={'Sin información'}
        value={info}
        isResizeable={false}
        onChange={(e) => setInfo(e.target.value)}
      />
      <Button
        label={'Guardar'}
        variant={'secondary'}
        size={'small'}
        isDisabled={!changesDetected}
        onClick={handleSave}
      />
    </div>
  );
};

export default InboxDetailsInfo;
