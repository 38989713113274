import React, { useState, useEffect } from 'react';

import Card from 'components/interface/card';
import OrganizationMemberCard from '../organizationMemberCard';

import { useOrganization } from 'features/organization/hooks/useOrganization';

import { type OrganizationMember } from 'features/organization/providers/OrganizationProvider';

import './styles.css';

/**
 * OrganizationMembersCard
 * @description Description of OrganizationMembersCard
 * @returns {TSX.Element} OrganizationMembersCard component
 */

const OrganizationMembersCard: React.FC = () => {
  const { organizationData } = useOrganization();

  const [onlineMembers, setOnlineMembers] = useState<OrganizationMember[]>([]);
  const [offlineMembers, setOfflineMembers] = useState<OrganizationMember[]>(
    [],
  );

  useEffect(() => {
    if (!organizationData) return;

    const orderedMembers = Object.values(organizationData.members || {}).sort(
      (a, b) => {
        return (
          new Date(b.lastOnline).getTime() - new Date(a.lastOnline).getTime()
        );
      },
    );

    setOnlineMembers(orderedMembers.filter((member) => member.isOnline));
    setOfflineMembers(orderedMembers.filter((member) => !member.isOnline));
  }, [organizationData]);

  return (
    <Card
      title={'Miembros de la organización'}
      style={{
        flex: 2,
        alignSelf: 'stretch',
      }}
    >
      <div className={'organization-members-card'}>
        {onlineMembers.map((member) => (
          <OrganizationMemberCard key={member.id} member={member} />
        ))}
        {offlineMembers.map((member) => (
          <OrganizationMemberCard key={member.id} member={member} />
        ))}
      </div>
    </Card>
  );
};

export default OrganizationMembersCard;
