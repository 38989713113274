import React, { useState } from 'react';

import { toast } from 'react-toastify';

import IconButton from 'components/interface/iconButton';

import { useApi } from 'features/api/hooks/useApi';

import { IconName } from 'components/interface/icon';

type ApiKeyActionsProps = {
  id: number;
};

const ApiKeyActions: React.FC<ApiKeyActionsProps> = ({ id }) => {
  const { revokeApiKey } = useApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRevoke = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const response = await revokeApiKey(id);
    if (response?.data?.success) {
      toast.success('Llave revocada correctamente');
    } else {
      toast.error('Error al revocar la llave');
    }
    setIsLoading(false);
  };

  return (
    <div style={{ display: 'flex', gap: 8, justifyContent: 'flex-end' }}>
      <IconButton
        iconName={'prohibitInset' as IconName}
        hint={'Revocar'}
        isLoading={isLoading}
        requiresConfirmation={true}
        confirmationTitle={'Revocar llave'}
        confirmationText={
          '¿Estás seguro de que quieres revocar esta llave? Cualquier integración que use esta llave dejará de funcionar.'
        }
        onClick={handleRevoke}
      />
    </div>
  );
};

export default ApiKeyActions;
