import React, { forwardRef, TextareaHTMLAttributes } from 'react';

import './styles.css';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  placeholder: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isResizeable?: boolean;
  value?: string | number;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

/**
 * TextArea component
 * @description Custom TextArea component
 * @param {String} placeholder - Placeholder text
 * @param {Boolean} isDisabled - Is disabled
 * @param {Boolean} isRequired - Is required
 * @param {Boolean} isResizeable - Is resizeable
 * @param {String | Number} value - Value
 * @param {String} error - Error message
 * @param {Function} onChange - On change function
 * @param {Function} onKeyDown - On key press function
 * @returns {TSX.Element} TextArea component
 */

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      placeholder,
      isDisabled = false,
      isRequired = false,
      isResizeable = true,
      value,
      error,
      onChange,
      onKeyDown,
      ...props
    },
    ref,
  ) => {
    return (
      <>
        <textarea
          ref={ref}
          className={`textarea${error ? ' textarea-error' : ''}`}
          placeholder={placeholder}
          disabled={isDisabled}
          required={isRequired}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          style={{
            resize: isResizeable ? 'vertical' : 'none',
          }}
          {...props}
        ></textarea>
        {error && <span className="textarea-error-message">{error}</span>}
      </>
    );
  },
);

export default TextArea;
