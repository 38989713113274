import React, { useState, useEffect } from 'react';

import Text from 'components/interface/text';
import SidebarExpandable from '../sidebarExpandable';
import SidebarSelectable from '../sidebarSelectable';

import { useInbox } from 'features/inbox/hooks/useInbox';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

/**
 * InboxSidebarTags
 * @description Sidebar component to toggle thread tags
 * @returns {TSX.Element} InboxSidebarTags component
 */

const InboxSidebarTags: React.FC = () => {
  const { inboxFilters, filterToggleTag } = useInbox();
  const { chatbotsData } = useChatbot();

  const [tagFilters, setTagFilters] = useState<any[]>([]);

  useEffect(() => {
    handleChatbotFilterUpdates();
  }, [inboxFilters.selectedChatbots]);

  const handleChatbotFilterUpdates = () => {
    const tagFiltersAux: any[] = Object.values(chatbotsData).reduce(
      (filters, chatbot) => {
        if (
          !inboxFilters.selectedChatbots.includes(chatbot.id) ||
          !Object.keys(chatbot.tags).length
        ) {
          return filters;
        }

        const updatedFilters = [
          ...filters,
          {
            type: 'title',
            name: chatbot.nickname,
          },
        ];

        return updatedFilters.concat(
          Object.values(chatbot.tags).map((tag) => ({
            type: 'tag',
            id: tag.id,
            name: tag.name,
            color: tag.color,
            chatbotId: chatbot.id,
            chatbotName: chatbot.nickname,
          })),
        );
      },
      [] as any,
    );

    setTagFilters(tagFiltersAux);
  };

  const handleTagClick = (tagId: string) => {
    filterToggleTag(tagId);
  };

  return (
    <SidebarExpandable
      title={'Etiquetas'}
      elements={Object.values(tagFilters).map((element) => {
        if (element.type === 'title') {
          return (
            <Text variant={'b3'} color={'var(--gray)'} key={element.name}>
              {element.name}
            </Text>
          );
        }

        return (
          <SidebarSelectable
            key={element.id}
            title={element.name}
            iconName={'tag'}
            color={`tag-${element.color}`}
            isActive={inboxFilters.selectedTags.includes(element.id)}
            onClick={() => handleTagClick(element.id)}
          />
        );
      })}
    />
  );
};

export default InboxSidebarTags;
