import React, { useState } from 'react';

import PulseLoader from 'react-spinners/PulseLoader';

import Text from 'components/interface/text';
import Modal from '../modal';

import './styles.css';

type ButtonProps = {
  label?: string;
  type?: 'button' | 'submit';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger';
  size?: 'small' | 'medium';
  icon?: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  requiresConfirmation?: boolean;
  confirmationTitle?: string;
  confirmationText?: string;
  onClick?: () => void;
};

/**
 * Button
 * @description Custom button component
 * @param {String} label - Button label
 * @param {String} type - Button type
 * @param {String} size - Button size
 * @param {String} variant - Button variant
 * @param {TSX.Element} icon - Button icon
 * @param {Boolean} isDisabled - Button disabled
 * @param {Boolean} isLoading - Button loading
 * @param {Boolean} requiresConfirmation - Button requires confirmation
 * @param {String} confirmationTitle - Button confirmation title
 * @param {String} confirmationText - Button confirmation text
 * @param {Function} onClick - Button onClick function
 * @returns {TSX.Element} Button component
 */

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  label,
  variant = 'primary',
  size = 'medium',
  icon,
  isDisabled = false,
  isLoading = false,
  requiresConfirmation = false,
  confirmationTitle = '',
  confirmationText = '',
  onClick = () => {},
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  return (
    <>
      <button
        type={type}
        className={`button button-${size} button-${variant}`}
        onClick={
          !isDisabled && !isLoading
            ? requiresConfirmation
              ? () => setIsConfirmModalOpen(true)
              : onClick
            : () => {}
        }
        disabled={isDisabled}
      >
        {isLoading ? (
          <div className={'button-loader-container'}>
            <PulseLoader
              size={size !== 'small' ? 8 : 6}
              color={variant === 'primary' ? 'var(--white)' : 'var(--gray)'}
              speedMultiplier={0.8}
            />
          </div>
        ) : null}
        <div className={`button-label-container ${isLoading ? 'hidden' : ''}`}>
          {icon && (
            <span style={{ display: 'flex', marginLeft: -6 }}>{icon}</span>
          )}
          {label && (
            <Text variant={size !== 'small' ? 'b2' : 'b3'} numberOfLines={1}>
              {label}
            </Text>
          )}
        </div>
      </button>
      {requiresConfirmation && (
        <Modal
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          title={confirmationTitle}
        >
          <Text variant={'b2'}>{confirmationText}</Text>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 8,
              marginTop: 8,
            }}
          >
            <button
              type={'button'}
              className={'button button-secondary'}
              onClick={() => setIsConfirmModalOpen(false)}
            >
              <Text variant={'b2'}>Cancelar</Text>
            </button>
            <button
              type={'button'}
              className={`button button-${variant}`}
              onClick={isLoading ? () => {} : onClick}
            >
              <Text variant={'b2'}>Confirmar acción</Text>
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Button;
