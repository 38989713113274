import React, { useState, useEffect } from 'react';

import fallbackImage from 'assets/images/other/default.png';

import './styles.css';

type ImageProps = {
  src: string;
  width?: number;
  height?: number;
  skeletonWidth?: number;
  skeletonHeight?: number;
  className?: string;
  blackAndWhite?: boolean;
};

/**
 * Image
 * @description Custom image component
 * @param {String} src - Image source
 * @param {Number} width - Image width
 * @param {Number} height - Image height
 * @param {Number} skeletonWidth - Skeleton width
 * @param {Number} skeletonHeight - Skeleton height
 * @param {String} className - Image className
 * @param {Boolean} blackAndWhite - Black and white image
 * @returns {TSX.Element} Image component
 */

const Image: React.FC<ImageProps> = ({
  src,
  width,
  height,
  skeletonWidth,
  skeletonHeight,
  className,
  blackAndWhite,
}) => {
  const [currentSrc, setCurrentSrc] = useState(src);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setCurrentSrc(src);
  }, [src]);

  const handleError = () => {
    setCurrentSrc(fallbackImage);
  };

  return (
    <>
      {isLoading && (
        <div
          className={`skeleton-loader ${className}`}
          style={{ width: skeletonWidth, height: skeletonHeight }}
        />
      )}
      <img
        src={currentSrc}
        width={width ?? 'auto'}
        height={height ?? 'auto'}
        className={`${className} ${blackAndWhite ? 'image-baw' : ''} ${
          isLoading ? 'image-loading' : ''
        }`}
        onLoad={() => setIsLoading(false)}
        onError={handleError}
      />
    </>
  );
};

export default Image;
