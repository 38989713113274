import React, { useState, useEffect } from 'react';

import Text from 'components/interface/text';
import Thread from 'features/inbox/components/thread';

import { useInbox } from 'features/inbox/hooks/useInbox';

import { type Thread as ThreadType } from 'features/inbox/types/inboxTypes';

import './styles.css';

/**
 * InboxDetailsRelatedThreads
 * @description Container to display related threads
 * @returns {TSX.Element} InboxDetailsRelatedThreads component
 */

const InboxDetailsRelatedThreads: React.FC = () => {
  const { inbox } = useInbox();

  const [threads, setThreads] = useState<ThreadType[]>([]);

  useEffect(() => {
    if (inbox.activeThread) {
      const relatedThreads = Object.values(inbox.threads).filter(
        (thread) =>
          thread.conversationId === inbox.activeThread?.conversationId &&
          thread.id !== inbox.activeThread?.id,
      );

      setThreads(relatedThreads);
    }
  }, [inbox.activeThread, inbox.threads]);

  if (threads.length === 0) {
    return null;
  }

  return (
    <div className={'inbox-related-threads'}>
      <Text variant={'b2'}>Otras conversaciones</Text>
      {threads.map((thread) => (
        <div key={thread.id} className={'inbox-related-thread'}>
          <Thread thread={thread} isStandalone={true} />
        </div>
      ))}
    </div>
  );
};

export default InboxDetailsRelatedThreads;
