import React, { useState, useEffect } from 'react';

import { UUID } from 'crypto';

import TemplateCard from '../templateCard';
import TemplateCardSkeleton from '../templateCardSkeleton';

import { useTemplate } from 'features/templates/hooks/useTemplate';

import './styles.css';

type TemplateSelectorProps = {
  chatbotId: UUID;
  integrationId: number;
  selectables?: number;
  defaultSelectedTemplates?: string[];
  enableLoading?: boolean;
  onSelectedTemplatesChange: (templateName: any[]) => void;
};

const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  chatbotId,
  integrationId,
  selectables = 1,
  defaultSelectedTemplates = [],
  enableLoading = false,
  onSelectedTemplatesChange,
}) => {
  const { getIntegrationTemplates } = useTemplate();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>(
    defaultSelectedTemplates,
  );

  useEffect(() => {
    const fetchTemplates = async () => {
      const templates = await getIntegrationTemplates(chatbotId, integrationId);
      setTemplates(templates?.data?.templates ?? []);
    };

    if (integrationId) fetchTemplates();
    if (!integrationId) setTemplates([]);
  }, [chatbotId, integrationId]);

  useEffect(() => {
    const selectedTemplatesAux = selectedTemplates;
    if (selectedTemplates.length > selectables) {
      selectedTemplatesAux.shift();
    }
    setSelectedTemplates(selectedTemplatesAux);
    onSelectedTemplatesChange(selectedTemplatesAux);
  }, [selectedTemplates]);

  return (
    <div className={'templates-container'}>
      {enableLoading &&
        templates.length <= 0 &&
        [...Array(4)].map((_, i) => <TemplateCardSkeleton key={i} />)}
      {templates.map((template: any) => (
        <TemplateCard
          key={template.id}
          templateData={template}
          isSelected={
            selectedTemplates.includes(template) ||
            selectedTemplates.includes(template?.name)
          }
          onClick={() => {
            setSelectedTemplates([...selectedTemplates, template]);
          }}
        />
      ))}
    </div>
  );
};

export default TemplateSelector;
