import { capitalize } from 'utils/strings';

import { type UserData } from '../providers/UserProvider';
import { type OrganizationMember } from 'features/organization/providers/OrganizationProvider';

export const getDisplayName = (userData: UserData | OrganizationMember) => {
  return userData.firstName && userData.lastName
    ? capitalize(userData.firstName) + ' ' + capitalize(userData.lastName)
    : userData.email;
};
