/**
 * Format a number to a string with commas
 * @param number - The number to format
 * @returns The formatted number
 * @example
 * formatNumber(1000) // '1,000'
 * formatNumber(1000000) // '1,000,000'
 * formatNumber(1000000000) // '1,000,000,000'
 * formatNumber(1000000000000) // '1,000,000,000,000'
 * formatNumber(1000000000000000) // '1,000,000,000,000,000'
 */
export const formatNumber = (
  number: number,
  minDecimals?: number,
  maxDecimals?: number,
) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDecimals || 0,
    maximumFractionDigits: maxDecimals || 0,
  }).format(number);
};
