import React from 'react';

import Text from 'components/interface/text';
import UserAvatar from 'components/interface/userAvatar';

import { useUser } from 'features/user/hooks/useUser';

import { type OrganizationMember } from 'features/organization/providers/OrganizationProvider';

import { formatDateTimeOrDate } from 'utils/dates';

import './styles.css';

/**
 * OrganizationMemberCard
 * @description Description of OrganizationMemberCard
 * @param {OrganizationMember} member - Member of the organization
 * @returns {TSX.Element} OrganizationMemberCard component
 */

type OrganizationMemberCardProps = {
  member: OrganizationMember;
};

const OrganizationMemberCard: React.FC<OrganizationMemberCardProps> = ({
  member,
}) => {
  const { userDisplayData } = useUser();

  return (
    <div key={member.id} className={'organization-member'}>
      <UserAvatar organizationMember={member} />
      <div className={'organization-member-text'}>
        <Text variant={'b2'}>
          {userDisplayData.id === member.id
            ? `${member.displayName} (Tú)`
            : member.displayName}
        </Text>
        <Text variant={'b3'} color={'var(--gray)'}>
          {member.isOnline || userDisplayData.id === member.id
            ? 'En línea'
            : `Visto por última vez ${formatDateTimeOrDate(member.lastOnline)}`}
        </Text>
      </div>
    </div>
  );
};

export default OrganizationMemberCard;
