import React from 'react';

import { UUID } from 'crypto';

import { ChatbotContext } from '../providers/ChatbotProvider';

import {
  postChatbot,
  deleteChatbot,
  fetchChatbotsData,
  putChatbotGeneralData,
  putChatbotBehaviorData,
  fetchChatbotKnowledgeSources,
  postChatbotKnowledgeSource,
  putChatbotKnowledgeSource,
  deleteChatbotKnowledgeSource,
  fetchChatbotKnowledgeChunks,
  fetchChatbotSendableMedia,
  postChatbotSendableMedia,
  putChatbotSendableMedia,
  deleteChatbotSendableMedia,
  postChatbotTag,
  putChatbotTag,
  deleteChatbotTag,
  putChatbotAssignees,
  postChatbotWhatsappIntegration,
  putChatbotWhatsappIntegration,
} from '../functions/chatbotFunctions';

import { type ChatbotData } from '../providers/ChatbotProvider';
import { type ChatbotsData } from '../providers/ChatbotProvider';
import { type ChatbotKnowledgeSource } from '../providers/ChatbotProvider';
import { type ChatbotKnowledgeChunk } from '../providers/ChatbotProvider';
import { type ChatbotSendableMedia } from '../providers/ChatbotProvider';
import { type ChatbotTag } from '../providers/ChatbotProvider';
import { type ChatbotAssignee } from '../providers/ChatbotProvider';
import { type ChatbotWhatsappIntegration } from '../providers/ChatbotProvider';
import { type ChatbotPhoneCallIntegration } from '../providers/ChatbotProvider';

/**
 * @hook useChatbot
 * @description This hook is used to access the chatbot context
 * @returns {Object} The chatbot context
 */
export const useChatbot = () => {
  const { chatbotsData, saveChatbotsData } = React.useContext(ChatbotContext);

  /**
   * createChatbot
   * Function to create a new chatbot
   * @param {string} nickname
   * @param {string} name
   * @returns {Object} { status} | { status, ...errors }
   */
  const createChatbot = async (
    nickname: string,
    name: string,
  ): Promise<any> => {
    const response = await postChatbot(nickname, name);
    if (response.status === 200) {
      getChatbotsData();
    }
    return response;
  };

  /**
   * deactivateChatbot
   * Function to delete the chatbot
   * @param {UUID} id
   * @returns {Object} { status} | { status, ...errors }
   */
  const deactivateChatbot = async (id: UUID): Promise<any> => {
    const response = await deleteChatbot(id);
    if (response.status === 200) {
      getChatbotsData();
    }
    return response;
  };

  /**
   * getChatbots
   * Function to get the chatbots data
   */
  const getChatbotsData = async (): Promise<any> => {
    const response = await fetchChatbotsData();
    if (response.status === 200) {
      const chatbotsData = response.data;
      const chatbotsDict: { [key: UUID]: ChatbotData } = {};

      chatbotsData.forEach((element: ChatbotData, index: number) => {
        const tagsData = chatbotsData[index].tags;
        const tagsDict: { [key: string]: ChatbotTag } = {};
        tagsData.forEach((tag: ChatbotTag) => {
          tagsDict[tag.id] = {
            id: tag.id,
            name: tag.name,
            color: tag.color,
            trigger: tag.trigger,
            isConversion: tag.isConversion,
            isAuto: tag.isAuto,
            instructions: tag.instructions,
            notifications: tag.notifications,
          };
        });

        const assigneesData = chatbotsData[index].assignees;
        const assigneesDict: { [key: string]: ChatbotAssignee } = {};
        assigneesData.forEach((assignee: ChatbotAssignee) => {
          assigneesDict[assignee.userId] = {
            userId: assignee.userId,
            chatbotId: assignee.chatbotId,
            isEligible: assignee.isEligible,
          };
        });

        const whatsappIntegrationData =
          chatbotsData[index].whatsappIntegrations;
        const whatsappIntegrationDict: {
          [key: string]: ChatbotWhatsappIntegration;
        } = {};
        whatsappIntegrationData.forEach(
          (whatsappIntegration: ChatbotWhatsappIntegration) => {
            whatsappIntegrationDict[whatsappIntegration.id] = {
              id: whatsappIntegration.id,
              chatbotId: element.id,
              whatsappAccountId: whatsappIntegration.whatsappAccountId,
              whatsappNumberId: whatsappIntegration.whatsappNumberId,
              whatsappSecretId: whatsappIntegration.whatsappSecretId,
              pageAccessToken: whatsappIntegration.pageAccessToken,
              botActiveDefault: whatsappIntegration.botActiveDefault,
            };
          },
        );

        const phoneCallIntegrationData =
          chatbotsData[index].phoneCallIntegrations;
        const phoneCallIntegrationDict: {
          [key: string]: ChatbotPhoneCallIntegration;
        } = {};
        phoneCallIntegrationData.forEach(
          (phoneCallIntegration: ChatbotPhoneCallIntegration) => {
            phoneCallIntegrationDict[phoneCallIntegration.id] = {
              id: phoneCallIntegration.id,
              chatbotId: element.id,
              phoneNumber: phoneCallIntegration.phoneNumber,
              phoneNumberId: phoneCallIntegration.phoneNumberId,
              voiceName: phoneCallIntegration.voiceName,
              voiceId: phoneCallIntegration.voiceId,
            };
          },
        );

        chatbotsDict[element.id] = {
          id: element.id,
          nickname: element.nickname,
          name: element.name,
          image: element.image,
          threshold: element.threshold,
          createdAt: element.createdAt,
          isActive: element.isActive,
          personality: element.personality,
          instructionsWhatsapp: element.instructionsWhatsapp,
          instructionsPhoneCall: element.instructionsPhoneCall,
          objective: element.objective,
          businessName: element.businessName,
          businessDescription: element.businessDescription,
          knowledgeSources: {},
          sendableMedia: {},
          tags: tagsDict,
          assignees: assigneesDict,
          whatsappIntegrations: whatsappIntegrationDict,
          phoneCallIntegrations: phoneCallIntegrationDict,
        };
      });

      saveChatbotsData(chatbotsDict);
    }
    return response;
  };

  /**
   * updateChatbotGeneralData
   * Function to update the chatbot general data
   */
  const updateChatbotGeneralData = async (
    id: UUID,
    nickname: string,
    threshold: number,
    isActive: boolean,
  ): Promise<any> => {
    const response = await putChatbotGeneralData(
      id,
      nickname,
      threshold,
      isActive,
    );
    if (response.status === 200) {
      getChatbotsData();
    }
    return response;
  };

  /**
   * updateChatbotBehaviorData
   * Function to update the chatbot behavior data
   */
  const updateChatbotBehaviorData = async (
    id: UUID,
    name: string,
    personality: string,
    instructionsWhatsapp: string,
    instructionsPhoneCall: string,
    objective: string,
    businessName: string,
    businessDescription: string,
  ): Promise<any> => {
    const response = await putChatbotBehaviorData(
      id,
      name,
      personality,
      instructionsWhatsapp,
      instructionsPhoneCall,
      objective,
      businessName,
      businessDescription,
    );
    if (response.status === 200) {
      getChatbotsData();
    }
    return response;
  };

  /**
   * getChatbotKnowledgeSources
   * Function to get the chatbot knowledge sources
   * @param {UUID} id The chatbot id
   */
  const getChatbotKnowledgeSources = async (id: UUID): Promise<any> => {
    const response = await fetchChatbotKnowledgeSources(id);
    if (response.status === 200) {
      const knowledgeSources = response?.data || [];
      const knowledgeSourcesDict: { [key: string]: ChatbotKnowledgeSource } =
        {};
      await knowledgeSources.forEach(
        async (element: ChatbotKnowledgeSource) => {
          knowledgeSourcesDict[element.id] = {
            id: element.id,
            chatbotId: id,
            name: element.name,
            type: element.type,
            file: element.file,
            lastUpdated: element.lastUpdated,
            chunks: {},
          };
        },
      );

      const chatbotsDataCopy: ChatbotsData = { ...chatbotsData };
      chatbotsDataCopy[id].knowledgeSources = knowledgeSourcesDict;

      saveChatbotsData(chatbotsDataCopy);
    }
    return response;
  };

  /**
   * getChatbotKnowledgeChunks
   * Function to get a knowledge source's chunks
   * @param {UUID} chatbotId The chatbot id
   * @param {string} knowledgeSourceid The knowledge source id
   */
  const getChatbotKnowledgeChunks = async (
    chatbotId: UUID,
    knowledgeSourceid: string,
  ): Promise<any> => {
    const response = await fetchChatbotKnowledgeChunks(knowledgeSourceid);
    if (response.status === 200) {
      const knowledgeChunks = response.data || [];
      const knowledgeChunksDict: { [key: string]: ChatbotKnowledgeChunk } = {};
      knowledgeChunks.forEach((element: ChatbotKnowledgeChunk) => {
        knowledgeChunksDict[element.id] = {
          id: element.id,
          knowledgeSourceId: element.knowledgeSourceId,
          summary: element.summary,
          content: element.content,
        };
      });

      const chatbotsDataCopy: ChatbotsData = { ...chatbotsData };

      chatbotsDataCopy[chatbotId].knowledgeSources[knowledgeSourceid].chunks =
        knowledgeChunksDict;

      saveChatbotsData(chatbotsDataCopy);
    }
    return response;
  };

  /**
   * createChatbotKnowledgeSource
   * Function to create the chatbot knowledge source
   * @param {UUID} botId
   * @param {FormData} formData
   */
  const createChatbotKnowledgeSource = async (
    botId: UUID,
    formData: FormData,
  ): Promise<any> => {
    formData.append('botId', botId);
    const response = await postChatbotKnowledgeSource(formData);
    getChatbotKnowledgeSources(botId);
    return response;
  };

  /**
   * updateChatbotKnowledgeSource
   * Function to update the chatbot knowledge source
   * @param {UUID} botId
   * @param {string} knowledgeSourceId
   * @param {string} name
   */
  const updateChatbotKnowledgeSource = async (
    botId: UUID,
    knowledgeSourceId: string,
    name: string,
  ): Promise<any> => {
    const response = await putChatbotKnowledgeSource(
      knowledgeSourceId,
      botId,
      name,
    );
    if (response.status === 200) {
      getChatbotKnowledgeSources(botId);
    }
    return response;
  };

  /**
   * removeChatbotKnowledgeSource
   * Function to remove the chatbot knowledge source
   * @param {UUID} botId
   * @param {string} knowledgeSourceId
   */
  const removeChatbotKnowledgeSource = async (
    botId: UUID,
    knowledgeSourceId: string,
  ): Promise<any> => {
    const response = await deleteChatbotKnowledgeSource(knowledgeSourceId);
    if (response.status === 200) {
      getChatbotKnowledgeSources(botId);
    }
    return response;
  };

  /**
   * getChatbotSendableMedia
   * Function to get the chatbot sendable media
   * @param {string} id The chatbot id
   */
  const getChatbotSendableMedia = async (id: string): Promise<any> => {
    const response = await fetchChatbotSendableMedia(id);
    if (response.status === 200) {
      const sendableMedia = response.data?.sendableMedia || [];
      const sendableMediaDict: { [key: string]: ChatbotSendableMedia } = {};
      sendableMedia.forEach((element: ChatbotSendableMedia) => {
        sendableMediaDict[element.id] = {
          id: element.id,
          name: element.name,
          description: element.description,
          file: element.file,
        };
      });

      const chatbotsDataCopy: ChatbotsData = { ...chatbotsData };
      chatbotsDataCopy[id as UUID].sendableMedia = sendableMediaDict;

      saveChatbotsData(chatbotsDataCopy);
    }
    return response;
  };

  /**
   * createChatbotSendableMedia
   * Function to create the chatbot sendable media
   * @param {UUID} botId
   * @param {FormData} formData
   */
  const createChatbotSendableMedia = async (
    botId: UUID,
    formData: FormData,
  ): Promise<any> => {
    formData.append('botId', botId);
    const response = await postChatbotSendableMedia(formData);
    if (response.status === 200) {
      getChatbotSendableMedia(botId);
    }
    return response;
  };

  /**
   * updateChatbotSendableMedia
   * Function to update the chatbot sendable media
   * @param {UUID} botId
   * @param {UUID} mediaId
   * @param {FormData} formData
   */
  const updateChatbotSendableMedia = async (
    botId: UUID,
    mediaId: string,
    formData: FormData,
  ): Promise<any> => {
    formData.append('botId', botId);
    formData.append('mediaId', mediaId);
    const response = await putChatbotSendableMedia(formData);
    if (response.status === 200) {
      getChatbotSendableMedia(botId);
    }
    return response;
  };

  /**
   * eliminateChatbotSendableMedia
   * Function to delete the chatbot sendable media
   * @param {UUID} botId
   * @param {string} mediaId
   */
  const eliminateChatbotSendableMedia = async (
    botId: UUID,
    mediaId: string,
  ): Promise<any> => {
    const response = await deleteChatbotSendableMedia(botId, mediaId);
    if (response.status === 200) {
      getChatbotSendableMedia(botId);
    }
    return response;
  };

  /**
   * createChatbotTag
   * Function to create the chatbot tag
   * @param {UUID} botId
   * @param {string} name
   * @param {string} color
   * @param {boolean} isConversion
   * @param {boolean} isAuto
   * @param {string} instructions
   * @param {boolean} notifications
   * @returns {Object} { status} | { status, ...errors }
   */
  const createChatbotTag = async (
    botId: UUID,
    name: string,
    color: string,
    trigger: string,
    isConversion: boolean,
    isAuto: boolean,
    instructions: string,
    notifications: boolean,
  ): Promise<any> => {
    const response = await postChatbotTag(
      botId,
      name,
      color,
      trigger,
      isConversion,
      isAuto,
      instructions,
      notifications,
    );
    if (response.status === 200) {
      getChatbotsData();
    }
    return response;
  };

  /**
   * updateChatbotTag
   * Function to update the chatbot tag
   * @param {UUID} botId
   * @param {string} tagId
   * @param {string} name
   * @param {string} color
   * @param {boolean} isConversion
   * @param {boolean} isAuto
   * @param {string} instructions
   * @param {boolean} notifications
   * @returns {Object} { status} | { status, ...errors }
   */
  const updateChatbotTag = async (
    botId: UUID,
    tagId: string,
    name: string,
    color: string,
    trigger: string,
    isConversion: boolean,
    isAuto: boolean,
    instructions: string,
    notifications: boolean,
  ): Promise<any> => {
    const response = await putChatbotTag(
      botId,
      tagId,
      name,
      color,
      trigger,
      isConversion,
      isAuto,
      instructions,
      notifications,
    );
    if (response.status === 200) {
      getChatbotsData();
    }
    return response;
  };

  /**
   * eliminateChatbotTag
   * Function to delete the chatbot tag
   * @param {UUID} botId
   * @param {string} tagId
   * @returns {Object} { status} | { status, ...errors }
   */
  const eliminateChatbotTag = async (
    botId: UUID,
    tagId: string,
  ): Promise<any> => {
    const response = await deleteChatbotTag(botId, tagId);
    if (response.status === 200) {
      getChatbotsData();
    }
    return response;
  };

  /**
   * updateChatbotEligibleAssignees
   * Function to update the chatbot eligible assignees
   * @param {UUID} botId
   * @param {string[]} eligibleAssignees
   * @returns {Object} { status} | { status, ...errors }
   */
  const updateChatbotEligibleAssignees = async (
    botId: UUID,
    eligibleAssignees: string[],
  ): Promise<any> => {
    const response = await putChatbotAssignees(botId, eligibleAssignees);
    if (response.status === 200) {
      getChatbotsData();
    }
    return response;
  };

  /**
   * createChatbotWhatsappIntegration
   * Function to create the chatbot whatsapp integration
   * @param {UUID} botId
   * @param {string} whatsappAccountId
   * @param {string} whatsappNumberId
   * @param {string} whatsappSecretId
   * @param {string} pageAccessToken
   * @param {boolean} botActiveDefault
   * @returns {Object} { status} | { status, ...errors }
   */
  const createChatbotWhatsappIntegration = async (
    botId: UUID,
    whatsappAccountId: string,
    whatsappNumberId: string,
    whatsappSecretId: string,
    pageAccessToken: string,
    botActiveDefault: boolean,
  ): Promise<any> => {
    const response = await postChatbotWhatsappIntegration(
      botId,
      whatsappAccountId,
      whatsappNumberId,
      whatsappSecretId,
      pageAccessToken,
      botActiveDefault,
    );
    if (response.status === 200) {
      getChatbotsData();
    }
    return response;
  };

  /**
   * updateChatbotWhatsappIntegration
   * Function to update the chatbot whatsapp integration
   * @param {UUID} botId
   * @param {string} integrationId
   * @param {string} whatsappAccountId
   * @param {string} whatsappNumberId
   * @param {string} whatsappSecretId
   * @param {string} pageAccessToken
   * @param {boolean} botActiveDefault
   * @returns {Object} { status} | { status, ...errors }
   */
  const updateChatbotWhatsappIntegration = async (
    botId: UUID,
    integrationId: string,
    whatsappAccountId: string,
    whatsappNumberId: string,
    whatsappSecretId: string,
    pageAccessToken: string,
    botActiveDefault: boolean,
  ): Promise<any> => {
    const response = await putChatbotWhatsappIntegration(
      botId,
      integrationId,
      whatsappAccountId,
      whatsappNumberId,
      whatsappSecretId,
      pageAccessToken,
      botActiveDefault,
    );
    if (response.status === 200) {
      getChatbotsData();
    }
    return response;
  };

  return {
    chatbotsData,
    createChatbot,
    deactivateChatbot,
    getChatbotsData,
    updateChatbotGeneralData,
    updateChatbotBehaviorData,
    createChatbotKnowledgeSource,
    updateChatbotKnowledgeSource,
    removeChatbotKnowledgeSource,
    getChatbotKnowledgeSources,
    getChatbotKnowledgeChunks,
    getChatbotSendableMedia,
    createChatbotSendableMedia,
    updateChatbotSendableMedia,
    eliminateChatbotSendableMedia,
    createChatbotTag,
    updateChatbotTag,
    eliminateChatbotTag,
    updateChatbotEligibleAssignees,
    createChatbotWhatsappIntegration,
    updateChatbotWhatsappIntegration,
  };
};
