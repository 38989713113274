import React, { useState, useEffect, useRef, useCallback } from 'react';

import InboxThread from '../thread';
import ThreadSkeleton from '../threadSkeleton';
import ThreadsLoading from '../threadsLoading';
import ThreadsEmpty from '../threadsEmpty';

import { useInbox } from 'features/inbox/hooks/useInbox';

import { type Thread } from 'features/inbox/types/inboxTypes';

import './styles.css';

/**
 * InboxThreadsBody
 * @description Component to display list of threads
 * @returns {TSX.Element} InboxThreadsBody component
 */

const InboxThreadsBody: React.FC = () => {
  const { inbox, inboxFilters, fetchingThreads, getThreads } = useInbox();

  const observer = useRef<any>();
  const loaderRef = useCallback(
    (node: any) => {
      if (fetchingThreads) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && inbox.hasMore) getThreads(false);
      });
      if (node) observer.current.observe(node);
    },
    [inbox, fetchingThreads],
  );

  const [threads, setThreads] = useState<Thread[]>([]);

  useEffect(() => {
    const unOrderedThreads = Object.values(inbox.threads);

    const filteredThreads = unOrderedThreads.filter((thread) =>
      inboxFilters.selectedChannels.includes(thread.integrationType),
    );

    const orderedThreads = filteredThreads.sort((a, b) => {
      const aDate = new Date(a.latestMessage.sentAt);
      const bDate = new Date(b.latestMessage.sentAt);
      if (inboxFilters.orderBy === 'asc') {
        return aDate.getTime() - bDate.getTime();
      } else {
        return bDate.getTime() - aDate.getTime();
      }
    });
    setThreads(orderedThreads);
  }, [inbox.threads, fetchingThreads, inboxFilters]);

  return (
    <div className={'inbox-threads-body'}>
      {threads.length > 0 ? (
        <div className={'inbox-threads-body-scroll'}>
          {Object.values(threads).map((thread) => (
            <InboxThread key={thread.id} thread={thread} />
          ))}
          {inbox.hasMore && (
            <div ref={loaderRef}>
              <ThreadSkeleton />
            </div>
          )}
        </div>
      ) : fetchingThreads ? (
        <ThreadsLoading />
      ) : (
        <ThreadsEmpty />
      )}
    </div>
  );
};

export default InboxThreadsBody;
