import React, { useState, useEffect } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Modal from 'components/interface/modal';
import Button from 'components/interface/button';

import UploadCampaignContactsForm from '../../forms/UploadCampaignContactsForm';

import './styles.css';

const example_contacts = [
  {
    id: '719',
    contact: '8713218723',
    name: 'Emilia Huerta Boeheim',
    context: 'Producto: TCD; Saldo actual: 12,000.00; Saldo vencido: 0.00',
  },
  {
    id: '720',
    contact: '8113552343',
    name: 'Jorge Rodriguez Figueroa',
    context: 'Producto: TCD; Saldo actual: 12,000.00; Saldo vencido: 0.00',
  },
  {
    id: '721',
    contact: '5513272334',
    name: 'Brenda Martinez Lopez',
    context: 'Producto: TCD; Saldo actual: 12,000.00; Saldo vencido: 0.00',
  },
  {
    id: '722',
    contact: '8711234567',
    name: 'Rafael Sanzio Strozzi',
    context: 'Producto: TCD; Saldo actual: 12,000.00; Saldo vencido: 0.00',
  },
];

type TutorialSlideProps = {
  onNext: () => void;
};

const TutorialSlide: React.FC<TutorialSlideProps> = ({ onNext }) => {
  return (
    <>
      <Text variant={'b2'}>
        Para añadir contactos a la campaña, es necesario organizarlos en un
        archivo .csv siguiendo el formato del ejemplo a continuación.
      </Text>
      <div style={{ height: 8 }} />
      <Text variant={'b2'} weight={500}>
        Explicación de las columnas:
      </Text>
      <div style={{ height: 8 }} />
      <Text variant={'b2'} weight={500}>
        id:
      </Text>
      <Text variant={'b2'}>Identificador del contacto (para uso interno).</Text>
      <div style={{ height: 8 }} />
      <Text variant={'b2'} weight={500}>
        contact:
      </Text>
      <Text variant={'b2'}>Número de teléfono del contacto.</Text>
      <div style={{ height: 8 }} />
      <Text variant={'b2'} weight={500}>
        name:
      </Text>
      <Text variant={'b2'}>
        {
          'Nombre del contacto, la primera palabra se utilizará como variable {{1}} en las plantillas.'
        }
      </Text>
      <div style={{ height: 8 }} />
      <Text variant={'b2'} weight={500}>
        context:
      </Text>
      <Text variant={'b2'}>
        Esta columna puede contener una combinación de la información del
        contacto, como el saldo actual, el saldo vencido, el producto, etc.
      </Text>
      <div style={{ height: 8 }} />
      <Text variant={'b2'}>
        Recomendamos fuertemente separar las variables con (;).
      </Text>
      <Text variant={'b2'}>
        Ej. Producto: TCD; Saldo actual: 12,000.00; Descuento: 30%;
      </Text>
      <div className={'campaign-contacts-upload-example'}>
        <div className={'campaign-contacts-upload-example-row'}>
          <div>
            <Text variant={'b2'}>id</Text>
          </div>
          <div>
            <Text variant={'b2'}>contact</Text>
          </div>
          <div>
            <Text variant={'b2'}>name</Text>
          </div>
          <div>
            <Text variant={'b2'}>context</Text>
          </div>
        </div>
        {example_contacts.map((contact, index) => (
          <div key={index} className={'campaign-contacts-upload-example-row'}>
            <div>
              <Text variant={'b2'} color={'var(--gray)'} numberOfLines={1}>
                {contact.id}
              </Text>
            </div>
            <div>
              <Text variant={'b2'} color={'var(--gray)'} numberOfLines={1}>
                {contact.contact}
              </Text>
            </div>
            <div>
              <Text variant={'b2'} color={'var(--gray)'} numberOfLines={1}>
                {contact.name}
              </Text>
            </div>
            <div>
              <Text variant={'b2'} color={'var(--gray)'} numberOfLines={1}>
                {contact.context}
              </Text>
            </div>
          </div>
        ))}
      </div>
      <div style={{ height: 16 }} />
      <Button label={'Siguiente'} onClick={onNext} />
    </>
  );
};

type CampaignContactsUploadProps = {
  campaignId: string;
  onSubmitCallback: () => void;
};

/**
 * CampaignContactsUpload
 * @description Button that opens a modal to upload contacts to a campaign
 * @param {string} campaignId - Campaign id
 * @param {function} onSubmitCallback - Callback to execute after adding contacts
 * @returns {TSX.Element} CampaignContactsUpload component
 */

const CampaignContactsUpload: React.FC<CampaignContactsUploadProps> = ({
  campaignId,
  onSubmitCallback,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tutorialSlide, setTutorialSlide] = useState<boolean>(true);

  useEffect(() => {
    if (isModalOpen) setTutorialSlide(true);
  }, [isModalOpen]);

  return (
    <>
      <Button
        label={'Subir'}
        variant={'secondary'}
        icon={<Icon name={'upload'} />}
        onClick={() => setIsModalOpen(true)}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={'Subir contactos'}
      >
        {tutorialSlide ? (
          <TutorialSlide onNext={() => setTutorialSlide(false)} />
        ) : (
          <UploadCampaignContactsForm
            campaignId={campaignId}
            onSubmitCallback={() => {
              onSubmitCallback();
              setIsModalOpen(false);
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default CampaignContactsUpload;
