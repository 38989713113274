import React from 'react';

import { UUID } from 'crypto';

import { fetchIntegrationTemplates } from '../functions/templateFunctions';

/**
 * @hook useTemplate
 * @description Custom hook to manage integration WhatsApp templates
 * @returns {Object} Hook object
 */
export const useTemplate = () => {
  /**
   * getIntegrationTemplates
   * Function that gets templates by integration
   * @param {UUID} botId Chatbot ID
   * @param {number} integrationId Integration ID
   * @returns {Object} { status} | { status, ...errors }
   */
  const getIntegrationTemplates = async (
    botId: UUID,
    integrationId: number,
  ): Promise<any> => {
    const response = await fetchIntegrationTemplates(botId, integrationId);
    return response;
  };

  return {
    getIntegrationTemplates,
  };
};
