export enum CallTypeEnum {
  OUTBOUND = 'outbound',
  INBOUND = 'inbound',
}

export enum CallStatusEnum {
  QUEUED = 'queued',
  RINGING = 'ringing',
  IN_PROGRESS = 'in-progress',
  FORWARDING = 'forwarding',
  ENDED = 'ended',
  FAILED = 'failed',
}

export enum CallAnsweredByEnum {
  HUMAN = 'human',
  MACHINE = 'machine',
  UNKNOWN = 'unknown',
}
