import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Icon from 'components/interface/icon';
import Input from 'components/interface/input';
import Button from 'components/interface/button';
import Select from 'components/interface/select';
import TextArea from 'components/interface/textArea';
import FileInput from 'components/interface/fileInput';
import SettingsFields from 'components/interface/settingsFields';

import { useUser } from 'features/user/hooks/useUser';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type SettingFields } from 'components/interface/settingsFields';
import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';

type FormInputs = {
  name: string;
  type: string;
  text?: string;
  file?: File;
};

type KnowledgeSourceCreateFormProps = {
  chatbotData: ChatbotData;
  goBack: () => void;
};

const KnowledgeSourceCreateForm: React.FC<KnowledgeSourceCreateFormProps> = ({
  chatbotData,
  goBack,
}) => {
  const { userHasPermission } = useUser();
  const { createChatbotKnowledgeSource } = useChatbot();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormInputs>();

  const isAccessible =
    userHasPermission('bots.all.update') ||
    userHasPermission(`bots.${chatbotData.id}.update`);

  const onSubmit: SubmitHandler<FormInputs> = async (data, event: any) => {
    if (isLoading) return;
    setIsLoading(true);

    // Get form data
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('type', data.type);
    if (data.type === 'text') {
      formData.append('text', data.text ?? '');
    }
    if (data.type === 'pdf') {
      if (event.target.file.files[0]) {
        formData.append('file', event.target.file.files[0]);
      }
    }

    // Send request to API
    const response = await createChatbotKnowledgeSource(
      chatbotData.id,
      formData,
    );

    if (response?.errors) {
      if (response.errors?.name) {
        setError('name', {
          type: 'manual',
          message: response.errors?.name,
        });
      }
      if (response.errors?.type) {
        setError('type', {
          type: 'manual',
          message: response.errors?.type,
        });
      }
      if (response.errors?.file) {
        setError('file', {
          type: 'manual',
          message: response.errors?.file,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al crear fuente de conocimiento');
      }
    } else {
      toast.info('Creando fuente de conocimiento...');
      goBack();
    }
    setIsLoading(false);
  };

  const fields: SettingFields = {
    name: {
      label: 'Nombre de la fuente de conocimiento',
      description:
        'Este nombre será solo dentro de Altur para identificar la fuente de conocimiento.',
      component: (
        <Input
          placeholder={'Nombre'}
          isDisabled={!isAccessible}
          error={errors?.name?.message}
          {...register('name')}
        />
      ),
    },
    type: {
      label: 'Tipo de contenido inicial',
      description:
        'Selecciona el tipo de contenido sobre el cual se creará la de fuente de conocimiento, puede ser texto plano o un documento PDF.',
      component: (
        <Select
          options={[
            { label: 'Texto', value: 'text' },
            { label: 'Archivo PDF', value: 'pdf' },
          ]}
          placeholder={'Selecciona un tipo de contenido'}
          isDisabled={!isAccessible}
          error={errors?.type?.message}
          {...register('type')}
        />
      ),
    },
  };

  const selectedType = watch('type');

  if (selectedType == 'text') {
    fields.text = {
      label: 'Texto',
      description: 'Escribe el contenido de la fuente de conocimiento.',
      component: (
        <TextArea
          placeholder={'Texto'}
          isDisabled={!isAccessible}
          error={errors?.text?.message}
          {...register('text')}
        />
      ),
    };
  }
  if (selectedType == 'pdf') {
    fields.file = {
      label: 'Archivo',
      description: 'Sube un documento PDF.',
      component: (
        <FileInput
          isDisabled={!isAccessible}
          allowedExtensions={['.pdf']}
          error={errors?.file?.message}
          {...register('file')}
        />
      ),
    };
  }

  return (
    <>
      <div style={{ marginTop: -16, marginBottom: 16 }}>
        <Button
          variant={'tertiary'}
          label={'Atrás'}
          icon={<Icon name={'arrowLeft'} />}
          onClick={goBack}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SettingsFields fields={fields} />
        {isAccessible ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
            <Button
              label={'Cancelar'}
              variant={'secondary'}
              onClick={() => goBack()}
            />
            <Button
              type={'submit'}
              label={'Guardar'}
              variant={'primary'}
              isLoading={isLoading}
            />
          </div>
        ) : null}
      </form>
    </>
  );
};

export default KnowledgeSourceCreateForm;
