import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Icon from 'components/interface/icon';
import Input from 'components/interface/input';
import Button from 'components/interface/button';
import SettingsFields from 'components/interface/settingsFields';

import KnowledgeSourceChunks from '../components/knowledgeSourceChunks';

import { useUser } from 'features/user/hooks/useUser';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type SettingFields } from 'components/interface/settingsFields';
import { type ChatbotKnowledgeSource } from 'features/chatbots/providers/ChatbotProvider';

type FormInputs = {
  name: string;
};

type KnowledgeSourceEditFormProps = {
  knowledgeSource?: ChatbotKnowledgeSource;
  goBack: () => void;
};

const KnowledgeSourceEditForm: React.FC<KnowledgeSourceEditFormProps> = ({
  knowledgeSource,
  goBack,
}) => {
  const { userHasPermission } = useUser();
  const {
    getChatbotKnowledgeChunks,
    updateChatbotKnowledgeSource,
    removeChatbotKnowledgeSource,
  } = useChatbot();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getKnowledgeSources = async () => {
      if (!knowledgeSource) return;
      await getChatbotKnowledgeChunks(
        knowledgeSource.chatbotId,
        knowledgeSource.id,
      );
    };

    getKnowledgeSources();
  }, [knowledgeSource]);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>();

  const isAccessible =
    userHasPermission('bots.all.update') ||
    userHasPermission(`bots.${knowledgeSource?.chatbotId}.update`);

  const handleDelete = () => {
    if (knowledgeSource) {
      removeChatbotKnowledgeSource(
        knowledgeSource.chatbotId,
        knowledgeSource.id,
      );
      toast.success('Fuente de conocimiento eliminada correctamente');
      goBack();
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    if (!knowledgeSource) return;

    // Send request to API
    const response = await updateChatbotKnowledgeSource(
      knowledgeSource?.chatbotId,
      knowledgeSource?.id,
      data.name,
    );

    if (response?.errors) {
      if (response.errors?.name) {
        setError('name', {
          type: 'manual',
          message: response.errors?.name,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar la fuente de conocimiento');
      }
    } else {
      if (response?.data.success) {
        toast.success('Fuente de conocimiento actualizada correctamente');
        goBack();
      }
    }
    setIsLoading(false);
  };

  const fields: SettingFields = {
    name: {
      label: 'Nombre de la fuente de conocimiento',
      description:
        'Este nombre será solo dentro de Altur para identificar la fuente de conocimiento.',
      component: (
        <Input
          placeholder={'Nombre'}
          defaultValue={knowledgeSource?.name}
          isDisabled={!isAccessible}
          error={errors?.name?.message}
          {...register('name')}
        />
      ),
    },
    chunks: {
      label: 'Contenido',
      description:
        'Aquí se puede ver y editar el contenido de la fuente de conocimiento. Se divide en bloques de texto de máximo 1000 caracteres. El asistente buscará coincidencias en estos bloques para responder a las preguntas de los usuarios.',
      component: <KnowledgeSourceChunks knowledgeSource={knowledgeSource} />,
    },
    delete: {
      label: 'Eliminar fuente de conocimiento',
      description:
        'Si eliminas esta fuente de conocimiento, perderás todos los bloques de texto que hayas añadido. Esta acción no se puede deshacer.',
      component: (
        <Button
          variant={'danger'}
          label={'Eliminar'}
          requiresConfirmation={true}
          confirmationTitle={'Eliminar fuente de conocimiento'}
          confirmationText={
            '¿Estás seguro de que quieres eliminar esta fuente de conocimiento?'
          }
          onClick={handleDelete}
        />
      ),
    },
  };

  return (
    <>
      <div style={{ marginTop: -16, marginBottom: 16 }}>
        <Button
          variant={'tertiary'}
          label={'Atrás'}
          icon={<Icon name={'arrowLeft'} />}
          onClick={goBack}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SettingsFields fields={fields} />
        {isAccessible ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
            <Button
              label={'Cancelar'}
              variant={'secondary'}
              onClick={() => goBack()}
            />
            <Button
              type={'submit'}
              label={'Guardar'}
              variant={'primary'}
              isLoading={isLoading}
            />
          </div>
        ) : null}
      </form>
    </>
  );
};

export default KnowledgeSourceEditForm;
