import React from 'react';
import { useNavigate } from 'react-router-dom';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';

import { useDashboard } from 'features/dashboard/hooks/useDashboard';

import { type Notification } from 'features/notifications/types/notificationTypes';
import { type IconName } from 'components/interface/icon';

import { timeSince } from 'utils/dates';

import './styles.css';

const NOTIFICATION_FEATURE_ICONS: { [key: string]: IconName } = {
  inbox: 'tray',
  chatbots: 'robot',
  campaigns: 'megaphoneSimple',
  templates: 'file',
  billing: 'chartDonut',
  organizations: 'buildings',
};

const NOTIFICATION_FEATURE_TRANSLATIONS: { [key: string]: string } = {
  inbox: 'Inbox',
  chatbots: 'Asistentes',
  campaigns: 'Campañas',
  templates: 'Plantillas',
  billing: 'Plan y Uso',
  organizations: 'Organización',
};

const NOTIFICATION_TYPE_COLORS: {
  [key: string]: { color: string; backgroundColor: string };
} = {
  info: {
    color: 'var(--primary)',
    backgroundColor: 'var(--primary-pale)',
  },
  alert: {
    color: 'var(--error)',
    backgroundColor: 'var(--error-pale)',
  },
};

type NotificationProps = {
  notification: Notification;
};

const NotificationItem: React.FC<NotificationProps> = ({ notification }) => {
  const navigate = useNavigate();
  const { toggleNotifications } = useDashboard();

  const handleNotificationClick = () => {
    if (notification.inAppUrl) {
      const url = new URL(notification.inAppUrl);
      const relativePath = url.pathname + url.search + url.hash;

      navigate(relativePath);
      toggleNotifications();
    }
  };

  return (
    <div className={'notification'} onClick={handleNotificationClick}>
      <div
        className={'notification-icon'}
        style={{
          backgroundColor:
            NOTIFICATION_TYPE_COLORS[notification.type].backgroundColor,
        }}
      >
        <Icon
          name={NOTIFICATION_FEATURE_ICONS[notification.feature]}
          size={16}
          color={NOTIFICATION_TYPE_COLORS[notification.type].color}
        />
      </div>
      <div className={'notification-content'}>
        <Text variant={'b2'} color={'var(--body)'} weight={500}>
          {notification.title}
        </Text>
        <Text variant={'b3'} color={'var(--gray)'} numberOfLines={3}>
          {notification.message}
        </Text>
        <Text variant={'b3'} color={'var(--gray)'}>
          {NOTIFICATION_FEATURE_TRANSLATIONS[notification.feature]} -{' '}
          {timeSince(notification.createdAt)}
        </Text>
      </div>
    </div>
  );
};

export default NotificationItem;
