import React, { useState, useEffect } from 'react';

import Text from 'components/interface/text';

import { useUser } from 'features/user/hooks/useUser';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import KnowledgeSourceCard from '../components/knowledgeSourceCard';
import KnowledgeSourceCreateForm from '../forms/KnowledgeSourceCreateForm';
import KnowledgeSourceEditForm from '../forms/KnowledgeSourceEditForm';

import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';
import { type ChatbotKnowledgeSource } from 'features/chatbots/providers/ChatbotProvider';

type KnowledgeSourceViewProps = {
  chatbotData: ChatbotData;
};

const KnowledgeSourceView: React.FC<KnowledgeSourceViewProps> = ({
  chatbotData,
}) => {
  const { userHasPermission } = useUser();
  const { getChatbotKnowledgeSources } = useChatbot();
  const [openNewKnowledgeSource, setOpenNewKnowledgeSource] =
    useState<boolean>(false);
  const [openKnowledgeSource, setOpenKnowledgeSource] =
    useState<ChatbotKnowledgeSource | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getKnowledgeSources = async () => {
      await getChatbotKnowledgeSources(chatbotData.id);
      setIsLoading(false);
    };

    getKnowledgeSources();
  }, []);

  const handleOpenNewSource = () => {
    setOpenNewKnowledgeSource(true);
  };

  const handleOpenSource = (knowledgeSource?: ChatbotKnowledgeSource) => {
    setOpenKnowledgeSource(knowledgeSource ?? null);
  };

  const isAccessible =
    userHasPermission('bots.all.update') ||
    userHasPermission(`bots.${chatbotData.id}.update`);

  return !openNewKnowledgeSource && !openKnowledgeSource ? (
    <>
      <Text variant={'h8'} weight={500}>
        Base de Conocimiento
      </Text>
      <div style={{ height: 4 }}></div>
      <Text variant={'b3'} color={'gray'}>
        Aquí puedes subir información relevante a traves de documentos o texto
        para que el asistente pueda responder correctamente a las preguntas de
        tus usuarios.
      </Text>
      <div style={{ height: 16 }}></div>
      <div className={'knowledge-source-card-container'}>
        {isAccessible ? (
          <KnowledgeSourceCard
            isAddCard
            onClick={() => handleOpenNewSource()}
          />
        ) : null}
        {isLoading
          ? null
          : Object.values(chatbotData.knowledgeSources).map(
              (knowledgeSource) => {
                return (
                  <KnowledgeSourceCard
                    key={knowledgeSource.id}
                    knowledgeSource={knowledgeSource}
                    onClick={() => handleOpenSource(knowledgeSource)}
                  />
                );
              },
            )}
      </div>
    </>
  ) : isAccessible ? (
    openNewKnowledgeSource ? (
      <KnowledgeSourceCreateForm
        chatbotData={chatbotData}
        goBack={() => setOpenNewKnowledgeSource(false)}
      />
    ) : (
      openKnowledgeSource && (
        <KnowledgeSourceEditForm
          knowledgeSource={openKnowledgeSource}
          goBack={() => setOpenKnowledgeSource(null)}
        />
      )
    )
  ) : null;
};

export default KnowledgeSourceView;
