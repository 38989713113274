import React from 'react';

import {
  AddressBookTabs,
  Archive,
  ArchiveTray,
  ArrowClockwise,
  ArrowLeft,
  ArrowRight,
  ArrowLineLeft,
  ArrowLineRight,
  Atom,
  Bell,
  BellZ,
  Book,
  Books,
  BookBookmark,
  Buildings,
  CalendarBlank,
  Cards,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  ChatTeardropText,
  ChatsTeardrop,
  ChartDonut,
  Check,
  Checks,
  CheckCircle,
  Circuitry,
  Clock,
  CloudArrowUp,
  Code,
  Copy,
  CrownSimple,
  Cube,
  DeviceMobile,
  CurrencyDollar,
  DotsThree,
  DownloadSimple,
  EnvelopeSimple,
  EnvelopeOpen,
  Eye,
  EyeSlash,
  File,
  FileX,
  Funnel,
  GearSix,
  GoogleChromeLogo,
  Hourglass,
  HourglassLow,
  HourglassMedium,
  HourglassHigh,
  Image,
  Images,
  InstagramLogo,
  Key,
  LightbulbFilament,
  MagnifyingGlass,
  MegaphoneSimple,
  MessengerLogo,
  Microphone,
  OfficeChair,
  Paperclip,
  PaperPlaneTilt,
  PaperPlaneRight,
  Password,
  Pause,
  PencilSimpleLine,
  Person,
  Phone,
  PhoneCall,
  PhoneDisconnect,
  PhoneIncoming,
  PhoneOutgoing,
  PhoneX,
  Play,
  Plug,
  Plugs,
  PlugsConnected,
  Plus,
  PlusSquare,
  ProhibitInset,
  Question,
  Repeat,
  Robot,
  SealCheck,
  SealWarning,
  SidebarSimple,
  SignOut,
  Shield,
  SquaresFour,
  TagSimple,
  Tray,
  UploadSimple,
  User,
  Users,
  UserCircle,
  UserCheck,
  UserList,
  UserMinus,
  UserPlus,
  UserGear,
  WarningCircle,
  WebhooksLogo,
  WhatsappLogo,
  Voicemail,
  X,
  XCircle,
} from '@phosphor-icons/react';

import { colors } from 'constants/colors';

export type IconName =
  | 'addressBookTabs'
  | 'archive'
  | 'archiveTray'
  | 'arrowClockwise'
  | 'arrowLeft'
  | 'arrowRight'
  | 'arrowLineLeft'
  | 'arrowLineRight'
  | 'atom'
  | 'bell'
  | 'bellZ'
  | 'book'
  | 'books'
  | 'bookBookmark'
  | 'buildings'
  | 'calendarBlank'
  | 'cards'
  | 'caretDown'
  | 'caretLeft'
  | 'caretRight'
  | 'caretUp'
  | 'chats'
  | 'chatText'
  | 'chartDonut'
  | 'check'
  | 'checks'
  | 'checkCircle'
  | 'chrome'
  | 'circuits'
  | 'clock'
  | 'close'
  | 'cloudArrowUp'
  | 'code'
  | 'copy'
  | 'crown'
  | 'cube'
  | 'deviceMobile'
  | 'dollar'
  | 'dotsThree'
  | 'download'
  | 'envelopeSimple'
  | 'envelopeOpen'
  | 'eye'
  | 'eyeSlash'
  | 'file'
  | 'fileX'
  | 'funnel'
  | 'gear'
  | 'hourglass'
  | 'hourglassLow'
  | 'hourglassMedium'
  | 'hourglassHigh'
  | 'image'
  | 'images'
  | 'instagram'
  | 'key'
  | 'lightbulb'
  | 'magnifyingGlass'
  | 'megaphoneSimple'
  | 'messenger'
  | 'microphone'
  | 'officeChair'
  | 'paperclip'
  | 'password'
  | 'pause'
  | 'pencilSimpleLine'
  | 'person'
  | 'phone'
  | 'phoneCall'
  | 'phoneDisconnect'
  | 'phoneIncoming'
  | 'phoneOutgoing'
  | 'phoneX'
  | 'play'
  | 'plug'
  | 'plugs'
  | 'plugsConnected'
  | 'plus'
  | 'plusSquare'
  | 'prohibitInset'
  | 'question'
  | 'repeat'
  | 'robot'
  | 'sealCheck'
  | 'sealWarning'
  | 'send'
  | 'sendRight'
  | 'sidebarSimple'
  | 'signOut'
  | 'shield'
  | 'squaresFour'
  | 'tag'
  | 'tray'
  | 'upload'
  | 'user'
  | 'users'
  | 'userCircle'
  | 'userCheck'
  | 'userList'
  | 'userMinus'
  | 'userPlus'
  | 'userGear'
  | 'warningCircle'
  | 'webhooks'
  | 'whatsapp'
  | 'voicemail'
  | 'xCircle';

export type IconStyle =
  | 'thin'
  | 'light'
  | 'regular'
  | 'bold'
  | 'fill'
  | 'duotone';

type IconProps = {
  size?: number;
  name?: IconName | undefined;
  color?: string;
  style?: IconStyle;
  backgroundColor?: string;
};

/**
 * Icon
 * @description Custom icon component
 * @param {Number} size - Size of the loader
 * @param {String} name - Name of icon to be displayed
 * @param {String} color - Color of the icon
 * @param {String} style - Style of the icon
 * @param {String} backgroundColor - Background color of the icon
 * @returns {TSX.Element} Icon component
 */

const Icon: React.FC<IconProps> = ({
  size = 16,
  name,
  color = colors.body,
  style = 'regular',
  backgroundColor,
}) => {
  const switchCase = () => {
    switch (name) {
      case undefined:
        return <Question size={size} color={color} weight={style} />;
      case 'addressBookTabs':
        return <AddressBookTabs size={size} color={color} weight={style} />;
      case 'archive':
        return <Archive size={size} color={color} weight={style} />;
      case 'archiveTray':
        return <ArchiveTray size={size} color={color} weight={style} />;
      case 'arrowClockwise':
        return <ArrowClockwise size={size} color={color} weight={style} />;
      case 'arrowLeft':
        return <ArrowLeft size={size} color={color} weight={style} />;
      case 'arrowRight':
        return <ArrowRight size={size} color={color} weight={style} />;
      case 'arrowLineLeft':
        return <ArrowLineLeft size={size} color={color} weight={style} />;
      case 'arrowLineRight':
        return <ArrowLineRight size={size} color={color} weight={style} />;
      case 'atom':
        return <Atom size={size} color={color} weight={style} />;
      case 'bell':
        return <Bell size={size} color={color} weight={style} />;
      case 'bellZ':
        return <BellZ size={size} color={color} weight={style} />;
      case 'book':
        return <Book size={size} color={color} weight={style} />;
      case 'books':
        return <Books size={size} color={color} weight={style} />;
      case 'bookBookmark':
        return <BookBookmark size={size} color={color} weight={style} />;
      case 'buildings':
        return <Buildings size={size} color={color} weight={style} />;
      case 'calendarBlank':
        return <CalendarBlank size={size} color={color} weight={style} />;
      case 'cards':
        return <Cards size={size} color={color} weight={style} />;
      case 'caretDown':
        return <CaretDown size={size} color={color} weight={style} />;
      case 'caretLeft':
        return <CaretLeft size={size} color={color} weight={style} />;
      case 'caretRight':
        return <CaretRight size={size} color={color} weight={style} />;
      case 'caretUp':
        return <CaretUp size={size} color={color} weight={style} />;
      case 'chats':
        return <ChatsTeardrop size={size} color={color} weight={style} />;
      case 'chatText':
        return <ChatTeardropText size={size} color={color} weight={style} />;
      case 'chartDonut':
        return <ChartDonut size={size} color={color} weight={style} />;
      case 'check':
        return <Check size={size} color={color} weight={style} />;
      case 'checks':
        return <Checks size={size} color={color} weight={style} />;
      case 'checkCircle':
        return <CheckCircle size={size} color={color} weight={style} />;
      case 'chrome':
        return <GoogleChromeLogo size={size} color={color} weight={style} />;
      case 'circuits':
        return <Circuitry size={size} color={color} weight={style} />;
      case 'clock':
        return <Clock size={size} color={color} weight={style} />;
      case 'close':
        return <X size={size} color={color} weight={style} />;
      case 'cloudArrowUp':
        return <CloudArrowUp size={size} color={color} weight={style} />;
      case 'code':
        return <Code size={size} color={color} weight={style} />;
      case 'copy':
        return <Copy size={size} color={color} weight={style} />;
      case 'crown':
        return <CrownSimple size={size} color={color} weight={style} />;
      case 'cube':
        return <Cube size={size} color={color} weight={style} />;
      case 'deviceMobile':
        return <DeviceMobile size={size} color={color} weight={style} />;
      case 'dollar':
        return <CurrencyDollar size={size} color={color} weight={style} />;
      case 'dotsThree':
        return <DotsThree size={size} color={color} weight={style} />;
      case 'download':
        return <DownloadSimple size={size} color={color} weight={style} />;
      case 'envelopeSimple':
        return <EnvelopeSimple size={size} color={color} weight={style} />;
      case 'envelopeOpen':
        return <EnvelopeOpen size={size} color={color} weight={style} />;
      case 'eye':
        return <Eye size={size} color={color} weight={style} />;
      case 'eyeSlash':
        return <EyeSlash size={size} color={color} weight={style} />;
      case 'file':
        return <File size={size} color={color} weight={style} />;
      case 'fileX':
        return <FileX size={size} color={color} weight={style} />;
      case 'funnel':
        return <Funnel size={size} color={color} weight={style} />;
      case 'gear':
        return <GearSix size={size} color={color} weight={style} />;
      case 'hourglass':
        return <Hourglass size={size} color={color} weight={style} />;
      case 'hourglassLow':
        return <HourglassLow size={size} color={color} weight={style} />;
      case 'hourglassMedium':
        return <HourglassMedium size={size} color={color} weight={style} />;
      case 'hourglassHigh':
        return <HourglassHigh size={size} color={color} weight={style} />;
      case 'image':
        return <Image size={size} color={color} weight={style} />;
      case 'images':
        return <Images size={size} color={color} weight={style} />;
      case 'instagram':
        return <InstagramLogo size={size} color={color} weight={style} />;
      case 'key':
        return <Key size={size} color={color} weight={style} />;
      case 'lightbulb':
        return <LightbulbFilament size={size} color={color} weight={style} />;
      case 'magnifyingGlass':
        return <MagnifyingGlass size={size} color={color} weight={style} />;
      case 'megaphoneSimple':
        return <MegaphoneSimple size={size} color={color} weight={style} />;
      case 'messenger':
        return <MessengerLogo size={size} color={color} weight={style} />;
      case 'microphone':
        return <Microphone size={size} color={color} weight={style} />;
      case 'officeChair':
        return <OfficeChair size={size} color={color} weight={style} />;
      case 'paperclip':
        return <Paperclip size={size} color={color} weight={style} />;
      case 'password':
        return <Password size={size} color={color} weight={style} />;
      case 'pause':
        return <Pause size={size} color={color} weight={style} />;
      case 'pencilSimpleLine':
        return <PencilSimpleLine size={size} color={color} weight={style} />;
      case 'person':
        return <Person size={size} color={color} weight={style} />;
      case 'phone':
        return <Phone size={size} color={color} weight={style} />;
      case 'phoneCall':
        return <PhoneCall size={size} color={color} weight={style} />;
      case 'phoneDisconnect':
        return <PhoneDisconnect size={size} color={color} weight={style} />;
      case 'phoneIncoming':
        return <PhoneIncoming size={size} color={color} weight={style} />;
      case 'phoneOutgoing':
        return <PhoneOutgoing size={size} color={color} weight={style} />;
      case 'phoneX':
        return <PhoneX size={size} color={color} weight={style} />;
      case 'play':
        return <Play size={size} color={color} weight={style} />;
      case 'plug':
        return <Plug size={size} color={color} weight={style} />;
      case 'plugs':
        return <Plugs size={size} color={color} weight={style} />;
      case 'plugsConnected':
        return <PlugsConnected size={size} color={color} weight={style} />;
      case 'plusSquare':
        return <PlusSquare size={size} color={color} weight={style} />;
      case 'plus':
        return <Plus size={size} color={color} weight={style} />;
      case 'prohibitInset':
        return <ProhibitInset size={size} color={color} weight={style} />;
      case 'repeat':
        return <Repeat size={size} color={color} weight={style} />;
      case 'robot':
        return <Robot size={size} color={color} weight={style} />;
      case 'sealCheck':
        return <SealCheck size={size} color={color} weight={style} />;
      case 'sealWarning':
        return <SealWarning size={size} color={color} weight={style} />;
      case 'send':
        return <PaperPlaneTilt size={size} color={color} weight={style} />;
      case 'sendRight':
        return <PaperPlaneRight size={size} color={color} weight={style} />;
      case 'sidebarSimple':
        return <SidebarSimple size={size} color={color} weight={style} />;
      case 'signOut':
        return <SignOut size={size} color={color} weight={style} />;
      case 'shield':
        return <Shield size={size} color={color} weight={style} />;
      case 'squaresFour':
        return <SquaresFour size={size} color={color} weight={style} />;
      case 'tag':
        return <TagSimple size={size} color={color} weight={style} />;
      case 'tray':
        return <Tray size={size} color={color} weight={style} />;
      case 'upload':
        return <UploadSimple size={size} color={color} weight={style} />;
      case 'user':
        return <User size={size} color={color} weight={style} />;
      case 'users':
        return <Users size={size} color={color} weight={style} />;
      case 'userCircle':
        return <UserCircle size={size} color={color} weight={style} />;
      case 'userCheck':
        return <UserCheck size={size} color={color} weight={style} />;
      case 'userList':
        return <UserList size={size} color={color} weight={style} />;
      case 'userMinus':
        return <UserMinus size={size} color={color} weight={style} />;
      case 'userPlus':
        return <UserPlus size={size} color={color} weight={style} />;
      case 'userGear':
        return <UserGear size={size} color={color} weight={style} />;
      case 'warningCircle':
        return <WarningCircle size={size} color={color} weight={style} />;
      case 'webhooks':
        return <WebhooksLogo size={size} color={color} weight={style} />;
      case 'whatsapp':
        return <WhatsappLogo size={size} color={color} weight={style} />;
      case 'voicemail':
        return <Voicemail size={size} color={color} weight={style} />;
      case 'xCircle':
        return <XCircle size={size} color={color} weight={style} />;
      default:
        return <Question size={size} color={color} weight={style} />;
    }
  };
  return (
    <div
      className={'icon'}
      style={{
        width: size + 8,
        height: size + 8,
        backgroundColor: backgroundColor ?? 'transparent',
      }}
    >
      {switchCase()}
    </div>
  );
};

export default Icon;
