import api from 'api';

/**
 * fetchApiKeys
 * @description Function that fetches api keys data by organization
 * @returns {Object} { status } | { status, ...errors }
 */

export const fetchApiKeys = async () => {
  try {
    const response = await api.get(
      process.env.REACT_APP_WEST_BASE_URL + '/api-keys/',
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postApiKey
 * @description Function that creates a new api key
 * @param {string} name - The name of the api key
 * @returns {Object} { secretKey } | { status, ...errors }
 */

export const postApiKey = async (name: string) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/api-keys/',
      {
        name,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * deleteApiKey
 * @description Function that revokes an api key
 * @param {number} id - The id of the api key
 * @returns {Object} { status } | { status, ...errors }
 */

export const deleteApiKey = async (id: number) => {
  try {
    const response = await api.delete(
      `${process.env.REACT_APP_WEST_BASE_URL}/api-keys/?id=${id}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};
