import React, { useState } from 'react';

import Icon from 'components/interface/icon';
import Button from 'components/interface/button';

import { useUser } from 'features/user/hooks/useUser';

type TagCreateButtonProps = {
  chatbotId: string;
  callback?: () => void;
};

const TagCreateButton: React.FC<TagCreateButtonProps> = ({
  chatbotId,
  callback = () => {},
}) => {
  const { userHasPermission } = useUser();

  if (
    !userHasPermission('bots.all.update') &&
    !userHasPermission(`bots.${chatbotId}.update`)
  )
    return null;

  return (
    <>
      <Button
        variant={'secondary'}
        label={'Nueva etiqueta'}
        icon={<Icon name={'plusSquare'} size={18} />}
        onClick={callback}
      />
    </>
  );
};

export default TagCreateButton;
