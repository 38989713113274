import React, { useState, useEffect } from 'react';
import { Table } from 'ka-table';
import { DataType, EditingMode, SortingMode } from 'ka-table/enums';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Modal from 'components/interface/modal';
import Button from 'components/interface/button';
import CopyInput from 'components/interface/copyInput';
import PageTitle from 'features/dashboard/components/pageTitle';
import ApiKeyActions from '../features/api/components/apiKeyActions';
import EmptyState from 'components/interface/emptyState';

import CreateApiKeyForm from '../features/api/forms/createApiKeyForm';

import { useUser } from 'features/user/hooks/useUser';
import { useApi } from 'features/api/hooks/useApi';

import { type ApiKey } from 'features/api/providers/ApiProvider';

import { formatDate } from 'utils/dates';

const ApiKeysView: React.FC = () => {
  const { userHasPermission } = useUser();
  const { apiKeys, getApiKeys } = useApi();
  const [apiKeysData, setApiKeysData] = useState<ApiKey[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(
    Object.keys(apiKeys).length < 1,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [newApiKey, setNewApiKey] = useState<string | null>(null);

  const columns = [
    {
      key: 'name',
      title: `Nombre`,
      dataType: DataType.String,
    },
    {
      key: 'displayableKey',
      title: `Llave secreta`,
      dataType: DataType.String,
    },
    {
      key: 'createdAt',
      title: `Creada`,
      dataType: DataType.Date,
      style: { width: 120 },
    },
    {
      key: 'lastUsedAt',
      title: `Último uso`,
      dataType: DataType.Date,
      style: { width: 120 },
    },
  ];

  if (userHasPermission('api.delete')) {
    columns.push({
      key: 'revoked',
      title: '',
      dataType: DataType.String,
      style: { width: 120 },
    });
  }

  useEffect(() => {
    handleGetApiKeys();
  }, []);

  useEffect(() => {
    setApiKeysData(Object.values(apiKeys));
  }, [apiKeys]);

  const handleGetApiKeys = async () => {
    await getApiKeys();
    setIsLoading(false);
  };

  return (
    <div className={'dashboard-page'}>
      <PageTitle
        title={'API Keys'}
        iconName={'key'}
        button={
          <>
            {userHasPermission('api.create') && (
              <div style={{ marginTop: -16 }}>
                <Button
                  variant={'primary'}
                  label={'Nueva llave'}
                  icon={<Icon name={'plus'} size={16} color={'var(--white)'} />}
                  onClick={() => setIsModalOpen(true)}
                />
              </div>
            )}
          </>
        }
      />
      <div style={{ height: 24 }} />
      <Text variant={'b2'} color={'var(--gray)'}>
        Tus claves secretas de API están listadas abajo. Ten en cuenta que no
        mostramos tus claves secretas de API otra vez después de que las
        generes.
      </Text>
      <div style={{ height: 8 }} />
      <Text variant={'b2'} color={'var(--gray)'}>
        No compartas tu clave de API con otros, ni la expongas en el navegador u
        otro código del lado del cliente. Para proteger la seguridad de tu
        cuenta, Altur también puede deshabilitar automáticamente cualquier clave
        de API que hayamos encontrado que se haya filtrado públicamente.
      </Text>
      <div style={{ height: 24 }} />
      <Table
        columns={columns}
        data={apiKeysData}
        rowKeyField={'displayableKey'}
        editingMode={EditingMode.None}
        sortingMode={SortingMode.Single}
        childComponents={{
          cellText: {
            content: (props) => {
              switch (props.column.key) {
                case 'name':
                case 'displayableKey':
                  return (
                    <Text variant={'b2'} numberOfLines={1}>
                      {props.value}
                    </Text>
                  );
                case 'createdAt':
                case 'lastUsedAt':
                  return (
                    <Text
                      variant={'b3'}
                      color={'var(--gray)'}
                      numberOfLines={1}
                    >
                      {props.value ? formatDate(props.value) : '-'}
                    </Text>
                  );
                case 'revoked':
                  return userHasPermission('api.delete') ? (
                    <ApiKeyActions id={props.rowData.id} />
                  ) : (
                    <></>
                  );
                default:
                  return props.value;
              }
            },
          },
        }}
      />
      {apiKeysData.length === 0 && (
        <EmptyState
          iconName={'key'}
          text={'No hay llaves creadas'}
          isLoading={isLoading}
        />
      )}
      {userHasPermission('api.create') && (
        <Modal
          title={'Nueva Llave'}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setNewApiKey(null);
          }}
        >
          {newApiKey ? (
            <>
              <Text variant={'b2'} color={'var(--gray)'}>
                Guarda esta llave secreta de API en un lugar seguro. No la
                mostraremos otra vez.
              </Text>
              <CopyInput text={newApiKey} />
            </>
          ) : (
            <CreateApiKeyForm
              submitCallback={(key) => {
                setNewApiKey(key);
              }}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default ApiKeysView;
