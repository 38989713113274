import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Icon from 'components/interface/icon';
import Input from 'components/interface/input';
import Button from 'components/interface/button';

import { useOrganization } from 'features/organization/hooks/useOrganization';

type Inputs = {
  email: string;
};

type InviteFormProps = {
  submitCallback?: () => void;
};

const InviteForm: React.FC<InviteFormProps> = ({
  submitCallback = () => {},
}) => {
  const { sendOrganizationInvite } = useOrganization();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await sendOrganizationInvite(data.email);

    if (response?.errors) {
      if (response.errors?.email) {
        setError('email', {
          type: 'manual',
          message: response.errors?.email,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar los parametros');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Invitación enviada');
        reset();
        submitCallback();
      } else {
        toast.error('Error al enviar la invitación');
        reset();
      }
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={'input-row'}>
        <div>
          <Input
            placeholder={'Correo electrónico'}
            style={{ marginRight: 10 }}
            error={errors?.email?.message}
            {...register('email')}
          />
        </div>
        <Button
          type={'submit'}
          icon={<Icon name={'send'} color={'var(--white)'} size={14} />}
          variant={'primary'}
          label={'Enviar'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default InviteForm;
