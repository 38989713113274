import React, { useState, useEffect } from 'react';

import './styles.css';

type RolePermissionsGraphProps = {
  permissions: any;
};

const RolePermissionsGraph: React.FC<RolePermissionsGraphProps> = ({
  permissions,
}) => {
  const [permissionsGraph, setPermissionsGraph] = useState<any>([]);

  useEffect(() => {
    const permissionsGraphData: number[] = [];
    Object.keys(permissions).forEach((key: string) => {
      let truePermissions = 0;
      let totalPermissions = 0;
      Object.keys(permissions[key]).forEach((permissionKey: string) => {
        if (typeof permissions[key][permissionKey] === 'object') {
          Object.keys(permissions[key][permissionKey]).forEach(
            (subPermissionKey: string) => {
              if (permissionKey === 'all') {
                if (permissions[key][permissionKey][subPermissionKey]) {
                  truePermissions += 4;
                }
              } else if (permissions[key][permissionKey][subPermissionKey]) {
                truePermissions++;
              }
              totalPermissions++;
            },
          );
        } else {
          if (permissions[key][permissionKey]) {
            truePermissions++;
          }
          totalPermissions++;
        }
      });
      permissionsGraphData.push(
        totalPermissions ? truePermissions / totalPermissions : 0,
      );
    });
    setPermissionsGraph(permissionsGraphData);
  }, [permissions]);

  return (
    <div className={'role-permission-graph'}>
      {permissionsGraph.map((permission: number, index: number) => (
        <div
          className={'role-permission-graph-bar'}
          style={{
            backgroundColor: `rgba(0, 102, 255, ${0.05 + permission * 0.9})`,
          }}
          key={index}
        />
      ))}
    </div>
  );
};

export default RolePermissionsGraph;
