import React, {
  FC,
  createContext,
  useState,
  useReducer,
  useMemo,
  useEffect,
} from 'react';

import authReducer from './authReducer';
import LoadingView from '../../../views/LoadingView';

export type AuthState = {
  accessToken: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean;
  saveAccessToken: (accessToken: string | null) => void;
  saveRefreshToken: (refreshToken: string | null) => void;
  saveIsAuthenticated: (isAuthenticated: boolean) => void;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthContext = createContext<AuthState>({
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  saveAccessToken: () => {},
  saveRefreshToken: () => {},
  saveIsAuthenticated: () => {},
});

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const initialState: AuthState = {
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    isAuthenticated: false,
    saveAccessToken: (accessToken: string | null) => {
      return null;
    },
    saveRefreshToken: (refreshToken: string | null) => {
      return null;
    },
    saveIsAuthenticated: (isAuthenticated: boolean) => {
      return null;
    },
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const saveAccessToken = (accessToken: string | null) => {
    dispatch({
      type: 'SAVE_ACCESS_TOKEN',
      payload: accessToken,
    });
  };

  const saveRefreshToken = (refreshToken: string | null) => {
    dispatch({
      type: 'SAVE_REFRESH_TOKEN',
      payload: refreshToken,
    });
  };

  const saveIsAuthenticated = (isAuthenticated: boolean) => {
    dispatch({
      type: 'SAVE_IS_AUTHENTICATED',
      payload: isAuthenticated,
    });
  };

  useEffect(() => {
    const checkTokens = async () => {
      const localStorageAccessToken = localStorage.getItem('accessToken');
      const localStorageRefreshToken = localStorage.getItem('refreshToken');
      saveIsAuthenticated(
        localStorageAccessToken !== null && localStorageRefreshToken !== null,
      );
      setIsLoading(false);
    };
    checkTokens();
  }, []);

  const contextValue = useMemo(() => {
    return {
      accessToken: state.accessToken,
      refreshToken: state.refreshToken,
      isAuthenticated: state.isAuthenticated,
      saveAccessToken,
      saveRefreshToken,
      saveIsAuthenticated,
    };
  }, [state.accessToken, state.refreshToken, state.isAuthenticated]);

  return (
    <AuthContext.Provider value={contextValue}>
      {!isLoading ? children : <LoadingView />}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
