import React from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import { useInbox } from 'features/inbox/hooks/useInbox';

import { formatDateLong } from 'utils/dates';

import './styles.css';

/**
 * InboxDetailsDetails
 * @description Container for the thread's details
 * @returns {TSX.Element} InboxDetailsDetails component
 */

const InboxDetailsDetails: React.FC = () => {
  const { inbox } = useInbox();

  return (
    <div className={'inbox-details-details'}>
      <div className={'inbox-details-item'}>
        <Text variant={'b3'} color={'var(--gray)'}>
          Mensajes
        </Text>
        <div>
          <Icon name={'chatText'} size={14} />
          <Text variant={'b3'}>{inbox?.activeThread?.messages.length}</Text>
        </div>
      </div>
      <div className={'inbox-details-item'}>
        <Text variant={'b3'} color={'var(--gray)'}>
          Primer mensaje
        </Text>
        <div>
          <Icon name={'calendarBlank'} size={14} />
          <Text variant={'b3'}>
            {formatDateLong(
              inbox?.activeThread?.messages[0]?.sentAt ?? '1979-01-01',
            )}
          </Text>
        </div>
      </div>
      <div className={'inbox-details-item'}>
        <Text variant={'b3'} color={'var(--gray)'}>
          Último mensaje
        </Text>
        <div>
          <Icon name={'calendarBlank'} size={14} />
          <Text variant={'b3'}>
            {formatDateLong(
              inbox?.activeThread?.messages[
                inbox?.activeThread?.messages.length - 1
              ]?.sentAt ?? '1979-01-01',
            )}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default InboxDetailsDetails;
