import React from 'react';

import Icon from 'components/interface/icon';
import Button from 'components/interface/button';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

/**
 * InboxInputChatbotToggle
 * @description Component to toggle chatbot automatic replies
 * @param {boolean} allowToggle - allow user input
 * @returns {TSX.Element} InboxInputChatbotToggle component
 */

type InboxInputChatbotToggleProps = {
  allowToggle?: boolean;
};

const InboxInputChatbotToggle: React.FC<InboxInputChatbotToggleProps> = ({
  allowToggle = true,
}) => {
  const { inbox, toggleThreadChatbot } = useInbox();

  return (
    <div className={'inbox-chatbot-toggle'} onClick={toggleThreadChatbot}>
      <Button
        label={
          inbox.activeThread?.active
            ? 'Asistente Habilitado'
            : 'Asistente Deshabilitado'
        }
        size={'small'}
        variant={'secondary'}
        isDisabled={!allowToggle}
        icon={
          <Icon
            name={'robot'}
            size={16}
            color={
              allowToggle
                ? inbox.activeThread?.active
                  ? 'var(--success)'
                  : 'var(--error)'
                : 'var(--gray-light)'
            }
          />
        }
      />
    </div>
  );
};

export default InboxInputChatbotToggle;
