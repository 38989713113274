import React from 'react';

import Text from 'components/interface/text';
import Image from 'components/interface/image';
import Toggle from 'components/interface/toggle';
import RoleTag from 'components/interface/roleTag';

import { useOrganization } from 'features/organization/hooks/useOrganization';

import './styles.css';

type AssigneeCardProps = {
  userId: string;
  isEligible?: boolean;
  canEdit?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const AssigneeCard: React.FC<AssigneeCardProps> = ({
  userId,
  isEligible = false,
  canEdit = false,
  onChange = () => {},
}) => {
  const { organizationData } = useOrganization();

  return (
    <div className={'assignee-card'}>
      <div className={'assignee-card-left'}>
        <div
          className={'assignee-card__image'}
          style={
            isEligible
              ? {
                  border: '1px solid var(--primary)',
                  outline: '4px solid var(--primary-pale)',
                }
              : {}
          }
        >
          <Image
            src={organizationData.members[userId].profilePicture}
            width={40}
            height={40}
            skeletonHeight={40}
            skeletonWidth={40}
          />
        </div>
        <div className={'assignee-card__info'}>
          <div className={'assignee-card__info__top'}>
            <Text
              variant={'b2'}
              color={isEligible ? 'var(--body)' : 'var(--gray)'}
            >
              {organizationData.members[userId].displayName}
            </Text>
            <RoleTag
              text={
                organizationData.roles[organizationData.members[userId].role]
                  .name
              }
              color={'var(--gray)'}
              backgroundColor={'var(--gray-lighter)'}
            />
          </div>
          <Text
            variant={'b3'}
            color={isEligible ? 'var(--primary)' : 'var(--gray)'}
            weight={400}
          >
            {isEligible ? 'Asignado' : 'No asignado'}
          </Text>
        </div>
      </div>
      <div className={'assignee-card-right'}>
        {canEdit && <Toggle defaultChecked={isEligible} onChange={onChange} />}
      </div>
    </div>
  );
};

export default AssigneeCard;
