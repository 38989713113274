import React from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import { useInbox } from 'features/inbox/hooks/useInbox';

import { type IconName } from 'components/interface/icon';

import './styles.css';

/**
 * InboxSidebarInboxChoice
 * @description Component for choosing inbox (all or assigned)
 * @param {string} text - Text to display
 * @param {string} icon - Icon name
 * @param {boolean} isActive - Whether the inbox is active
 * @param {function} onClick - Function to call on click
 * @returns {TSX.Element} InboxSidebarInboxChoice component
 */
const InboxSidebarInboxChoice: React.FC<{
  text: string;
  icon: IconName;
  isActive: boolean;
  onClick?: () => void;
}> = ({ text, icon, isActive, onClick }) => {
  return (
    <div
      className={`inbox-sidebar-inbox ${
        isActive ? 'inbox-sidebar-inbox-active' : ''
      }`}
      onClick={onClick}
    >
      <Icon name={icon} color={isActive ? 'var(--body)' : 'var(--gray)'} />
      <Text
        variant={'b2'}
        size={14}
        color={isActive ? 'var(--body)' : 'var(--gray)'}
      >
        {text}
      </Text>
    </div>
  );
};

/**
 * InboxSidebarInboxChooser
 * @description Component for choosing inbox (all or assigned)
 * @returns {TSX.Element} InboxSidebarInboxChooser component
 */

const InboxSidebarInboxChooser: React.FC = () => {
  const { inboxFilters, filterSetShowAssignedOnly } = useInbox();

  return (
    <div className={'inbox-sidebar-inboxes'}>
      <InboxSidebarInboxChoice
        text={'Asignados a mi'}
        icon={'chatText'}
        isActive={inboxFilters.showAssignedOnly}
        onClick={() => filterSetShowAssignedOnly(true)}
      />
      <InboxSidebarInboxChoice
        text={'Inbox General'}
        icon={'buildings'}
        isActive={!inboxFilters.showAssignedOnly}
        onClick={() => filterSetShowAssignedOnly(false)}
      />
    </div>
  );
};

export default InboxSidebarInboxChooser;
