import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import { UUID } from 'crypto';

import Text from 'components/interface/text';
import Input from 'components/interface/input';
import Select from 'components/interface/select';
import Button from 'components/interface/button';
import TextArea from 'components/interface/textArea';

import { useEndUser } from 'features/endUsers/hooks/useEndUser';

import { type EndUserList } from 'features/endUsers/providers/EndUserProvider';

type FormInputs = {
  name: string;
  description: string;
  color: string;
};

type ContactListFormProps = {
  endUserList?: EndUserList | null;
  onSubmitCallback: () => void;
};

const ContactListForm: React.FC<ContactListFormProps> = ({
  endUserList = null,
  onSubmitCallback,
}) => {
  const { createEndUserList, updateEndUserList, eliminateEndUserList } =
    useEndUser();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    // Send request to API conditional to create or update
    const response = endUserList
      ? await updateEndUserList(
          endUserList.id,
          data.name,
          data.description,
          data.color,
        )
      : await createEndUserList(data.name, data.description, data.color);

    // Manage response errors or success
    if (response?.errors) {
      if (response.errors?.name) {
        setError('name', {
          type: 'manual',
          message: response.errors.name,
        });
      }
      if (response.errors?.description) {
        setError('description', {
          type: 'manual',
          message: response.errors.description,
        });
      }
      if (response.errors?.color) {
        setError('color', {
          type: 'manual',
          message: response.errors.color,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar la lista');
      }
    } else {
      if (response.status === 200) {
        toast.success('Lista guardada correctamente');
      } else {
        toast.error('Error al guardar la lista');
        reset();
      }
      onSubmitCallback();
    }
    setIsLoading(false);
  };

  const onDelete = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const response = await eliminateEndUserList(endUserList?.id ?? '');

    if (response?.data?.success) {
      toast.success('Lista eliminada correctamente');
      onSubmitCallback();
    } else {
      toast.error('Error al eliminar la lista');
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: -16 }}>
      <Text variant={'s1'}>Nombre</Text>
      <Input
        placeholder={'Nombre de la lista'}
        defaultValue={endUserList?.name ?? ''}
        error={errors.name?.message}
        {...register('name')}
      />
      <Text variant={'s1'}>Descripción</Text>
      <TextArea
        placeholder={'Descripción de la lista'}
        defaultValue={endUserList?.description ?? ''}
        error={errors.description?.message}
        {...register('description')}
      />
      <Text variant={'s1'}>Color</Text>
      <Select
        options={[
          { value: 'blue', label: 'Azul' },
          { value: 'red', label: 'Rojo' },
          { value: 'green', label: 'Verde' },
          { value: 'orange', label: 'Naranja' },
          { value: 'pink', label: 'Rosa' },
          { value: 'purple', label: 'Morado' },
          { value: 'yellow', label: 'Amarillo' },
        ]}
        placeholder={'Selecciona un color'}
        defaultValue={endUserList?.color ?? 'blue'}
        error={errors.color?.message}
        {...register('color')}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
        {endUserList ? (
          <Button
            label={'Eliminar'}
            variant={'tertiary'}
            requiresConfirmation={true}
            confirmationTitle={'Eliminar lista'}
            confirmationText={'¿Estás seguro que deseas eliminar esta lista?'}
            onClick={onDelete}
          />
        ) : null}
        <Button
          label={'Cancelar'}
          variant={'secondary'}
          onClick={() => {
            onSubmitCallback();
            reset();
          }}
        />
        <Button
          type={'submit'}
          label={'Guardar'}
          variant={'primary'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default ContactListForm;
