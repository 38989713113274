import React, { useState } from 'react';

import { UUID } from 'crypto';

import IconButton from 'components/interface/iconButton';
import NewMessageModal from '../threadsNewMessageModal';
import SendTemplateModal from '../sendTemplateModal';

import './styles.css';

/**
 * InboxThreadsNewMessageButton
 * @description Component to handle inbox threads new message button
 * @returns {TSX.Element} InboxThreadsNewMessageButton component
 */

const InboxThreadsNewMessageButton: React.FC = () => {
  const [chatbotId, setChatbotId] = useState<UUID | undefined>();
  const [integrationId, setIntegrationId] = useState<string | undefined>();
  const [recipient, setRecipient] = useState<string | undefined>();
  const [isNewMessageModalOpen, setIsNewMessageModalOpen] =
    useState<boolean>(false);
  const [isSendTemplateModalOpen, setIsSendTemplateModalOpen] =
    useState<boolean>(false);

  const handleNextStep = () => {
    if (chatbotId && integrationId && recipient) {
      setIsNewMessageModalOpen(false);
      setIsSendTemplateModalOpen(true);
    }
  };

  return (
    <>
      <div className={'inbox-threads-new-message'}>
        <IconButton
          iconName={'send'}
          iconColor={'var(--gray)'}
          hint={'Redactar'}
          onClick={() => setIsNewMessageModalOpen(true)}
        />
      </div>
      <NewMessageModal
        isOpen={isNewMessageModalOpen}
        chatbotId={chatbotId}
        integrationId={integrationId}
        recipient={recipient}
        setChatbotId={setChatbotId}
        setIntegrationId={setIntegrationId}
        setRecipient={setRecipient}
        onNext={handleNextStep}
        onClose={() => setIsNewMessageModalOpen(false)}
      />
      <SendTemplateModal
        isOpen={isSendTemplateModalOpen}
        chatbotId={chatbotId}
        integrationId={integrationId}
        recipient={recipient}
        onClose={() => setIsSendTemplateModalOpen(false)}
      />
    </>
  );
};

export default InboxThreadsNewMessageButton;
