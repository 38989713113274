import api from '../../../api';

/**
 * fetchUserData
 * @description Function that gets user data
 * @returns {Object} { status, data: {userData} } | { status, ...errors }
 */

export const fetchUserData = async () => {
  try {
    const response = await api.get(
      process.env.REACT_APP_WEST_BASE_URL + '/user/',
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putUserData
 * @description Function that puts user data
 * @param firstName
 * @param lastName
 * @param phoneNumber
 * @returns {Object} { status } | { status, ...errors }
 */

export const putUserData = async (
  firstName: string,
  lastName: string,
  phoneNumber: string,
) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/user/settings/general/',
      {
        firstName,
        lastName,
        phoneNumber,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putUserNotificationPreferences
 * @description Function that puts user notification preferences
 * @param notificationPreferences { [key: string]: boolean }
 * @returns {Object} { status } | { status, ...errors }
 */

export const putUserNotificationPreferences = async (notificationPreferences: {
  [key: string]: boolean;
}) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/user/settings/notifications/',
      {
        ...notificationPreferences,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putUserPassword
 * @description Function that puts user password
 * @param currentpassword
 * @param password
 * @param vPassword
 * @returns {Object} { status } | { status, ...errors }
 */

export const putUserPassword = async (
  currentPassword: string,
  newPassword: string,
  vNewPassword: string,
) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/user/settings/password/',
      {
        currentPassword,
        newPassword,
        vNewPassword,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postCreateAccount
 * @description Function that creates user account
 * @param email
 * @param password
 * @param v_password
 * @returns {Object} { status, data: {userData} } | { status, ...errors }
 */

export const postCreateAccount = async (
  email: string,
  password: string,
  vPassword: string,
) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/user/create/',
      {
        email,
        password,
        vPassword,
      },
      { headers: { 'No-Auth': true } },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postVerifyPhoneNumber
 * @description Function that verifies user phone number with OTP
 * @param code
 * @returns {Object} { status, data: {userData} } | { status, ...errors }
 */

export const postVerifyPhoneNumber = async (code: string) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/user/phone/verify/',
      {
        code,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * getResendPhoneNumberVerificationCode
 * @description Function that resends phone number verification code
 * @returns {Object} { status, data: {userData} } | { status, ...errors }
 */

export const getResendPhoneNumberVerificationCode = async () => {
  try {
    const response = await api.get(
      process.env.REACT_APP_WEST_BASE_URL + '/user/phone/verify/',
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};
