import { type NotifiactionsState } from 'features/notifications/providers/NotificationProvider';

export type NotificationsTypes = 'SAVE_NOTIFICATIONS_DATA';

type NotificationAction = {
  type: NotificationsTypes;
  payload?: any;
};

const notificationReducer = (
  state: NotifiactionsState,
  action: NotificationAction,
) => {
  const { type, payload } = action;

  switch (type) {
    case 'SAVE_NOTIFICATIONS_DATA':
      return {
        ...state,
        notifications: payload,
      };
    default:
      return state;
  }
};

export default notificationReducer;
