import React from 'react';

import SidebarStatus from '../sidebarStatus';
import SidebarInboxChooser from '../sidebarInboxChooser';
import SidebarChannels from '../sidebarChannels';
import SidebarChatbots from '../sidebarChatbots';
import SidebarTags from '../sidebarTags';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

/**
 * InboxSidebar
 * @description Inbox sidebar component
 * @returns {TSX.Element} InboxSidebar component
 */

const InboxSidebar: React.FC = () => {
  const { inboxStatus } = useInbox();

  return (
    <div className={'inbox-sidebar'}>
      <div className={'inbox-sidebar-header'}>
        <SidebarStatus {...inboxStatus} />
      </div>
      <div className={'inbox-sidebar-body'}>
        <SidebarInboxChooser />
        <div style={{ height: 16 }} />
        <SidebarChannels />
        <SidebarChatbots />
        <SidebarTags />
      </div>
    </div>
  );
};

export default InboxSidebar;
