import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import WaveSurfer from 'wavesurfer.js';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

type AudioPlayerProps = {
  url?: string;
};

/**
 * AudioPlayer
 * @description Audio player component
 * @param {string} url - Audio url
 * @returns {TSX.Element} Audio player component
 */

const AudioPlayer: React.FC<AudioPlayerProps> = ({ url }) => {
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const [loadingPercentage, setLoadingPercentage] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isWaveSurferReady, setIsWaveSurferReady] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { getCallRecording } = useInbox();

  useEffect(() => {
    let wavesurfer: WaveSurfer | null = null;

    const fetchAudio = async () => {
      if (!url) return;

      try {
        const blob = await getCallRecording(url);

        if (!(blob instanceof Blob)) {
          throw new Error('Invalid response: Expected Blob');
        }

        const audioUrl = window.URL.createObjectURL(blob);

        if (waveformRef.current) {
          wavesurfer = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: '#d4d4d4',
            progressColor: '#767676',
            cursorColor: '#f5f5f5',
            height: 32,
            barWidth: 3,
            barGap: 1,
            barRadius: 3,
            fillParent: true,
            normalize: true,
            dragToSeek: true,
          });

          wavesurfer.on('loading', (percentage: number) => {
            setLoadingPercentage(percentage);
          });

          wavesurfer.on('ready', () => {
            setIsWaveSurferReady(true);
          });

          wavesurfer.on('finish', () => {
            setIsPlaying(false);
          });

          wavesurfer.load(audioUrl);
          wavesurferRef.current = wavesurfer;
        }
      } catch (error) {
        console.error('Error fetching audio:', error);
        setError('Error al cargar el audio');
      }
    };

    fetchAudio();

    return () => {
      if (wavesurfer && isWaveSurferReady) {
        wavesurfer.destroy();
      }
    };
  }, [url]);

  const toggleAudio = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
      setIsPlaying(!isPlaying);
    }
  };

  const downloadAudio = async () => {
    if (!url) {
      toast.error('El audio no está disponible');
      return;
    }

    const blob = await getCallRecording(url);
    const audioUrl = window.URL.createObjectURL(blob);

    fetch(audioUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = 'call-recording.mp3';

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      })
      .catch(() => {
        toast.error('Error al descargar el audio');
      });
  };

  return (
    <div className={'audio-player'}>
      <div style={{ cursor: 'pointer' }} onClick={toggleAudio}>
        <Icon
          name={isPlaying ? 'pause' : 'play'}
          style={'fill'}
          size={20}
          color={'var(--gray)'}
        />
      </div>
      {error ? (
        <div className={'error'}>
          <Text variant={'b3'} color={'var(--gray-2)'}>
            {error}
          </Text>
        </div>
      ) : (
        <>
          <div
            id={'waveform'}
            ref={waveformRef}
            style={{
              flex: 1,
              display: isWaveSurferReady ? 'block' : 'none',
              overflow: 'hidden',
            }}
          />
          <div
            className={'loading'}
            style={{ display: isWaveSurferReady ? 'none' : 'flex' }}
          >
            <Text variant={'b3'} color={'var(--gray-2)'}>
              Cargando... {loadingPercentage}%
            </Text>
          </div>
        </>
      )}
      <div style={{ cursor: 'pointer' }} onClick={downloadAudio}>
        <Icon
          name={'download'}
          style={'fill'}
          size={20}
          color={'var(--gray)'}
        />
      </div>
    </div>
  );
};

export default AudioPlayer;
