import React, { useState, useEffect } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Modal from 'components/interface/modal';
import Button from 'components/interface/button';

import ImportCampaignContactsFromListForm from '../../forms/ImportCampaignContactsFromListForm';

import './styles.css';

type CampaignContactsAddFromListProps = {
  campaignId: string;
  onSubmitCallback: () => void;
};

/**
 * CampaignContactsAddFromList
 * @description Button that opens a modal to add contacts to a campaign from an EndUserList list
 * @param {string} campaignId - Campaign id
 * @param {function} onSubmitCallback - Callback to execute after adding contacts
 * @returns {TSX.Element} CampaignContactsAddFromList component
 */

const CampaignContactsAddFromList: React.FC<
  CampaignContactsAddFromListProps
> = ({ campaignId, onSubmitCallback }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        label={'Importar Lista'}
        variant={'secondary'}
        icon={<Icon name={'userList'} />}
        onClick={() => setIsModalOpen(true)}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={'Importar contactos desde lista'}
      >
        <ImportCampaignContactsFromListForm
          campaignId={campaignId}
          onSubmitCallback={() => {
            onSubmitCallback();
            setIsModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default CampaignContactsAddFromList;
