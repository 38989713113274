import React from 'react';

import Text from 'components/interface/text';

import './styles.css';

type RoleTagProps = {
  text: string;
  color: string;
  backgroundColor: string;
};

/**
 * RoleTag
 * @description Component to render a role tag
 * @param {string} text - Text to display in the tag
 * @param {string} color - Color of the tag
 * @param {string} backgroundColor - Background color of the tag
 * @returns {TSX.Element} RoleTag component
 */

const RoleTag: React.FC<RoleTagProps> = ({ text, color, backgroundColor }) => {
  return (
    <div className={'role-tag'} style={{ backgroundColor: backgroundColor }}>
      <Text variant={'b3'} size={11} color={color}>
        {text}
      </Text>
    </div>
  );
};

export default RoleTag;
