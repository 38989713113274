import React, { useState, useEffect } from 'react';

import Icon from 'components/interface/icon';

import { type IconName } from 'components/interface/icon';
import { type MessageStatusType } from 'features/inbox/types/inboxTypes';

/**
 * InboxMessageStatusIcon
 * @description Component to display inbox message status icon
 * @param {MessageStatusType} status - message status
 * @param {number} size - icon size
 * @returns {TSX.Element} InboxMessageStatusIcon component
 */

type InboxMessageStatusIconProps = {
  status: MessageStatusType;
  size?: number;
  light?: boolean;
};

const InboxMessageStatusIcon: React.FC<InboxMessageStatusIconProps> = ({
  status,
  size = 14,
  light = false,
}) => {
  const [iconName, setIconName] = useState<IconName>('question');
  const [iconColor, setIconColor] = useState<string>(
    light ? 'var(--gray-2)' : 'var(--gray)',
  );
  const [iconStyle, setIconStyle] = useState<any>('regular');

  useEffect(() => {
    switch (status) {
      case 'sent':
        setIconName('check');
        setIconStyle('bold');
        break;
      case 'delivered':
        setIconName('checks');
        setIconStyle('bold');
        break;
      case 'read':
        setIconName('checks');
        setIconStyle('bold');
        setIconColor('var(--info)');
        break;
      case 'failed':
        setIconName('warningCircle');
        setIconColor('var(--error)');
        break;
      case 'warning':
        setIconName('warningCircle');
        setIconColor('var(--warning)');
        break;
      default:
        setIconName('clock');
        break;
    }
  }, [status]);

  return (
    <Icon name={iconName} size={size} style={iconStyle} color={iconColor} />
  );
};

export default InboxMessageStatusIcon;
