import React, { useState, useEffect } from 'react';

import { Table } from 'ka-table';
import { DataType, EditingMode, SortingMode } from 'ka-table/enums';

import SettingsFields from 'components/interface/settingsFields';
import InviteButton from 'features/dashboard/features/organization/components/inviteButton';
import InviteActions from 'features/dashboard/features/organization/components/inviteActions';
import EmptyState from 'components/interface/emptyState';

import { useUser } from 'features/user/hooks/useUser';
import { useOrganization } from 'features/organization/hooks/useOrganization';

import { formatDate } from 'utils/dates';

import 'ka-table/style.css';

const InvitesOrganizationView: React.FC = () => {
  const { userHasPermission } = useUser();
  const { organizationData } = useOrganization();
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    const columns: any[] = [
      {
        key: 'email',
        title: `Email`,
        dataType: DataType.String,
      },
      {
        key: 'sender',
        title: `Creador`,
        dataType: DataType.String,
      },
      {
        key: 'lastRetry',
        title: `Fecha`,
        dataType: DataType.String,
      },
    ];
    if (userHasPermission('organization.update')) {
      columns.push({
        key: 'actions',
        width: '120',
        dataType: DataType.String,
      });
    }
    setColumns(columns);
  }, []);

  const InviteTable = () => {
    const organizationInvites: { [key: string]: Object }[] = [];
    Object.keys(organizationData.invites).forEach((key: string) => {
      organizationInvites.push({
        email: organizationData.invites[key].email,
        sender:
          organizationData.members[organizationData.invites[key].sender]
            .displayName,
        lastRetry: formatDate(organizationData.invites[key].lastRetry),
        actions: key,
      });
    });

    return (
      <>
        <Table
          columns={columns}
          data={organizationInvites}
          rowKeyField={'actions'}
          editingMode={EditingMode.None}
          sortingMode={SortingMode.Single}
          childComponents={{
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case 'actions':
                    return <InviteActions code={props.value} />;
                  default:
                    return props.value;
                }
              },
            },
          }}
        />
        {organizationInvites.length === 0 && (
          <EmptyState
            text={'No hay invitaciones'}
            iconName={'envelopeSimple'}
          />
        )}
      </>
    );
  };

  const fields = {
    passwordChange: {
      label: 'Invitaciones',
      description:
        'Envía invitaciones a tus colaboradores para que se unan a tu organización. También puedes reenviar invitaciones o revocarlas.',
      options: [<InviteButton />],
      component: <InviteTable />,
    },
  };

  return <SettingsFields fields={fields} />;
};

export default InvitesOrganizationView;
