import { type ApiState } from './ApiProvider';

export type ApiTypes = 'SAVE_API_KEYS';

type ApiAction = {
  type: ApiTypes;
  payload?: any;
};

const apiReducer = (state: ApiState, action: ApiAction) => {
  const { type, payload } = action;

  switch (type) {
    case 'SAVE_API_KEYS':
      return {
        ...state,
        apiKeys: payload,
      };
    default:
      return state;
  }
};

export default apiReducer;
