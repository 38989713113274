import React, { useState, useEffect, useRef } from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import HoverMenu from 'components/interface/hoverMenu';
import UserAvatar from 'components/interface/userAvatar';

import { useInbox } from 'features/inbox/hooks/useInbox';
import { useOrganization } from 'features/organization/hooks/useOrganization';

import './styles.css';

/**
 * InboxDetailsAssignees
 * @description Container the thread's assignee(s)
 * @returns {TSX.Element} InboxDetailsAssignees component
 */

const InboxDetailsAssignees: React.FC = () => {
  const { inbox, assignThread } = useInbox();
  const { organizationData } = useOrganization();

  const assignRef = useRef<HTMLDivElement>(null);
  const [isAssignOpen, setIsAssignOpen] = useState(false);

  useEffect(() => {
    if (isAssignOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isAssignOpen]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      assignRef.current &&
      !assignRef.current.contains(event.target as Node)
    ) {
      setIsAssignOpen(false);
    }
  };

  return (
    <div className={'inbox-details-assignees'} ref={assignRef}>
      {inbox?.activeThread?.assignees.map((assignee) => (
        <div
          key={assignee}
          className={'inbox-details-assignee'}
          onClick={() => setIsAssignOpen(true)}
        >
          <UserAvatar
            organizationMember={organizationData.members?.[assignee]}
            size={24}
          />
          <Text variant={'b2'} numberOfLines={1}>
            {organizationData.members?.[assignee].displayName}
          </Text>
        </div>
      ))}
      {inbox?.activeThread?.assignees.length === 0 && (
        <div
          className={'inbox-details-assignee'}
          onClick={() => setIsAssignOpen(true)}
        >
          <div className={'inbox-details-assignee-icon'}>
            <Icon name={'question'} size={18} color={'var(--gray-2)'} />
          </div>
          <Text variant={'b2'} color={'var(--gray)'}>
            Sin asignar
          </Text>
        </div>
      )}
      {isAssignOpen && (
        <div className={'inbox-details-assign'}>
          {Object.values(organizationData.members ?? {}).map(
            (user) =>
              !inbox?.activeThread?.assignees.includes(user.id) && (
                <div
                  key={user.id}
                  className={'inbox-details-assignee'}
                  onClick={() => {
                    if (inbox.activeThread)
                      assignThread(inbox.activeThread?.id, user.id);
                    setIsAssignOpen(false);
                  }}
                >
                  <UserAvatar organizationMember={user} size={24} />
                  <Text variant={'b2'} numberOfLines={1}>
                    {user.displayName}
                  </Text>
                </div>
              ),
          )}
        </div>
      )}
    </div>
  );
};

export default InboxDetailsAssignees;
