import React from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Image from 'components/interface/image';

import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';

import './styles.css';

type ChatbotCardProps = {
  chatbot: ChatbotData;
};

const ChatbotCard: React.FC<ChatbotCardProps> = ({ chatbot }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/dashboard/assistants/${chatbot.id}`);
  };

  return (
    <button className={'chatbot-card'} onClick={handleClick}>
      <div className={'chatbot-card__header'}>
        <Image
          src={chatbot.image}
          className={'chatbot-card__header__image'}
          width={48}
          height={48}
          skeletonHeight={48}
          skeletonWidth={48}
        />
        <div className={'chatbot-card__header__info'}>
          <Text variant={'h7'}>{chatbot.nickname}</Text>
          <Text variant={'b3'} color={'var(--gray)'} style={{ marginTop: -2 }}>
            ({chatbot.name})
          </Text>
          <div className={'chatbot-card__header__id'}>
            <Text
              variant={'b3'}
              size={10}
              color={'var(--gray)'}
              align={'left'}
              numberOfLines={1}
            >
              {chatbot.id}
            </Text>
          </div>
        </div>
      </div>
      <div className={'chatbot-card__body'}>
        {chatbot.isActive ? (
          <div className={'chatbot-card__body__active'}>
            <div></div>
            <Text variant={'b3'} color={'var(--gray)'}>
              Active
            </Text>
          </div>
        ) : (
          <div className={'chatbot-card__body__inactive'}>
            <div></div>
            <Text variant={'b3'} color={'var(--gray)'}>
              Inactive
            </Text>
          </div>
        )}
        <div className={'chatbot-card__body__integrations'}>
          <Icon
            name={'whatsapp'}
            size={18}
            color={
              Object.keys(chatbot.whatsappIntegrations).length > 0
                ? 'var(--gray)'
                : 'var(--gray-light)'
            }
          />
          <Icon
            name={'phone'}
            size={18}
            color={
              Object.keys(chatbot.phoneCallIntegrations).length > 0
                ? 'var(--gray)'
                : 'var(--gray-light)'
            }
          />
        </div>
      </div>
    </button>
  );
};

export default ChatbotCard;
