import React, { useState } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import './styles.css';

type SidebarExpandableProps = {
  title: string;
  elements: Array<React.ReactNode>;
};

/**
 * SidebarExpandable
 * @description Expandable sidebar component
 * @param {string} title - Description of title
 * @param {Array<React.ReactNode>} elements - Description of elements
 * @returns {TSX.Element} SidebarExpandable component
 */

const SidebarExpandable: React.FC<SidebarExpandableProps> = ({
  title,
  elements,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  return (
    <div
      className={`sidebar-expandable ${
        isExpanded ? 'sidebar-expandable-expanded' : ''
      }`}
    >
      <div
        className={'sidebar-expandable-header'}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className={'icon-rotate'}>
          <Icon name={'caretRight'} size={14} />
        </div>
        <Text variant={'b2'}>{title}</Text>
      </div>
      <div
        className={'sidebar-expandable-elements'}
        style={!isExpanded ? { overflow: 'hidden' } : {}}
      >
        {elements.map((element, index) => (
          <div key={index}>{element}</div>
        ))}
      </div>
    </div>
  );
};

export default SidebarExpandable;
