import React from 'react';

import { NotificationContext } from '../providers/NotificationProvider';

import {
  fetchNotifications,
  postReadNotification,
} from '../functions/notificationFunctions';

import { type Notification } from '../types/notificationTypes';

/**
 * @hook useNotification
 * @description This hook provides the notification context
 * @returns {Object} The notification context
 */
export const useNotification = () => {
  const { notifications, saveNotifications } =
    React.useContext(NotificationContext);

  /**
   * getNotifications
   * Fetches unread notifications from the API
   */
  const getNotifications = async () => {
    const response = await fetchNotifications();

    if (response.status === 200) {
      saveNotifications(response.data);
    }
  };

  /**
   * appendNotification
   * Appends a new notification to the notifications list
   */
  const appendNotification = (notification: Notification) => {
    saveNotifications([...notifications, notification]);
  };

  /**
   * markAsRead
   * Marks a notification as read
   * @param {number} id The ID of the notification to mark as read
   */
  const markAsRead = async (id: number) => {
    const response = await postReadNotification(id);

    if (response.status === 200) {
      getNotifications();
    }
  };

  /**
   * markBatchAsRead
   * Marks a batch of notifications as read
   * @param {number[]} ids The IDs of the notifications to mark as read
   */
  const markBatchAsRead = async (ids: number[]) => {
    const response = await Promise.all(
      ids.map((id) => postReadNotification(id)),
    );

    if (response.every((res) => res.status === 200)) {
      getNotifications();
    }
  };

  /**
   * checkRouteNotifications
   * Checks if the current location pathname has unread notifications and marks them as read
   * @param {string} pathname The current location pathname
   */
  const checkRouteNotifications = (pathname: string) => {
    const matchingNotifications = notifications.filter((notification) => {
      const url = new URL(notification.inAppUrl);
      return url.pathname === pathname;
    });

    if (matchingNotifications.length > 0) {
      markBatchAsRead(
        matchingNotifications.map((notification) => notification.id),
      );
    }
  };

  return {
    notifications,
    getNotifications,
    appendNotification,
    markAsRead,
    markBatchAsRead,
    checkRouteNotifications,
  };
};
