import React, {
  useState,
  useEffect,
  forwardRef,
  InputHTMLAttributes,
} from 'react';

import Text from 'components/interface/text';

import './styles.css';

type extensions =
  | '.jpg'
  | '.jpeg'
  | '.png'
  | '.gif'
  | '.pdf'
  | '.doc'
  | '.docx'
  | '.csv';

interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  allowedExtensions?: extensions[];
  isDisabled?: boolean;
  isRequired?: boolean;
  defaultValue?: string | number;
  error?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * FileInput
 * @description Cutom file input component
 * @param {Array} allowedExtensions - Allowed file extensions
 * @param {Boolean} isDisabled - Input disabled
 * @param {Boolean} isRequired - Input required
 * @param {String} defaultValue - Input default value
 * @param {String} error - Input error
 * @param {Function} onChange - Input onChange function
 * @returns {TSX.Element} Input component
 */

const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      allowedExtensions,
      isDisabled = false,
      isRequired = false,
      defaultValue,
      error,
      onChange = () => {},
      ...props
    },
    ref,
  ) => {
    const [inputId, setInputId] = useState<string>(Math.random().toString(36));

    useEffect(() => {
      setInputId(Math.random().toString(36));
    }, []);

    return (
      <>
        <label htmlFor={inputId} className={`file-input-drop`}>
          <Text variant={'b2'} color={'var(--gray)'}>
            Arrastra tu documento aquí
          </Text>
          <Text variant={'b3'} color={'var(--gray)'}>
            o
          </Text>
          <input
            ref={ref}
            id={inputId}
            type={'file'}
            className={`file-input ${error ? ' file-input-error' : ''}`}
            disabled={isDisabled}
            required={isRequired}
            defaultValue={defaultValue}
            accept={allowedExtensions?.join(',')}
            onChange={onChange}
            {...props}
          />
        </label>
        {error && <span className="file-input-error-message">{error}</span>}
      </>
    );
  },
);

export default FileInput;
