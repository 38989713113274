import React, { forwardRef, InputHTMLAttributes } from 'react';

import './styles.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  type?: 'text' | 'password' | 'number' | 'email' | 'time';
  variant?: 'primary' | 'secondary';
  isDisabled?: boolean;
  isRequired?: any;
  defaultValue?: string | number;
  minValueValidation?: number;
  maxValueValidation?: number;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Input
 * @description Cutom input component
 * @param {String} placeholder - Input placeholder
 * @param {String} type - Input type
 * @param {String} variant - Input variant
 * @param {Boolean} isDisabled - Input disabled
 * @param {Boolean} isRequired - Input required
 * @param {String} defaultValue - Input default value
 * @param {Number} minValueValidation - Input min value validation
 * @param {Number} maxValueValidation - Input max value validation
 * @param {String} error - Input error
 * @param {Function} onChange - Input onChange function
 * @returns {TSX.Element} Input component
 */

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      type = 'text',
      variant = 'primary',
      isDisabled = false,
      isRequired = false,
      defaultValue = '',
      minValueValidation,
      maxValueValidation,
      error,
      onChange,
      ...props
    },
    ref,
  ) => {
    return (
      <>
        <input
          ref={ref}
          type={type}
          className={`input input-${variant}${error ? ' input-error' : ''}`}
          placeholder={placeholder}
          disabled={isDisabled}
          required={isRequired}
          defaultValue={defaultValue}
          {...(type === 'number'
            ? { min: minValueValidation, max: maxValueValidation }
            : {})}
          onChange={onChange}
          {...props}
        />
        {error && <span className="input-error-message">{error}</span>}
      </>
    );
  },
);

export default Input;
