import React, { useEffect } from 'react';

import Image from 'components/interface/image';
import IconButton from 'components/interface/iconButton';
import SidebarLink from '../sidebarLink';
import SidebarProfile from '../sidebarProfile';
import NotificationSidebar from 'features/dashboard/components/notificationSidebar';
import PermissionGate from 'features/permissions/components/PermissionGate';

import { IconName } from 'components/interface/icon';

import { useDashboard } from 'features/dashboard/hooks/useDashboard';
import { useNotification } from 'features/notifications/hooks/useNotification';

import logo from 'assets/svgs/brand/logo_isotype_white.svg';

import './styles.css';

type Tab = {
  id: string;
  name: string;
  icon: IconName;
  hasNotifications?: boolean;
  requiredPermissions?: string[];
};

const DashboardNavigator: React.FC = () => {
  const {
    activeTab,
    isSidebarOpen,
    isNotificationsOpen,
    forceSidebarClosed,
    setActiveTab,
    toggleSidebar,
    toggleNotifications,
  } = useDashboard();

  const { notifications, checkRouteNotifications } = useNotification();

  useEffect(() => {
    const path = window.location.pathname.split('/');
    const tab = path[2];
    setActiveTab(tab || '');
    checkRouteNotifications(window.location.pathname);
  }, [window.location.pathname]);

  const TABS: Tab[] = [
    {
      id: '',
      name: 'Resumen',
      icon: 'squaresFour',
    },
    {
      id: 'inbox',
      name: 'Inbox',
      icon: 'tray',
      hasNotifications:
        notifications.find((n) => n.feature === 'inbox') !== undefined,
    },
    {
      id: 'contacts',
      name: 'Contactos',
      icon: 'addressBookTabs',
      hasNotifications:
        notifications.find((n) => n.feature === 'contacts') !== undefined,
    },
    {
      id: 'assistants',
      name: 'Asistentes',
      icon: 'robot',
      hasNotifications:
        notifications.find((n) => n.feature === 'bots') !== undefined,
    },
    {
      id: 'campaigns',
      name: 'Campañas',
      icon: 'megaphoneSimple',
      hasNotifications:
        notifications.find((n) => n.feature === 'campaigns') !== undefined,
      requiredPermissions: ['campaigns.read'],
    },
    {
      id: 'usage',
      name: 'Plan y Uso',
      icon: 'chartDonut',
      hasNotifications:
        notifications.find((n) => n.feature === 'billing') !== undefined,
      requiredPermissions: ['billing.read'],
    },
    {
      id: 'organization',
      name: 'Organización',
      icon: 'buildings',
      hasNotifications:
        notifications.find((n) => n.feature === 'organizations') !== undefined,
    },
    {
      id: 'integrations',
      name: 'Integraciones',
      icon: 'plugs',
    },
    {
      id: 'api',
      name: 'API Keys',
      icon: 'key',
      requiredPermissions: ['api.read'],
    },
  ];

  return (
    <>
      <div
        className={`navigator ${
          !isSidebarOpen || forceSidebarClosed ? 'navigator-small' : ''
        }`}
      >
        <div className="navigator-header">
          <div className="navigator-image">
            <Image src={logo} height={30} />
          </div>
          {!forceSidebarClosed && (
            <div className={`${!isSidebarOpen ? 'navigator-expand-icon' : ''}`}>
              <IconButton
                size={28}
                iconSize={16}
                iconName={isSidebarOpen ? 'caretLeft' : 'caretRight'}
                iconColor={'var(--gray)'}
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>

        <div className="navigator-links">
          {TABS.map((tab) => (
            <PermissionGate
              key={tab.id}
              requiredPermissions={tab?.requiredPermissions ?? []}
            >
              <SidebarLink
                name={tab.name}
                icon={tab.icon}
                active={activeTab === tab.id}
                isExpanded={isSidebarOpen && !forceSidebarClosed}
                nNotifications={
                  notifications.filter((n) => n.feature === tab.id).length
                }
                callback={() => setActiveTab(tab.id)}
              />
            </PermissionGate>
          ))}
        </div>
        <div className={'navigator-bottom'}>
          <div className="navigator-links">
            <SidebarLink
              name={'Notificaciones'}
              icon={'bell'}
              active={isNotificationsOpen}
              isExpanded={isSidebarOpen && !forceSidebarClosed}
              nNotifications={notifications.length}
              callback={toggleNotifications}
            />
          </div>
          <SidebarProfile />
        </div>
      </div>
      <NotificationSidebar />
    </>
  );
};

export default DashboardNavigator;
