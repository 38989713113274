import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Hr from 'components/interface/hr';
import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Input from 'components/interface/input';
import Button from 'components/interface/button';
import TextArea from 'components/interface/textArea';
import SettingsFields from 'components/interface/settingsFields';
import RolePermissionsInput from '../components/rolePermissionsInput';

import { useUser } from 'features/user/hooks/useUser';
import { useOrganization } from 'features/organization/hooks/useOrganization';

import { OrganizationRole } from 'features/organization/providers/OrganizationProvider';

import 'ka-table/style.css';

type FormInputs = {
  name: string;
  description: string;
  permissions: object;
};

type RoleDetailsViewProps = {
  role: OrganizationRole;
  goBack: () => void;
};

const RoleDetailsView: React.FC<RoleDetailsViewProps> = ({ role, goBack }) => {
  const { userHasPermission } = useUser();
  const { updateOrganizationRole } = useOrganization();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [updatedPermissions, setUpdatedPermissions] = useState(
    role.permissions,
  );

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>();

  const isAccessible =
    role.isSystem === false && userHasPermission('organization.update');

  const handlePermissionsChange = (newPermissions: any) => {
    setUpdatedPermissions(newPermissions);
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await updateOrganizationRole(
      role.id,
      data.name,
      data.description,
      updatedPermissions,
    );

    if (response?.errors) {
      if (response.errors?.name) {
        setError('name', {
          type: 'manual',
          message: response.errors?.name,
        });
      }
      if (response.errors?.description) {
        setError('description', {
          type: 'manual',
          message: response.errors?.description,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar los parametros');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Datos actualizados');
      } else {
        toast.error('Error al actualizar los datos');
        reset();
      }
    }
    setIsLoading(false);
  };

  const fields = {
    name: {
      label: 'Nombre del rol',
      description:
        'El nombre del rol es visible para todos los miembros de tu organización.',
      component: (
        <Input
          placeholder={'Nombre'}
          defaultValue={role.name}
          isDisabled={!isAccessible}
          error={errors?.name?.message}
          {...register('name')}
        />
      ),
    },
    description: {
      label: 'Descripción del rol',
      description:
        'La descripción del rol es visible para todos los miembros de tu organización.',
      component: (
        <TextArea
          placeholder={'Descripción'}
          defaultValue={role.description}
          isDisabled={!isAccessible}
          error={errors?.description?.message}
          {...register('description')}
        />
      ),
    },
    permissions: {
      label: 'Permisos del rol',
      description:
        'Los permisos del rol definen las acciones que los miembros con este rol pueden realizar dentro de la aplicación.',
      component: (
        <RolePermissionsInput
          permissions={role.permissions}
          onPermissionsChange={handlePermissionsChange}
          isAccessable={isAccessible}
        />
      ),
    },
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ marginTop: -16 }}>
          <Button
            variant={'tertiary'}
            label={'Lista de roles'}
            icon={<Icon name={'arrowLeft'} />}
            onClick={goBack}
          />
        </div>
        <div style={{ height: 16 }}></div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Text variant={'h6'}>{role.name}</Text>
          <div
            className={`role-creator-tag-large ${
              role.isSystem ? 'rct-system' : 'rct-custom'
            }`}
          >
            <Text
              variant={'b2'}
              color={role.isSystem ? 'var(--primary)' : 'var(--gray)'}
              size={12}
            >
              {role.isSystem ? 'Predeterminado' : 'Personalizado'}
            </Text>
          </div>
        </div>
        <Hr />
        <SettingsFields fields={fields} />
        {isAccessible && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
            <Button
              label={'Cancelar'}
              variant={'secondary'}
              onClick={() => reset()}
            />
            <Button
              type={'submit'}
              label={'Guardar'}
              variant={'primary'}
              isLoading={isLoading}
            />
          </div>
        )}
      </form>
    </>
  );
};

export default RoleDetailsView;
