import React from 'react';

import ThreadsHeader from '../threadsHeader';
import ThreadBody from '../threadsBody';

import './styles.css';

/**
 * InboxThreads
 * @description Component to display inbox threads (conversations)
 * @returns {TSX.Element} InboxThreads component
 */

const InboxThreads: React.FC = () => {
  return (
    <div className={'inbox-threads'}>
      <ThreadsHeader />
      <ThreadBody />
    </div>
  );
};

export default InboxThreads;
