type ColorPalette = {
  primary: string;
  body: string;
  success: string;
  warning: string;
  error: string;
  white: string;
};

export const colors: ColorPalette = {
  primary: '#0066ff',
  body: '#262626',
  success: '#29a029',
  warning: '#a59643',
  error: '#c24f4f',
  white: '#ffffff',
};
