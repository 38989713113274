import React from 'react';

import ConversationHeader from '../conversationHeader';
import ConversationMessages from '../conversationMessages';
import ConversationInput from '../inputSection';
import ConversationEmpty from '../conversationEmpty';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

/**
 * InboxConversation
 * @description Container for the inbox conversation
 * @returns {TSX.Element} InboxConversation component
 */

const InboxConversation: React.FC = () => {
  const { inbox } = useInbox();

  return (
    <div className={'inbox-conversation'}>
      {inbox.activeThread ? (
        <>
          <ConversationHeader thread={inbox.activeThread} />
          <ConversationMessages />
          <ConversationInput />
        </>
      ) : (
        <ConversationEmpty />
      )}
    </div>
  );
};

export default InboxConversation;
