import React from 'react';

import Text from 'components/interface/text';

import './styles.css';

export type SettingFields = {
  [key: string]: {
    label: string;
    labelExtra?: React.ReactNode;
    description: string;
    options?: React.ReactNode[];
    hide?: boolean;
    component: React.ReactNode;
  };
};

type SettingsFieldsProps = {
  fields: SettingFields;
};

/**
 * SettingsFields
 * @description Custom settings fields component
 * @param {SettingFields} fields - Settings fields
 * @returns {TSX.Element} SettingsFields component
 */

const SettingsFields: React.FC<SettingsFieldsProps> = ({ fields }) => {
  return (
    <div className={'settings-fields'}>
      {Object.keys(fields).map((fieldKey) => (
        <div
          key={fieldKey}
          className={'settings-field'}
          style={fields[fieldKey].hide ? { display: 'none' } : {}}
        >
          <div className={'settings-field-label'}>
            <div className={'settings-field-title'}>
              <Text variant={'h8'} weight={500}>
                {fields[fieldKey].label}
              </Text>
              {fields[fieldKey].labelExtra && fields[fieldKey].labelExtra}
            </div>
            <Text variant={'b3'} color={'gray'}>
              {fields[fieldKey].description}
            </Text>
            {fields[fieldKey].options && (
              <div className={'settings-field-options'}>
                {fields[fieldKey].options?.map((option, index) => (
                  <div key={index}>{option}</div>
                ))}
              </div>
            )}
          </div>
          <div className={'settings-field-component'}>
            {fields[fieldKey].component}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SettingsFields;
