import React, { useState, useEffect } from 'react';

import { UUID } from 'crypto';

import { Table } from 'ka-table';
import { DataType, EditingMode, SortingMode } from 'ka-table/enums';

import Checkbox from 'components/interface/checkbox';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { capitalize } from 'utils/strings';

import 'ka-table/style.css';

type RolePermissionsInputProps = {
  permissions: any;
  isAccessable: boolean;
  onPermissionsChange?: (permissions: any) => void;
};

/**
 * RolePermissionsInput
 * @description Description of RolePermissionsInput
 * @param {Any} permissions - Permissions object
 * @param {boolean} isAccessable - Is accessable
 * @returns {TSX.Element} RolePermissionsInput component
 */

const RolePermissionsInput: React.FC<RolePermissionsInputProps> = ({
  permissions,
  isAccessable,
  onPermissionsChange = () => {},
}) => {
  const { chatbotsData } = useChatbot();
  const [rolePermissions, setRolePermissions] = useState<any[]>(permissions);
  const [rolePermissionsRows, setRolePermissionsRows] = useState<any[]>([]);

  useEffect(() => {
    const rolePermissionsRows: any[] = [];

    Object.keys(permissions).forEach((key: string) => {
      if (key === 'bots') {
        Object.keys(permissions[key]).forEach((botKey: string) => {
          const botPermission = permissions[key][botKey];
          rolePermissionsRows.push({
            permission: `${capitalize(key)} (${
              botKey !== 'all'
                ? capitalize(chatbotsData[botKey as UUID]?.name)
                : capitalize(botKey)
            })`,
            read: {
              path: `${key}.${botKey}.read`,
              isChecked: botPermission.read ?? false,
              isDisabled: false || !isAccessable,
            },
            update: {
              path: `${key}.${botKey}.update`,
              isChecked: botPermission.update ?? false,
              isDisabled: false || !isAccessable,
            },
            create: {
              path: `${key}.${botKey}.create`,
              isChecked: botPermission.create ?? false,
              isDisabled: botKey !== 'all' || !isAccessable,
            },
            delete: {
              path: `${key}.${botKey}.delete`,
              isChecked: botPermission.delete ?? false,
              isDisabled: botKey !== 'all' || !isAccessable,
            },
          });
        });
        return;
      }
      const permission = permissions[key];
      rolePermissionsRows.push({
        permission: capitalize(key),
        read: {
          path: `${key}.read`,
          isChecked: permission.read ?? false,
          isDisabled: false || !isAccessable,
        },
        update: {
          path: `${key}.update`,
          isChecked: permission.update ?? false,
          isDisabled: false || !isAccessable,
        },
        create: {
          path: `${key}.create`,
          isChecked: permission.create ?? false,
          isDisabled:
            key === 'billing' || key === 'organization' || !isAccessable,
        },
        delete: {
          path: `${key}.delete`,
          isChecked: permission.delete ?? false,
          isDisabled:
            key === 'billing' || key === 'organization' || !isAccessable,
        },
      });
    });

    setRolePermissionsRows(rolePermissionsRows);
  }, [permissions]);

  const handleCheckboxChange = (path: string, value: boolean) => {
    if (!isAccessable) return;
    const pathArray = path.split('.');
    let updatedPermissions = { ...rolePermissions };

    let currentPermissions = updatedPermissions;
    pathArray.forEach((pathKey: any, index: number) => {
      if (index === pathArray.length - 1) {
        currentPermissions[pathKey] = value;
      } else {
        currentPermissions[pathKey] = currentPermissions[pathKey] ?? {};
        currentPermissions = currentPermissions[pathKey];
      }
    });

    setRolePermissions(updatedPermissions);
    onPermissionsChange(updatedPermissions);
  };

  const columns: any[] = [
    {
      key: 'permission',
      title: `Permiso`,
      dataType: DataType.String,
    },
    {
      key: 'read',
      title: 'Ver',
      style: { textAlign: 'center' },
      width: 80,
      dataType: DataType.Object,
    },
    {
      key: 'update',
      title: `Editar`,
      style: { textAlign: 'center' },
      width: 80,
      dataType: DataType.Object,
    },
    {
      key: 'create',
      title: `Crear`,
      style: { textAlign: 'center' },
      width: 80,
      dataType: DataType.Object,
    },
    {
      key: 'delete',
      title: `Eliminar`,
      style: { textAlign: 'center' },
      width: 80,
      dataType: DataType.Object,
    },
  ];

  return (
    <Table
      columns={columns}
      data={rolePermissionsRows}
      rowKeyField={'permission'}
      editingMode={EditingMode.None}
      sortingMode={SortingMode.Single}
      childComponents={{
        cellText: {
          content: (props) => {
            switch (props.column.key) {
              case 'read':
              case 'update':
              case 'create':
              case 'delete':
                return (
                  <Checkbox
                    defaultChecked={props.value.isChecked}
                    isDisabled={props.value.isDisabled}
                    onChange={(e) =>
                      handleCheckboxChange(props.value.path, e.target.checked)
                    }
                  />
                );
              default:
                return props.value;
            }
          },
        },
      }}
    />
  );
};

export default RolePermissionsInput;
