import { UserPermissions } from 'features/user/providers/UserProvider';

export const hasPermission = (
  userPermissions: UserPermissions,
  requiredPermission: string,
) => {
  const permissionLevels = requiredPermission.split('.');
  let current: UserPermissions | boolean = userPermissions;

  for (const level of permissionLevels) {
    if (typeof current === 'boolean') {
      return current;
    }
    if (current[level] === undefined) {
      return false;
    }
    current = current[level] as UserPermissions | boolean;
  }

  return typeof current === 'boolean' ? current : false;
};
