import React, { useState, useEffect } from 'react';

import Icon from 'components/interface/icon';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

/**
 * InboxThreadsSearch
 * @description Component to search inbox threads
 * @returns {TSX.Element} InboxThreadsSearch component
 */

const InboxThreadsSearch: React.FC = () => {
  const { filterSetSearch } = useInbox();
  const [searchTerm, setSearchTerm] = useState('ö');

  useEffect(() => {
    if (searchTerm === 'ö') return;
    filterSetSearch(searchTerm);
  }, [searchTerm]);

  const handleInputChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className={'inbox-threads-search'}>
      <Icon name={'magnifyingGlass'} color={'var(--gray)'} />
      <input placeholder={'Buscar...'} onChange={handleInputChange} />
    </div>
  );
};

export default InboxThreadsSearch;
