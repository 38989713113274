import React from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import AudioPlayer from 'components/interface/audioPlayer';

import { useInbox } from 'features/inbox/hooks/useInbox';

import { type Message } from 'features/inbox/types/inboxTypes';

import { formatTime } from 'utils/dates';

import './styles.css';

/**
 * MessageContentSystemCall
 * @description Component to display system tag message
 * @param {UUID} chatbotId - chatbot id
 * @param {Message} message - message object
 * @param {string} callMessage - call message
 * @returns {TSX.Element} MessageContentSystemCall component
 */

type MessageContentSystemCallProps = {
  message: Message;
  callMessage: string;
};

const MessageContentSystemCall: React.FC<MessageContentSystemCallProps> = ({
  message,
  callMessage,
}) => {
  const { inbox } = useInbox();

  if (!inbox.activeThread) return null;

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div
        className={`inbox-message-system-call inbox-message-system-call-${callMessage}`}
      >
        <div className={'inbox-message-system-call-content'}>
          <div
            className={'inbox-message-system-call-icon'}
            style={{
              backgroundColor:
                callMessage === 'started'
                  ? 'var(--success-pale)'
                  : 'var(--error-pale)',
            }}
          >
            <Icon
              name={callMessage === 'started' ? 'phoneCall' : 'phoneDisconnect'}
              size={16}
              color={
                callMessage === 'started' ? 'var(--success)' : 'var(--error'
              }
            />
          </div>
          <div className={'inbox-message-system-call-text'}>
            <Text variant={'b2'} size={12} color={'var(--body)'}>
              {message.content}
            </Text>
            <Text variant={'b3'} size={10} color={'var(--gray)'}>
              a las {formatTime(message.sentAt)}
            </Text>
          </div>
        </div>
        {callMessage === 'ended' && message.media && (
          <AudioPlayer url={message.media} />
        )}
      </div>
    </div>
  );
};

export default MessageContentSystemCall;
