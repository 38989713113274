import React, { useState, useEffect } from 'react';

import TagInput from 'components/interface/tagInput';

import { useInbox } from 'features/inbox/hooks/useInbox';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { TagInputTag } from 'components/interface/tagInput';

import './styles.css';

/**
 * InboxDetailsTags
 * @description Container for the thread's tags
 * @returns {TSX.Element} InboxDetailsTags component
 */

const InboxDetailsTags: React.FC = () => {
  const { inbox, assignThreadTag, unassignThreadTag } = useInbox();
  const { chatbotsData } = useChatbot();

  const [availableTags, setAvailableTags] = useState<TagInputTag[]>([]);
  const [selectedTags, setSelectedTags] = useState<TagInputTag[]>([]);

  useEffect(() => {
    if (!inbox.activeThread) return;

    const availableTagsAux: TagInputTag[] = [];
    const selectedTagsAux: TagInputTag[] = [];

    if (chatbotsData[inbox.activeThread?.chatbotId]) {
      const chatbot = chatbotsData[inbox.activeThread?.chatbotId];
      Object.values(chatbot.tags).forEach((tag) => {
        availableTagsAux.push({
          id: tag.id,
          name: tag.name,
          color: tag.color,
          onClick: () => {
            if (inbox.activeThread)
              unassignThreadTag(inbox.activeThread?.id, tag.id);
          },
        });
        if (inbox.activeThread?.tags.includes(tag.id)) {
          selectedTagsAux.push({
            id: tag.id,
            name: tag.name,
            color: tag.color,
            onClick: () => {
              if (inbox.activeThread)
                unassignThreadTag(inbox.activeThread?.id, tag.id);
            },
          });
        }
      });
    }

    setAvailableTags(availableTagsAux);
    setSelectedTags(selectedTagsAux);
  }, [inbox.activeThread?.tags, chatbotsData]);

  if (!inbox.activeThread) return null;

  return (
    <div className={'inbox-tags'}>
      <TagInput
        availableTags={availableTags}
        selectedTags={selectedTags}
        onTagAdd={(tag) => {
          if (inbox.activeThread)
            assignThreadTag(inbox.activeThread?.id, tag.id);
        }}
      />
    </div>
  );
};

export default InboxDetailsTags;
