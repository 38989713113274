import React from 'react';
import { useNavigate } from 'react-router-dom';

import Text from 'components/interface/text';
import IconButton from 'components/interface/iconButton';

import './styles.css';

type DetailViewProps = {
  title?: string;
  buttons?: React.ReactNode[];
  children?: React.ReactNode;
};

const DetailView: React.FC<DetailViewProps> = ({
  title = '',
  buttons,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={'detail-view-header'}>
        <div>
          <IconButton iconName={'arrowLeft'} onClick={() => navigate(-1)} />
          <Text variant={'h6'}>{title}</Text>
        </div>
        <div>
          {buttons &&
            buttons.map((button, key) => <div key={key}>{button}</div>)}
        </div>
      </div>
      {children}
    </>
  );
};

export default DetailView;
