import React from 'react';

import Text from 'components/interface/text';
import ThreadsSearch from '../threadsSearch';
import ThreadUnreadFilter from '../threadsUnreadFilter';
import ThreadsFilterButton from '../threadsFilterButton';
import ThreadsNewMessageButton from '../threadsNewMessageButton';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

/**
 * InboxThreadsHeader
 * @description Inbox threads header component
 * @returns {TSX.Element} InboxThreadsHeader component
 */

const InboxThreadsHeader: React.FC = () => {
  const { inboxFilters } = useInbox();

  return (
    <div className={'inbox-threads-header'}>
      <div className={'inbox-threads-header-row'}>
        <div>
          <Text variant={'b3'} color={'var(--gray)'}>
            {inboxFilters.showAssignedOnly == false
              ? 'Inbox Principal'
              : 'Inbox Asignados'}
          </Text>
          <Text variant={'h7'}>Conversaciones</Text>
        </div>
        <div style={{ display: 'flex', gap: 4 }}>
          <ThreadsFilterButton />
          <ThreadsNewMessageButton />
        </div>
      </div>
      <ThreadsSearch />
      <ThreadUnreadFilter />
    </div>
  );
};

export default InboxThreadsHeader;
