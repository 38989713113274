import React from 'react';

import Text from 'components/interface/text';

import {
  type ChatbotKnowledgeSource,
  type ChatbotKnowledgeChunk,
} from 'features/chatbots/providers/ChatbotProvider';

import './styles.css';

type KnowledgeSourceChunksProps = {
  knowledgeSource?: ChatbotKnowledgeSource;
};

/**
 * KnowledgeSourceChunks
 * @description Component to display and edit knowledge source chunks
 * @param {ChatbotKnowledgeSource} knowledgeSource - Knowledge source data
 * @returns {TSX.Element} KnowledgeSourceChunks component
 */

const KnowledgeSourceChunks: React.FC<KnowledgeSourceChunksProps> = ({
  knowledgeSource,
}) => {
  return (
    <div>
      {Object.values(knowledgeSource?.chunks ?? {}).map(
        (chunk: ChatbotKnowledgeChunk) => {
          return (
            <div key={chunk.id} className={'knowledge-chunk'}>
              <Text variant={'h8'}>{chunk.summary}</Text>
              <Text variant={'b3'}>{chunk.content}</Text>
            </div>
          );
        },
      )}
    </div>
  );
};

export default KnowledgeSourceChunks;
