import React, { useState } from 'react';

import Text from 'components/interface/text';
import Select from 'components/interface/select';
import Checkbox from 'components/interface/checkbox';
import Modal from 'components/interface/modal';
import IconButton from 'components/interface/iconButton';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

/**
 * InboxThreadsFilterButton
 * @description Component to handle inbox threads filter button
 * @returns {TSX.Element} InboxThreadsFilterButton component
 */

const InboxThreadsFilterButton: React.FC = () => {
  const { inboxFilters, filterSetOrderBy, filterSetShowUnreplied } = useInbox();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className={'inbox-threads-filter'}>
        <IconButton
          iconName={'funnel'}
          iconColor={'var(--gray)'}
          onClick={() => setIsModalOpen(true)}
          hint={'Filtros'}
        />
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={'Opciónes de filtrado'}
      >
        <Text variant={'b3'}>Ordenar por</Text>
        <Select
          options={[
            { value: 'desc', label: 'Fecha descendiente' },
            { value: 'asc', label: 'Fecha ascendiente' },
          ]}
          defaultValue={inboxFilters.orderBy}
          onChange={(e) => filterSetOrderBy(e.target.value as 'asc' | 'desc')}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            marginTop: 16,
          }}
        >
          <Checkbox
            defaultChecked={inboxFilters.showUnreplied}
            onChange={() => filterSetShowUnreplied(!inboxFilters.showUnreplied)}
          />
          <Text variant={'b3'}>Mostrar conversaciones sin respuesta</Text>
        </div>
      </Modal>
    </>
  );
};

export default InboxThreadsFilterButton;
