import React, { useState, useEffect } from 'react';

import { UUID } from 'crypto';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Card from 'components/interface/card';
import Button from 'components/interface/button';
import CampaignConfigScheduling from '../campaignConfigScheduling';
import CampaignConfigTemplates from '../campaignConfigTemplates';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type IconName } from 'components/interface/icon';
import { type CampaignData } from 'features/campaigns/providers/CampaignProvider';

import { campaignStatusTranslations } from 'features/campaigns/utils/translations';

import './styles.css';

type CampaignConfigParameterProps = {
  icon: IconName;
  value: string;
};

const CampaignConfigParameter: React.FC<CampaignConfigParameterProps> = ({
  icon,
  value,
}) => {
  return (
    <div className={'campaign-config-card-section__parameter'}>
      <Icon name={icon} color={'var(--gray)'} />
      <Text variant={'b2'} color={'var(--gray)'}>
        {value}
      </Text>
    </div>
  );
};

type CampaignConfigCardProps = {
  campaignId: string;
  canUpdate?: boolean;
};

type CampaignConfigAction = {
  action: string;
  label: string;
  icon: IconName;
  disabled: boolean;
};

const CampaignConfigActionDefault: CampaignConfigAction = {
  action: 'active',
  label: 'Detonar',
  icon: 'play',
  disabled: true,
};

/**
 * CampaignConfigCard
 * @description Card for campaign configuration
 * @param {string} campaignId - Campaign ID
 * @param {boolean} canUpdate - If user can update campaign
 * @returns {TSX.Element} CampaignConfigCard component
 */

const CampaignConfigCard: React.FC<CampaignConfigCardProps> = ({
  campaignId,
  canUpdate = false,
}) => {
  const { campaignsData, updateCampaignStatus } = useCampaign();
  const { chatbotsData } = useChatbot();
  const [campaign, setCampaign] = useState<CampaignData | undefined>(undefined);
  const [integrationType, setIntegrationType] = useState<string>('');
  const [action, setAction] = useState<CampaignConfigAction>(
    CampaignConfigActionDefault,
  );

  useEffect(() => {
    const campaignAux = Object.values(campaignsData).find(
      (c) => c.id === campaignId,
    );

    if (campaignAux?.integrationType === 'bots | Integration Whatsapp') {
      setIntegrationType('WhatsApp');
    }
    if (campaignAux?.integrationType === 'bots | Integration Phone Call') {
      setIntegrationType('Llamadas');
    }

    if (
      campaignAux?.status === 'pending' ||
      campaignAux?.status === 'finished'
    ) {
      setAction({
        action: '',
        label: 'Detonar',
        icon: 'play',
        disabled: true,
      });
    } else {
      if (campaignAux?.status === 'ready') {
        setAction({
          action: 'active',
          label: 'Detonar',
          icon: 'play',
          disabled: false,
        });
      }
      if (campaignAux?.status === 'active') {
        setAction({
          action: 'inactive',
          label: 'Pausar',
          icon: 'pause',
          disabled: false,
        });
      }
      if (campaignAux?.status === 'inactive') {
        setAction({
          action: 'active',
          label: 'Reanudar',
          icon: 'play',
          disabled: false,
        });
      }
    }

    setCampaign(campaignAux);
  }, [campaignsData, campaignId]);

  const handleAction = async () => {
    if (!campaign) return;

    await updateCampaignStatus(campaign.id, action.action);
  };

  return (
    <Card>
      <div className={'campaign-config-card-sections'}>
        <div className={'campaign-config-card-section'}>
          <Text variant={'h8'}>Configuración</Text>
          <div style={{ height: 12 }} />
          <div className={'campaign-config-card-section__parameters'}>
            <CampaignConfigParameter icon={'plugs'} value={integrationType} />
            <CampaignConfigParameter
              icon={'repeat'}
              value={`${campaign?.retries} reintentos`}
            />
            <CampaignConfigParameter
              icon={'robot'}
              value={chatbotsData[campaign?.botId as UUID]?.nickname}
            />
            {integrationType === 'WhatsApp' && (
              <CampaignConfigParameter
                icon={'envelopeSimple'}
                value={`${campaign?.messageLimit} msgs`}
              />
            )}
          </div>
        </div>
        <div className={'campaign-config-card-section'}>
          <Text variant={'h8'}>Calendarización</Text>
          <div style={{ height: 12 }} />
          <CampaignConfigScheduling
            campaignId={campaignId}
            canUpdate={canUpdate}
          />
        </div>
        {integrationType === 'WhatsApp' && (
          <div className={'campaign-config-card-section'}>
            <Text variant={'h8'}>Plantillas</Text>
            <div style={{ height: 12 }} />
            <CampaignConfigTemplates campaignId={campaignId} />
          </div>
        )}
        <div className={'campaign-config-card-section'}>
          <div className={'campaign-config-card-section__header'}>
            <div
              className={'campaign-config-card-status'}
              style={{
                backgroundColor: `var(--${campaign?.status})`,
                borderColor: `var(--${campaign?.status}-pale)`,
              }}
            ></div>
            <Text variant={'h8'}>
              Estatus:{' '}
              {campaign?.status
                ? campaignStatusTranslations[
                    campaign?.status as keyof typeof campaignStatusTranslations
                  ]
                : 'Desconocido'}
            </Text>
          </div>
          <Button
            label={action.label}
            variant={'secondary'}
            icon={
              <Icon
                name={action.icon}
                size={14}
                color={
                  action.disabled || !canUpdate
                    ? 'var(--gray-light)'
                    : 'var(--body)'
                }
              />
            }
            isDisabled={action.disabled || !canUpdate}
            onClick={handleAction}
          />
        </div>
      </div>
    </Card>
  );
};

export default CampaignConfigCard;
