import React, { FC, createContext, useReducer, useMemo } from 'react';

import apiReducer from './apiReducer';

export type ApiKey = {
  id: number;
  name: string;
  displayableKey: string;
  createdAt: string;
  createdBy: string;
  lastUsedAt: string;
  revoked: boolean;
};

export type ApiState = {
  apiKeys: { [key: number]: ApiKey };
  saveApiKeys: (apiKeys: { [key: number]: ApiKey }) => void;
};

type ApiProviderProps = {
  children: React.ReactNode;
};

export const ApiContext = createContext<ApiState>({
  apiKeys: {},
  saveApiKeys: () => {},
});

const ApiProvider: FC<ApiProviderProps> = ({ children }) => {
  const initialState = {
    apiKeys: {},
    saveApiKeys: (apiKeys: { [key: number]: ApiKey }) => {
      return null;
    },
  };

  const [state, dispatch] = useReducer(apiReducer, initialState);

  const saveApiKeys = (apiKeys: { [key: number]: ApiKey }) => {
    dispatch({ type: 'SAVE_API_KEYS', payload: apiKeys });
  };

  const contextValue = useMemo(() => {
    return {
      apiKeys: state.apiKeys,
      saveApiKeys,
    };
  }, [state.apiKeys]);

  return (
    <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
  );
};

export default ApiProvider;
