import React, { useState, useEffect } from 'react';

import { Table } from 'ka-table';
import { DataType, EditingMode, SortingMode } from 'ka-table/enums';

import RoleTag from 'components/interface/roleTag';
import UserAvatar from 'components/interface/userAvatar';
import EmptyState from 'components/interface/emptyState';
import InviteButton from 'features/dashboard/features/organization/components/inviteButton';
import MemberActions from '../components/memberActions';
import SettingsFields from 'components/interface/settingsFields';

import MemberDetailsView from './MemberDetailsView';

import { useOrganization } from 'features/organization/hooks/useOrganization';

import 'ka-table/style.css';

const MembersOrganizationView: React.FC = () => {
  const { organizationData } = useOrganization();
  const [openMember, setOpenMember] = useState<string | null>(null);

  useEffect(() => {
    if (openMember && !organizationData.members?.[openMember]) {
      setOpenMember(null);
    }
  }, [organizationData]);

  const MemberTable = () => {
    const organzationMembers: { [key: string]: Object }[] = [];
    Object.keys(organizationData.members).forEach((key: string) => {
      organzationMembers.push({
        avatar: organizationData.members[key],
        name: organizationData.members[key].displayName,
        email: organizationData.members[key].email,
        role: organizationData.members[key].role,
        actions: organizationData.members[key],
      });
    });

    return (
      <>
        <Table
          columns={[
            {
              key: 'avatar',
              title: '',
              dataType: DataType.Object,
              width: 68,
              style: { paddingBottom: 12 },
            },
            {
              key: 'name',
              title: 'Nombre',
              dataType: DataType.String,
            },
            {
              key: 'email',
              title: 'Email',
              dataType: DataType.String,
            },
            {
              key: 'role',
              title: 'Rol',
              dataType: DataType.String,
              width: 120,
            },
            {
              key: 'actions',
              title: '',
              dataType: DataType.Object,
              width: 80,
            },
          ]}
          data={organzationMembers}
          rowKeyField={'email'}
          editingMode={EditingMode.None}
          sortingMode={SortingMode.Single}
          childComponents={{
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case 'avatar':
                    return <UserAvatar organizationMember={props.value} />;
                  case 'role':
                    return (
                      <RoleTag
                        text={organizationData.roles[props.value].name}
                        color={
                          organizationData.roles[props.value].isSystem
                            ? 'var(--primary)'
                            : 'var(--gray)'
                        }
                        backgroundColor={
                          organizationData.roles[props.value].isSystem
                            ? 'var(--primary-pale)'
                            : 'var(--gray-lighter)'
                        }
                      />
                    );
                  case 'actions':
                    return (
                      <MemberActions
                        callback={setOpenMember}
                        {...props.value}
                      />
                    );
                }
              },
            },
          }}
        />
        {organzationMembers.length === 0 && (
          <EmptyState
            text={'No hay miembros en tu organización'}
            iconName={'users'}
          />
        )}
      </>
    );
  };

  const fields = {
    members: {
      label: 'Miembros',
      description:
        'Invita a tus colaboradores y asigna roles predeterminados o personalizados. Para más detalles, visita la pestaña "Roles".',
      options: [<InviteButton />],
      component: <MemberTable />,
    },
  };

  return (
    <>
      {openMember && organizationData.members[openMember] && (
        <MemberDetailsView
          member={organizationData.members[openMember]}
          goBack={() => setOpenMember(null)}
        />
      )}
      {!openMember && <SettingsFields fields={fields} />}
    </>
  );
};

export default MembersOrganizationView;
