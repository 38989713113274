import React from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';

import { IconName } from 'components/interface/icon';

import './styles.css';

type PageTitleProps = {
  title: string;
  iconName?: IconName;
  button?: React.ReactNode;
};

const PageTitle: React.FC<PageTitleProps> = ({ title, iconName, button }) => {
  return (
    <div className="page-title">
      <div>
        {iconName && <Icon name={iconName} size={24} />}
        <Text variant={'h5'}>{title}</Text>
      </div>
      {button}
    </div>
  );
};

export default PageTitle;
