import React from 'react';

import Text from 'components/interface/text';

import { type CallStatus } from 'features/calls/types';

import { callStatusTranslation } from 'features/calls/translations';

import './styles.css';

type CampaignCallStatusProps = {
  status: CallStatus;
};

/**
 * CampaignCallStatus
 * @description Component that displays the status of a call
 * @param {string} status - Status of the call
 * @returns {TSX.Element} CampaignCallStatus component
 */

const CampaignCallStatus: React.FC<CampaignCallStatusProps> = ({ status }) => {
  return (
    <div className="campaign-call-status">
      <div
        style={{
          backgroundColor: `var(--${status})`,
          borderColor: `var(--${status}-pale)`,
        }}
      />
      <Text variant={'b3'} color={'var(--gray)'} numberOfLines={1}>
        {callStatusTranslation[status]}
      </Text>
    </div>
  );
};

export default CampaignCallStatus;
