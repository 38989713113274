import React from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import './styles.css';

/**
 * ConversationEmpty
 * @description Component to display empty state
 * @returns {TSX.Element} ConversationEmpty component
 */

const ConversationEmpty: React.FC = () => {
  return (
    <div className={'inbox-conversation-empty'}>
      <Icon name={'chats'} size={24} color={'var(--gray-2)'} />
      <Text variant={'b3'} color={'var(--gray-2)'}>
        Selecciona una conversación
      </Text>
    </div>
  );
};

export default ConversationEmpty;
