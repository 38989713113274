import axios, { AxiosInstance, AxiosError } from 'axios';

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_WEST_BASE_URL,
  timeout: 32000,
});

const requestQueue: any = [];
let isRefreshing: boolean = false;
let refreshTokenPromise: any = null;

api.interceptors.request.use(
  (config) => {
    if (!config.headers['No-Auth']) {
      const accessToken: string | null = localStorage.getItem('accessToken');
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    delete config.headers['No-Auth'];
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        const refreshToken = localStorage.getItem('refreshToken');
        refreshTokenPromise = await axios
          .post(`${process.env.REACT_APP_WEST_BASE_URL}/token/refresh/`, {
            refresh: refreshToken,
          })
          .then((response) => {
            const { access, refresh } = response.data;
            localStorage.setItem('accessToken', access);
            localStorage.setItem('refreshToken', refresh);
            requestQueue.forEach((callback: any) => callback(access));
            return access;
          })
          .catch((error) => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            window.location.href = '/auth/login';
            return Promise.reject(error);
          })
          .finally(() => {
            requestQueue.length = 0;
            isRefreshing = false;
          });
      }

      await refreshTokenPromise;

      if (refreshTokenPromise) {
        const newAccessToken = await refreshTokenPromise;
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      }
    }

    return Promise.reject(error);
  },
);

export default api;
