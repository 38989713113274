import React, { useState, useEffect } from 'react';

import AssigneeCard from '../assingeeCard';

import { type ChatbotAssignee } from 'features/chatbots/providers/ChatbotProvider';

import './styles.css';

type AssigneeCardsProps = {
  assignees: ChatbotAssignee[];
  eligableAssigneesIds?: string[];
  canEdit?: boolean;
  setEligibleAssigneesIds: (assigneesIds: string[]) => void;
};

const AssigneeCards: React.FC<AssigneeCardsProps> = ({
  assignees,
  eligableAssigneesIds = [],
  canEdit = false,
  setEligibleAssigneesIds = () => {},
}) => {
  return (
    <div className={'assignee-cards'}>
      {assignees.map((assignee) => (
        <AssigneeCard
          key={assignee.userId}
          userId={assignee.userId}
          isEligible={eligableAssigneesIds.includes(assignee.userId)}
          canEdit={canEdit}
          onChange={(e) => {
            if (!canEdit) return;
            if (e.target.checked) {
              if (!eligableAssigneesIds.includes(assignee.userId)) {
                setEligibleAssigneesIds([
                  ...eligableAssigneesIds,
                  assignee.userId,
                ]);
              }
            } else {
              setEligibleAssigneesIds(
                eligableAssigneesIds.filter((id) => id !== assignee.userId),
              );
            }
          }}
        />
      ))}
    </div>
  );
};

export default AssigneeCards;
