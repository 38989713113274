import React from 'react';

import Text from 'components/interface/text';

import './styles.css';

/**
 * InputApi
 * @description Component that displays the input disabled section for inbox
 * @returns {TSX.Element} InputApi component
 */

const InputApi: React.FC = () => {
  return (
    <div className={'inbox-disabled'}>
      <Text variant={'b3'} color={'var(--gray)'} align={'center'}>
        Actualmente las interacciones desde el inbox con usuarios por medio del
        API no están habilitadas.
      </Text>
    </div>
  );
};

export default InputApi;
