import React, { useState, forwardRef, InputHTMLAttributes } from 'react';

import './styles.css';

interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: 'primary' | 'expressive';
  isDisabled?: boolean;
  isRequired?: any;
  defaultChecked?: boolean;
  checkedLabel?: string;
  uncheckedLabel?: string;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Toggle Input
 * @description Cutom input component
 * @param {String} variant - Toggle input variant
 * @param {Boolean} isDisabled - Toggle input disabled
 * @param {Boolean} isRequired - Toggle input required
 * @param {String} defaultChecked - Toggle input default value
 * @param {String} checkedLabel - Toggle input checked label
 * @param {String} uncheckedLabel - Toggle input unchecked label
 * @param {String} error - Toggle input error
 * @param {Function} onChange - Toggle input onChange function
 * @returns {TSX.Element} Toggle input component
 */

const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  (
    {
      variant = 'primary',
      isDisabled = false,
      isRequired = false,
      defaultChecked = false,
      checkedLabel,
      uncheckedLabel,
      error,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [isChecked, setIsChecked] = useState<boolean>(defaultChecked);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.target.checked);
      if (onChange) {
        onChange(e);
      }
    };

    return (
      <div className={`checkbox checkbox-${variant}`}>
        <input
          ref={ref}
          type={'checkbox'}
          className={`toggle ${error ? ' toggle-error' : ''}`}
          disabled={isDisabled}
          required={isRequired}
          defaultChecked={defaultChecked}
          onChange={handleChange}
          {...props}
        />
        <label className="toggle-label">
          {isChecked
            ? checkedLabel && (
                <span className="toggle-label">{checkedLabel}</span>
              )
            : uncheckedLabel && (
                <span className="toggle-label">{uncheckedLabel}</span>
              )}
        </label>
        {error && <span className="input-error-message">{error}</span>}
      </div>
    );
  },
);

export default Toggle;
