import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Input from 'components/interface/input';
import Button from 'components/interface/button';

import { useApi } from 'features/api/hooks/useApi';

type Inputs = {
  name: string;
};

type CreateApiKeyFormProps = {
  submitCallback?: (key: string) => void;
};

const CreateApiKeyForm: React.FC<CreateApiKeyFormProps> = ({
  submitCallback = () => {},
}) => {
  const { createApiKey } = useApi();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await createApiKey(data.name);

    if (response?.errors) {
      if (response.errors?.name) {
        setError('name', {
          type: 'manual',
          message: response.errors?.name,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al crear la llave');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Llave creada correctamente');
        reset();
        submitCallback(response?.data?.secretKey);
      } else {
        toast.error('Error al crear la llave');
        reset();
      }
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: -8,
        }}
      >
        <Input
          placeholder={'Nombre de la llave'}
          error={errors?.name?.message}
          {...register('name')}
        />
      </div>
      <Button
        type={'submit'}
        variant={'primary'}
        label={'Crear llave'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default CreateApiKeyForm;
