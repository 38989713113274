import React from 'react';

import Button from 'components/interface/button';
import Image from 'components/interface/image';

import { useAuth } from 'features/auth/hooks/useAuth';

import google from 'assets/images/integrations/google.png';

/**
 * GoogleAuthButton component
 * @description Button to authenticate with Google
 * @returns {TSX.Element} GoogleAuthButton component
 */

const GoogleAuthButton: React.FC = () => {
  const { authGoogleRedirect } = useAuth();

  return (
    <Button
      label={'Continúa con Google'}
      icon={<Image src={google} width={16} />}
      variant={'secondary'}
      onClick={authGoogleRedirect}
    />
  );
};

export default GoogleAuthButton;
