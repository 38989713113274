import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Input from 'components/interface/input';
import Button from 'components/interface/button';
import IconTag from 'components/interface/iconTag';
import PhoneFormInput from 'components/interface/phoneFormInput';
import SettingsFields from 'components/interface/settingsFields';

import VerifyPhoneNumberModal from '../components/verifyPhoneNumberModal';

import { useUser } from 'features/user/hooks/useUser';

type Inputs = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

const AccountForm: React.FC = () => {
  const { userDisplayData, updateUserData, resendPhoneNumberVerificationCode } =
    useUser();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [isVerifyPhoneNumberModalOpen, setIsVerifyPhoneNumberModalOpen] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await updateUserData(
      data.firstName,
      data.lastName,
      data.phoneNumber,
    );

    if (response?.errors) {
      if (response.errors?.firstName) {
        setError('firstName', {
          type: 'manual',
          message: response.errors?.firstName,
        });
      }
      if (response.errors?.lastName) {
        setError('lastName', {
          type: 'manual',
          message: response.errors?.lastName,
        });
      }
      if (response.errors?.phoneNumber) {
        setError('phoneNumber', {
          type: 'manual',
          message: response.errors?.phoneNumber,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar los parametros');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Información actualizada');
        if (response?.data?.requiresPhoneNumberVerification)
          setIsVerifyPhoneNumberModalOpen(true);
      } else {
        toast.error('Error al actualizar los datos');
        reset();
      }
    }
    setIsLoading(false);
  };

  const fields = {
    firstName: {
      label: 'Nombre',
      description: 'Nombre(s) para mostrar en la plataforma.',
      component: (
        <Input
          defaultValue={userDisplayData?.firstName}
          placeholder={'Primer nombre'}
          error={errors?.firstName?.message}
          {...register('firstName')}
        />
      ),
    },
    lastName: {
      label: 'Apellido',
      description: 'Apellido(s) para mostrar en la plataforma.',
      component: (
        <Input
          defaultValue={userDisplayData?.lastName}
          placeholder={'Apellido'}
          error={errors?.lastName?.message}
          {...register('lastName')}
        />
      ),
    },
    email: {
      label: 'Correo electrónico',
      description:
        'Correo electrónico asociado a la cuenta, aquí se enviarán las notificaciones.',
      component: (
        <Input
          defaultValue={userDisplayData?.email}
          placeholder={'Correo'}
          type={'email'}
          isDisabled={true}
        />
      ),
    },
    phoneNumber: {
      label: 'Número de teléfono',
      labelExtra:
        userDisplayData?.phoneNumber.length > 0 ? (
          userDisplayData?.phoneNumberVerified ? (
            <IconTag
              text={'Verificado'}
              iconName={'sealCheck'}
              color={'var(--tag-green)'}
              backgroundColor={'var(--tag-green-pale)'}
            />
          ) : (
            <IconTag
              text={'No verificado'}
              iconName={'sealWarning'}
              color={'var(--tag-yellow)'}
              backgroundColor={'var(--tag-yellow-pale)'}
            />
          )
        ) : (
          <></>
        ),
      description:
        'Número de teléfono asociado a la cuenta. Se debera verificar por WhatsApp, aquí se enviarán las notificaciones.',
      options: [
        userDisplayData?.phoneNumber.length > 0 &&
          !userDisplayData?.phoneNumberVerified && (
            <Button
              label={'Verificar número'}
              variant={'secondary'}
              onClick={() => {
                resendPhoneNumberVerificationCode();
                setIsVerifyPhoneNumberModalOpen(true);
              }}
            />
          ),
        ,
      ],
      component: (
        <PhoneFormInput
          name={'phoneNumber'}
          control={control}
          defaultValue={userDisplayData?.phoneNumber}
          placeholder={'Número de teléfono'}
          isRequired={false}
          error={errors?.phoneNumber?.message}
        />
      ),
    },
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SettingsFields fields={fields} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
          <Button
            label={'Cancelar'}
            variant={'secondary'}
            onClick={() => reset()}
          />
          <Button
            type={'submit'}
            label={'Guardar'}
            variant={'primary'}
            isLoading={isLoading}
          />
        </div>
      </form>
      <VerifyPhoneNumberModal
        isOpen={isVerifyPhoneNumberModalOpen}
        setIsOpen={setIsVerifyPhoneNumberModalOpen}
      />
    </>
  );
};

export default AccountForm;
