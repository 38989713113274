import { type AuthState } from './AuthProvider';

export type AuthTypes =
  | 'SAVE_ACCESS_TOKEN'
  | 'SAVE_REFRESH_TOKEN'
  | 'SAVE_IS_AUTHENTICATED';

type AuthAction = {
  type: AuthTypes;
  payload?: any;
};

const authReducer = (state: AuthState, action: AuthAction) => {
  const { type, payload } = action;

  switch (type) {
    case 'SAVE_ACCESS_TOKEN':
      payload === null
        ? localStorage.removeItem('accessToken')
        : localStorage.setItem('accessToken', payload);
      return {
        ...state,
        accessToken: payload,
      };
    case 'SAVE_REFRESH_TOKEN':
      payload === null
        ? localStorage.removeItem('refreshToken')
        : localStorage.setItem('refreshToken', payload);
      return {
        ...state,
        refreshToken: payload,
      };
    case 'SAVE_IS_AUTHENTICATED':
      return {
        ...state,
        isAuthenticated: payload,
      };
    default:
      return state;
  }
};

export default authReducer;
