import React, { forwardRef, SelectHTMLAttributes } from 'react';

import './styles.css';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  variant?: 'primary' | 'secondary';
  placeholder?: string;
  options: Array<{ value: string; label: string }>;
  selectedOption?: string | number;
  isDisabled?: boolean;
  isRequired?: boolean;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

/**
 * Select
 * @description Cutom Select component
 * @param {String} variant - Select variant
 * @param {String} placeholder - Select placeholder
 * @param {Array} options - Select options
 * @param {String} selectedOption - Select default value
 * @param {Boolean} isDisabled - Select disabled
 * @param {Boolean} isRequired - Select required
 * @param {Function} onChange - Select onChange
 * @param {String} error - Select error
 * @returns {TSX.Element} Select component
 */

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      variant = 'primary',
      placeholder = '',
      options,
      selectedOption = '',
      isDisabled = false,
      isRequired = false,
      error,
      onChange,
      ...props
    },
    ref,
  ) => {
    return (
      <>
        <select
          ref={ref}
          className={`select select-${variant}${error ? ' select-error' : ''}`}
          defaultValue={selectedOption}
          disabled={isDisabled}
          required={isRequired}
          onChange={onChange}
          {...props}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {error && <span className="select-error-message">{error}</span>}
      </>
    );
  },
);

export default Select;
