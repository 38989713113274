import React from 'react';

import DetailsEndUser from '../detailsEndUser';
import RelatedThread from '../detailsRelatedThreads';
import SidebarExpandable from '../sidebarExpandable';
import DetailsInfo from '../detailsInfo';
import DetailsTags from '../detailsTags';
import DetailsLists from '../detailsLists';
import DetailsCampaigns from '../detailsCampaigns';
import DetailsDetails from '../detailsDetails';
import DetailsAssignees from '../detailsAssignees';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

/**
 * InboxDetails
 * @description Container for the end user's info
 * @returns {TSX.Element} InboxDetails component
 */

const InboxDetails: React.FC = () => {
  const { inboxPreferences } = useInbox();

  return (
    <div
      className={'inbox-details'}
      style={{ maxWidth: inboxPreferences.detailsExpanded ? 240 : 0 }}
    >
      <DetailsEndUser />
      <RelatedThread />
      <SidebarExpandable
        title={'Asignado a'}
        elements={[<DetailsAssignees />]}
      />
      <SidebarExpandable title={'Campañas'} elements={[<DetailsCampaigns />]} />
      <SidebarExpandable title={'Etiquetas'} elements={[<DetailsTags />]} />
      <SidebarExpandable title={'Listas'} elements={[<DetailsLists />]} />
      <SidebarExpandable title={'Contexto'} elements={[<DetailsInfo />]} />
      <SidebarExpandable title={'Detalles'} elements={[<DetailsDetails />]} />
    </div>
  );
};

export default InboxDetails;
