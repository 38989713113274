import React, { memo } from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';

import { IconName } from 'components/interface/icon';

type NavigatorLinkProps = {
  name: string;
  icon: IconName;
  active?: boolean;
  isExpanded?: boolean;
  nNotifications?: number;
  callback?: any;
};

const NavigatorLink: React.FC<NavigatorLinkProps> = ({
  name,
  icon,
  active,
  isExpanded,
  nNotifications = 0,
  callback = () => {},
}) => {
  return (
    <div
      className={`navigator-link ${active && 'nl-active'}`}
      onClick={callback}
    >
      <Icon
        name={icon}
        size={18}
        color={active ? 'var(--body)' : 'var(--gray)'}
      />
      {isExpanded && (
        <Text
          variant={'h8'}
          weight={500}
          size={13}
          color={active ? 'var(--body)' : 'var(--gray)'}
          style={{ userSelect: 'none' }}
        >
          {name}
        </Text>
      )}
      {nNotifications > 0 && (
        <div
          className={`navigator-link-notification${
            !isExpanded ? '-small' : ''
          }`}
        >
          <Text variant={'b3'} size={10} color={'var(--white)'}>
            {nNotifications}
          </Text>
        </div>
      )}
      {!isExpanded && (
        <div className="navigator-link-hint">
          <Text variant={'h8'} color={'var(--white)'}>
            {name}
          </Text>
        </div>
      )}
    </div>
  );
};

export default memo(NavigatorLink);
