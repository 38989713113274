import { type InboxState } from './InboxProvider';

export type InboxTypes =
  | 'SAVE_INBOX'
  | 'SAVE_INBOX_FILTERS'
  | 'SAVE_INBOX_STATUS'
  | 'SAVE_INBOX_PREFERENCES'
  | 'SAVE_ACTIVE_INBOX'
  | 'SAVE_FETCHING_THREADS';

type InboxAction = {
  type: InboxTypes;
  payload?: any;
};

const inboxReducer = (state: InboxState, action: InboxAction) => {
  const { type, payload } = action;

  switch (type) {
    case 'SAVE_INBOX':
      return {
        ...state,
        inbox: payload,
      };
    case 'SAVE_INBOX_FILTERS':
      return {
        ...state,
        inboxFilters: payload,
      };
    case 'SAVE_INBOX_STATUS':
      return {
        ...state,
        inboxStatus: payload,
      };
    case 'SAVE_INBOX_PREFERENCES':
      return {
        ...state,
        inboxPreferences: payload,
      };
    case 'SAVE_FETCHING_THREADS':
      return {
        ...state,
        fetchingThreads: payload,
      };
    default:
      return state;
  }
};

export default inboxReducer;
