import api from 'api';

/**
 * fetchEndUsersData
 * @description Function that gets endUsers data
 * @returns {Object} { status, data: {endUsersData} } | { status, ...errors }
 */

export const fetchEndUsersData = async (
  pageIndex: number,
  pageSize: number,
  sortBy: string,
  sortOrder: 'asc' | 'desc',
  searchTerm?: string,
) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/endusers/?pageIndex=${pageIndex}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * fetchEndUserLists
 * @description Function that gets endUser lists
 * @returns {Object} { status, data: {endUserLists} } | { status, ...errors }
 */

export const fetchEndUserLists = async () => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/endusers/lists/`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postCreateEndUserList
 * @description Function that creates an endUser list
 * @returns {Object} { status, success: boolean } | { status, ...errors }
 */
export const postCreateEndUserList = async (
  name: string,
  description: string,
  color: string,
) => {
  try {
    const response = await api.post(
      `${process.env.REACT_APP_WEST_BASE_URL}/endusers/lists/`,
      {
        name,
        description,
        color,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putUpdateEndUserList
 * @description Function that updates an endUser list
 * @returns {Object} { status, success: boolean } | { status, ...errors }
 */
export const putUpdateEndUserList = async (
  id: string,
  name: string,
  description: string,
  color: string,
) => {
  try {
    const response = await api.put(
      `${process.env.REACT_APP_WEST_BASE_URL}/endusers/lists/`,
      {
        id,
        name,
        description,
        color,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * deleteEndUserList
 * @description Function that deletes an endUser list
 * @returns {Object} { status, success: boolean } | { status, ...errors }
 */
export const deleteEndUserList = async (id: string) => {
  try {
    const response = await api.delete(
      `${process.env.REACT_APP_WEST_BASE_URL}/endusers/lists/?id=${id}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postAddEndUserToList
 * @description Function that adds an endUser to a list
 * @returns {Object} { status, data: {endUserLists} } | { status, ...errors }
 */

export const postAddEndUserToList = async (
  endUserListId: string,
  endUserId: string,
) => {
  try {
    const response = await api.post(
      `${process.env.REACT_APP_WEST_BASE_URL}/endusers/lists/users/`,
      {
        endUserListId,
        endUserId,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postRemoveEndUserFromList
 * @description Function that removes an endUser from a list
 * @returns {Object} { status, data: {endUserLists} } | { status, ...errors }
 */

export const postRemoveEndUserFromList = async (
  endUserListId: string,
  endUserId: string,
) => {
  try {
    const response = await api.delete(
      `${process.env.REACT_APP_WEST_BASE_URL}/endusers/lists/users/?endUserListId=${endUserListId}&endUserId=${endUserId}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};
