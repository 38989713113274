import React, { useState, useEffect, useRef } from 'react';

import Tag from 'components/interface/tag';

import { type TagColor } from 'components/interface/tag';
import { type IconName } from 'components/interface/icon';

import './styles.css';

export type TagInputTag = {
  id: string;
  name: string;
  color: string;
  onClick: () => void;
};

type TagInputProps = {
  inputPlaceholder?: string;
  iconName?: IconName;
  availableTags: TagInputTag[];
  selectedTags: TagInputTag[];
  onTagAdd: (tag: TagInputTag) => void;
};

/**
 * TagInput
 * @description Input field to assign/remove tags from a list
 * @param {string} inputPlaceholder - Placeholder for the input field
 * @param {IconName} iconName - Name of the icon to display
 * @param {TagInputTag[]} availableTags - List of available tags to assign
 * @param {TagInputTag[]} selectedTags - List of selected tags
 * @param {function} onTagAdd - Function to execute when a tag is added
 * @returns {TSX.Element} TagInput component
 */

const TagInput: React.FC<TagInputProps> = ({
  inputPlaceholder = 'Añadir etiqueta...',
  iconName = 'tag',
  availableTags,
  selectedTags,
  onTagAdd = () => {},
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [suggestedTags, setSuggestedTags] = useState<TagInputTag[]>(
    availableTags.filter((tag) => !selectedTags.includes(tag)),
  );

  useEffect(() => {
    if (inputValue.length > 0) {
      const suggestedTags = availableTags.filter(
        (tag) =>
          tag.name.toLowerCase().includes(inputValue.toLowerCase()) &&
          !selectedTags.some((selectedTag) => selectedTag.id === tag.id),
      );
      setSuggestedTags(suggestedTags);
    } else {
      setSuggestedTags(
        availableTags.filter(
          (tag) =>
            !selectedTags.some((selectedTag) => selectedTag.id === tag.id),
        ),
      );
    }
  }, [inputValue, availableTags, selectedTags]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setInputFocused(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (suggestedTags.length > 0) {
        onTagAdd(suggestedTags[0]);
        setInputValue('');
        setSuggestedTags([]);
      }
    }
  };

  const handleTagClick = (tag: TagInputTag) => {
    onTagAdd(tag);
    setInputValue('');
    setSuggestedTags([]);
  };

  const handleFocus = () => {
    setInputFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setInputFocused(false);
    }, 200);
  };

  const handleContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    inputRef.current?.focus();
  };

  return (
    <div className={'tag-input-container'}>
      <div className={'tag-input'} onClick={handleContainerClick}>
        {selectedTags.map((tag) => (
          <Tag
            key={tag.id}
            text={tag.name}
            iconName={iconName}
            color={tag.color as TagColor}
            isClickable={true}
            onClick={() => {
              tag.onClick();
            }}
          />
        ))}
        <input
          ref={inputRef}
          className={'tag-input-add'}
          type={'text'}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={inputPlaceholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
      {inputFocused && (
        <div className={'tag-input-suggestions'}>
          {suggestedTags.length > 0 ? (
            suggestedTags.map((tag) => (
              <Tag
                key={tag.id}
                text={tag.name}
                iconName={iconName}
                altIconName={'plus'}
                color={tag.color as TagColor}
                isClickable={true}
                onClick={() => {
                  handleTagClick(tag);
                }}
              />
            ))
          ) : (
            <Tag
              text={'Sin resultados'}
              iconName={'question'}
              color={'white'}
              isClickable={false}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TagInput;
