import React, { useState } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Modal from 'components/interface/modal';
import Button from 'components/interface/button';

import CreateRoleForm from 'features/dashboard/features/organization/forms/createRoleForm';

import { useUser } from 'features/user/hooks/useUser';

const RoleCreateButton: React.FC = () => {
  const { userHasPermission } = useUser();
  const [isOpen, setIsOpen] = useState(false);

  if (!userHasPermission('organization.update')) return null;

  return (
    <>
      <Button
        variant={'secondary'}
        label={'Crear nuevo rol'}
        icon={<Icon name={'userPlus'} size={18} />}
        onClick={() => setIsOpen(true)}
      />
      <Modal
        title={'Nuevo rol'}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Text variant={'b2'}>
          Crea un rol personalizado para tu organización
        </Text>
        <CreateRoleForm submitCallback={() => setIsOpen(false)} />
      </Modal>
    </>
  );
};

export default RoleCreateButton;
