import React, { useState } from 'react';

import PageTitle from 'features/dashboard/components/pageTitle';
import TabMenu from 'components/interface/tabMenu';
import Contacts from 'features/dashboard/features/contacts/components/contacts';
import ContactLists from '../features/contacts/components/contactLists';

import { useUser } from 'features/user/hooks/useUser';

import { type IconName } from 'components/interface/icon';

const ChatbotsView: React.FC = () => {
  const { userHasPermission } = useUser();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const tabs = {
    contacts: {
      label: 'Directorio de Contactos',
      iconName: 'addressBookTabs' as IconName,
      component: <Contacts canUpdate={true} />,
    },
    lists: {
      label: 'Listas de Contactos',
      iconName: 'userList' as IconName,
      component: <ContactLists canUpdate={true} />,
    },
  };

  return (
    <div className={'dashboard-page'}>
      <PageTitle
        title={'Contactos'}
        iconName={'addressBookTabs'}
        button={
          <>
            {userHasPermission('bots.all.create') && (
              // <div style={{ marginTop: -16 }}>
              //   <Button
              //     variant={'primary'}
              //     label={'Agregar Contacto'}
              //     icon={<Icon name={'plus'} size={16} color={'var(--white)'} />}
              //     onClick={() => setIsModalOpen(true)}
              //   />
              // </div>
              <></>
            )}
          </>
        }
      />

      <TabMenu tabs={tabs} />

      {userHasPermission('bots.all.create') && (
        // TODO: Implement CreateContactForm
        // <Modal
        //   isOpen={isModalOpen}
        //   title={'Nuevo Asistente'}
        //   onClose={() => setIsModalOpen(false)}
        // >
        //   <Text variant={'b2'}>
        //     Crea un nuevo asistente virtual para tu empresa, por medio de este
        //     podrás atender a tus clientes de una manera más rápida y eficaz.
        //   </Text>
        //   <CreateChatbotForm submitCallback={() => setIsModalOpen(false)} />
        // </Modal>
        <></>
      )}
    </div>
  );
};

export default ChatbotsView;
