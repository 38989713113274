import React, { useState, useEffect } from 'react';

import Text from 'components/interface/text';

import SendTemplateModal from 'features/inbox/components/sendTemplateModal';

import { useInbox } from 'features/inbox/hooks/useInbox';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import './styles.css';

/**
 * InputPhoneCall
 * @description Component that displays the send template section for inbox
 * @returns {TSX.Element} InputPhoneCall component
 */

const InputPhoneCall: React.FC = () => {
  const { inbox } = useInbox();
  const { chatbotsData } = useChatbot();

  const [whatsappIntegrationId, setWhatsappIntegrationId] = useState<
    string | null
  >(null);
  const [isSendTemplateModalOpen, setIsSendTemplateModalOpen] = useState(false);

  useEffect(() => {
    if (inbox.activeThread) {
      const chatbotId = inbox.activeThread.chatbotId;
      const whatsappIntegrations = chatbotsData[chatbotId].whatsappIntegrations;
      setWhatsappIntegrationId(
        Object.values(whatsappIntegrations)[0]?.id ?? null,
      );
    }
  }, [inbox.activeThread?.id, chatbotsData]);

  return (
    <>
      <div
        className={'inbox-send-template'}
        onClick={
          whatsappIntegrationId != null
            ? () => setIsSendTemplateModalOpen(true)
            : () => {}
        }
      >
        {whatsappIntegrationId != null ? (
          <Text variant={'b3'} color={'var(--gray)'} align={'center'}>
            Continuar conversación por WhatsApp
          </Text>
        ) : (
          <Text variant={'b3'} color={'var(--gray)'} align={'center'}>
            Integra este asistente con WhatsApp para continuar la conversación
          </Text>
        )}
      </div>
      {whatsappIntegrationId && (
        <SendTemplateModal
          chatbotId={inbox.activeThread?.chatbotId}
          integrationId={whatsappIntegrationId}
          recipient={inbox.activeThread?.endUser?.phoneCall?.phoneNumber}
          endUserId={inbox.activeThread?.endUser?.id}
          isOpen={isSendTemplateModalOpen}
          onClose={() => setIsSendTemplateModalOpen(false)}
        />
      )}
    </>
  );
};

export default InputPhoneCall;
