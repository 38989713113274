import React, { useState, useEffect } from 'react';

import { ResponsiveBar } from '@nivo/bar';

import Text from 'components/interface/text';
import Button from 'components/interface/button';

import { type BillingCycle } from 'features/organization/providers/OrganizationProvider';

import { formatDateDashes } from 'utils/dates';

import './styles.css';

const customTooltip = (data: any) => {
  return (
    <div className={'plan-cycle-chart-tooltip'}>
      <Text variant={'b3'} weight={500}>
        {data.id === 'outboundMessages'
          ? 'Mensajes WhatsApp Outbound'
          : data.id === 'generatedMessages'
          ? 'Mensajes WhatsApp Generados IA'
          : data.id === 'outboundCallMinutes'
          ? 'Minutos de Llamada'
          : ''}
      </Text>
      <div>
        <Text variant={'b3'} formatter={'number'}>
          {data.value}
        </Text>
        <Text variant={'b3'} color={'var(--gray)'}>
          {data.indexValue}
        </Text>
      </div>
    </div>
  );
};

type PlanCardProps = {
  cycle: BillingCycle;
  canNext: boolean;
  canPrev: boolean;
  handleNext: () => void;
  handlePrev: () => void;
};

/**
 * PlanCard
 * @description Description of PlanCard
 * @param {BillingCycle} cycle - Billing cycle
 * @param {boolean} canNext - Can go to next cycle
 * @param {boolean} canPrev - Can go to previous cycle
 * @param {() => void} handleNext - Handle next cycle
 * @param {() => void} handlePrev - Handle previous cycle
 * @returns {TSX.Element} PlanCard component
 */

const PlanCard: React.FC<PlanCardProps> = ({
  cycle,
  canNext,
  canPrev,
  handleNext,
  handlePrev,
}) => {
  const [data, setData] = useState<
    {
      date: string;
      generatedMessages: number;
      outboundMessages: number;
      outboundCallMinutes: number;
    }[]
  >([]);

  useEffect(() => {
    if (!cycle?.days) return;

    const dayArray = [];

    const [year, month, day] = cycle.startCycleDate
      .split('-')
      .map((num) => parseInt(num, 10));
    const date = new Date(year, month - 1, day);

    const [endYear, endMonth, endDay] = cycle.nextCycleDate
      .split('-')
      .map((num) => parseInt(num, 10));
    const endDate = new Date(endYear, endMonth - 1, endDay);

    while (date <= endDate) {
      const dayString = formatDateDashes(String(date));
      dayArray.push(dayString);
      date.setDate(date.getDate() + 1);
    }

    const data = dayArray.map((day) => {
      const generatedMessageCount = cycle.days[day]?.generatedMessageCount || 0;
      const outboundMessageCount = cycle.days[day]?.outboundMessageCount || 0;
      const outboundCallMinutes = cycle.days[day]?.outboundCallMinutes || 0;

      return {
        date: day,
        generatedMessages: generatedMessageCount,
        outboundMessages: outboundMessageCount,
        outboundCallMinutes: outboundCallMinutes,
      };
    });

    setData(data);
  }, [cycle?.days]);

  return (
    <div className={'plan-cycle-chart'}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Text variant={'h7'}>{'Consumo diario de mensajes'}</Text>
          <div style={{ height: 4 }} />
          <Text variant={'b3'}>
            {
              'Visualiza el consumo de tu organización a través de el periodo seleccionado.'
            }
          </Text>
        </div>
        <div style={{ display: 'flex', gap: 8, marginTop: -16 }}>
          <Button
            label={'Anterior'}
            variant={'secondary'}
            size={'small'}
            isDisabled={!canPrev}
            onClick={handlePrev}
          />
          <Button
            label={'Siguiente'}
            variant={'secondary'}
            size={'small'}
            isDisabled={!canNext}
            onClick={handleNext}
          />
        </div>
      </div>
      <div style={{ height: 8 }} />
      <ResponsiveBar
        data={data}
        indexBy={'date'}
        keys={['outboundCallMinutes', 'outboundMessages', 'generatedMessages']}
        colors={['#0066FF', '#64a2ff', '#99c2ff']}
        padding={0.5}
        margin={{ top: 16, right: 16, bottom: 64, left: 32 }}
        enableLabel={false}
        tooltip={customTooltip}
        animate={true}
        motionConfig={'stiff'}
        axisBottom={{
          tickSize: 4,
          tickPadding: 4,
          tickRotation: -45,
          format: (value) => {
            return value.split('-')[1] + '/' + value.split('-')[2];
          },
        }}
      />
    </div>
  );
};

export default PlanCard;
