const UNICODE_ASCII_CHARACTER_SET =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const generateStateSessionToken = (
  length: number = 30,
  chars: string = UNICODE_ASCII_CHARACTER_SET,
): string => {
  let stateToken = '';
  const charsLength = chars.length;

  for (let i = 0; i < length; i++) {
    stateToken += chars.charAt(Math.floor(Math.random() * charsLength));
  }

  return stateToken;
};
