import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ResponsiveLine } from '@nivo/line';
import { Pie } from '@nivo/pie';

import Text from 'components/interface/text';
import Card from 'components/interface/card';

import { useOrganization } from 'features/organization/hooks/useOrganization';

import { type BillingCycle } from 'features/organization/providers/OrganizationProvider';

import { formatDateDashes } from 'utils/dates';

import './styles.css';

/**
 * UsageCard component
 * @description This component is used to display the usage information in the overview page
 * @returns {React.FunctionComponent}
 */

const UsageCard = () => {
  const navitage = useNavigate();
  const { organizationData, getBillingCycleDays } = useOrganization();

  const [cycle, setCycle] = useState<BillingCycle>();
  const [callMinutesData, setCallMinutesData] = useState<{
    id: string;
    data: { x: string; y: number }[];
  }>({
    id: 'Minutos de Llamada',
    data: [],
  });
  const [generatedMessagesData, setGeneratedDataMessages] = useState<{
    id: string;
    data: { x: string; y: number }[];
  }>({
    id: 'Mensajes Generados IA',
    data: [],
  });
  const [outboundMessagesData, setOutboundMessagesData] = useState<{
    id: string;
    data: { x: string; y: number }[];
  }>({
    id: 'Mensajes Outbound',
    data: [],
  });

  useEffect(() => {
    let auxCycles =
      Object.values(organizationData.billing?.billingCycles) ?? [];

    auxCycles = auxCycles.sort((a, b) => {
      return (
        new Date(a.nextCycleDate).getTime() -
        new Date(b.nextCycleDate).getTime()
      );
    });

    setCycle(auxCycles[auxCycles.length - 1]);
  }, []);

  useEffect(() => {
    const getCycleDays = async () => {
      if (!cycle) return;

      await getBillingCycleDays(organizationData?.id, cycle?.id);

      const dayArray = [];

      const [year, month, day] = cycle.startCycleDate
        .split('-')
        .map((num) => parseInt(num, 10));
      const date = new Date(year, month - 1, day);

      const [endYear, endMonth, endDay] = cycle.nextCycleDate
        .split('-')
        .map((num) => parseInt(num, 10));
      const endDate = new Date(endYear, endMonth - 1, endDay);

      while (date <= endDate) {
        const dayString = formatDateDashes(String(date));
        dayArray.push(dayString);
        date.setDate(date.getDate() + 1);
      }

      const currBillingCycleDays =
        organizationData.billing.billingCycles[cycle.id].days;

      const callMinutes = [];
      let nonEmptyRecords = 0;
      for (let i = 0; i < dayArray.length; i++) {
        const day = dayArray[i];

        if (nonEmptyRecords >= Object.keys(currBillingCycleDays).length) break;
        if (currBillingCycleDays[day]?.outboundCallMinutes) nonEmptyRecords++;

        callMinutes.push({
          x: day,
          y: currBillingCycleDays[day]?.outboundCallMinutes || 0,
        });
      }

      const generatedMessages = [];
      nonEmptyRecords = 0;
      for (let i = 0; i < dayArray.length; i++) {
        const day = dayArray[i];

        if (nonEmptyRecords >= Object.keys(currBillingCycleDays).length) break;
        if (currBillingCycleDays[day]?.generatedMessageCount) nonEmptyRecords++;

        generatedMessages.push({
          x: day,
          y: currBillingCycleDays[day]?.generatedMessageCount || 0,
        });
      }

      const outboundMessages = [];
      nonEmptyRecords = 0;
      for (let i = 0; i < dayArray.length; i++) {
        const day = dayArray[i];

        if (nonEmptyRecords >= Object.keys(currBillingCycleDays).length) break;
        if (currBillingCycleDays[day]?.outboundMessageCount) nonEmptyRecords++;

        outboundMessages.push({
          x: day,
          y: currBillingCycleDays[day]?.outboundMessageCount || 0,
        });
      }

      setCallMinutesData({
        ...callMinutesData,
        data: callMinutes,
      });
      setGeneratedDataMessages({
        ...generatedMessagesData,
        data: generatedMessages,
      });
      setOutboundMessagesData({
        ...outboundMessagesData,
        data: outboundMessages,
      });
    };

    getCycleDays();
  }, [cycle]);

  return (
    <Card
      title={'Consumo en periodo actual'}
      style={{
        flex: 1,
        alignSelf: 'stretch',
      }}
      onClick={() => navitage('/dashboard/usage')}
    >
      <div className={'overview-usage-card'}>
        <div className={'overview-usage-card-left'}>
          <div className={'overview-usage-card-left-item'}>
            <Pie
              animate={false}
              data={[
                {
                  id: 'callMinutes',
                  label: 'Utilizados',
                  value: cycle?.outboundCallMinutes || 0,
                },
                {
                  id: 'callMinutesLimit',
                  label: 'Disponibles',
                  value:
                    organizationData.billing.outboundCallMinutesLimit -
                    (cycle?.outboundCallMinutes || 0),
                },
              ]}
              height={32}
              width={32}
              innerRadius={0.6}
              colors={['#0066FF', 'var(--gray-light)']}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              isInteractive={false}
            />
            <div>
              <Text variant={'b3'}>Minutos de Llamada</Text>
              <Text variant={'h7'}>
                {(
                  ((cycle?.outboundCallMinutes ?? 0) /
                    organizationData.billing.outboundCallMinutesLimit) *
                  100
                ).toFixed(1)}
                %
              </Text>
            </div>
          </div>
          <div className={'overview-usage-card-left-item'}>
            <Pie
              animate={false}
              data={[
                {
                  id: 'generatedMessages',
                  label: 'Utilizados',
                  value: cycle?.outboundMessageCount || 0,
                },
                {
                  id: 'generatedMessagesLimit',
                  label: 'Disponibles',
                  value:
                    organizationData.billing.outboundMessageLimit -
                    (cycle?.outboundMessageCount || 0),
                },
              ]}
              height={32}
              width={32}
              innerRadius={0.6}
              colors={['#3ba33b', 'var(--gray-light)']}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              isInteractive={false}
            />
            <div>
              <Text variant={'b3'}>Mensajes WhatsApp Outbound</Text>
              <Text variant={'h7'}>
                {(
                  ((cycle?.outboundMessageCount ?? 0) /
                    organizationData.billing.outboundMessageLimit) *
                  100
                ).toFixed(1)}
                %
              </Text>
            </div>
          </div>
          <div className={'overview-usage-card-left-item'}>
            <Pie
              animate={false}
              data={[
                {
                  id: 'generatedMessages',
                  label: 'Utilizados',
                  value: cycle?.generatedMessageCount || 0,
                },
                {
                  id: 'generatedMessagesLimit',
                  label: 'Disponibles',
                  value:
                    organizationData.billing.generatedMessageLimit -
                    (cycle?.generatedMessageCount || 0),
                },
              ]}
              height={32}
              width={32}
              innerRadius={0.6}
              colors={['#9fd99f', 'var(--gray-light)']}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              isInteractive={false}
            />
            <div>
              <Text variant={'b3'}>Mensajes WhatsApp Generados IA</Text>
              <Text variant={'h7'}>
                {(
                  ((cycle?.generatedMessageCount ?? 0) /
                    organizationData.billing.generatedMessageLimit) *
                  100
                ).toFixed(1)}
                %
              </Text>
            </div>
          </div>
        </div>
        <div className={'overview-usage-card-right'}>
          <ResponsiveLine
            animate={true}
            curve={'monotoneX'}
            data={[
              callMinutesData,
              outboundMessagesData,
              generatedMessagesData,
            ]}
            defs={[
              {
                colors: [
                  {
                    color: 'inherit',
                    offset: 0,
                  },
                  {
                    color: 'inherit',
                    offset: 100,
                    opacity: 0,
                  },
                ],
                id: 'gradientA',
                type: 'linearGradient',
              },
            ]}
            enableArea
            enablePoints={false}
            enableGridX={false}
            enableGridY={false}
            colors={['#0066FF', '#3ba33b', '#9fd99f']}
            fill={[
              {
                id: 'gradientA',
                match: '*',
              },
            ]}
            margin={{
              bottom: 16,
              left: 4,
              right: 4,
              top: 16,
            }}
            axisLeft={null}
            axisBottom={{
              tickValues: 5,
              tickPadding: 16,
            }}
            yScale={{
              stacked: false,
              type: 'linear',
            }}
          />
        </div>
      </div>
    </Card>
  );
};

export default UsageCard;
