import React, { useState, useEffect } from 'react';

import Text from 'components/interface/text';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

/**
 * InboxThreadsUnreadFilter
 * @description Component to filter inbox threads by unread messages
 * @returns {TSX.Element} InboxThreadsUnreadFilter component
 */

const InboxThreadsUnreadFilter: React.FC = () => {
  const { filterSetShowUnreadOnly } = useInbox();

  const [onlyUnread, setOnlyUnread] = useState<boolean>(false);

  useEffect(() => {
    filterSetShowUnreadOnly(onlyUnread);
  }, [onlyUnread]);

  return (
    <div className={'inbox-threads-unread-filter'}>
      <div
        className={'inbox-threads-unread-filter-toggle'}
        style={onlyUnread ? { transform: 'translateX(100%)' } : {}}
      />
      <div onClick={() => setOnlyUnread(false)}>
        <Text variant={'b3'}>Todo</Text>
      </div>
      <div onClick={() => setOnlyUnread(true)}>
        <Text variant={'b3'}>Sin leer</Text>
      </div>
    </div>
  );
};

export default InboxThreadsUnreadFilter;
