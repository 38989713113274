import React from 'react';

import Hr from 'components/interface/hr';
import Text from 'components/interface/text';
import Icon from 'components/interface/icon';

import { IconName } from 'components/interface/icon';

import './styles.css';

export type HoverMenuAnchor =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

export type HoverMenuOption = {
  label: string;
  icon: IconName;
  onClick: () => void;
};

type HoverMenuProps = {
  isOpen?: boolean;
  options: HoverMenuOption[];
  anchor?: HoverMenuAnchor;
  bottomOption?: HoverMenuOption;
};

/**
 * HoverMenu
 * @description Custom hover menu component
 * @param {Boolean} isOpen - Hover menu open
 * @param {HoverMenuOption[]} options - Hover menu options
 * @param {HoverMenuOption} bottomOption - Hover menu bottom option
 * @param {HoverMenuAnchor} anchor - Hover menu anchor
 * @returns {TSX.Element} HoverMenu component
 */

const HoverMenu: React.FC<HoverMenuProps> = ({
  isOpen,
  options,
  anchor = 'top-left',
  bottomOption,
}) => {
  return (
    <>
      <div
        className={`hover-menu hover-menu-${anchor} ${
          isOpen ? 'hover-menu-fade-in visible' : 'hover-menu-fade-in'
        }`}
      >
        {options.map((option: HoverMenuOption, index: number) => {
          return (
            <div
              key={index}
              className="hover-menu-option"
              onClick={option.onClick}
            >
              <div className="hover-menu-option-icon">
                <Icon name={option.icon} size={16} color={'var(--gray)'} />
              </div>
              <Text
                variant={'h8'}
                color={'var(--gray)'}
                size={13}
                numberOfLines={1}
              >
                {option.label}
              </Text>
            </div>
          );
        })}
        {bottomOption ? (
          <>
            <Hr marginTop={0} marginBottom={0} />
            <div className="hover-menu-option" onClick={bottomOption.onClick}>
              <div className="hover-menu-option-icon">
                <Icon
                  name={bottomOption.icon}
                  size={16}
                  color={'var(--gray)'}
                />
              </div>
              <Text
                variant={'h8'}
                color={'var(--gray)'}
                size={13}
                numberOfLines={1}
              >
                {bottomOption.label}
              </Text>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default HoverMenu;
