import React from 'react';

import { useUser } from 'features/user/hooks/useUser';

import { hasPermission } from 'features/permissions/utils/permissionUtils';

type PermissionGateProps = {
  requiredPermissions: string[];
  children: React.ReactNode;
};

const PermissionGate: React.FC<PermissionGateProps> = ({
  requiredPermissions,
  children,
}) => {
  const { userPermissions } = useUser();

  const permissionGranted = requiredPermissions.every((permission) =>
    hasPermission(userPermissions, permission),
  );

  if (!permissionGranted) {
    return <></>;
  }

  return <>{children}</>;
};

export default PermissionGate;
