import React from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';

import { type IconName } from 'components/interface/icon';

import './styles.css';

type IconTagProps = {
  text: string;
  iconName: IconName;
  color?: string;
  backgroundColor?: string;
};

/**
 * IconTag
 * @description Tag component with icon to represent a status etc
 * @param {String} text - text to display
 * @param {String} iconName - icon name for the tag
 * @param {String} color - primary color for the icon and text
 * @param {String} backgroundColor - background color for the tag
 * @returns {TSX.Element} IconTag component
 */

const IconTag: React.FC<IconTagProps> = ({
  text,
  iconName,
  color = 'var(--gray)',
  backgroundColor = 'var(--gray-lighter)',
}) => {
  return (
    <div
      className={'icon-tag'}
      style={{
        backgroundColor,
      }}
    >
      <Icon name={iconName} color={color} size={14} style={'bold'} />
      <Text variant={'b3'} color={color}>
        {text}
      </Text>
    </div>
  );
};

export default IconTag;
