import React, { FC, createContext, useReducer, useMemo } from 'react';

import notificationReducer from './notificationReducer';

import { type Notification } from 'features/notifications/types/notificationTypes';

export type NotifiactionsState = {
  notifications: Notification[];
  saveNotifications: (notifications: Notification[]) => void;
};

type NotificationProviderProps = {
  children: React.ReactNode;
};

export const NotificationContext = createContext<NotifiactionsState>({
  notifications: [],
  saveNotifications: () => {},
});

const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
  const initialState: NotifiactionsState = {
    notifications: [],
    saveNotifications: (notifications: Notification[]) => {
      return null;
    },
  };

  const [state, dispatch] = useReducer(notificationReducer, initialState);

  const saveNotifications = (notifications: Notification[]) => {
    dispatch({ type: 'SAVE_NOTIFICATIONS_DATA', payload: notifications });
  };

  const contextValue = useMemo(() => {
    return {
      notifications: state.notifications,
      saveNotifications,
    };
  }, [state.notifications]);

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
