import React, { useState, useEffect } from 'react';

import Hr from 'components/interface/hr';
import Text from 'components/interface/text';
import Card from 'components/interface/card';

import {
  type BillingPlan,
  type BillingCycle,
} from 'features/organization/providers/OrganizationProvider';

import { formatDateLong } from 'utils/dates';
import { formatNumber } from 'utils/numbers';

import './styles.css';

type PlanUsageCardProps = {
  billing: BillingPlan;
  cycle: BillingCycle;
};

/**
 * PlanUsageCard
 * @description Description of PlanUsageCard
 * @param {BillingPlan} billing - Billing information
 * @param {BillingCycle} cycle - Billing cycle
 * @returns {TSX.Element} PlanUsageCard component
 */

const PlanUsageCard: React.FC<PlanUsageCardProps> = ({ billing, cycle }) => {
  const [callMinutesCompletion, setCallMinutesCompletion] = useState<number>(0);
  const [outboundCompletion, setOutboundCompletion] = useState<number>(0);
  const [generatedCompletion, setGeneratedCompletion] = useState<number>(0);

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  useEffect(() => {
    if (cycle) {
      const callMinutesCompletion =
        (cycle.outboundCallMinutes / billing.outboundCallMinutesLimit) * 100;
      const outboundCompletion =
        (cycle.outboundMessageCount / billing.outboundMessageLimit) * 100;
      const generatedCompletion =
        (cycle.generatedMessageCount / billing.generatedMessageLimit) * 100;

      setCallMinutesCompletion(callMinutesCompletion);
      setOutboundCompletion(outboundCompletion);
      setGeneratedCompletion(generatedCompletion);

      const [startYear, startMonth, startDay] = cycle.startCycleDate
        .split('-')
        .map((num) => parseInt(num, 10));
      setStartDate(
        new Date(startYear, startMonth - 1, startDay).toDateString(),
      );

      const [endYear, endMonth, endDay] = cycle.nextCycleDate
        .split('-')
        .map((num) => parseInt(num, 10));
      setEndDate(new Date(endYear, endMonth - 1, endDay).toDateString());
    }
  }, [cycle]);

  return (
    <div className={'plan-card-usage'}>
      <Card
        title={'Consumo en periodo'}
        subtitle={`${formatDateLong(startDate)} - ${formatDateLong(endDate)}`}
      >
        <div className={'plan-usage-card-item'}>
          <Text variant={'b2'} weight={400}>
            Minutos de Llamada
          </Text>
          <div className={'plan-usage-card__completion'}>
            <div
              style={{
                width: `${
                  callMinutesCompletion > 100 ? 100 : callMinutesCompletion
                }%`,
                backgroundColor:
                  callMinutesCompletion > 100
                    ? 'var(--error)'
                    : 'var(--primary)',
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Text variant={'b3'}>
                {formatNumber(cycle?.outboundCallMinutes, 0, 1)}
                {' / '}
                {formatNumber(billing.outboundCallMinutesLimit, 0, 1)}
              </Text>
            </div>
            <Text variant={'b3'}>{callMinutesCompletion.toFixed(0)}%</Text>
          </div>
        </div>
        <div style={{ height: 16 }} />
        <div className={'plan-usage-card-item'}>
          <Text variant={'b2'} weight={400}>
            Mensajes WhatsApp Outbound
          </Text>
          <div className={'plan-usage-card__completion'}>
            <div
              style={{
                width: `${
                  outboundCompletion > 100 ? 100 : outboundCompletion
                }%`,
                backgroundColor:
                  outboundCompletion > 100 ? 'var(--error)' : 'var(--primary)',
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Text variant={'b3'}>
                {formatNumber(cycle?.outboundMessageCount)}
                {' / '}
                {formatNumber(billing.outboundMessageLimit)}
              </Text>
            </div>
            <Text variant={'b3'}>{outboundCompletion.toFixed(0)}%</Text>
          </div>
        </div>
        <div style={{ height: 16 }} />
        <div className={'plan-usage-card-item'}>
          <Text variant={'b2'} weight={400}>
            Mensajes WhatsApp Generados IA
          </Text>
          <div className={'plan-usage-card__completion'}>
            <div
              style={{
                width: `${
                  generatedCompletion > 100 ? 100 : generatedCompletion
                }%`,
                backgroundColor:
                  generatedCompletion > 100 ? 'var(--error)' : 'var(--primary)',
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Text variant={'b3'}>
                {formatNumber(cycle?.generatedMessageCount)}
                {' / '}
                {formatNumber(billing.generatedMessageLimit)}
              </Text>
            </div>
            <Text variant={'b3'}>{generatedCompletion.toFixed(0)}%</Text>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PlanUsageCard;
