import { waidToPhoneNumber, formatPhoneNumber } from './strings';

export const displayIntegrationName: any = {
  whatsapp: 'WhatsApp',
  phonecall: 'Phone Call',
  api: 'API',
};

export const displayIntegrationIcon: any = {
  whatsapp: {
    name: 'whatsapp',
    color: 'var(--integration-whatsapp)',
  },
  phonecall: {
    name: 'phone',
    color: 'var(--integration-phone-call)',
  },
  api: {
    name: 'code',
    color: 'var(--integration-api)',
  },
};

export const formatSocialId = (social: string, socialId: string) => {
  social = social.toLowerCase();
  switch (social) {
    case 'whatsapp':
      return waidToPhoneNumber(socialId);
    case 'phonecall':
      return formatPhoneNumber(socialId);
    default:
      return socialId.length > 16 ? socialId.slice(0, 16) + '...' : socialId;
  }
};
