import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Text from 'components/interface/text';
import Input from 'components/interface/input';
import Button from 'components/interface/button';

import { useUser } from 'features/user/hooks/useUser';

type Inputs = {
  currentPassword: string;
  newPassword: string;
  vNewPassword: string;
};

const PasswordChangeForm: React.FC = () => {
  const { updateUserPassword } = useUser();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await updateUserPassword(
      data.currentPassword,
      data.newPassword,
      data.vNewPassword,
    );

    if (response?.errors) {
      if (response.errors?.currentPassword) {
        setError('currentPassword', {
          type: 'manual',
          message: response.errors?.currentPassword,
        });
      }
      if (response.errors?.newPassword) {
        setError('newPassword', {
          type: 'manual',
          message: response.errors?.newPassword,
        });
      }
      if (response.errors?.vNewPassword) {
        setError('vNewPassword', {
          type: 'manual',
          message: response.errors?.vNewPassword,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar los parametros');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Datos actualizados');
      } else {
        toast.error('Error al actualizar los datos, verifica su contraseña');
      }
      reset();
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant={'label'} color={'body'}>
        Contraseña actual
      </Text>
      <Input
        type={'password'}
        placeholder={'Contraseña actual'}
        error={errors?.currentPassword?.message}
        {...register('currentPassword')}
      />
      <div style={{ height: 16 }}></div>
      <Text variant={'label'} color={'body'}>
        Nueva contraseña
      </Text>
      <Input
        type={'password'}
        placeholder={'Contraseña'}
        error={errors?.newPassword?.message}
        {...register('newPassword')}
      />
      <div style={{ height: 8 }}></div>
      <Input
        type={'password'}
        placeholder={'Repetir contraseña'}
        error={errors?.vNewPassword?.message}
        {...register('vNewPassword')}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
        <Button
          label={'Cancelar'}
          variant={'secondary'}
          onClick={() => reset()}
        />
        <Button type={'submit'} label={'Guardar'} isLoading={isLoading} />
      </div>
    </form>
  );
};

export default PasswordChangeForm;
