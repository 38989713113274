import React from 'react';

import IconButton from 'components/interface/iconButton';

import { IconName } from 'components/interface/icon';

type TagActionsProps = {
  id: string;
  callback?: (id: string) => void;
};

const TagActions: React.FC<TagActionsProps> = ({ id, callback }) => {
  const handleEdit = async () => {
    if (callback) callback(id);
  };

  return (
    <div style={{ display: 'flex', gap: 8 }}>
      <IconButton
        iconName={'gear' as IconName}
        hint={'Editar'}
        onClick={handleEdit}
      />
    </div>
  );
};

export default TagActions;
