import React, { useState } from 'react';

import PulseLoader from 'react-spinners/PulseLoader';

import Text from 'components/interface/text';
import Modal from 'components/interface/modal';

import Icon, { IconStyle } from 'components/interface/icon';

import { IconName } from 'components/interface/icon';

import './styles.css';

type IconButtonProps = {
  size?: number;
  iconName?: IconName | undefined;
  iconSize?: number;
  iconColor?: string;
  iconStyle?: IconStyle | undefined;
  isDisabled?: boolean;
  isLoading?: boolean;
  requiresConfirmation?: boolean;
  confirmationTitle?: string;
  confirmationText?: string;
  hint?: string;
  onClick?: (e: any) => void;
};

/**
 * IconButton
 * @description Custom icon button component
 * @param {Number} size - Icon button size
 * @param {IconName} iconName - Icon name
 * @param {Number} iconSize - Icon size
 * @param {String} iconColor - Icon color
 * @param {IconStyle} iconStyle - Icon variant
 * @param {Boolean} isDisabled - Icon button disabled
 * @param {Boolean} isLoading - Icon button loading
 * @param {Boolean} requiresConfirmation - Icon button requires confirmation
 * @param {String} confirmationTitle - Icon button confirmation title
 * @param {String} confirmationText - Icon button confirmation text
 * @param {String} hint - Icon button hint
 * @param {Function} onClick - Icon button onClick function
 * @returns {TSX.Element} IconButton component
 */

const IconButton: React.FC<IconButtonProps> = ({
  size = 40,
  iconName,
  iconSize,
  iconColor,
  iconStyle,
  isDisabled = false,
  isLoading = false,
  requiresConfirmation = false,
  confirmationTitle = '',
  confirmationText = '',
  hint,
  onClick = () => {},
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className={'button-icon-container'}>
        <button
          type={'button'}
          className={`button-icon`}
          style={{ width: size, height: size }}
          onClick={
            !isDisabled && !isLoading
              ? requiresConfirmation
                ? () => setIsConfirmModalOpen(true)
                : onClick
              : () => {}
          }
          disabled={isDisabled}
        >
          {isLoading ? (
            <PulseLoader size={6} color={'var(--gray)'} speedMultiplier={0.8} />
          ) : (
            <Icon
              name={iconName}
              color={isDisabled ? 'var(--border)' : iconColor}
              size={iconSize || 18}
              style={iconStyle}
            />
          )}
        </button>
        {hint && (
          <div className={'button-icon-hint'}>
            <Text variant={'h8'} color={'var(--white)'}>
              {isLoading ? 'Cargando...' : hint}
            </Text>
          </div>
        )}
      </div>
      {requiresConfirmation && (
        <Modal
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          title={confirmationTitle}
        >
          <Text variant={'b2'}>{confirmationText}</Text>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 8,
              marginTop: 8,
            }}
          >
            <button
              type={'button'}
              className={'button button-secondary'}
              onClick={() => setIsConfirmModalOpen(false)}
            >
              <Text variant={'b2'}>Cancelar</Text>
            </button>
            <button
              type={'button'}
              className={'button button-primary'}
              onClick={isLoading ? () => {} : onClick}
            >
              <Text variant={'b2'}>Confirmar acción</Text>
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default IconButton;
