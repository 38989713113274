import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { type UUID } from 'crypto';

import Modal from 'components/interface/modal';

import TemplateSelectStep from './templateSelectStep';
import TemplateVariablesStep from './templateVariablesStep';
import TemplateSendStep from './templateSendStep';

import { useInbox } from 'features/inbox/hooks/useInbox';

import {
  type Template,
  type TemplateVariable,
} from 'features/templates/types/templateTypes';

import './styles.css';

/**
 * SendTemplateModal
 * @description Modal component to send template message
 * @param {UUID} chatbotId - chatbot id
 * @param {number} integrationId - integration id
 * @param {string} recipient - recipient contact (phone number)
 * @param {UUID} endUserId - end user id
 * @param {boolean} isOpen - modal open state
 * @param {function} onClose - function to close modal
 * @returns {TSX.Element} SendTemplateModal component
 */

const STEP_TITLES = [
  'Selecciona una plantilla',
  'Configura las variables',
  'Vista previa',
];

type SendTemplateModalProps = {
  chatbotId?: UUID;
  integrationId?: string;
  recipient?: string;
  endUserId?: UUID;
  isOpen: boolean;
  onClose: () => void;
};

const SendTemplateModal: React.FC<SendTemplateModalProps> = ({
  chatbotId,
  integrationId,
  recipient,
  endUserId,
  isOpen,
  onClose,
}) => {
  const { inbox, sendTemplate } = useInbox();

  const [stepCount] = useState<number>(STEP_TITLES.length);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const [selectedChatbotId, setSelectedChatbotId] = useState<UUID>(
    chatbotId ?? (inbox.activeThread?.chatbotId as UUID),
  );
  const [selectedIntegrationId, setSelectedIntegrationId] = useState<string>(
    integrationId ?? (inbox.activeThread?.integrationId as string),
  );
  const [selectedRecipient, setSelectedRecipient] = useState<string>(
    recipient ?? (inbox.activeThread?.endUser?.whatsapp?.fId as string),
  );

  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const [selectedHeaderVariables, setSelectedHeaderVariables] = useState<
    TemplateVariable[]
  >([]);
  const [selectedBodyVariables, setSelectedBodyVariables] = useState<
    TemplateVariable[]
  >([]);

  const [variablesReady, setVariablesReady] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  useEffect(() => {
    setSelectedChatbotId(chatbotId ?? (inbox.activeThread?.chatbotId as UUID));
  }, [chatbotId, inbox.activeThread?.chatbotId]);

  useEffect(() => {
    setSelectedIntegrationId(
      integrationId ?? (inbox.activeThread?.integrationId as string),
    );
  }, [integrationId, inbox.activeThread?.integrationId]);

  useEffect(() => {
    setSelectedRecipient(
      recipient ?? (inbox.activeThread?.endUser?.whatsapp?.fId as string),
    );
  }, [recipient, inbox.activeThread?.endUser?.whatsapp?.fId]);

  useEffect(() => {
    let ready = true;
    selectedHeaderVariables.forEach((v) => {
      if (v.type === 'TEXT' && !v.text) ready = false;
      if (v.type === 'IMAGE' && !v.file) ready = false;
      if (v.type === 'DOCUMENT' && !v.file) ready = false;
    });
    selectedBodyVariables.forEach((v) => {
      if (v.type === 'TEXT' && !v.text) ready = false;
    });
    setVariablesReady(ready);
  }, [selectedHeaderVariables, selectedBodyVariables]);

  const handleNextStep = () => {
    if (stepIndex < stepCount) setStepIndex(stepIndex + 1);
  };

  const handlePreviousStep = () => {
    if (stepIndex > 0) setStepIndex(stepIndex - 1);
  };

  const handleSendTemplate = async () => {
    setIsSending(true);

    if (!selectedChatbotId || !selectedIntegrationId || !selectedRecipient)
      return;

    const headerVariables = selectedHeaderVariables.map((v) => {
      if (v.type === 'TEXT') return v.text;
      return {
        type: 'id',
        media: v?.id,
      };
    });

    const bodyVariables = selectedBodyVariables.map((v) => {
      return v?.text;
    });

    const template: any = { ...selectedTemplate };
    template.headerValues = headerVariables;
    template.bodyValues = bodyVariables;

    const response = await sendTemplate(
      selectedChatbotId,
      selectedIntegrationId,
      selectedRecipient,
      template,
      endUserId,
    );

    if (response.data?.success) {
      toast.success('Plantilla enviada correctamente');
    } else {
      toast.error('Error al enviar la plantilla');
    }

    setIsSending(false);
    setStepIndex(0);
    onClose();
  };

  if (!selectedChatbotId || !selectedIntegrationId || !selectedRecipient)
    return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={STEP_TITLES[stepIndex]}
      isLarge
    >
      {stepIndex === 0 ? (
        <TemplateSelectStep
          chatbotId={selectedChatbotId}
          integrationId={selectedIntegrationId}
          canNext={selectedTemplate !== undefined}
          onNext={handleNextStep}
          onTemplateChange={(templates: Template[]) =>
            setSelectedTemplate(templates[0] ?? undefined)
          }
        />
      ) : stepIndex === 1 && selectedTemplate ? (
        <TemplateVariablesStep
          template={selectedTemplate}
          headerVariables={selectedHeaderVariables}
          bodyVariables={selectedBodyVariables}
          chatbotId={selectedChatbotId}
          integrationId={selectedIntegrationId}
          canNext={variablesReady}
          onNext={handleNextStep}
          onBack={handlePreviousStep}
          setHeaderVariables={(vars: TemplateVariable[]) =>
            setSelectedHeaderVariables(vars)
          }
          setBodyVariables={(vars: TemplateVariable[]) =>
            setSelectedBodyVariables(vars)
          }
        />
      ) : stepIndex === 2 && selectedTemplate ? (
        <TemplateSendStep
          template={selectedTemplate}
          headerVariables={selectedHeaderVariables}
          bodyVariables={selectedBodyVariables}
          isSending={isSending}
          onBack={handlePreviousStep}
          onSend={handleSendTemplate}
        />
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default SendTemplateModal;
