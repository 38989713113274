import React, { useState, useEffect } from 'react';

import { Table } from 'ka-table';
import { DataType, EditingMode, SortingMode } from 'ka-table/enums';

import Tag from 'components/interface/tag';
import Modal from 'components/interface/modal';
import Checkbox from 'components/interface/checkbox';
import TagForm from 'features/dashboard/features/chatbots/forms/TagForm';
import TagActions from 'features/dashboard/features/chatbots/components/tagActions';
import TagCreateButton from 'features/dashboard/features/chatbots/components/tagCreateButton';
import SettingsFields from 'components/interface/settingsFields';
import EmptyState from 'components/interface/emptyState';

import { useUser } from 'features/user/hooks/useUser';

import { type ChatbotTag } from 'features/chatbots/providers/ChatbotProvider';
import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';

import 'ka-table/style.css';

type TagsViewProps = {
  chatbotData: ChatbotData;
};

const TagsView: React.FC<TagsViewProps> = ({ chatbotData }) => {
  const { userHasPermission } = useUser();
  const [columns, setColumns] = useState<any[]>([]);
  const [openTag, setOpenTag] = useState<ChatbotTag | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const columns: any[] = [
      {
        key: 'name',
        title: `Etiqueta`,
        dataType: DataType.Object,
      },
      {
        key: 'isAuto',
        title: `Automática`,
        style: { textAlign: 'center' },
        dataType: DataType.Boolean,
      },
      {
        key: 'notifications',
        title: `Notificaciones`,
        style: { textAlign: 'center' },
        dataType: DataType.Boolean,
      },
      {
        key: 'isConversion',
        title: `Señala Conversión`,
        style: { textAlign: 'center' },
        dataType: DataType.Boolean,
      },
    ];
    if (
      userHasPermission('bots.all.update') ||
      userHasPermission(`bots.${chatbotData.id}.update`)
    ) {
      columns.push({
        key: 'actions',
        title: '',
        width: '72',
        dataType: DataType.String,
      });
    }
    setColumns(columns);
  }, []);

  useEffect(() => {
    if (!isModalOpen) setOpenTag(null);
  }, [isModalOpen]);

  const handleOpenModal = (tag?: ChatbotTag) => {
    setOpenTag(tag ?? null);
    setIsModalOpen(true);
  };

  const TagTable = () => {
    const chatbotTags: { [key: string]: Object }[] = [];
    Object.keys(chatbotData.tags).forEach((key: string) => {
      chatbotTags.push({
        name: {
          text: chatbotData.tags[key].name,
          color: chatbotData.tags[key].color,
        },
        isConversion: chatbotData.tags[key].isConversion,
        isAuto: chatbotData.tags[key].isAuto,
        notifications: chatbotData.tags[key].notifications,
        actions: key,
      });
    });

    return (
      <>
        <Table
          columns={columns}
          data={chatbotTags}
          rowKeyField={'actions'}
          editingMode={EditingMode.None}
          sortingMode={SortingMode.Single}
          childComponents={{
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case 'name':
                    return <Tag {...props.value} />;
                  case 'isConversion':
                  case 'isAuto':
                  case 'notifications':
                    return (
                      <Checkbox
                        defaultChecked={props.value}
                        isDisabled={true}
                      />
                    );
                  case 'actions':
                    return (
                      <TagActions
                        id={props.value}
                        callback={() =>
                          handleOpenModal(chatbotData.tags[props.value])
                        }
                      />
                    );
                  default:
                    return props.value;
                }
              },
            },
          }}
        />
        {chatbotTags.length === 0 && (
          <EmptyState text={'No hay etiquetas'} iconName={'tag'} />
        )}
      </>
    );
  };

  const fields = {
    tags: {
      label: 'Etiquetas inteligentes',
      description:
        'Crea etiquetas inteligentes para identificar conversaciones de manera más efectiva, estás pueden ser asignadas manualmente o automáticamente por tu asistente por medio de instrucciones claras sobre cuándo las debe asignar.',
      options: [
        <TagCreateButton
          chatbotId={chatbotData.id}
          callback={() => {
            setOpenTag(null);
            handleOpenModal();
          }}
        />,
      ],
      component: <TagTable />,
    },
  };

  return (
    <>
      <SettingsFields fields={fields} />
      {userHasPermission('bots.all.update') ||
      userHasPermission(`bots.${chatbotData.id}.update`) ? (
        <Modal
          isOpen={isModalOpen}
          title={openTag ? 'Editar etiqueta' : 'Crear etiqueta'}
          onClose={() => setIsModalOpen(false)}
        >
          <TagForm
            chatbotId={chatbotData.id}
            tag={openTag}
            onSubmitCallback={() => {
              setIsModalOpen(false);
            }}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default TagsView;
