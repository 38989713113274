import React from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import './styles.css';

/**
 * InboxThreadsLoading
 * @description Component to display inbox threads loading
 * @returns {TSX.Element} InboxThreadsLoading component
 */

const InboxThreadsLoading: React.FC = () => {
  return (
    <div className={'inbox-threads-empty'}>
      <Icon name={'tray'} color={'var(--gray-2)'} size={20} />
      <Text variant={'b3'} color={'var(--gray-2)'}>
        No hay conversaciones
      </Text>
    </div>
  );
};

export default InboxThreadsLoading;
