import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Input from 'components/interface/input';
import Button from 'components/interface/button';
import Toggle from 'components/interface/toggle';
import SettingsFields from 'components/interface/settingsFields';

import { useUser } from 'features/user/hooks/useUser';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type SettingFields } from 'components/interface/settingsFields';
import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';

type FormInputs = {
  nickname: string;
  threshold: number;
  isActive: boolean;
};

type GeneralFormProps = {
  chatbotData: ChatbotData;
};

const GeneralForm: React.FC<GeneralFormProps> = ({ chatbotData }) => {
  const { userHasPermission } = useUser();
  const { updateChatbotGeneralData, deactivateChatbot } = useChatbot();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>();

  const isAccessible =
    userHasPermission('bots.all.update') ||
    userHasPermission(`bots.${chatbotData.id}.update`);

  const canDeactivateChatbot =
    userHasPermission('bots.all.delete') ||
    userHasPermission(`bots.${chatbotData.id}.delete`);

  const handleDeactivateChatbot = async () => {
    const response = await deactivateChatbot(chatbotData.id);
    if (response.status === 200) {
      toast.success('Asistente eliminado correctamente');
    } else {
      toast.error('Error al eliminar el asistente');
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await updateChatbotGeneralData(
      chatbotData.id,
      data.nickname,
      data.threshold,
      data.isActive,
    );

    if (response?.errors) {
      if (response.errors?.nickname) {
        setError('nickname', {
          type: 'manual',
          message: response.errors?.nickname,
        });
      }
      if (response.errors?.threshold) {
        setError('threshold', {
          type: 'manual',
          message: response.errors?.threshold,
        });
      }
      if (response.errors?.isActive) {
        setError('isActive', {
          type: 'manual',
          message: response.errors?.isActive,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar los parametros');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Parametros actualizados correctamente');
      } else {
        toast.error('Error al actualizar los parametros');
        reset();
      }
    }
    setIsLoading(false);
  };

  const fields: SettingFields = {
    id: {
      label: 'Assistant ID',
      description:
        'El ID del asistente es único y no puede ser modificado. Se utiliza para identificarlo en procesos internos y la API.',
      component: (
        <Input
          placeholder={'ID'}
          defaultValue={chatbotData.id}
          disabled={true}
        />
      ),
    },
    nickname: {
      label: 'Nombre del asistente',
      description:
        'El nombre del asistente será visible dentro y fuera de la plataforma.',
      component: (
        <Input
          placeholder={'Nombre'}
          defaultValue={chatbotData.nickname}
          isDisabled={!isAccessible}
          error={errors?.nickname?.message}
          {...register('nickname')}
        />
      ),
    },
    threshold: {
      label: 'Umbral de conversación',
      description:
        'Este valor define el límite de tiempo (en minutos) entre mensajes para ser considerados como parte de una misma conversación. Recomendamos cualquier valor entre 5 y 20 minutos.',
      component: (
        <Input
          type={'number'}
          placeholder={'15'}
          defaultValue={chatbotData.threshold}
          minValueValidation={1}
          maxValueValidation={30}
          isDisabled={!isAccessible}
          error={errors?.threshold?.message}
          {...register('threshold')}
        />
      ),
    },
    deleteChatbot: {
      label: 'Eliminar asistente',
      description:
        'Al eliminar el asistente, se eliminarán todos los datos asociados a él (incluyendo toda su configuración, conversaciones, contactos, etc). Recomendamos editar este asistente en lugar de eliminarlo y crear uno nuevo.',
      component: (
        <div style={{ display: 'flex' }}>
          <Button
            label={'Eliminar'}
            variant={'danger'}
            requiresConfirmation={true}
            confirmationTitle={'Eliminar asistente'}
            confirmationText={
              '¿Estás seguro de que deseas eliminar este asistente?'
            }
            isDisabled={!canDeactivateChatbot}
            onClick={handleDeactivateChatbot}
          />
        </div>
      ),
    },
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SettingsFields fields={fields} />
      {isAccessible ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
          <Button
            label={'Cancelar'}
            variant={'secondary'}
            onClick={() => reset()}
          />
          <Button
            type={'submit'}
            label={'Guardar'}
            variant={'primary'}
            isLoading={isLoading}
          />
        </div>
      ) : null}
    </form>
  );
};

export default GeneralForm;
