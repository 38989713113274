import React, { useState } from 'react';

import Icon from 'components/interface/icon';
import Button from 'components/interface/button';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';

type CampaignContactsDownloadProps = {
  campaignId: string;
};

/**
 * CampaignContactsDownload
 * @description Button that opens a modal to download contacts to a campaign
 * @param {string} campaignId - Campaign id
 * @returns {TSX.Element} CampaignContactsDownload component
 */

const CampaignContactsDownload: React.FC<CampaignContactsDownloadProps> = ({
  campaignId,
}) => {
  const { exportCampaignContactsFile } = useCampaign();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDownload = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await exportCampaignContactsFile(campaignId);
    setIsLoading(false);
  };

  return (
    <>
      <Button
        label={'Descargar'}
        variant={'secondary'}
        icon={<Icon name={'download'} />}
        isLoading={isLoading}
        onClick={handleDownload}
      />
    </>
  );
};

export default CampaignContactsDownload;
