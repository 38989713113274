import React from 'react';

import './styles.css';

/**
 * InboxThreadSkeleton
 * @description Component to display thread in threads section
 * @returns {TSX.Element} InboxThreadSkeleton component
 */

const InboxThreadSkeleton: React.FC = () => {
  return (
    <div className={'inbox-thread-skeleton'}>
      <div className={'inbox-thread-skeleton-header'}>
        <div className={'inbox-thread-skeleton-picture skeleton-loader'} />
        <div className={'inbox-thread-skeleton-header-info'}>
          <div className={'inbox-thread-skeleton-chatbot skeleton-loader'} />
          <div className={'inbox-thread-skeleton-enduser skeleton-loader'} />
        </div>
        <div className={'inbox-thread-skeleton-header-updates'}>
          <div
            className={
              'inbox-thread-skeleton-header-updates-time skeleton-loader'
            }
          />
          <div
            className={
              'inbox-thread-skeleton-header-updates-unread skeleton-loader'
            }
          />
        </div>
      </div>
      <div className={'inbox-thread-skeleton-body'}>
        <div className={'skeleton-loader'} />
        <div className={'skeleton-loader'} />
      </div>
      <div className={'inbox-thread-skeleton-tags'}>
        <div className={'skeleton-loader'} />
        <div className={'skeleton-loader'} />
      </div>
    </div>
  );
};

export default InboxThreadSkeleton;
