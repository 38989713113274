import React, { useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import InboxSidebar from 'features/inbox/components/sidebar';
import InboxThreads from 'features/inbox/components/threads';
import InboxConversation from 'features/inbox/components/conversation';
import InboxDetails from 'features/inbox/components/details';

import { useInbox } from 'features/inbox/hooks/useInbox';
import { useOrganization } from 'features/organization/hooks/useOrganization';

import 'features/inbox/styles/index.css';

const InboxView = () => {
  const {
    inbox,
    inboxFilters,
    updateInboxStatus,
    getThreads,
    recieveMessage,
    recieveMessageUpdate,
    recieveThreadUpdate,
  } = useInbox();
  const { organizationData } = useOrganization();

  const websocketUrl = `${process.env.REACT_APP_WEST_INBOX_WS_URL}/${organizationData.id}/`;
  const { lastJsonMessage, readyState } = useWebSocket(websocketUrl, {
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: 3000,
    share: true,
  });

  useEffect(() => {
    const handleGetThreads = async () => {
      await getThreads(true);
    };
    handleGetThreads();
  }, [inboxFilters]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      updateInboxStatus(true, false);
    } else if (readyState === ReadyState.CONNECTING) {
      updateInboxStatus(false, true);
    } else {
      updateInboxStatus(false, false);
    }
  }, [readyState]);

  useEffect(() => {
    if (lastJsonMessage?.type === 'chat_message')
      recieveMessage(lastJsonMessage);
    if (lastJsonMessage?.type === 'chat_message_update')
      recieveMessageUpdate(lastJsonMessage);
    if (lastJsonMessage?.type === 'chat_thread_update')
      recieveThreadUpdate(lastJsonMessage);
  }, [lastJsonMessage]);

  return (
    <>
      <div className={'inbox-page'}>
        <InboxSidebar />
        <InboxThreads />
        <InboxConversation />
        {inbox.activeThread && <InboxDetails />}
      </div>
    </>
  );
};

export default InboxView;
