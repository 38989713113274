import React from 'react';

import IconButton from 'components/interface/iconButton';

import { useUser } from 'features/user/hooks/useUser';
import { useOrganization } from 'features/organization/hooks/useOrganization';

type MemberActionsProps = {
  id: string;
  email: string;
  displayName: string;
  profilePicture: string;
  role: string;
  callback: (id: string) => void;
};

const MemberActions: React.FC<MemberActionsProps> = ({
  id,
  email,
  role,
  callback,
}) => {
  const { userDisplayData, userHasPermission } = useUser();
  const { organizationData } = useOrganization();

  const isSelf = userDisplayData?.email === email;
  const isOwner = organizationData.roles?.[role]?.name === 'Owner';
  const isAccessible =
    userHasPermission('organization.update') && !isOwner && !isSelf;

  if (!isAccessible) return null;

  return (
    <IconButton
      iconName={'gear'}
      hint={'Editar'}
      onClick={() => callback(id)}
      isDisabled={!isAccessible}
    />
  );
};

export default MemberActions;
