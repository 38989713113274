import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { UUID } from 'crypto';

import TabMenu from 'components/interface/tabMenu';
import DetailView from 'features/dashboard/components/detailView';
import GeneralForm from 'features/dashboard/features/chatbots/forms/GeneralForm';
import BehaviorForm from 'features/dashboard/features/chatbots/forms/BehaviorForm';
import KnowledgeSourceView from '../features/chatbots/views/KnowledgeSourceView';
import SendableMediaView from 'features/dashboard/features/chatbots/views/SendableMediaView';
import TagsView from 'features/dashboard/features/chatbots/views/TagsView';
import AssigneesView from 'features/dashboard/features/chatbots/views/AssigneesView';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type IconName } from 'components/interface/icon';
import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';

const ChatbotView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: UUID }>();
  const { chatbotsData } = useChatbot();

  const [chatbotData, setChatbotData] = useState<ChatbotData>(
    chatbotsData[id as UUID],
  );

  useEffect(() => {
    if (id === undefined) return;

    const chatbot = chatbotsData[id];
    if (chatbot === undefined) {
      navigate('/assistants');
      return;
    }

    setChatbotData(chatbot);
  }, [id, chatbotsData, navigate]);

  const tabs = {
    general: {
      label: 'General',
      iconName: 'robot' as IconName,
      component: <GeneralForm chatbotData={chatbotData} />,
    },
    behavior: {
      label: 'Comportamiento',
      iconName: 'lightbulb' as IconName,
      component: <BehaviorForm chatbotData={chatbotData} />,
    },
    knowledgeSource: {
      label: 'Base de Conocimiento',
      iconName: 'books' as IconName,
      component: <KnowledgeSourceView chatbotData={chatbotData} />,
    },
    media: {
      label: 'Archivos',
      iconName: 'images' as IconName,
      component: <SendableMediaView chatbotData={chatbotData} />,
    },
    tags: {
      label: 'Etiquetas',
      iconName: 'tag' as IconName,
      component: <TagsView chatbotData={chatbotData} />,
    },
    assignees: {
      label: 'Asignación',
      iconName: 'users' as IconName,
      component: <AssigneesView chatbotData={chatbotData} />,
    },
  };

  return (
    <div className={'dashboard-page'}>
      <DetailView title={`${chatbotData?.nickname} (${chatbotData?.name})`}>
        <TabMenu tabs={tabs} />
      </DetailView>
    </div>
  );
};

export default ChatbotView;
