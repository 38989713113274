import React, { useState, useEffect } from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import Image from 'components/interface/image';

import {
  type Template,
  type TemplateComponent,
  type TemplateButton,
  type TemplateVariable,
} from 'features/templates/types/templateTypes';

import './styles.css';

type TemplatePreviewProps = {
  templateData: Template;
  headerVariables?: TemplateVariable[];
  bodyVariables?: TemplateVariable[];
};

const TemplatePreview: React.FC<TemplatePreviewProps> = ({
  templateData,
  headerVariables,
  bodyVariables,
}) => {
  const [headerComponent, setHeaderComponent] =
    useState<TemplateComponent | null>(null);
  const [bodyComponent, setBodyComponent] = useState<TemplateComponent | null>(
    null,
  );
  const [footerComponent, setFooterComponent] =
    useState<TemplateComponent | null>(null);
  const [buttons, setButtons] = useState<TemplateButton[]>([]);

  const [originalHeaderText, setOriginalHeaderText] = useState<string>('');
  const [originalBodyText, setOriginalBodyText] = useState<string>('');

  useEffect(() => {
    const templateComponents = templateData?.components;

    const componentHeader =
      templateComponents?.find((component) => component.type === 'HEADER') ??
      null;
    const componentBody =
      templateComponents?.find((component) => component.type === 'BODY') ??
      null;
    const componentFooter =
      templateComponents?.find((component) => component.type === 'FOOTER') ??
      null;
    const componentButtons =
      templateComponents?.find((component) => component.type === 'BUTTONS')
        ?.buttons ?? [];

    setHeaderComponent(componentHeader);
    setBodyComponent(componentBody);
    setFooterComponent(componentFooter);
    setButtons(componentButtons);

    if (componentHeader?.format === 'TEXT') {
      setOriginalHeaderText(componentHeader?.text ?? '');
    }
    setOriginalBodyText(componentBody?.text ?? '');
  }, [templateData]);

  useEffect(() => {
    if (headerComponent) {
      const headerComponentAux = { ...headerComponent };
      if (headerComponent?.format === 'TEXT') {
        let headerTextAux = originalHeaderText;
        headerVariables?.forEach((v: TemplateVariable, i) => {
          if (headerComponent?.example?.header_text_named_params) {
            headerTextAux = headerTextAux.replace(
              `{{${headerComponent?.example?.header_text_named_params?.[i]?.param_name}}}`,
              v?.text?.length ?? 0 > 0
                ? v?.text ?? ''
                : `{{${headerComponent?.example?.header_text_named_params?.[i]?.param_name}}}`,
            );
          } else if (headerComponent?.example?.header_text) {
            headerTextAux = headerTextAux.replace(
              `{{${i + 1}}}`,
              v?.text?.length ?? 0 > 0 ? v?.text ?? '' : `{{${i + 1}}}`,
            );
          }
        });
        headerComponentAux.text = headerTextAux;
        setHeaderComponent(headerComponentAux);
      }
    }
    if (bodyComponent?.text) {
      const bodyComponentAux = { ...bodyComponent };
      let bodyTextAux = originalBodyText;
      bodyVariables?.forEach((v, i) => {
        if (bodyComponent?.example?.body_text_named_params) {
          bodyTextAux = bodyTextAux.replace(
            `{{${bodyComponent?.example?.body_text_named_params?.[i]?.param_name}}}`,
            v?.text?.length ?? 0 > 0
              ? v?.text ?? ''
              : `{{${bodyComponent?.example?.body_text_named_params?.[i]?.param_name}}}`,
          );
        } else if (bodyComponent?.example?.body_text) {
          bodyTextAux = bodyTextAux.replace(
            `{{${i + 1}}}`,
            v?.text?.length ?? 0 > 0 ? v?.text ?? '' : `{{${i + 1}}}`,
          );
        }
      });
      bodyComponentAux.text = bodyTextAux;
      setBodyComponent(bodyComponentAux);
    }
  }, [
    headerComponent?.type,
    bodyComponent?.type,
    headerVariables,
    bodyVariables,
  ]);

  return (
    <div className={'template-preview-background'}>
      <div className={'template-preview-card'}>
        {headerComponent && (
          <div className={'template-preview-card__header'}>
            {headerComponent.format === 'IMAGE' && (
              <div>
                {headerVariables?.[0]?.src ? (
                  <Image src={headerVariables?.[0]?.src} />
                ) : (
                  <div className={'template-preview-card__header__icon'}>
                    <Icon name={'image'} size={42} color={'var(--gray)'} />
                  </div>
                )}
              </div>
            )}
            {headerComponent.format === 'DOCUMENT' && (
              <div>
                {headerVariables?.[0]?.src ? (
                  <div className={'template-preview-card__header__file'}>
                    <Icon name={'file'} size={32} color={'var(--gray)'} />
                    <Text
                      variant={'b3'}
                      color={'var(--gray)'}
                      numberOfLines={2}
                    >
                      {headerVariables?.[0]?.file?.name}
                    </Text>
                  </div>
                ) : (
                  <div className={'template-preview-card__header__icon'}>
                    <Icon name={'file'} size={42} color={'var(--gray)'} />
                  </div>
                )}
              </div>
            )}
            {headerComponent.format === 'TEXT' && (
              <Text
                variant={'b2'}
                weight={500}
                style={{ padding: 4, paddingBottom: 0 }}
                numberOfLines={2}
              >
                {headerComponent?.text}
              </Text>
            )}
          </div>
        )}
        {bodyComponent && (
          <div className={'template-preview-card__body'}>
            <Text variant={'b2'} numberOfLines={3}>
              {bodyComponent.text}
            </Text>
          </div>
        )}
        {footerComponent && (
          <div className={'template-preview-card__footer'}>
            <Text variant={'s2'}>{footerComponent?.text}</Text>
          </div>
        )}
      </div>
      {buttons.length > 0 && (
        <div className={'template-preview-buttons'}>
          {buttons.map((button: TemplateButton, i) => {
            return (
              <div key={i} className={'template-preview-button'}>
                <Text
                  variant={'b2'}
                  align={'center'}
                  color={'var(--primary)'}
                  numberOfLines={1}
                >
                  {button.text}
                </Text>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TemplatePreview;
