import api from 'api';

import { UUID } from 'crypto';

/**
 * fetchThreads
 * @description Function that fetches chatbot threads
 * @param {UUID[]} chatbotId
 * @param {string[]} tagIds
 * @param {string} orderBy
 * @param {string[]} integrationChannels
 * @param {boolean} showUnreplied
 * @param {boolean} showUnreadOnly
 * @param {boolean} showAssignedOnly
 * @param {string} search
 * @param {number} page
 * @param {AbortController.signal} signal
 * @returns {Object} { status } | { status, ...errors }
 */

export const fetchThreads = async (
  chatbotIds: UUID[],
  tagIds: string[],
  orderBy: string,
  integrationChannels: string[],
  showUnreplied: boolean,
  showUnreadOnly: boolean,
  showAssignedOnly: boolean,
  search: string,
  page: number = 0,
  signal?: AbortSignal,
) => {
  const response = await api.get(
    `${process.env.REACT_APP_WEST_BASE_URL}/inbox/threads/?botIds=${chatbotIds}&tagIds=${tagIds}&integrationChannels=${integrationChannels}&orderBy=${orderBy}&showUnreplied=${showUnreplied}&showUnreadOnly=${showUnreadOnly}&showAssignedOnly=${showAssignedOnly}&search=${search}&page=${page}`,
    { signal },
  );
  return { status: 200, data: response?.data };
};

/**
 * fetchThreadMessages
 * @description Function that fetches a chatbot thread's messages
 * @param {number} threadId
 * @param {AbortController.signal} signal
 * @returns {Object} { status } | { status, ...errors }
 */

export const fetchThreadMessages = async (
  threadId: number,
  signal?: AbortSignal,
) => {
  const response = await api.get(
    `${process.env.REACT_APP_WEST_BASE_URL}/inbox/threads/messages/?threadId=${threadId}`,
    { signal },
  );
  return { status: 200, data: response?.data };
};

/**
 * fetchMessageMediaBinary
 * @description Function that fetches a message's media binary data
 * @param {UUID} chatbotId
 * @param {string} integrationId
 * @param {string} mediaId
 * @returns {Object} { stats, data } | { status, ...errors }
 */

export const fetchMessageMediaBinary = async (
  chatbotId: UUID,
  integrationId: string,
  mediaId: string,
) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/inbox/messages/media/?botId=${chatbotId}&integrationId=${integrationId}&mediaId=${mediaId}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * fetchCallRecording
 * @description Function that fetches a call recording
 * @param {string} callId
 * @returns {Object} { status, data: { url } } | { status, ...errors }
 */

export const fetchCallRecording = async (callId: string) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/inbox/calls/recording/?callId=${callId}`,
      { responseType: 'blob' },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postMessageMedia
 * @description Function that posts a message's media
 * @param {FormData} formData
 * @returns {Object} { status, data: { sucess, mediaId, name } } | { status, ...errors }
 */

export const postMessageMedia = async (formData: FormData) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/inbox/messages/media/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postAssignThreadTag
 * @description Function that assigns a tag to a thread
 * @param {number} threadId
 * @param {string} tagId
 * @returns {Object} { status } | { status, ...errors }
 */

export const postAssignThreadTag = async (threadId: number, tagId: string) => {
  try {
    const response = await api.post(
      `${process.env.REACT_APP_WEST_BASE_URL}/inbox/threads/tag/assign/`,
      {
        threadId,
        tagId,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postUnassignThreadTag
 * @description Function that unassigns a tag from a thread
 * @param {number} threadId
 * @param {string} tagId
 * @returns {Object} { status } | { status, ...errors }
 */

export const postUnassignThreadTag = async (
  threadId: number,
  tagId: string,
) => {
  try {
    const response = await api.post(
      `${process.env.REACT_APP_WEST_BASE_URL}/inbox/threads/tag/unassign/`,
      {
        threadId,
        tagId,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postThreadEndUserInfo
 * @description Function that ends a thread and saves user info
 * @param {number} threadId
 * @param {string} info
 * @returns {Object} { status } | { status, ...errors }
 */

export const postThreadEndUserInfo = async (threadId: number, info: string) => {
  try {
    const response = await api.post(
      `${process.env.REACT_APP_WEST_BASE_URL}/inbox/threads/enduser/info/`,
      {
        threadId,
        info,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postThreadChatbotToggle
 * @description Function that toggles a thread's chatbot automatic replies
 * @param {number} threadId
 * @returns {Object} { status, active } | { status, ...errors }
 */

export const postThreadChatbotToggle = async (threadId: number) => {
  try {
    const response = await api.post(
      `${process.env.REACT_APP_WEST_BASE_URL}/inbox/threads/chatbot/toggle/`,
      {
        threadId,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postSendMessage
 * @description Function that sends a message
 * @param {UUID} botId - The chatbot id
 * @param {number} threadId - The thread id
 * @param {string} type - The message type
 * @param {string} content - message content or nuññ
 * @param {string} media - media id or null
 * @returns {Object} { status } | { status, ...errors }
 */

export const postSendMessage = async (
  botId: UUID,
  threadId: number,
  type: string,
  content: string | null,
  media: string | null,
) => {
  try {
    const payload: {
      botId: UUID;
      threadId: number;
      type: string;
      content?: string;
      media?: string;
    } = {
      botId,
      threadId,
      type,
    };

    if (content !== null) {
      payload.content = content;
    }
    if (media !== null) {
      payload.media = media;
    }

    const response = await api.post(
      `${process.env.REACT_APP_WEST_BASE_URL}/inbox/messages/send/`,
      payload,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postSendTemplate
 * @description Function that sends a template
 * @param {UUID} botId - The chatbot id
 * @param {string} integrationId - The integration id
 * @param {string} recipient - The recipient contact (phone number)
 * @param {Template} template - The template json object
 * @param {UUID} endUserId - The end user id
 * @returns {Object} { status } | { status, ...errors }
 */

export const postSendTemplate = async (
  botId: UUID,
  integrationId: string,
  recipient: string,
  template: any,
  endUserId?: UUID,
) => {
  try {
    const response = await api.post(
      `${process.env.REACT_APP_WEST_BASE_URL}/inbox/messages/send/template/`,
      {
        botId,
        integrationId,
        recipient,
        template,
        endUserId,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postMessageRead
 * @description Function that marks a message as read
 * @param {UUID} botId - The chatbot id
 * @param {number} id - The message id
 * @returns {Object} { status } | { status, ...errors }
 */

export const postMessageRead = async (id: number, botId: UUID) => {
  try {
    const response = await api.post(
      `${process.env.REACT_APP_WEST_BASE_URL}/inbox/messages/read/`,
      {
        id,
        botId,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postThreadAssign
 * @description Function that assigns a thread to a user
 * @param {number} threadId
 * @param {array} assignees
 * @returns {Object} { status } | { status, ...errors }
 */

export const postThreadAssign = async (
  threadId: number,
  assignees: string[],
) => {
  try {
    const response = await api.post(
      `${process.env.REACT_APP_WEST_BASE_URL}/inbox/threads/assign/`,
      {
        threadId,
        assignees,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};
