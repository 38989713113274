import React from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';

import { type CallAnsweredBy } from 'features/calls/types';

import { callAnsweredByTranslation } from 'features/calls/translations';

import { CallAnsweredByEnum } from 'features/calls/enums';

import { type IconName } from 'components/interface/icon';

import './styles.css';

type CampaignCallAnsweredByProps = {
  answeredBy: CallAnsweredBy;
};

/**
 * CampaignCallAnsweredBy
 * @description Component that displays the answered by of a call
 * @param {string} answeredBy - Type of the call
 * @returns {TSX.Element} CampaignCallAnsweredBy component
 */

const CampaignCallAnsweredBy: React.FC<CampaignCallAnsweredByProps> = ({
  answeredBy,
}) => {
  const answeredByIcon: Record<CallAnsweredBy, IconName> = {
    [CallAnsweredByEnum.HUMAN]: 'user',
    [CallAnsweredByEnum.MACHINE]: 'voicemail',
    [CallAnsweredByEnum.UNKNOWN]: 'question',
  };

  return (
    <div className="campaign-call-answered-by">
      <Icon name={answeredByIcon[answeredBy]} color={'var(--gray)'} />
      <Text variant={'b3'} color={'var(--gray)'} numberOfLines={1}>
        {callAnsweredByTranslation[answeredBy]}
      </Text>
    </div>
  );
};

export default CampaignCallAnsweredBy;
