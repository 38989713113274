import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import { UUID } from 'crypto';

import Text from 'components/interface/text';
import Input from 'components/interface/input';
import Toggle from 'components/interface/toggle';
import Select from 'components/interface/select';
import Button from 'components/interface/button';
import TextArea from 'components/interface/textArea';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type ChatbotTag } from 'features/chatbots/providers/ChatbotProvider';

type FormInputs = {
  name: string;
  color: string;
  trigger: string;
  isConversion: boolean;
  isAuto: boolean;
  instructions: string;
  notifications: boolean;
};

type TagFormProps = {
  chatbotId: UUID;
  tag?: ChatbotTag | null;
  onSubmitCallback: () => void;
};

const TagForm: React.FC<TagFormProps> = ({
  chatbotId,
  tag,
  onSubmitCallback,
}) => {
  const { createChatbotTag, updateChatbotTag, eliminateChatbotTag } =
    useChatbot();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormInputs>();

  const triggerValue = watch('trigger');

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    // Send request to API conditional to create or update
    const response = tag
      ? await updateChatbotTag(
          chatbotId,
          tag.id,
          data.name,
          data.color,
          data.trigger,
          data.isConversion,
          data.isAuto,
          data.instructions,
          data.notifications,
        )
      : await createChatbotTag(
          chatbotId,
          data.name,
          data.color,
          data.trigger,
          data.isConversion,
          data.isAuto,
          data.instructions,
          data.notifications,
        );

    // Manage response errors or success
    if (response?.errors) {
      if (response.errors?.name) {
        setError('name', {
          type: 'manual',
          message: response.errors.name,
        });
      }
      if (response.errors?.color) {
        setError('color', {
          type: 'manual',
          message: response.errors.color,
        });
      }
      if (response.errors?.trigger) {
        setError('trigger', {
          type: 'manual',
          message: response.errors.trigger,
        });
      }
      if (response.errors?.isConversion) {
        setError('isConversion', {
          type: 'manual',
          message: response.errors.isConversion,
        });
      }
      if (response.errors?.isAuto) {
        setError('isAuto', {
          type: 'manual',
          message: response.errors.isAuto,
        });
      }
      if (response.errors?.instructions) {
        setError('instructions', {
          type: 'manual',
          message: response.errors.instructions,
        });
      }
      if (response.errors?.notifications) {
        setError('notifications', {
          type: 'manual',
          message: response.errors.notifications,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar la etiqueta');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Etiqueta guardada correctamente');
      } else {
        toast.error('Error al guardar la etiqueta');
        reset();
      }
      onSubmitCallback();
    }
    setIsLoading(false);
  };

  const onDelete = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const response = await eliminateChatbotTag(chatbotId, tag?.id ?? '');
    if (response?.errors) {
      response.errors?.non_field_errors.map((error: string) => {
        toast.error(error);
      });
    } else {
      if (response?.data?.success) {
        toast.success('La etiqueta se eliminó correctamente');
      } else {
        toast.error('Error al eliminar la etiqueta');
      }
      onSubmitCallback();
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: -16 }}>
      <Text variant={'s1'}>Nombre</Text>
      <Input
        placeholder={'Nombre de la etiqueta'}
        defaultValue={tag?.name ?? ''}
        error={errors.name?.message}
        {...register('name')}
      />
      <Text variant={'s1'}>Color</Text>
      <Select
        options={[
          { value: 'blue', label: 'Azul' },
          { value: 'red', label: 'Rojo' },
          { value: 'green', label: 'Verde' },
          { value: 'orange', label: 'Naranja' },
          { value: 'pink', label: 'Rosa' },
          { value: 'purple', label: 'Morado' },
          { value: 'yellow', label: 'Amarillo' },
        ]}
        placeholder={'Selecciona un color'}
        defaultValue={tag?.color ?? 'blue'}
        error={errors.color?.message}
        {...register('color')}
      />
      <Text variant={'s1'}>Detonador de asignación automática</Text>
      <Select
        options={[
          { value: 'instructions', label: 'Instrucciones' },
          { value: 'call_voicemail', label: 'Buzón de voz' },
          { value: 'call_hangup_no_answer', label: 'Contesta y cuelga' },
        ]}
        defaultValue={tag?.trigger ?? ''}
        error={errors.trigger?.message}
        {...register('trigger')}
      />
      {triggerValue === 'instructions' ||
      (!triggerValue && tag?.trigger === 'instructions') ||
      (!triggerValue && !tag?.trigger) ? (
        <>
          <Text variant={'s1'}>Instrucciones de asignación</Text>
          <TextArea
            placeholder={'Instrucciones para asignación automática'}
            defaultValue={tag?.instructions ?? ''}
            error={errors.instructions?.message}
            {...register('instructions')}
          />
        </>
      ) : null}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 16,
        }}
      >
        <Text variant={'s1'} style={{ margin: 0 }}>
          Asignación automática
        </Text>
        <Toggle
          defaultChecked={tag?.isAuto ?? false}
          error={errors.isAuto?.message}
          {...register('isAuto')}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 8,
        }}
      >
        <Text variant={'s1'} style={{ margin: 0 }}>
          Notificaciones
        </Text>
        <Toggle
          defaultChecked={tag?.notifications ?? false}
          error={errors.notifications?.message}
          {...register('notifications')}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 8,
        }}
      >
        <Text variant={'s1'} style={{ margin: 0 }}>
          Señala Conversión
        </Text>
        <Toggle
          defaultChecked={tag?.isConversion ?? false}
          error={errors.isConversion?.message}
          {...register('isConversion')}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
        {tag ? (
          <Button
            label={'Eliminar'}
            variant={'tertiary'}
            requiresConfirmation={true}
            confirmationTitle={'Eliminar etiqueta'}
            confirmationText={
              '¿Estás seguro que deseas eliminar esta etiqueta?'
            }
            onClick={onDelete}
          />
        ) : null}
        <Button
          label={'Cancelar'}
          variant={'secondary'}
          onClick={() => {
            onSubmitCallback();
            reset();
          }}
        />
        <Button
          type={'submit'}
          label={'Guardar'}
          variant={'primary'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default TagForm;
