import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ReactGA from 'react-ga4';
import { ToastContainer, Slide } from 'react-toastify';

import AppRouter from './routers/AppRouter';
import AuthProvider from 'features/auth/providers/AuthProvider';

import 'react-toastify/dist/ReactToastify.css';
import 'themes/index.css';

ReactGA.initialize(`${process.env.REACT_APP_GA_MEASUREMENT_ID}`);

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ hitType: 'page', page: location.pathname + location.search });
  }, [location]);

  return (
    <AuthProvider>
      <ToastContainer
        position={'top-center'}
        autoClose={2000}
        closeButton={false}
        transition={Slide}
        limit={1}
      />
      <AppRouter />
    </AuthProvider>
  );
}

export default App;
