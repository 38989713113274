import React, { useState, useEffect } from 'react';

import PageTitle from 'features/dashboard/components/pageTitle';
import PlanCard from 'features/dashboard/features/usage/components/planCard';
import PlanUsageCard from 'features/dashboard/features/usage/components/planUsageCard';
import PlanCycleChart from 'features/dashboard/features/usage/components/planCycleChart';

import { useOrganization } from 'features/organization/hooks/useOrganization';

import { type BillingCycle } from 'features/organization/providers/OrganizationProvider';

const Usage: React.FC = () => {
  const { organizationData, getBillingCycleDays } = useOrganization();

  const [cycles, setCycles] = useState<BillingCycle[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [canNext, setCanNext] = useState<boolean>(false);
  const [canPrev, setCanPrev] = useState<boolean>(false);

  useEffect(() => {
    let auxCycles =
      Object.values(organizationData.billing?.billingCycles) ?? [];

    auxCycles = auxCycles.sort((a, b) => {
      return (
        new Date(a.nextCycleDate).getTime() -
        new Date(b.nextCycleDate).getTime()
      );
    });

    setSelectedIndex(auxCycles.length - 1);
    setCycles(auxCycles);
  }, []);

  useEffect(() => {
    if (organizationData?.id && cycles[selectedIndex]?.id) {
      getBillingCycleDays(organizationData?.id, cycles[selectedIndex]?.id);
    }

    setCanNext(selectedIndex < cycles.length - 1);
    setCanPrev(selectedIndex > 0);
  }, [cycles, selectedIndex]);

  const handleNext = () => {
    if (selectedIndex < cycles.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handlePrev = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  return (
    <div className={'dashboard-page'}>
      <PageTitle title={'Plan y Uso'} iconName={'chartDonut'} />
      <div style={{ height: 24 }} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <div style={{ display: 'flex', gap: 16 }}>
          <PlanCard
            billing={organizationData.billing}
            cycle={cycles[selectedIndex]}
          />
          <PlanUsageCard
            billing={organizationData.billing}
            cycle={cycles[selectedIndex]}
          />
        </div>
        <PlanCycleChart
          cycle={cycles[selectedIndex]}
          canNext={canNext}
          canPrev={canPrev}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      </div>
    </div>
  );
};

export default Usage;
